import React, { createContext, useContext, useMemo, useState } from 'react';

interface HiddenEvent {
  eventId: string;
  timestamp: number;
  reason: string;
}

interface EventContextType {
  hiddenEvents: HiddenEvent[];
  setHiddenEvents: (events: HiddenEvent[]) => void;
  addToHiddenEvents: (eventId: string) => void;
  groupedEvents: Record<string, any>;
  setGroupedEvents: (events: Record<string, any>) => void;
  loadedEvents: any[];
  setLoadedEvents: (events: any[]) => void;
}

const EventContext = createContext<EventContextType>({
  hiddenEvents: [],
  setHiddenEvents: () => {},
  addToHiddenEvents: () => {},
  groupedEvents: {},
  setGroupedEvents: () => {},
  loadedEvents: [],
  setLoadedEvents: () => {}
});

export const EventProvider = ({ children }: { children: React.ReactNode }) => {
  const [hiddenEvents, setHiddenEvents] = useState<HiddenEvent[]>([]);
  const [groupedEvents, setGroupedEvents] = useState<Record<string, any>>({});
  const [loadedEvents, setLoadedEvents] = useState<any[]>([]);

  const addToHiddenEvents = (eventId: string) => {
    console.log('📢 EventContext: Adding to hidden events:', eventId);
    
    const newHiddenEvent = {
      eventId: eventId,
      timestamp: Date.now(),
      reason: 'dismissed'
    };

    try {
      // Nettoyer le localStorage d'abord
      const stored = JSON.parse(localStorage.getItem('hiddenEvents') || '[]');
      console.log('📢 Current stored hidden events:', stored);
      
      // Ne garder que les événements avec la bonne structure
      const cleanedEvents = stored.filter((event: any) => 
        event.eventId && event.reason && event.timestamp
      );
      
      // Ajouter le nouvel événement
      const updatedEvents = [...cleanedEvents, newHiddenEvent];
      
      localStorage.setItem('hiddenEvents', JSON.stringify(updatedEvents));
      setHiddenEvents(updatedEvents);
      
      window.dispatchEvent(new CustomEvent('hiddenEventsUpdated'));
    } catch (error) {
      console.error('Error updating hidden events:', error);
    }
  };

  const value = useMemo(() => ({
    hiddenEvents,
    setHiddenEvents,
    addToHiddenEvents,
    groupedEvents,
    setGroupedEvents,
    loadedEvents,
    setLoadedEvents
  }), [hiddenEvents, groupedEvents, loadedEvents]);

  return (
    <EventContext.Provider value={value}>
      {children}
    </EventContext.Provider>
  );
};

export const useEventContext = () => useContext(EventContext);

export {}; 