import { useMemo } from 'react';
import { Event } from '../types/event.types';
import { formatDate, isToday, isTomorrow, isWeekend } from '../utils/dateUtils';

interface FilterOptions {
  searchText: string;
  searchCity: string;
  selectedTags: string[];
  dateFilter: string[];
  timeFilter: string[];
  customDate?: string;
  distance?: number;
  userLocation?: { latitude: number; longitude: number };
}

export const useEventFilters = (events: Event[] | undefined, options: FilterOptions) => {
  const { 
    searchText, 
    searchCity, 
    selectedTags, 
    dateFilter, 
    timeFilter, 
    customDate,
    distance,
    userLocation 
  } = options;

  return useMemo(() => {
    if (!events) return [];
    
    console.log('🔍 Starting filtration with:', {
      searchText,
      searchCity,
      selectedTags,
      dateFilter,
      timeFilter,
      customDate
    });
    
    return events.filter(event => {
      const matchesSearch = searchText === '' || 
        event.title.toLowerCase().includes(searchText.toLowerCase()) ||
        event.description?.toLowerCase().includes(searchText.toLowerCase());
  
      const matchesCity = searchCity === '' ||
        event.location?.toLowerCase().includes(searchCity.toLowerCase());
  
      const matchesTags = selectedTags.length === 0 || 
        selectedTags.some(tag => {
          if (tag === 'favorites') {
            return localStorage.getItem(`favorite-${event.id}`) === 'true';
          }
          if (tag === 'free') {
            return !event.prices || 
                   event.prices.length === 0 || 
                   event.prices[0]?.amount === 0;
          }
          return event.tags?.includes(tag) || false;
        });

      const getEventDate = (event: Event): Date | null => {
        if (!event.datetime) return null;
        const date = new Date(event.datetime);
        return isNaN(date.getTime()) ? null : date;
      };

      let matchesDate = true;
      const eventDate = getEventDate(event);
      if (dateFilter.length > 0 && eventDate) {
        console.log('📅 Checking date for event:', event.title, {
          eventDate,
          dateFilter,
          customDate
        });
        
        matchesDate = dateFilter.some(filter => {
          if (filter.startsWith('custom_')) {
            const customDateStr = filter.split('_')[1];
            console.log('📅 Custom date comparison:', {
              customDateStr,
              eventDate: formatDate(eventDate)
            });
            return formatDate(eventDate) === customDateStr;
          }
          
          switch (filter) {
            case 'today':
              return isToday(eventDate);
            case 'tomorrow':
              return isTomorrow(eventDate);
            case 'weekend':
              return isWeekend(eventDate);
            default:
              return false;
          }
        });
      }
  
      let matchesTime = true;
      if (timeFilter.length > 0 && eventDate) {
        const eventHour = eventDate.getHours();
        matchesTime = timeFilter.some(filter => {
          switch (filter) {
            case 'morning':
              return eventHour >= 6 && eventHour < 12;
            case 'afternoon':
              return eventHour >= 12 && eventHour < 18;
            case 'evening':
              return eventHour >= 18 || eventHour < 6;
            default:
              return false;
          }
        });
      }

      // Filtre par distance
      let matchesDistance = true;
      if (distance && userLocation && event.latitude && event.longitude) {
        const eventDistance = calculateDistance(
          userLocation.latitude,
          userLocation.longitude,
          event.latitude,
          event.longitude
        );
        matchesDistance = eventDistance <= distance;
      }

      const matches = matchesSearch && matchesCity && matchesTags && matchesDate && matchesTime && matchesDistance;
      if (!matches) {
        console.log('❌ Event filtered out:', event.title, {
          matchesSearch,
          matchesCity,
          matchesTags,
          matchesDate,
          matchesTime,
          matchesDistance
        });
      }
  
      return matches;
    });
  }, [events, searchText, searchCity, selectedTags, dateFilter, timeFilter, customDate, distance, userLocation]);
};

// Fonction utilitaire pour calculer la distance (en km) entre deux points
function calculateDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
  const R = 6371; // Rayon de la Terre en km
  const dLat = (lat2 - lat1) * Math.PI / 180;
  const dLon = (lon2 - lon1) * Math.PI / 180;
  const a = 
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
    Math.sin(dLon/2) * Math.sin(dLon/2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  return R * c;
} 