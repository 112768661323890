import React, { useState } from 'react';
import LocationFilter from '../patterns/LocationFilter';

interface CodeSection {
  id: string;
  isCollapsed: boolean;
}

const LocationFilterSection: React.FC = () => {
  const [currentLocation, setCurrentLocation] = useState<{ latitude: number; longitude: number } | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [collapsedSections, setCollapsedSections] = useState<CodeSection[]>([
    { id: 'code', isCollapsed: true },
    { id: 'props', isCollapsed: true }
  ]);

  const toggleSection = (sectionId: string) => {
    setCollapsedSections(prev => prev.map(section => 
      section.id === sectionId 
        ? { ...section, isCollapsed: !section.isCollapsed }
        : section
    ));
  };

  const isSectionCollapsed = (sectionId: string) => {
    return collapsedSections.find(section => section.id === sectionId)?.isCollapsed ?? true;
  };

  const handleLocationChange = (location: { latitude: number; longitude: number }) => {
    setCurrentLocation(location);
  };

  return (
    <section className="component-section">
      <div className="control-type-container">
        <div className="control-group">
          <h4>Location Filter</h4>
          <p className="control-description">
            Filtre de localisation avec support de l'autocomplétion Google Places et de la géolocalisation.
            Permet la sélection précise d'un lieu ou l'utilisation de la position actuelle.
          </p>

          <div className="control-preview">
            <div className="preview-item">
              <h5>État par défaut</h5>
              <LocationFilter
                onLocationChange={handleLocationChange}
                isLoading={isLoading}
                currentLocation={currentLocation}
              />
            </div>

            <div className="preview-item">
              <h5>État de chargement</h5>
              <LocationFilter
                onLocationChange={handleLocationChange}
                isLoading={true}
                currentLocation={null}
              />
            </div>
          </div>
        </div>

        <div className="component-documentation">
          <div className="code-example">
            <h3 onClick={() => toggleSection('code')}>Code</h3>
            {!isSectionCollapsed('code') && (
              <pre>
{`const [currentLocation, setCurrentLocation] = useState(null);
const [isLoading, setIsLoading] = useState(false);

const handleLocationChange = (location) => {
  setCurrentLocation(location);
};

<LocationFilter
  onLocationChange={handleLocationChange}
  isLoading={isLoading}
  currentLocation={currentLocation}
/>`}
              </pre>
            )}
          </div>

          <div className="code-example">
            <h3 onClick={() => toggleSection('props')}>Props</h3>
            {!isSectionCollapsed('props') && (
              <div className="props-table">
                <table>
                  <thead>
                    <tr>
                      <th>Prop</th>
                      <th>Type</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>onLocationChange</td>
                      <td>{`(location: { latitude: number; longitude: number }) => void`}</td>
                      <td>Callback appelé lors du changement de localisation</td>
                    </tr>
                    <tr>
                      <td>isLoading</td>
                      <td>boolean</td>
                      <td>État de chargement du composant</td>
                    </tr>
                    <tr>
                      <td>currentLocation</td>
                      <td>{`{ latitude: number; longitude: number } | null`}</td>
                      <td>Localisation actuelle (optionnelle)</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>

        <div className="notes">
          <h4>Notes</h4>
          <ul>
            <li>Nécessite une clé API Google Maps valide</li>
            <li>Utilise l'API de géocodage inverse pour les adresses</li>
            <li>Support complet du mobile et du desktop</li>
            <li>Gestion intelligente des quartiers et arrondissements</li>
            <li>Formatage automatique des adresses</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default LocationFilterSection; 