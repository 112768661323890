import React from 'react';
import { Tag, TagGroup, ActivityTags, NoImageTags } from '../primitives/Tags';
import { PREDEFINED_TAGS } from '../types/tags.types';

const TagsSection = () => {
  // Filtrer les différents types de tags
  const activityTags = PREDEFINED_TAGS.filter(tag => 
    !['favorites', 'popular', 'today', 'free'].includes(tag.id)
  );

  const utilityTags = PREDEFINED_TAGS.filter(tag =>
    ['favorites', 'popular', 'today'].includes(tag.id)
  );

  const priceTag = PREDEFINED_TAGS.find(tag => tag.id === 'free');

  const statusTags = [
    { label: 'Published', status: 'published' },
    { label: 'Modified', status: 'modified' },
    { label: 'Draft', status: 'draft' },
  ] as const;

  return (
    <div className="section-content">
      <p className="section-description">
        Les tags permettent de catégoriser et d'identifier rapidement les informations clés d'un événement.
      </p>

      <section className="subsection">
        <h4>Tags d'activité</h4>
        <p>Utilisés pour catégoriser les types d'événements.</p>
        
        <div className="showcase-grid">
          {activityTags.map(tag => (
            <div key={tag.id} className="showcase-item">
              <div className="tag-preview">
                <Tag 
                  label={tag.label}
                  icon={tag.icon}
                  type="activity"
                />
              </div>
              <div className="showcase-info">
                <code className="showcase-name">type="activity" icon="{tag.icon}"</code>
                <p className="showcase-usage">Catégorie : {tag.label}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="subsection">
        <h4>Tags utilitaires</h4>
        <p>Utilisés pour des fonctionnalités spécifiques comme les favoris ou la popularité.</p>
        
        <div className="showcase-grid">
          {utilityTags.map(tag => (
            <div key={tag.id} className="showcase-item">
              <div className="tag-preview">
                <Tag 
                  label={tag.label}
                  icon={tag.icon}
                  type="activity"
                />
              </div>
              <div className="showcase-info">
                <code className="showcase-name">id="{tag.id}" icon="{tag.icon}"</code>
                <p className="showcase-usage">Utilité : {tag.label}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="subsection">
        <h4>Tags de prix</h4>
        <p>Distinction visuelle pour les événements gratuits.</p>
        
        <div className="showcase-grid">
          {priceTag && (
            <div className="showcase-item">
              <div className="tag-preview">
                <Tag 
                  label={priceTag.label}
                  icon={priceTag.icon}
                  type="price"
                  isFree={true}
                />
              </div>
              <div className="showcase-info">
                <code className="showcase-name">type="price" isFree={true}</code>
                <p className="showcase-usage">Événement gratuit (couleur secondaire)</p>
              </div>
            </div>
          )}
          <div className="showcase-item">
            <div className="tag-preview">
              <Tag 
                label="25€"
                type="price"
                isFree={false}
              />
            </div>
            <div className="showcase-info">
              <code className="showcase-name">type="price"</code>
              <p className="showcase-usage">Prix standard (couleur primaire)</p>
            </div>
          </div>
        </div>
      </section>

      <section className="subsection">
        <h4>Contextes d'utilisation</h4>
        <p>Les tags s'adaptent selon la présence ou non d'une image dans la carte.</p>

        <div className="showcase-grid">
          <div className="showcase-item">
            <div className="tag-preview" style={{ 
              position: 'relative',
              minHeight: '200px',
              background: 'var(--background-tertiary)'
            }} data-preview-type="event">
              <Tag label="25€" type="price" />
              <ActivityTags tags={activityTags.slice(0, 2)} />
            </div>
            <div className="showcase-info">
              <code className="showcase-name">&lt;ActivityTags /&gt;</code>
              <p className="showcase-usage">Prix en haut à gauche, activités en bas à gauche</p>
            </div>
          </div>

          <div className="showcase-item">
            <div className="tag-preview" style={{ 
              position: 'relative',
              minHeight: '100px',
              background: 'var(--background-tertiary)'
            }} data-preview-type="event">
              <NoImageTags 
                price={{ label: 'Gratuit', isFree: true }}
                activityTags={activityTags.slice(0, 2)}
              />
            </div>
            <div className="showcase-info">
              <code className="showcase-name">&lt;NoImageTags /&gt;</code>
              <p className="showcase-usage">Tous les tags alignés en haut, prix en premier</p>
            </div>
          </div>
        </div>
      </section>

      <section className="subsection">
        <h4>Tags de statut (Dashboard)</h4>
        <p>Utilisés dans le tableau de bord pour indiquer l'état d'un événement.</p>
        
        <div className="showcase-grid">
          {statusTags.map(tag => (
            <div key={tag.status} className="showcase-item">
              <div className="tag-preview">
                <Tag 
                  label={tag.label}
                  type="status"
                  size="sm"
                  status={tag.status}
                />
              </div>
              <div className="showcase-info">
                <code className="showcase-name">type="status" status="{tag.status}"</code>
                <p className="showcase-usage">
                  {tag.status === 'published' && 'Événement publié et visible'}
                  {tag.status === 'modified' && 'Modifications non publiées'}
                  {tag.status === 'draft' && 'Brouillon en cours d\'édition'}
                </p>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="subsection">
        <h4>Variantes de taille</h4>
        <p>Les tags sont disponibles en différentes tailles pour s'adapter à vos besoins.</p>
        
        <div className="showcase-grid">
          <div className="showcase-item">
            <div className="tag-preview">
              <Tag 
                label={activityTags[0].label}
                icon={activityTags[0].icon}
                type="activity" 
                size="sm" 
              />
            </div>
            <div className="showcase-info">
              <code className="showcase-name">size="sm"</code>
              <p className="showcase-usage">Pour les espaces restreints</p>
            </div>
          </div>
          
          <div className="showcase-item">
            <div className="tag-preview">
              <Tag 
                label={activityTags[0].label}
                icon={activityTags[0].icon}
                type="activity"
              />
            </div>
            <div className="showcase-info">
              <code className="showcase-name">size="md" (défaut)</code>
              <p className="showcase-usage">Taille par défaut</p>
            </div>
          </div>
          
          <div className="showcase-item">
            <div className="tag-preview">
              <Tag 
                label={activityTags[0].label}
                icon={activityTags[0].icon}
                type="activity"
                size="lg"
              />
            </div>
            <div className="showcase-info">
              <code className="showcase-name">size="lg"</code>
              <p className="showcase-usage">Pour plus de visibilité</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TagsSection; 