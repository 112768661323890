import { db } from './config';
import { 
  doc, 
  getDoc, 
  setDoc, 
  updateDoc,
  Timestamp,
  serverTimestamp,
  onSnapshot 
} from 'firebase/firestore';
import { OrganizerProfile, OrganizerProfileFormData } from '../types/organizer.types';

const COLLECTION_NAME = 'organizers'; 

class OrganizerService {
  // Pour les visiteurs (informations publiques)
  async getPublicProfile(organizerId: string) {
    try {
      console.log('Fetching public profile for:', organizerId);
      const organizerRef = doc(db, COLLECTION_NAME, organizerId);
      const organizerDoc = await getDoc(organizerRef);
      
      if (organizerDoc.exists()) {
        const data = organizerDoc.data();
        return {
          id: organizerId,
          organizer: data.organizer || '',
          organizerImage: data.organizerImage || '',
          subtitle: data.subtitle || '',
          description: data.description || '',
          location: data.location || '',
          locationName: data.locationName || '',
          organizerContact: data.organizerContact || '',
          phone: data.phone || '',
          email: data.email || '',
          website: data.website || '',
          instagram: data.instagram || '',
          galleryImages: data.galleryImages || [],
          latitude: data.latitude || 0,
          longitude: data.longitude || 0,
          createdAt: data.createdAt instanceof Timestamp ? data.createdAt.toDate() : new Date(),
          updatedAt: data.updatedAt instanceof Timestamp ? data.updatedAt.toDate() : new Date(),
          stats: {
            totalEvents: data.stats?.totalEvents || 0,
            activeEvents: data.stats?.activeEvents || 0,
            totalViews: data.stats?.totalViews || 0
          }
        };
      }
      console.log('No profile found for ID:', organizerId);
      return null;
    } catch (error) {
      console.error('Erreur lors de la récupération du profil public:', error);
      throw error;
    }
  }

  // Pour l'organisateur connecté (profil complet)
  async getProfile(userId: string): Promise<OrganizerProfile | null> {
    try {
      const organizerRef = doc(db, COLLECTION_NAME, userId);
      const organizerDoc = await getDoc(organizerRef);

      if (!organizerDoc.exists()) {
        return null;
      }

      const data = organizerDoc.data();
      
      return {
        id: organizerDoc.id,
        organizer: data.organizer || '',
        subtitle: data.subtitle || '',
        description: data.description || '',
        organizerImage: data.organizerImage || '',
        galleryImages: data.galleryImages || [],
        organizerContact: data.organizerContact || '',
        phone: data.phone || '',
        email: data.email || '',
        website: data.website || '',
        instagram: data.instagram || '',
        location: data.location || '',
        locationName: data.locationName || '',
        latitude: data.latitude || 0,
        longitude: data.longitude || 0,
        stats: data.stats || {
          totalEvents: 0,
          totalViews: 0,
          activeEvents: 0
        },
        createdAt: data.createdAt instanceof Timestamp ? data.createdAt.toDate() : new Date(),
        updatedAt: data.updatedAt instanceof Timestamp ? data.updatedAt.toDate() : new Date()
      };
    } catch (error) {
      console.error('Erreur lors de la récupération du profil:', error);
      throw error;
    }
  }

  async saveProfile(userId: string, profileData: OrganizerProfileFormData): Promise<void> {
    const organizerRef = doc(db, COLLECTION_NAME, userId);
    const organizerDoc = await getDoc(organizerRef);
  
    const profileToSave = {
      organizer: profileData.organizer || '',
      subtitle: profileData.subtitle || '',
      organizerImage: profileData.organizerImage || '',
      organizerContact: profileData.organizerContact || '',
      location: profileData.location || '',
      latitude: profileData.latitude || 0,
      longitude: profileData.longitude || 0,
      stats: {
        totalEvents: 0,
        totalViews: 0,
        activeEvents: 0
      }
    };
  
    if (!organizerDoc.exists()) {
      await setDoc(organizerRef, {
        ...profileToSave,
        id: userId,
        createdAt: Timestamp.now().toDate(),
        updatedAt: Timestamp.now().toDate()
      });
    } else {
      const { stats } = organizerDoc.data();
      await updateDoc(organizerRef, {
        ...profileToSave,
        stats: stats || profileToSave.stats,
        updatedAt: Timestamp.now().toDate()
      });
    }
  }
  subscribeToProfile(
    userId: string,
    onSuccess: (profile: OrganizerProfile) => void,
    onError: (error: any) => void
  ) {
    const organizerRef = doc(db, COLLECTION_NAME, userId);

    return onSnapshot(
      organizerRef,
      (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          const profile: OrganizerProfile = {
            id: doc.id,
            organizer: data.organizer || '',
            subtitle: data.subtitle || '',
            description: data.description || '',
            organizerImage: data.organizerImage || '',
            galleryImages: data.galleryImages || [],
            organizerContact: data.organizerContact || '',
            phone: data.phone || '',
            email: data.email || '',
            website: data.website || '',
            instagram: data.instagram || '',
            location: data.location || '',
            locationName: data.locationName || '',
            latitude: data.latitude || 0,
            longitude: data.longitude || 0,
            stats: data.stats || {
              totalEvents: 0,
              totalViews: 0,
              activeEvents: 0
            },
            createdAt: data.createdAt instanceof Timestamp ? data.createdAt.toDate() : new Date(),
            updatedAt: data.updatedAt instanceof Timestamp ? data.updatedAt.toDate() : new Date()
          };
          onSuccess(profile);
        } else {
          onSuccess({
            id: userId,
            organizer: '',
            subtitle: '',
            description: '',
            organizerImage: '',
            galleryImages: [],
            organizerContact: '',
            phone: '',
            email: '',
            website: '',
            instagram: '',
            location: '',
            locationName: '',
            latitude: 0,
            longitude: 0,
            stats: {
              totalEvents: 0,
              totalViews: 0,
              activeEvents: 0
            },
            createdAt: new Date(),
            updatedAt: new Date()
          });
        }
      },
      onError
    );
  }

  /**
   * Met à jour le profil d'un organisateur
   * @param uid ID de l'utilisateur organisateur
   * @param profileData Données du profil à mettre à jour
   */
  async updateProfile(uid: string, profileData: Partial<OrganizerProfile>): Promise<OrganizerProfile> {
    if (!uid) {
      throw new Error('User ID is required to update profile');
    }

    try {
      const profileRef = doc(db, COLLECTION_NAME, uid);
      
      // Préparer les données à mettre à jour (enlever les propriétés temporaires)
      const updateData = { ...profileData };
      delete updateData.avatarUploading;
      delete updateData.galleryUploading;
      
      // Mettre à jour le timestamp
      updateData.updatedAt = Timestamp.now().toDate();
      
      // Effectuer la mise à jour
      await updateDoc(profileRef, updateData);
      
      // Récupérer le profil mis à jour
      const updatedProfileSnapshot = await getDoc(profileRef);
      
      if (updatedProfileSnapshot.exists()) {
        const updatedProfile = {
          id: updatedProfileSnapshot.id,
          ...updatedProfileSnapshot.data()
        } as OrganizerProfile;
        
        return updatedProfile;
      } else {
        throw new Error('Profile not found after update');
      }
    } catch (error) {
      console.error('Error updating organizer profile:', error);
      throw error;
    }
  }
}


export const organizerService = new OrganizerService();