import React, { useState } from 'react';
import ToggleSwitch from '../primitives/ToggleSwitch';
import BinaryToggle from '../primitives/BinaryToggle';
import MultiSelectToggle from '../primitives/MultiSelectToggle';
import RadioButtonGroup from '../primitives/RadioButtonGroup';

interface CodeSection {
  id: string;
  isCollapsed: boolean;
}

const FormControlsSection: React.FC = () => {
  // États pour les démos
  const [radioValue, setRadioValue] = useState('option1');
  const [toggleValue, setToggleValue] = useState(false);
  const [binaryChoice, setBinaryChoice] = useState('oneShot');
  const [selectedDays, setSelectedDays] = useState<string[]>([]);

  // État pour le collapse des sections de code
  const [collapsedSections, setCollapsedSections] = React.useState<CodeSection[]>([
    { id: 'radio', isCollapsed: true },
    { id: 'toggle', isCollapsed: true },
    { id: 'binary', isCollapsed: true },
    { id: 'multiselect', isCollapsed: true }
  ]);

  const toggleSection = (sectionId: string) => {
    setCollapsedSections(prev => prev.map(section => 
      section.id === sectionId 
        ? { ...section, isCollapsed: !section.isCollapsed }
        : section
    ));
  };

  const isSectionCollapsed = (sectionId: string) => {
    return collapsedSections.find(section => section.id === sectionId)?.isCollapsed ?? true;
  };

  // Options pour les démos
  const radioOptions = [
    { label: 'Option 1', value: 'option1' },
    { label: 'Option 2', value: 'option2' },
    { label: 'Option désactivée', value: 'option3', disabled: true }
  ];

  const weekDays = [
    { id: 'monday', label: 'Lun' },
    { id: 'tuesday', label: 'Mar' },
    { id: 'wednesday', label: 'Mer' },
    { id: 'thursday', label: 'Jeu' },
    { id: 'friday', label: 'Ven' },
    { id: 'saturday', label: 'Sam' },
    { id: 'sunday', label: 'Dim' }
  ];

  return (
    <section className="component-section">
      
      <div className="controls-showcase">
        {/* Radio Buttons */}
        <div className="control-type-container">
          <div className="control-group">
            <h4>Radio Buttons</h4>
            <p className="control-description">
              Groupe de boutons radio pour sélectionner une option parmi plusieurs choix mutuellement exclusifs.
            </p>
            <div className="control-preview">
              <RadioButtonGroup
                options={radioOptions}
                name="demo-radio"
                onChange={setRadioValue}
                defaultValue={radioValue}
              />
            </div>
          </div>
          <div className="component-code">
            <div className={`code-example ${isSectionCollapsed('radio') ? 'collapsed' : ''}`}>
              <h3 onClick={() => toggleSection('radio')}>Code</h3>
              <pre>
{`const options = [
  { label: 'Option 1', value: 'option1' },
  { label: 'Option 2', value: 'option2' },
  { label: 'Option désactivée', value: 'option3', disabled: true }
];

<RadioButtonGroup
  options={options}
  name="demo-radio"
  onChange={setValue}
  defaultValue="option1"
/>`}
              </pre>
            </div>
          </div>
        </div>

        {/* Toggle Switch */}
        <div className="control-type-container">
          <div className="control-group">
            <h4>Toggle Switch</h4>
            <p className="control-description">
              Interrupteur à bascule pour les options on/off ou vrai/faux.
            </p>
            <div className="control-preview">
              <div className="toggle-demo">
                <ToggleSwitch
                  label="Notifications"
                  onChange={setToggleValue}
                  defaultChecked={toggleValue}
                />
                <ToggleSwitch
                  label="Option désactivée"
                  disabled
                  onChange={() => {}}
                />
              </div>
            </div>
          </div>
          <div className="component-code">
            <div className={`code-example ${isSectionCollapsed('toggle') ? 'collapsed' : ''}`}>
              <h3 onClick={() => toggleSection('toggle')}>Code</h3>
              <pre>
{`<ToggleSwitch
  label="Notifications"
  onChange={setEnabled}
  defaultChecked={false}
/>

<ToggleSwitch
  label="Option désactivée"
  disabled
  onChange={() => {}}
/>`}
              </pre>
            </div>
          </div>
        </div>

        {/* Binary Toggle */}
        <div className="control-type-container">
          <div className="control-group">
            <h4>Binary Toggle</h4>
            <p className="control-description">
              Toggle élégant pour les choix binaires, s'adapte automatiquement à la longueur du texte.
            </p>
            <div className="control-preview">
              <BinaryToggle
                leftLabel="One Shot"
                rightLabel="Récurrent"
                value={binaryChoice === "Récurrent"}
                onChange={(value: boolean) => setBinaryChoice(value ? "Récurrent" : "One Shot")}
              />
            </div>
          </div>
          <div className="component-code">
            <div className={`code-example ${isSectionCollapsed('binary') ? 'collapsed' : ''}`}>
              <h3 onClick={() => toggleSection('binary')}>Code</h3>
              <pre>
{`<BinaryToggle
  leftLabel="One Shot"
  rightLabel="Récurrent"
  value={binaryChoice === "Récurrent"}
  onChange={setValue}
/>`}
              </pre>
            </div>
          </div>
        </div>

        {/* Multi-select Toggle */}
        <div className="control-type-container">
          <div className="control-group">
            <h4>Multi-select Toggle</h4>
            <p className="control-description">
              Groupe de toggles pour sélection multiple, idéal pour les jours de la semaine ou options multiples.
            </p>
            <div className="control-preview">
              <MultiSelectToggle
                options={weekDays}
                selectedValues={selectedDays}
                onChange={setSelectedDays}
              />
            </div>
          </div>
          <div className="component-code">
            <div className={`code-example ${isSectionCollapsed('multiselect') ? 'collapsed' : ''}`}>
              <h3 onClick={() => toggleSection('multiselect')}>Code</h3>
              <pre>
{`const weekDays = [
  { id: 'monday', label: 'Lun' },
  { id: 'tuesday', label: 'Mar' },
  { id: 'wednesday', label: 'Mer' },
  { id: 'thursday', label: 'Jeu' },
  { id: 'friday', label: 'Ven' },
  { id: 'saturday', label: 'Sam' },
  { id: 'sunday', label: 'Dim' }
];

<MultiSelectToggle
  options={weekDays}
  selectedValues={selectedDays}
  onChange={setSelectedDays}
/>`}
              </pre>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FormControlsSection;