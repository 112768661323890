import { useState, useEffect, useMemo, useCallback } from 'react';
import { Event } from '../types/event.types';
import { geoEventQueries } from '../firebase/geoEventQueries';
import { toast } from 'react-hot-toast';

interface TextSearchConfig {
  searchText: string;
  weights: {
    title: number;
    organizer: number;
    description: number;
  };
}

interface UsePublicEventsOptions {
  coords?: {
    latitude: number;
    longitude: number;
  };
  radius?: number;
  excludeIds?: string[];
}

export const usePublicEvents = (options?: UsePublicEventsOptions) => {
  const [events, setEvents] = useState<Event[]>(() => {
    try {
      const cachedEvents = sessionStorage.getItem('cachedEvents');
      if (!cachedEvents) return [];
      
      const parsedEvents = JSON.parse(cachedEvents);
      
      if (!Array.isArray(parsedEvents)) return [];
      
      const validEvents = parsedEvents.filter(event => 
        typeof event === 'object' && 
        event !== null && 
        typeof event.id === 'string' && 
        typeof event.title === 'string'
      );
      
      return validEvents.map(event => ({
        ...event,
        datetime: event.datetime ? new Date(event.datetime) : event.datetime,
        endDateTime: event.endDateTime ? new Date(event.endDateTime) : event.endDateTime
      }));
    } catch {
      return [];
    }
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | undefined>();
  const [detailedEvents, setDetailedEvents] = useState<Map<string, Event>>(new Map());
  const [searchConfig, setSearchConfig] = useState<TextSearchConfig>({
    searchText: '',
    weights: {
      title: 1.0,
      organizer: 0.8,
      description: 0.5
    }
  });

  // Valeurs par défaut si pas d'options
  const coords = options?.coords;
  const radius = options?.radius ?? 5;
  const excludeIds = options?.excludeIds ?? [];

  // Mise à jour du cache avec conversion correcte des dates
  const updateLocalCache = useCallback((newEvents: Event[]) => {
    if (Array.isArray(newEvents) && newEvents.length > 0) {
      try {
        sessionStorage.setItem('cachedEvents', JSON.stringify(newEvents));
      } catch (error) {
        console.warn('Failed to update events cache:', error);
      }
    }
  }, []);

  useEffect(() => {
    let isActive = true;
    
    const fetchEvents = async () => {
      const preloadedData = window.__PRELOADED_DATA__;
      const isOffline = !navigator.onLine;
      setLoading(true);

      try {
        if (isOffline) {
          // En mode offline, utiliser directement les données préchargées
          if (preloadedData?.events) {
            setEvents(preloadedData.events);
            setLoading(false);
            return;
          }
          throw new Error('Pas de connexion Internet');
        }

        if (!coords) {
          if (preloadedData?.events) {
            setEvents(preloadedData.events);
            updateLocalCache(preloadedData.events);
          }
          setLoading(false);
          return;
        }

        let fetchedEvents: Event[] = [];
        
        if (navigator.onLine) {
          fetchedEvents = await geoEventQueries.getEventsInZone(coords, radius, excludeIds);
        }
        
        if (isActive) {
          // Mettre à jour le cache et les états avec les événements récupérés
          if (fetchedEvents && fetchedEvents.length > 0) {
            updateLocalCache(fetchedEvents);
            setEvents(fetchedEvents);
          } else if (preloadedData?.events) {
            updateLocalCache(preloadedData.events);
            setEvents(preloadedData.events);
          }
          
          setLoading(false);
          window.dispatchEvent(new CustomEvent('appReady'));
        }
      } catch (error) {
        console.error('Error fetching events:', error);
        if (isActive) {
          setError(error instanceof Error ? error.message : String(error));
          setLoading(false);
        }
      }
    };

    // Si pas d'événements en cache ou si les coordonnées ont changé, chercher des données fraîches
    if (events.length === 0 || coords) {
      fetchEvents();
    } else {
      // Déjà des événements en cache, planifier une mise à jour en arrière-plan
      setLoading(false);
      
      // Mettre à jour en arrière-plan si des coordonnées sont disponibles
      if (coords) {
        // Utiliser setTimeout sans stocker l'identifiant
        setTimeout(() => {
          if (isActive) fetchEvents();
        }, 100);
      }
    }

    return () => {
      isActive = false;
      // Nous n'avons plus besoin de nettoyer le timeout
    };
  }, [coords, radius, excludeIds.join(','), updateLocalCache]);

  useEffect(() => {
    const handleEventsUpdate = (e: CustomEvent) => {
      if (e.detail.hasNewEvents) {
        setEvents(e.detail.updatedEvents);
        toast('De nouveaux événements sont disponibles !', {
          icon: '🔄'
        });
      }
    };

    window.addEventListener('eventsUpdated', handleEventsUpdate as EventListener);
    return () => {
      window.removeEventListener('eventsUpdated', handleEventsUpdate as EventListener);
    };
  }, []);

  const getDetailedEvent = async (eventId: string): Promise<Event | null> => {
    try {
      return await geoEventQueries.getEventDetails(eventId);
    } catch (error) {
      console.error('Failed to get detailed event:', error);
      return null;
    }
  };

  // Filtrer les événements selon les critères de recherche
  const filteredEvents = useMemo(() => {
    if (!events || events.length === 0) return [];
    
    const now = new Date();
    
    const durationFiltered = events.filter(event => {
      if (!event.datetime) return false;
      
      const startDate = new Date(event.datetime);
      const endDate = event.endDateTime 
        ? new Date(event.endDateTime)
        : event.endTime 
          ? (() => {
              // Fallback pour la rétrocompatibilité
              const [hours, minutes] = event.endTime.split(':').map(Number);
              const end = new Date(startDate);
              end.setHours(hours, minutes);
              if (end < startDate) end.setDate(end.getDate() + 1);
              return end;
            })()
          : null;

      if (!endDate) return false;

      const durationHours = (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60);

      // Pour les événements courts (<4h)
      if (durationHours < 4) {
        return startDate > now;
      }
      
      // Pour les événements longs (≥4h)
      return endDate > now;
    });

    if (!searchConfig.searchText.trim()) return durationFiltered;

    const searchTerms = searchConfig.searchText.toLowerCase().trim().split(/\s+/);
    
    return durationFiltered.filter(event => {
      return searchTerms.every(term => {
        const titleMatch = event.title.toLowerCase().includes(term) ? searchConfig.weights.title : 0;
        const organizerMatch = (event.organizer || '').toLowerCase().includes(term) ? searchConfig.weights.organizer : 0;
        const descriptionMatch = (event.description || '').toLowerCase().includes(term) ? searchConfig.weights.description : 0;

        return (titleMatch + organizerMatch + descriptionMatch) > 0;
      });
    });
  }, [events, searchConfig]);

  return {
    events: filteredEvents,
    loading,
    error,
    searchConfig,
    setSearchConfig,
    getDetailedEvent
  };
}; 