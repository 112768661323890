import { useMemo } from 'react';
import { calculateDistance, formatDistance } from '../utils/eventSorting';

interface Location {
  latitude: number;
  longitude: number;
}

export const useDistance = (
  userLocation?: Location,
  eventLocation?: { latitude?: number; longitude?: number }
) => {
  // Retourner directement la valeur mémorisée
  return useMemo(() => {
    if (!userLocation || !eventLocation?.latitude || !eventLocation?.longitude) {
      return null;
    }
    
    const distance = calculateDistance(
      userLocation.latitude,
      userLocation.longitude,
      eventLocation.latitude,
      eventLocation.longitude
    );
    return formatDistance(distance);
  }, [userLocation, eventLocation?.latitude, eventLocation?.longitude]);
};
