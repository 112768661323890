import React from 'react';
import '../../../styles/components/LogoSVG.css';

// Import des logos
import logoBeige from '../../../styles/images/logobeige.png';
import logoRougeFondTexte from '../../../styles/images/logoreougefondtexte.png';
import logoRouge from '../../../styles/images/logorouge.png';
import logoRougeTexte from '../../../styles/images/logorougetexte.png';
import logoVert from '../../../styles/images/logovert.png';
import logoVertFondTexte from '../../../styles/images/logovertfondtexte.png';
import logoVertFondTextePalmier from '../../../styles/images/logovertfondtextepalmier.png';
import logoVertTexte from '../../../styles/images/logoverttexte.png';

// Import des nouveaux singes
import singeOrange from '../../../styles/images/singequimarcheorange.png';
import singeVert from '../../../styles/images/singequimarchevert.png';
import singeSuspenduOrange from '../../../styles/images/singesuspenduorange.png';
import singeSuspenduVert from '../../../styles/images/singesuspenduvert.png';
import singeTicketVert from '../../../styles/images/singeticketvert.png';

// Définition des variantes disponibles
export type LogoVariant = 
  | 'beige'
  | 'rougeFondTexte'
  | 'rouge'
  | 'rougeTexte'
  | 'vert'
  | 'vertFondTexte'
  | 'vertFondTextePalmier'
  | 'vertTexte'
  | 'eventConnect'
  | 'singeOrange'
  | 'singeVert'
  | 'singeSuspenduOrange'
  | 'singeSuspenduVert'
  | 'singeTicketVert';

interface LogoSVGProps {
  className?: string;
  variant: LogoVariant;
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'auto';
  padding?: number;
  color?: string;
}

const logoMap: Record<LogoVariant, string> = {
  beige: logoBeige,
  rougeFondTexte: logoRougeFondTexte,
  rouge: logoRouge,
  rougeTexte: logoRougeTexte,
  vert: logoVert,
  vertFondTexte: logoVertFondTexte,
  vertFondTextePalmier: logoVertFondTextePalmier,
  vertTexte: logoVertTexte,
  eventConnect: logoVertTexte,
  singeOrange: singeOrange,
  singeVert: singeVert,
  singeSuspenduOrange: singeSuspenduOrange,
  singeSuspenduVert: singeSuspenduVert,
  singeTicketVert: singeTicketVert
};

const LogoSVG: React.FC<LogoSVGProps> = ({ 
  className,
  variant = 'beige',
  size = 'md',
  padding = 8,
  color
}) => {
  const sizeMap = {
    sm: 24,
    md: 32,
    lg: 40,
    xl: 48,
    xxl: 64,
    auto: undefined
  };

  const style = size === 'auto' 
    ? { padding: `${padding}px` } as React.CSSProperties
    : { 
        '--logo-size': `${sizeMap[size]}px`,
        width: sizeMap[size],
        height: sizeMap[size]
      } as React.CSSProperties;

  return (
    <div className={`logo-svg ${size === 'auto' ? 'logo-svg--auto' : ''} ${className || ''}`} style={style}>
      <img 
        src={logoMap[variant]} 
        alt={`Event Connect ${variant} logo`} 
        className="logo-svg__image"
        style={color ? { filter: `color(${color})` } : undefined}
      />
    </div>
  );
};

export default React.memo(LogoSVG); 