import React from 'react';
import './styles/foundation-styles.css';

const SpacingSection = () => {
  const spacingTokens = [
    { name: '--space-xs ', value: '0.25rem', px: '4px' },
    { name: '--space-sm ', value: '0.5rem', px: '8px' },
    { name: '--space-md ', value: '0.75rem', px: '12px' },
    { name: '--space-lg ', value: '1rem', px: '16px' },
    { name: '--space-xl ', value: '1.5rem', px: '24px' },
    { name: '--space-xxl ', value: '2rem', px: '32px' },
  ];

  const semanticSpacing = [
    { name: '--spacing-xs', value: 'var(--space-1)', description: 'Très petit espacement, utilisé pour les éléments compacts' },
    { name: '--spacing-sm', value: 'var(--space-2)', description: 'Petit espacement, utilisé pour les éléments rapprochés' },
    { name: '--spacing-md', value: 'var(--space-4)', description: 'Espacement moyen, utilisé pour la majorité des cas' },
    { name: '--spacing-lg', value: 'var(--space-5)', description: 'Grand espacement, utilisé pour séparer les sections' },
    { name: '--spacing-xl', value: 'var(--space-6)', description: 'Très grand espacement, utilisé pour les marges importantes' },
  ];

  return (
    <div className="foundation-section">
      <h2>Spacing</h2>
      
      <section>
        <h3>Primitive Spacing</h3>
        <p>Ces espacements de base constituent la fondation de notre système de mise en page.</p>
        
        <div className="spacing-grid">
          {spacingTokens.map(token => (
            <div key={token.name} className="spacing-item">
              <div className="spacing-preview">
                <div 
                  className="spacing-box"
                  style={{ width: token.value, height: token.value }}
                />
              </div>
              <div className="spacing-info">
                <code className="spacing-name">{token.name}</code> 
                <span className="spacing-value">{token.value} ({token.px})</span>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section>
        <h3>Semantic Spacing</h3>
        <p>Ces variables sémantiques donnent du sens à nos espacements et assurent la cohérence.</p>
        
        <div className="semantic-spacing-list">
          {semanticSpacing.map(spacing => (
            <div key={spacing.name} className="semantic-spacing-item">
              <div className="semantic-spacing-header">
                <code className="spacing-name">{spacing.name}</code>
                <span className="spacing-value">{spacing.value}</span>
              </div>
              <div className="spacing-preview-horizontal" data-spacing={spacing.name.split('--spacing-')[1]}>
                <div className="spacing-marker left" />
                <div className="spacing-distance" />
                <div className="spacing-marker right" />
              </div>
              <p className="spacing-description">{spacing.description}</p>
            </div>
          ))}
        </div>
      </section>

      <section>
        <h3>Usage Guidelines</h3>
        <ul className="guidelines-list">
          <li>Utilisez toujours les variables sémantiques plutôt que les valeurs primitives</li>
          <li>Maintenez une hiérarchie cohérente avec les espacements (xs → xl)</li>
          <li>Utilisez des espacements plus grands entre les sections qu'entre les éléments</li>
          <li>Gardez les espacements cohérents pour des éléments similaires</li>
          <li>Adaptez les espacements en fonction de la taille de l'écran</li>
        </ul>
      </section>
    </div>
  );
};

export default SpacingSection; 