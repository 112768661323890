import React from 'react';
import { Tag as TagComponent } from './Tags';
import { Tag } from '../types/tags.types';
import '../../../styles/components/tags.css';

type TagListProps = {
  tags: Tag[];
  onTagSelect: (tagId: string) => void;
  selectedTags: string[];
  maxTags?: number;
  onMaxTagsReached?: () => void;
};

const TagList = ({ 
  tags, 
  onTagSelect, 
  selectedTags, 
  maxTags,
  onMaxTagsReached 
}: TagListProps) => {
  const handleTagSelect = (tagId: string) => {
    if (selectedTags.includes(tagId)) {
      // Si le tag est déjà sélectionné, on le retire
      onTagSelect(tagId);
    } else if (!maxTags || selectedTags.length < maxTags) {
      // Si pas de limite ou limite non atteinte, on ajoute le tag
      onTagSelect(tagId);
    } else if (onMaxTagsReached) {
      // Si limite atteinte, on notifie
      onMaxTagsReached();
    }
  };

  return (
    <div className="ds-taglist">
      {tags.map((tag) => {
        const isSelected = selectedTags.includes(tag.id);
        const isDisabled: boolean = Boolean(!isSelected && maxTags && selectedTags.length >= maxTags);

        return (
          <button
            key={tag.id}
            onClick={() => handleTagSelect(tag.id)}
            className={`ds-taglist__button ${isSelected ? 'ds-taglist__button--selected' : ''} ${isDisabled ? 'ds-taglist__button--disabled' : ''}`}
            aria-pressed={isSelected}
            disabled={isDisabled}
            title={isDisabled ? `Maximum ${maxTags} tags allowed` : undefined}
          >
            <TagComponent
              label={`${tag.icon} ${tag.label}`}
              type="activity"
              size="lg"
            />
          </button>
        );
      })}
    </div>
  );
};

export default TagList;