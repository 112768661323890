import React from 'react';
import { Icon } from './Icon';
import '../../../styles/components/number-input.css';

export interface NumberInputProps {
  // Props essentielles
  id: string;
  name: string;
  value: number;
  onChange: (value: number) => void;
  
  // Props optionnelles
  label?: string;
  helper?: string;
  error?: string;
  min?: number;
  max?: number;
  step?: number;
  required?: boolean;
  disabled?: boolean;
  className?: string;
}

export const NumberInput: React.FC<NumberInputProps> = ({
  id,
  name,
  value,
  onChange,
  label,
  helper,
  error,
  min,
  max,
  step = 1,
  required = false,
  disabled = false,
  className = '',
}) => {
  const inputClasses = [
    'number-input-field',
    error ? 'has-error' : '',
    disabled ? 'is-disabled' : '',
    className
  ].filter(Boolean).join(' ');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(e.target.value);
    if (!isNaN(newValue)) {
      handleValueChange(newValue);
    }
  };

  const handleValueChange = (newValue: number) => {
    if (min !== undefined && newValue < min) return;
    if (max !== undefined && newValue > max) return;
    onChange(newValue);
  };

  const increment = () => {
    handleValueChange(value + step);
  };

  const decrement = () => {
    handleValueChange(value - step);
  };

  return (
    <div className="number-input-container">
      {label && (
        <label htmlFor={id} className="number-input-label">
          {label}
          {required && <span className="required-mark">*</span>}
        </label>
      )}
      
      <div className="number-input-wrapper">
        <button
          type="button"
          className="number-input-button"
          onClick={decrement}
          disabled={disabled || (min !== undefined && value <= min)}
          aria-label="Decrease"
        >
          <Icon name="Minus" size={16} />
        </button>
        
        <input
          id={id}
          name={name}
          type="number"
          value={value}
          onChange={handleChange}
          min={min}
          max={max}
          step={step}
          disabled={disabled}
          required={required}
          className={inputClasses}
          aria-invalid={!!error}
          aria-describedby={helper ? `${id}-helper` : undefined}
        />
        
        <button
          type="button"
          className="number-input-button"
          onClick={increment}
          disabled={disabled || (max !== undefined && value >= max)}
          aria-label="Increase"
        >
          <Icon name="Plus" size={16} />
        </button>
      </div>

      {helper && !error && (
        <p id={`${id}-helper`} className="number-input-helper">
          {helper}
        </p>
      )}
      
      {error && (
        <p id={`${id}-error`} className="number-input-error" role="alert">
          {error}
        </p>
      )}
    </div>
  );
};

export default NumberInput; 