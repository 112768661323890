import React from 'react';
import { Icon } from './Icon';
import '../../../styles/components/checkbox.css';

export interface CheckboxProps {
  // Props essentielles
  id: string;
  name: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  
  // Props optionnelles
  label?: string;
  helper?: string;
  error?: string;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  indeterminate?: boolean;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  id,
  name,
  checked,
  onChange,
  label,
  helper,
  error,
  required = false,
  disabled = false,
  className = '',
  indeterminate = false,
}) => {
  const checkboxRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (checkboxRef.current) {
      checkboxRef.current.indeterminate = indeterminate;
    }
  }, [indeterminate]);

  const containerClasses = [
    'checkbox-container',
    error ? 'has-error' : '',
    disabled ? 'is-disabled' : '',
    className
  ].filter(Boolean).join(' ');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!indeterminate) {
      onChange(e.target.checked);
    }
  };

  const getIcon = () => {
    if (indeterminate) return 'Minus';
    return checked ? 'Check' : null;
  };

  return (
    <div className={containerClasses}>
      <label className="checkbox-wrapper" htmlFor={id}>
        <input
          ref={checkboxRef}
          id={id}
          name={name}
          type="checkbox"
          checked={checked}
          onChange={handleChange}
          disabled={disabled || indeterminate}
          required={required}
          className="checkbox-input"
          aria-invalid={!!error}
          aria-describedby={helper ? `${id}-helper` : undefined}
        />
        <div className={`checkbox-box ${indeterminate ? 'is-indeterminate' : ''}`}>
          {getIcon() && (
            <Icon 
              name={getIcon()!} 
              size={16}
              className="checkbox-icon"
              aria-hidden="true"
            />
          )}
        </div>
        
        {label && (
          <span className="checkbox-label">
            {label}
            {required && <span className="required-mark">*</span>}
          </span>
        )}
      </label>

      {helper && !error && (
        <p id={`${id}-helper`} className="checkbox-helper">
          {helper}
        </p>
      )}
      
      {error && (
        <p id={`${id}-error`} className="checkbox-error" role="alert">
          {error}
        </p>
      )}
    </div>
  );
};

export default Checkbox; 