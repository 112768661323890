import React, { useState } from 'react';
import '../../../styles/components/toggle.css';

interface ToggleSwitchProps {
    label?: string;  // Le label est optionnel
    onChange: (checked: boolean) => void;
    defaultChecked?: boolean;
    disabled?: boolean;  // Ajout de l'état disabled
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ 
    label, 
    onChange, 
    defaultChecked = false,
    disabled = false 
}) => {
    const [isChecked, setIsChecked] = useState(defaultChecked);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!disabled) {
            const newValue = e.target.checked;
            setIsChecked(newValue);
            onChange(newValue);
        }
    };

    return (
        <div className="toggle-group">
            <label className="toggle-switch-container">
                <input 
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleChange}
                    disabled={disabled}
                />
                <span className="toggle-switch-slider"></span>
            </label>
            {label && <span className="toggle-label">{label}</span>}
        </div>
    );
};

export default ToggleSwitch;