import React from 'react';
import '../../../styles/components/currency-input.css';
import Select from './Select';

interface CurrencyCache {
  code: string;
  timestamp: number;
}

interface Currency {
  code: string;
  symbol: string;
  position: 'before' | 'after';
  decimals: number;
  name: string;
  isLocal?: boolean;
  region: string;
  countryCode: string;
}

const createCurrency = (
  code: string,
  symbol: string,
  position: 'before' | 'after',
  decimals: number,
  name: string,
  region: string,
  countryCode: string
): Currency => ({
  code,
  symbol,
  position,
  decimals,
  name,
  region,
  countryCode
});

// Region names
const REGIONS = {
  AMERICAS: 'Americas',
  EUROPE: 'Europe',
  ASIA: 'Asia',
  OCEANIA: 'Oceania',
  MIDDLE_EAST: 'Middle East',
  AFRICA: 'Africa'
} as const;

type Region = typeof REGIONS[keyof typeof REGIONS];

// Main currencies always at the top (in this order)
const MAIN_CURRENCIES = ['LKR', 'USD', 'EUR'];

// List of common currencies
const ALL_CURRENCIES: Currency[] = [
  // Americas
  createCurrency('ARS', '$', 'before', 2, 'Argentine Peso', REGIONS.AMERICAS, 'AR'),
  createCurrency('BRL', 'R$', 'before', 2, 'Brazilian Real', REGIONS.AMERICAS, 'BR'),
  createCurrency('CAD', '$', 'before', 2, 'Canadian Dollar', REGIONS.AMERICAS, 'CA'),
  createCurrency('MXN', '$', 'before', 2, 'Mexican Peso', REGIONS.AMERICAS, 'MX'),
  createCurrency('USD', '$', 'before', 2, 'US Dollar', REGIONS.AMERICAS, 'US'),

  // Europe
  createCurrency('CHF', 'CHF', 'before', 2, 'Swiss Franc', REGIONS.EUROPE, 'CH'),
  createCurrency('DKK', 'kr', 'after', 2, 'Danish Krone', REGIONS.EUROPE, 'DK'),
  createCurrency('EUR', '€', 'after', 2, 'Euro', REGIONS.EUROPE, 'EU'),
  createCurrency('GBP', '£', 'before', 2, 'British Pound', REGIONS.EUROPE, 'GB'),
  createCurrency('NOK', 'kr', 'after', 2, 'Norwegian Krone', REGIONS.EUROPE, 'NO'),
  createCurrency('PLN', 'zł', 'after', 2, 'Polish Złoty', REGIONS.EUROPE, 'PL'),
  createCurrency('SEK', 'kr', 'after', 2, 'Swedish Krona', REGIONS.EUROPE, 'SE'),

  // Asia
  createCurrency('CNY', '¥', 'before', 2, 'Chinese Yuan', REGIONS.ASIA, 'CN'),
  createCurrency('HKD', 'HK$', 'before', 2, 'Hong Kong Dollar', REGIONS.ASIA, 'HK'),
  createCurrency('IDR', 'Rp', 'before', 2, 'Indonesian Rupiah', REGIONS.ASIA, 'ID'),
  createCurrency('INR', '₹', 'before', 2, 'Indian Rupee', REGIONS.ASIA, 'IN'),
  createCurrency('JPY', '¥', 'before', 0, 'Japanese Yen', REGIONS.ASIA, 'JP'),
  createCurrency('KRW', '₩', 'before', 0, 'South Korean Won', REGIONS.ASIA, 'KR'),
  createCurrency('LKR', 'Rs', 'before', 2, 'Sri Lankan Rupee', REGIONS.ASIA, 'LK'),
  createCurrency('MYR', 'RM', 'before', 2, 'Malaysian Ringgit', REGIONS.ASIA, 'MY'),
  createCurrency('SGD', 'S$', 'before', 2, 'Singapore Dollar', REGIONS.ASIA, 'SG'),
  createCurrency('THB', '฿', 'before', 2, 'Thai Baht', REGIONS.ASIA, 'TH'),
  createCurrency('VND', '₫', 'after', 0, 'Vietnamese Dong', REGIONS.ASIA, 'VN'),

  // Oceania
  createCurrency('AUD', 'A$', 'before', 2, 'Australian Dollar', REGIONS.OCEANIA, 'AU'),
  createCurrency('NZD', 'NZ$', 'before', 2, 'New Zealand Dollar', REGIONS.OCEANIA, 'NZ'),

  // Middle East
  createCurrency('AED', 'د.إ', 'after', 2, 'UAE Dirham', REGIONS.MIDDLE_EAST, 'AE'),
  createCurrency('SAR', '﷼', 'after', 2, 'Saudi Riyal', REGIONS.MIDDLE_EAST, 'SA'),

  // Africa
  createCurrency('EGP', 'E£', 'before', 2, 'Egyptian Pound', REGIONS.AFRICA, 'EG'),
  createCurrency('MAD', 'MAD', 'before', 2, 'Moroccan Dirham', REGIONS.AFRICA, 'MA'),
  createCurrency('ZAR', 'R', 'before', 2, 'South African Rand', REGIONS.AFRICA, 'ZA')
];

// Hook personnalisé pour gérer les devises
const useCurrencies = (
  onLocalCurrencyDetected?: (currency: Currency) => void,
  userLocation?: { latitude: number; longitude: number; city?: string }
): Currency[] => {
  const [currencies, setCurrencies] = React.useState<Currency[]>([]);

  // Cache de la devise locale
  const getCachedCurrency = (): CurrencyCache | null => {
    const cached = localStorage.getItem('localCurrency');
    if (cached) {
      const { code, timestamp } = JSON.parse(cached);
      // Cache valide pendant 24h
      if (Date.now() - timestamp < 24 * 60 * 60 * 1000) {
        return { code, timestamp };
      }
    }
    return null;
  };

  const setCachedCurrency = (code: string) => {
    localStorage.setItem('localCurrency', JSON.stringify({
      code,
      timestamp: Date.now()
    }));
  };

  React.useEffect(() => {
    // Fonction pour détecter la devise locale
    const detectLocalCurrency = (userLocale: string): Currency | null => {
      try {
        // 1. Vérifier d'abord la géolocalisation pour le Sri Lanka
        if (userLocation) {
          const isInSriLanka = userLocation.latitude >= 5.9 && 
                              userLocation.latitude <= 9.9 && 
                              userLocation.longitude >= 79.5 && 
                              userLocation.longitude <= 81.9;

          if (isInSriLanka) {
            const sriLankaCurrency = ALL_CURRENCIES.find(c => c.code === 'LKR');
            if (sriLankaCurrency) {
              return {
                ...sriLankaCurrency,
                isLocal: true
              };
            }
          }
        }

        // 2. Fallback sur la langue du navigateur
        const region = new Intl.Locale(userLocale).region;
        if (!region) return null;

        const currencyCode = new Intl.NumberFormat(userLocale, { style: 'currency' })
          .resolvedOptions().currency;

        const localCurrency = ALL_CURRENCIES.find(c => c.code === currencyCode);
        if (localCurrency) {
          return {
            ...localCurrency,
            isLocal: true
          };
        }

        return null;
      } catch (error) {
        console.warn('Unable to detect local currency:', error);
        return null;
      }
    };

    // 1. Récupérer la devise locale
    const localCurrency = detectLocalCurrency(navigator.language);
    if (localCurrency && onLocalCurrencyDetected) {
      onLocalCurrencyDetected(localCurrency);
    }

    // 2. Récupérer les devises principales (sauf la locale)
    const mainCurrenciesData = MAIN_CURRENCIES
      .map(code => ALL_CURRENCIES.find(c => c.code === code))
      .filter((c): c is Currency => c !== undefined && c.code !== localCurrency?.code);
    
    // 3. Récupérer les autres devises, triées par région et nom
    const otherCurrencies = ALL_CURRENCIES
      .filter(c => 
        !MAIN_CURRENCIES.includes(c.code) && 
        c.code !== localCurrency?.code
      )
      .sort((a, b) => {
        // D'abord trier par région
        if (a.region !== b.region) {
          return a.region.localeCompare(b.region);
        }
        // Puis par nom dans la région
        return a.name.localeCompare(b.name);
      });

    // 4. Combiner dans l'ordre souhaité
    const orderedCurrencies = [
      ...(localCurrency ? [localCurrency] : []),
      ...mainCurrenciesData,
      ...otherCurrencies
    ];

    setCurrencies(orderedCurrencies);
  }, [navigator.language, onLocalCurrencyDetected, userLocation]);

  return currencies;
};

export interface CurrencyInputProps {
  // Props essentielles
  id: string;
  value: {
    amount: number;
    currency: string;
  };
  onChange: (value: { amount: number; currency: string }) => void;
  
  // Props optionnelles
  label?: string;
  helper?: string;
  error?: string;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  userLocation?: {
    latitude: number;
    longitude: number;
    city?: string;
  };
}

export const CurrencyInput: React.FC<CurrencyInputProps> = ({
  id,
  value,
  onChange,
  label,
  helper,
  error,
  required = false,
  disabled = false,
  className = '',
  userLocation,
}) => {
  // Callback pour définir la devise locale au chargement
  const handleLocalCurrencyDetected = React.useCallback((localCurrency: Currency) => {
    if (!value.currency) {
      onChange({ ...value, currency: localCurrency.code });
    }
  }, [value, onChange]);

  const currencies = useCurrencies(handleLocalCurrencyDetected, userLocation);
  const [amount, setAmount] = React.useState(value.amount);

  // S'assurer qu'une devise est toujours sélectionnée
  React.useEffect(() => {
    if (!value.currency && currencies.length > 0) {
      const defaultCurrency = currencies.find(c => c.isLocal) || currencies[0];
      onChange({ ...value, currency: defaultCurrency.code });
    }
  }, [value, currencies, onChange]);

  // Préparer les options pour le Select avec des groupes
  const currencyOptions = React.useMemo(() => {
    const groupedCurrencies = currencies.reduce((acc, currency) => {
      const group = currency.region;
      if (!acc[group]) acc[group] = [];
      acc[group].push(currency);
      return acc;
    }, {} as Record<string, Currency[]>);

    // Trier les régions par ordre alphabétique
    return Object.values(REGIONS)
      .sort((a, b) => a.localeCompare(b))
      .flatMap(region => {
        const currenciesInRegion = groupedCurrencies[region] || [];
        if (currenciesInRegion.length === 0) return [];
        
        return [
          { label: region, value: `group-${region}`, disabled: true },
          ...currenciesInRegion
            .sort((a, b) => a.code.localeCompare(b.code)) // Trier par code de devise
            .map(c => ({
              label: `${c.code} - ${c.name}`,
              value: c.code
            })),
          { label: '─────────────', value: `divider-${region}`, disabled: true }
        ];
      });
  }, [currencies]);

  // Mise à jour du state parent
  const handleChange = (newAmount: number, newCurrency: string) => {
    setAmount(newAmount);
    onChange({ 
      amount: newAmount,
      currency: newCurrency
    });
  };

  // Ajouter un gestionnaire de focus pour sélectionner automatiquement le contenu
  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select();
  };

  const inputClasses = [
    'currency-amount-input',
    error ? 'has-error' : '',
    disabled ? 'is-disabled' : '',
    className
  ].filter(Boolean).join(' ');

  return (
    <div className="currency-input-container">
      {label && (
        <label htmlFor={id} className="currency-input-label">
          {label}
          {required && <span className="required-mark">*</span>}
        </label>
      )}

      <div className="currency-input-wrapper">
        <div className="currency-amount-wrapper">
          <input
            id={id}
            type="number"
            value={amount}
            onChange={(e) => {
              const newAmount = parseFloat(e.target.value) || 0;
              handleChange(newAmount, value.currency);
            }}
            className={inputClasses}
            disabled={disabled}
            required={required}
            min="0"
            step="0.01"
            placeholder="0.00"
            onFocus={handleFocus}
          />
          <span className="currency-symbol">
            {ALL_CURRENCIES.find(c => c.code === value.currency)?.symbol}
          </span>
        </div>

        <Select
          id={`${id}-currency`}
          name={`${id}-currency`}
          value={value.currency}
          onChange={(newCurrency) => handleChange(amount, newCurrency)}
          options={currencyOptions}
          disabled={disabled}
          required={required}
        />
      </div>

      {helper && !error && (
        <p className="currency-input-helper">{helper}</p>
      )}
      
      {error && (
        <p className="currency-input-error" role="alert">{error}</p>
      )}
    </div>
  );
};

export default CurrencyInput; 