import { useNavigate, useLocation } from 'react-router-dom';
import { PUBLIC_NAV_ITEMS, ORGANIZER_NAV_ITEMS, type NavItemConfig } from '../configs';

interface NavigationOptions {
  viewMode?: 'list' | 'map';
  activePath?: string;
  customItems?: Array<NavItem>;
  favoritesCount?: number;
  onViewModeChange?: () => void;
}

interface NavItem extends Omit<NavItemConfig, 'path'> {
  onClick?: () => void;
  badge?: number;
  viewMode?: 'list' | 'map';
  type?: 'filter' | 'menu';
}

export const useNavigation = (type: 'public' | 'organizer') => {
  const navigate = useNavigate();
  const location = useLocation();
  
  const getNavigationItems = (options: NavigationOptions = {}): NavItem[] => {
    const {
      viewMode = 'list',
      activePath = location.pathname,
      customItems = [],
      favoritesCount,
      onViewModeChange
    } = options;

    const configs = type === 'public' ? PUBLIC_NAV_ITEMS : ORGANIZER_NAV_ITEMS;
    
    if (type === 'public') {
      return [
        // View Mode (List/Map)
        {
          icon: viewMode === 'list' ? 'Map' : 'List',
          label: viewMode === 'list' ? 'Map view' : 'Swipe mode',
          onClick: () => {
            // Si on est sur une route spéciale (PreviewCard, etc.)
            if (activePath !== '/' && onViewModeChange) {
              navigate('/', { replace: true });
              // On attend que la navigation soit terminée
              setTimeout(() => {
                onViewModeChange();
              }, 50);
            } else if (onViewModeChange) {
              onViewModeChange();
            } else {
              navigate('/');
            }
          },
          viewMode: viewMode === 'list' ? 'map' : 'list'
        },
        // Filters
        {
          ...PUBLIC_NAV_ITEMS.filter,
          type: 'filter' as const
        },
        // Favorites
        {
          ...PUBLIC_NAV_ITEMS.favorites,
          onClick: () => navigate(PUBLIC_NAV_ITEMS.favorites.path),
          badge: favoritesCount
        },
        // Menu
        {
          ...PUBLIC_NAV_ITEMS.menu,
          type: 'menu' as const
        },
        ...customItems
      ];
    }

    // Pour le dashboard organizer
    return Object.entries(configs).map(([key, config]) => ({
      ...config,
      onClick: () => navigate(config.path),
      ...customItems.find(item => item.icon === config.icon)
    }));
  };

  return {
    getNavigationItems
  };
}; 