import React from 'react';
import './OfflineMessage.css';

interface OfflineMessageProps {
  hasCache: boolean;
}

const OfflineMessage: React.FC<OfflineMessageProps> = ({ hasCache }) => {
  return (
    <div className="offline-message">
      {hasCache ? (
        <>
          <h3>Offline mode</h3>
          <p>You are viewing the last loaded events</p>
        </>
      ) : (
        <>
          <h3>Connection required</h3>
          <p>An initial connection is needed to load events</p>
          <button 
            onClick={() => window.location.reload()}
            className="retry-button"
          >
            Retry
          </button>
        </>
      )}
    </div>
  );
};

export default OfflineMessage; 