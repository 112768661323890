import React, { useState } from 'react';
import DistanceSlider from '../primitives/DistanceSlider';
import { useRandomMonkey } from '../../../hooks/useRandomMonkey';
interface CodeSection {
  id: string;
  isCollapsed: boolean;
}

const DistanceSliderSection: React.FC = () => {
  // États pour les démos
  const [distance, setDistance] = useState(25);
  const [customDistance, setCustomDistance] = useState(10);

  // État pour le collapse des sections de code
  const [collapsedSections, setCollapsedSections] = React.useState<CodeSection[]>([
    { id: 'basic', isCollapsed: true },
    { id: 'custom', isCollapsed: true }
  ]);

  const toggleSection = (sectionId: string) => {
    setCollapsedSections(prev => prev.map(section => 
      section.id === sectionId 
        ? { ...section, isCollapsed: !section.isCollapsed }
        : section
    ));
  };

  const isSectionCollapsed = (sectionId: string) => {
    return collapsedSections.find(section => section.id === sectionId)?.isCollapsed ?? true;
  };

  // Nouveau hook avec positions disponibles
  const { getMonkeyProps } = useRandomMonkey({
    mode: 'atLeastOne',
    availablePositions: [0, 1, 2]  // Un index pour chaque slider
  });

  return (
    <section className="component-section">
      <div className="controls-showcase">
        {/* Basic Distance Slider */}
        <div className="control-type-container">
          <div className="control-group">
            <h4>Distance Slider</h4>
            <p className="control-description">
              Curseur de distance avec valeurs min/max et unité personnalisables.
              Parfait pour filtrer les résultats par distance.
            </p>
            <div className="control-preview">
              <div style={{ maxWidth: '300px' }}>
                <DistanceSlider
                  {...getMonkeyProps(0, true)}  // Premier slider
                  value={distance}
                  onChange={setDistance}
                  min={0}
                  max={50}
                  unit="km"
                />
                <div style={{ marginTop: '1rem', color: 'var(--text-secondary)' }}>
                  Distance sélectionnée : {distance}km
                </div>
              </div>
            </div>
          </div>
          <div className="component-code">
            <div className={`code-example ${isSectionCollapsed('basic') ? 'collapsed' : ''}`}>
              <h3 onClick={() => toggleSection('basic')}>Code</h3>
              <pre>
{`const [distance, setDistance] = useState(25);

<DistanceSlider
  value={distance}
  onChange={setDistance}
  min={0}
  max={50}
  unit="km"
/>`}
              </pre>
            </div>
          </div>
        </div>

        {/* Custom Distance Sliders */}
        <div className="control-type-container">
          <div className="control-group">
            <h4>Distance Slider Personnalisé</h4>
            <p className="control-description">
              Version personnalisée avec pas de 5km et état désactivé.
            </p>
            <div className="control-preview">
              <div style={{ maxWidth: '300px', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                <DistanceSlider
                  {...getMonkeyProps(1, true)}  // Deuxième slider
                  value={customDistance}
                  onChange={setCustomDistance}
                  min={0}
                  max={100}
                  step={5}
                  unit="km"
                />
                <DistanceSlider
                  {...getMonkeyProps(2, true)}  // Troisième slider
                  value={50}
                  onChange={() => {}}
                  min={0}
                  max={100}
                  unit="km"
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="component-code">
            <div className={`code-example ${isSectionCollapsed('custom') ? 'collapsed' : ''}`}>
              <h3 onClick={() => toggleSection('custom')}>Code</h3>
              <pre>
{`// Slider avec pas de 5km
<DistanceSlider
  value={distance}
  onChange={setDistance}
  min={0}
  max={100}
  step={5}
  unit="km"
/>

// Slider désactivé
<DistanceSlider
  value={50}
  onChange={() => {}}
  min={0}
  max={100}
  unit="km"
  disabled
/>`}
              </pre>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DistanceSliderSection; 