import { useMotionValue, useTransform, animate, MotionValue } from 'framer-motion';
import { useHiddenEvents } from './useHiddenEvents';

export interface SwipeAction {
  eventId: string;
  seriesId?: string;
  reason: 'dismissed' | 'favorited';
  timestamp: number;
}

interface DragProps {
  drag: "x";
  dragConstraints: { left: number; right: number };
  dragElastic: number;
  dragTransition: { bounceStiffness: number; bounceDamping: number };
  whileDrag: { cursor: string };
  onDragEnd: (e: any, info: { offset: { x: number }; velocity: { x: number } }) => void;
}

interface SwipeCardReturn {
  dragProps: DragProps;
  style: {
    x: MotionValue<number>;
    rotate: MotionValue<number>;
  };
  indicators: {
    like: {
      scale: MotionValue<number>;
      opacity: MotionValue<number>;
    };
    dismiss: {
      scale: MotionValue<number>;
      opacity: MotionValue<number>;
    };
  };
  // Rétrocompatibilité
  x: MotionValue<number>;
  rotate: MotionValue<number>;
  likeScale: MotionValue<number>;
  dismissScale: MotionValue<number>;
  likeOpacity: MotionValue<number>;
  dismissOpacity: MotionValue<number>;
  handleDragEnd: (e: any, info: { offset: { x: number }; velocity: { x: number } }) => void;
}

const SWIPE_THRESHOLD = 125; // Distance minimale pour déclencher le swipe
const VELOCITY_THRESHOLD = 500; // Vélocité minimale pour un swipe rapide
const ANIMATION_DURATION = 0.5; // Durée de l'animation en secondes

export const useSwipeCard = (
  eventId: string,
  onLike: () => void,
  onDismiss: () => void,
  seriesId?: string,
  seriesEvents?: Event[]
): SwipeCardReturn => {
  const { addToHiddenEvents } = useHiddenEvents();
  const x = useMotionValue(0);
  const rotate = useTransform(x, [-200, 0, 200], [-10, 0, 10]);
  const likeScale = useTransform(x, [0, SWIPE_THRESHOLD], [0.8, 1]);
  const dismissScale = useTransform(x, [-SWIPE_THRESHOLD, 0], [1, 0.8]);
  const likeOpacity = useTransform(x, [0, SWIPE_THRESHOLD], [0, 1]);
  const dismissOpacity = useTransform(x, [-SWIPE_THRESHOLD, 0], [1, 0]);

  const handleDragEnd = async (_: any, info: { offset: { x: number }; velocity: { x: number } }) => {
    const offset = info.offset.x;
    const velocity = info.velocity.x;

    const shouldSwipeRight = offset > SWIPE_THRESHOLD || (offset > 10 && velocity > VELOCITY_THRESHOLD);
    const shouldSwipeLeft = offset < -SWIPE_THRESHOLD || (offset < -10 && velocity < -VELOCITY_THRESHOLD);

    if (shouldSwipeRight) {
      await animate(x, window.innerWidth * 1.5, {
        type: "spring",
        duration: ANIMATION_DURATION,
        onComplete: () => {
          // Pour les événements récurrents, on utilise l'ID de la série
          const idToHide = seriesId || eventId;
          addToHiddenEvents(idToHide, 'favorited');
          onLike();
        }
      });
    } else if (shouldSwipeLeft) {
      await animate(x, -window.innerWidth * 1.5, {
        type: "spring",
        duration: ANIMATION_DURATION,
        onComplete: () => {
          const idToHide = seriesId || eventId;
          addToHiddenEvents(idToHide, 'dismissed');
          onDismiss();
        }
      });
    } else {
      animate(x, 0, {
        type: "spring",
        stiffness: 500,
        damping: 30
      });
    }
  };

  // Regrouper toutes les propriétés de drag
  const dragProps = {
    drag: "x" as const,
    dragConstraints: { left: 0, right: 0 },
    dragElastic: 1,
    dragTransition: { 
      bounceStiffness: 1000, 
      bounceDamping: 30 
    },
    whileDrag: { cursor: "grabbing" },
    onDragEnd: handleDragEnd
  };

  // Regrouper les styles d'animation
  const style = {
    x,
    rotate
  };

  // Regrouper les indicateurs visuels
  const indicators = {
    like: {
      scale: likeScale,
      opacity: likeOpacity
    },
    dismiss: {
      scale: dismissScale,
      opacity: dismissOpacity
    }
  };

  return {
    dragProps,
    style,
    indicators,
    // On garde ces props individuelles pour la rétrocompatibilité
    x,
    rotate,
    likeScale,
    dismissScale,
    likeOpacity,
    dismissOpacity,
    handleDragEnd
  };
}; 