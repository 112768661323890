import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap, useMapEvents } from 'react-leaflet';
import { calculateMapBounds, clusterEvents } from '../../utils/mapUtils';
import { Event } from '../../types/event.types';
import { useGeolocation } from '../../utils/hooks/useGeolocation';
import { calculateDistance, sortEvents } from '../../utils/eventSorting';
import { useNavigate } from 'react-router-dom';
import { LeafletMarker, UserLocationMarker, Button, Icon, ContentBlock} from '../../pages/DesignSystem';
import { getRandomEmptyStateMessage } from './emptyStateMessages';

import 'leaflet/dist/leaflet.css';
import './MapView.css';
import L from 'leaflet';

const MapUpdater = React.memo(({ 
  bounds, 
  zoom, 
  shouldUpdate 
}: { 
  bounds?: L.LatLngBounds; 
  zoom: number;
  shouldUpdate: boolean;
}) => {
  const map = useMap();
  
  useEffect(() => {
    // Ne centrer la carte que lors du chargement initial
    if (shouldUpdate && bounds && bounds.isValid()) {
      map.fitBounds(bounds, {
        padding: [50, 50],
        maxZoom: zoom
      });
    }
  }, []); // Dépendances vides pour n'exécuter qu'au montage
  
  return null;
});

const MapEventHandler = React.memo(({ 
  onMoveEnd,
  onZoomEnd,
  onMapClick
}: { 
  onMoveEnd: (center: [number, number]) => void;
  onZoomEnd: (zoom: number) => void;
  onMapClick?: () => void;
}) => {
  const map = useMapEvents({
    moveend: () => {
      const center = map.getCenter();
      onMoveEnd([center.lat, center.lng]);
    },
    zoomend: () => {
      onZoomEnd(map.getZoom());
    },
    click: (e) => {
      // Vérifier que le clic n'est pas sur un marker
      const target = e.originalEvent.target as HTMLElement;
      const isMarkerClick = target.closest('.leaflet-marker-icon');
      
      if (!isMarkerClick && onMapClick) {
        onMapClick();
      }
    }
  });
  return null;
});

interface MapViewProps {
  events: Event[];
  seriesMap?: Map<string, Event[]>;
  onEventClick: (event: Event) => void;
  onLocationChange?: (location: { 
    latitude: number; 
    longitude: number;
    shouldCenter?: boolean;
  }) => void;
  initialLocation?: { latitude: number; longitude: number };
  onMapClick?: () => void;
} 

const MapMarkerHandler = React.memo(({ 
  cluster,
  onMarkerClick,
  activeEventId,
}: { 
  cluster: ReturnType<typeof clusterEvents>[0];
  onMarkerClick: (cluster: ReturnType<typeof clusterEvents>[0]) => void;
  activeEventId: string | null;
}) => {
  const map = useMap();

  const ensureMarkerVisibility = useCallback((markerPosition: L.LatLng): Promise<void> => {
    return new Promise((resolve) => {
      // Dimensions de base
      const viewportHeight = window.innerHeight;
      const isSingleEvent = cluster.events.length === 1;
      
      // 1. Définir la zone cible fixe (en pixels depuis le haut)
      const bottomNavHeight = 64;
      const modalHeight = isSingleEvent ? viewportHeight * 0.3 : viewportHeight * 0.5;
      const margin = isSingleEvent ? 100 : 0;
      const targetY = viewportHeight - (bottomNavHeight + modalHeight + margin);

      // 2. Position actuelle du marker sur l'écran
      const markerPoint = map.latLngToContainerPoint(markerPosition);
      
      // 3. Vérifier si un déplacement est nécessaire
      if (markerPoint.y > targetY) {
        // Convertir la position cible en coordonnées
        const currentCenter = map.getCenter();
        const targetPoint = L.point(markerPoint.x, targetY);
        const targetLatLng = map.containerPointToLatLng(targetPoint);
        
        // Calculer le déplacement nécessaire
        const latDiff = targetLatLng.lat - markerPosition.lat;
        const newCenter = L.latLng(
          currentCenter.lat - latDiff,
          currentCenter.lng
        );
        
        map.once('moveend', () => {
          setTimeout(resolve, 100);
        });

        map.panTo(newCenter, {
          animate: true,
          duration: 0.5
        });
      } else {
        resolve();
      }
    });
  }, [map, cluster.events.length]);

  const handleClick = useCallback(() => {
    ensureMarkerVisibility(L.latLng(cluster.center.lat, cluster.center.lng));
    onMarkerClick(cluster);
  }, [cluster, ensureMarkerVisibility, onMarkerClick]);

  return (
    <LeafletMarker
      key={`cluster-${cluster.events.length}-${cluster.center.lat}-${cluster.center.lng}`}
      position={[cluster.center.lat, cluster.center.lng]}
      size={cluster.events.length > 1 ? 'lg' : 'md'}
      isSelected={cluster.events.length === 1 && cluster.events[0].id === activeEventId}
      isFavorite={cluster.events.length === 1 && localStorage.getItem(`favorite-${cluster.events[0].id}`) === 'true'}
      isCluster={cluster.events.length > 1}
      count={cluster.events.length > 1 ? cluster.events.length : undefined}
      onClick={handleClick}
    />
  );
});

const MapCenterHandler = React.memo(({ 
  location, 
  shouldCenter 
}: { 
  location: [number, number]; 
  shouldCenter?: boolean;
}) => {
  const map = useMap();

  useEffect(() => {
    if (shouldCenter) {
      map.setView(location, 13, {
        animate: true,
        duration: 0.5
      });
    }
  }, [location, shouldCenter, map]);

  return null;
});

const SearchAreaButton = React.memo(({ 
  onClick, 
  isLoading 
}: { 
  onClick: () => void;
  isLoading: boolean;
}) => (
  <div className="search-area-button-container">
    <Button 
      onClick={onClick}
      disabled={isLoading}
      variant="outline"
    >
      <Icon name={isLoading ? "Loader" : "Search"} size={20} />
      <span>{isLoading ? "Loading..." : "Search in this area"}</span>
    </Button>
  </div>
));

const MapView: React.FC<MapViewProps> = React.memo(({ events, seriesMap, onEventClick, onLocationChange, initialLocation, onMapClick }) => {
  const mapRef = useRef<L.Map | null>(null);
  const { location, updateFromMap } = useGeolocation();
  const navigate = useNavigate();
  const [initialLoad, setInitialLoad] = useState(true);
  const [currentZoom, setCurrentZoom] = useState(13);
  const [isLoading, setIsLoading] = useState(false);
  const [activeEventId, setActiveEventId] = useState<string | null>(null);
  const [shouldShowSearchButton, setShouldShowSearchButton] = useState(true);
  const [lastSearchCenter, setLastSearchCenter] = useState<[number, number] | null>(
    initialLocation ? [initialLocation.latitude, initialLocation.longitude] : null
  );
  const [isSearching, setIsSearching] = useState(false);
  const [visibleEvents, setVisibleEvents] = useState<number>(0);

  // Mémorisation de la location avec le centre
  const locationWithCenter = useMemo(() => 
    location ? {
      coordinates: [location.latitude, location.longitude] as [number, number],
      shouldCenter: location.shouldCenter ?? false
    } : null
  , [location]);

  // Centre par défaut mémorisé
  const mapCenter = useMemo(() => 
    locationWithCenter?.coordinates ?? [46.227638, 2.213749] as [number, number]
  , [locationWithCenter]);

  const sortedEvents = useMemo(() => 
    sortEvents(
      events,
      'map',
      location ? {
        latitude: location.latitude,
        longitude: location.longitude
      } : undefined
    ),
  [events, location]
  );

  // Recalculer les clusters quand le zoom change
  const clusters = useMemo(() => 
    clusterEvents(sortedEvents, currentZoom),
    [sortedEvents, currentZoom]
  );

  const checkVisibleEvents = useCallback(() => {
    const map = mapRef.current;
    if (!map) return;

    const bounds = map.getBounds();
    const visibleCount = clusters.reduce((count, cluster) => {
      const isVisible = bounds.contains([
        cluster.center.lat,
        cluster.center.lng
      ]);
      return count + (isVisible ? cluster.events.length : 0);
    }, 0);

    setVisibleEvents(visibleCount);
  }, [clusters]);

  const handleZoomEnd = useCallback(() => {
    const map = mapRef.current;
    if (!map) return;
    
    const newZoom = map.getZoom();
    setCurrentZoom(newZoom);
    // On peut maintenant appeler checkVisibleEvents de manière sûre
    requestAnimationFrame(() => {
      checkVisibleEvents();
    });
  }, [checkVisibleEvents]);

  const { bounds, zoom } = useMemo(() => 
    calculateMapBounds(
      sortedEvents, 
      location ? {
        latitude: location.latitude,
        longitude: location.longitude
      } : undefined
    ),
    [sortedEvents, location]
  );

  // Mettre à jour les événements visibles lors du déplacement/zoom
  const handleMoveEnd = useCallback((center: [number, number]) => {
    checkVisibleEvents();
    
    // Vérifier si on est loin du dernier point de recherche
    if (lastSearchCenter) {
      const distance = calculateDistance(
        center[0],
        center[1],
        lastSearchCenter[0],
        lastSearchCenter[1]
      );
      setShouldShowSearchButton(distance > 2);
    }
  }, [lastSearchCenter, checkVisibleEvents]);

  const handleMarkerClick = useCallback((cluster: ReturnType<typeof clusterEvents>[0]) => {
    navigate(`/events/${encodeURIComponent(cluster.events[0].location || '')}`, {
      state: {
        showMultiEventModal: true,
        multiEvents: cluster.events,
        isMapView: true,
        userLocation: location,
        isSingleEvent: cluster.events.length === 1,
        isSeriesEvent: cluster.events.length === 1 && cluster.events[0].seriesId !== undefined
      },
      replace: true
    });
  }, [navigate, location]);

  const markers = useMemo(() => 
    clusters.map((cluster) => (
      <MapMarkerHandler
        key={`cluster-${cluster.events.length}-${cluster.center.lat}-${cluster.center.lng}`}
        cluster={cluster}
        onMarkerClick={handleMarkerClick}
        activeEventId={activeEventId}
      />
    )),
    [clusters, handleMarkerClick, activeEventId]
  );

  // Désactiver initialLoad après le premier rendu
  useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false);
    }
  }, []);

  // Mettre à jour lastSearchCenter quand la location change via LocationFilter
  useEffect(() => {
    if (initialLocation) {
      setLastSearchCenter([initialLocation.latitude, initialLocation.longitude]);
      setShouldShowSearchButton(false); // Cacher le bouton car on a déjà une recherche active
    }
  }, [initialLocation]);

  const handleSearchArea = async () => {
    const map = mapRef.current;
    if (!map) return;

    const center = map.getCenter();
    setIsSearching(true);

    try {
      // Utiliser exactement la même logique que LocationFilter pour le chargement
      const newLocation = {
        latitude: center.lat,
        longitude: center.lng,
        timestamp: Date.now(),
        source: 'manual'
      };
      
      // Déclencher la même séquence d'événements que LocationFilter
      localStorage.setItem('userLocation', JSON.stringify(newLocation));
      window.dispatchEvent(new StorageEvent('storage', {
        key: 'userLocation',
        newValue: JSON.stringify(newLocation)
      }));
      
      // Sécuriser l'appel à onLocationChange
      if (onLocationChange) {
        onLocationChange({ 
          latitude: center.lat,
          longitude: center.lng,
          shouldCenter: false
        });
      }

      setLastSearchCenter([center.lat, center.lng]);
      setShouldShowSearchButton(false);
    } finally {
      setIsSearching(false);
    }
  };

  const EmptyStateMessage = () => {
    const message = useMemo(() => getRandomEmptyStateMessage(), []);
    
    return (
      <div className="map-empty-state">
        <ContentBlock
          
          title={message.title}
          subtitle={message.subtitle}
          variant="light"
          padding="md"
          size="sm"
          centered
        >
          <div />
        </ContentBlock>
      </div>
    );
  };

  // Ajouter cet useEffect pour vérifier les événements visibles lorsque les clusters changent
  useEffect(() => {
    // Attendre un court instant pour s'assurer que la carte est prête
    const timer = setTimeout(() => {
      checkVisibleEvents();
    }, 300);
    
    return () => clearTimeout(timer);
  }, [clusters, checkVisibleEvents]);

  // Ajouter cet effet après le rendu initial des marqueurs
  useEffect(() => {
    // Vérifier que les clusters ont été calculés et les marqueurs générés
    if (clusters.length > 0 && !isLoading) {
      // Laisser le temps à Leaflet de rendre les marqueurs visuellement
      const timer = setTimeout(() => {
        console.log('Map prête avec', clusters.length, 'clusters et', events.length, 'événements');
        window.dispatchEvent(new CustomEvent('mapReady'));
      }, 100);
      
      return () => clearTimeout(timer);
    }
    // Si pas de marqueurs mais les événements sont chargés, la carte est quand même prête
    else if (events.length >= 0 && !isLoading) {
      console.log('Map prête sans clusters');
      window.dispatchEvent(new CustomEvent('mapReady'));
    }
  }, [clusters, events, isLoading]);

  return (
    <div className="map-container">
      {isLoading && (
        <div className="map-loading-indicator">
          <Icon name="Loader" size={20} /> Chargement...
        </div>
      )}

     
      <MapContainer
        ref={mapRef}
        center={mapCenter}
        zoom={13}
        style={{ height: '100%' }}
        zoomControl={false}
      >
        {locationWithCenter && (
          <MapCenterHandler 
            location={locationWithCenter.coordinates}
            shouldCenter={locationWithCenter.shouldCenter}
          />
        )}
        <MapUpdater 
          bounds={bounds} 
          zoom={zoom} 
          shouldUpdate={initialLoad}
        />
        <MapEventHandler 
          onMoveEnd={handleMoveEnd}
          onZoomEnd={handleZoomEnd}
          onMapClick={onMapClick}
        />
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        />
        
        {location?.latitude && location?.longitude && (
          <UserLocationMarker position={[location.latitude, location.longitude]} />
        )}

        {markers}

        {!isLoading && 
          !shouldShowSearchButton && 
          visibleEvents === 0 && 
          clusters.length === 0 && (
          <EmptyStateMessage />
        )}

        {shouldShowSearchButton && !isLoading && (
          <SearchAreaButton
            onClick={handleSearchArea}
            isLoading={isSearching}
          />
        )}
      </MapContainer>
    </div>
  );
});

export default MapView;
