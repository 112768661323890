import React, { useState, useMemo } from 'react';
import { FormData } from '../types';
import { PREDEFINED_TAGS } from '../../../../pages/DesignSystem/types/tags.types';
import { formatDateTime } from '../../../../utils/dateFormatter';
import { useEventSeries } from '../../../../hooks/useEventSeries';
import { 
  Icon, 
    ContentBlock,
  DateIcon,
  LogoSVG,
  Button,
  ShareButton,
  FavoriteButton,
  NotificationBubble,
  Tag
} from '../../../../pages/DesignSystem';
import { MapContainer, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import '../../../../components/event/card.css';
import '../../../../components/event/RecurringEventCard.css';
import { useOrganizer } from '../../../../contexts/OrganizerContext';

const DAYS_ORDER = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

interface PreviewStepProps {
  formData: FormData;
}

const PreviewStep: React.FC<PreviewStepProps> = ({ formData }) => {
  const { organizerProfile } = useOrganizer();
  const [showModal, setShowModal] = useState(false);
  const [showShareNotification, setShowShareNotification] = useState(false);
  const [showDates, setShowDates] = useState(false);
  
  const getEndDate = () => {
    if (!formData.isRecurring || !formData.startDate) return undefined;
    
    if (formData.endType === 'date' && formData.endDate) {
      return formData.endDate;
    }
    
    if (formData.endType === 'occurrences' && formData.occurrences) {
      const startDate = new Date(formData.startDate);
      const weeksNeeded = Math.ceil(formData.occurrences / (formData.weekDays?.length || 1));
      const endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + (weeksNeeded * 7));
      return endDate.toISOString();
    }
    
    return undefined;
  };

  const previewSeries = formData.isRecurring ? {
    id: 'preview',
    organizerId: 'preview',
    title: formData.title,
    status: 'draft' as const,
    createdAt: new Date(),
    updatedAt: new Date(),
    frequency: formData.frequency,
    weekDays: formData.weekDays || [],
    startDate: formData.startDate,
    endDate: getEndDate(),
    endType: formData.endType,
    occurrences: formData.endType === 'occurrences' ? formData.occurrences : undefined,
    time: formData.time || formData.startTime,
    endTime: formData.endTime
  } : undefined;

  const { formatRecurrencePattern } = useEventSeries(undefined, previewSeries);

  const formatDateForIcon = (datetime: Date | undefined) => {
    if (!datetime) return { month: '', day: '' };
    const date = new Date(datetime);
    const month = date.toLocaleString('en-US', { month: 'short' }).toUpperCase();
    const day = date.getDate();
    return { month, day };
  };

  const renderPrice = () => {
    if (!formData.prices || formData.prices.length === 0 || formData.prices[0].amount === 0) {
      return (
        <Tag
          label="Free"
          type="price"
          isFree={true}
          size="md"
        />
      );
    }

    if (formData.prices.length > 1) {
      const totalPrices = formData.prices.length;
      const minPrice = Math.min(...formData.prices.map(p => p.amount));
      const maxPrice = Math.max(...formData.prices.map(p => p.amount));
      const currency = formData.prices[0].currency;
      const currencySymbol = currency === 'EUR' ? '€' : currency;

      return (
        <Tag
          label={`${minPrice}${currencySymbol} - ${maxPrice}${currencySymbol}`}
          type="price"
          size="md"
        />
      );
    }

    const price = formData.prices[0];
    const currencySymbol = price.currency === 'EUR' ? '€' : price.currency;

    return (
      <Tag
        label={`${price.amount}${currencySymbol}`}
        type="price"
        size="md"
      />
    );
  };

  const renderTags = () => (
    <div className="event-card__tags">
      
     

      {/* Tags d'activité */}
      {(formData.tags || []).map((tagId: string) => {
        const tag = PREDEFINED_TAGS.find(t => t.id === tagId);
        return tag ? (
          <Tag 
            key={tagId} 
            label={`${tag.icon} ${tag.label}`}
            type="activity"
            size="md"
          />
        ) : null;
      })}
    </div>
  );

  const getDateTime = () => {
    if (formData.isRecurring) {
      return formData.startDate ? new Date(formData.startDate) : undefined;
    }
    return formData.date ? new Date(formData.date) : undefined;
  };

  const recurrencePattern = formatRecurrencePattern();

  const previewSeriesEvents = useMemo(() => {
    if (!formData.isRecurring || !formData.startDate) return [];
    
    const events = [];
    const startDate = new Date(formData.startDate);
    
    // Vérifier si la date est valide
    if (isNaN(startDate.getTime())) return [];
    
    const endDate = formData.endDate ? new Date(formData.endDate) : undefined;
    
    for (let i = 0; i < 5; i++) {
      const date = new Date(startDate);
      date.setDate(startDate.getDate() + (i * 7));
      
      if (endDate && date > endDate) break;
      
      events.push({
        id: `preview-${i}`,
        datetime: date.toISOString(),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      });
    }
    
    return events;
  }, [formData.isRecurring, formData.startDate, formData.endDate]);

  const renderPreview = () => {
    const datetime = getDateTime();

    const commonContent = (
      <>
        {/* Header avec tags et boutons d'action */}
        <div className="event-card__header">
          <div className="event-card__tags">
            {renderTags()}
          </div>
          <div className="event-card__actions">
            <ShareButton
              shareData={{
                title: formData.title,
                text: `what2do.today\nCome discover ${formData.title}`,
                url: window.location.href
              }}
              variant="icon"
              size="lg"
              onShareSuccess={() => setShowShareNotification(true)}
            />
            <FavoriteButton
              id="preview"
              size="lg"
            />
          </div>
        </div>

        {/* Main content */}
        <div className="event-card__main">
          <div className="event-card__image">
            {formData.image ? (
              <img 
                src={formData.image}
                alt={formData.title}
                className="event-image"
              />
            ) : (
              <LogoSVG 
                variant="eventConnect"
                size="auto"
                padding={16}
                color="var(--primary-dark)"
                className="event-fallback-logo"
              />
            )}
          </div>

          <div className="event-card__info">
            <h3 className="event-card__title">{formData.title}</h3>
            <ContentBlock>
              <div className="event-card__datetime">
                {formData.isRecurring ? (
                  <div className="event-card__time-info">
                    <span className="recurrence-pattern">
                      {formatRecurrencePattern()}
                      {formData.endType === 'date' && formData.endDate && (
                        ` until ${formatDateTime(new Date(formData.endDate)).date}`
                      )}
                    </span>
                  </div>
                ) : (
                  <>
                    <DateIcon 
                      {...formatDateForIcon(datetime)}
                      variant="light"
                      size="sm"
                    />
                    <div className="event-card__time-info">
                      <span className="day">
                        {formatDateTime(datetime).weekday}
                      </span>
                      <span className="hours">
                        {formData.time || formData.startTime}
                      </span>
                    </div>
                  </>
                )}
              </div>
            </ContentBlock>
          </div>
        </div>

        {/* Footer avec prix et localisation */}
        <div className="event-card__footer">
          {formData.isRecurring && previewSeriesEvents.length > 0 && (
            <ContentBlock padding="sm">
              <div className="event-card__dates interactive">
                <button 
                  className="event-card__dates-toggle interactive"
                  onClick={() => setShowDates(!showDates)}
                >
                  <Icon name={showDates ? "ChevronUp" : "ChevronDown"} size={16} />
                  {showDates ? "Hide dates" : "Show all dates"}
                </button>
                <div className={`event-card__dates-list interactive ${showDates ? 'event-card__dates-list--expanded' : ''}`}>
                  {previewSeriesEvents.map((event) => (
                    <div key={event.id} className="event-card__date-item interactive">
                      <div className="event-card__date-info">
                        <span className="event-card__date">
                          {formatDateTime(new Date(event.datetime)).date}
                        </span>
                        <span className="event-card__time">
                          {formData.time || formData.startTime}
                        </span>
                      </div>
                      <span className="event-card__status event-card__status--upcoming">
                        Preview
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </ContentBlock>
          )}

          {/* Prix multiples */}
          {formData.prices && formData.prices.length > 1 && (
            <ContentBlock>
              <span className="modal-item-content">
                <span className="modal-item-icon">
                  <Icon name="Ticket" size={32} />
                </span>
                <span className="price-list">
                  {formData.prices.map((price, index) => (
                    <span key={index} className="price-item">
                      {price.amount === 0 ? (
                        <span className="price-free">Free</span>
                      ) : (
                        <span className="price-amount">
                          {price.amount} {price.currency === 'EUR' ? '€' : price.currency}
                        </span>
                      )}
                      {price.description && (
                        <span className="price-description">
                          {' - '}{price.description}
                        </span>
                      )}
                    </span>
                  ))}
                </span>
              </span>
            </ContentBlock>
          )}

          {/* Location */}
          <ContentBlock>
            <div className="event-card__location">
              <div className="modal-item-icon">
                <Icon name="MapPinned" size={32} />
              </div>
              <div className="event-card__location-content">
                <div className="event-card__location-details">
                  <span className="event-card__location-name">{formData.locationName}</span>
                  {formData.latitude && formData.longitude && (
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => {}}
                    >
                      Get directions
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </ContentBlock>
        </div>

        {/* Bloc organisateur */}
        <ContentBlock 
          className="event-modal-organizer-block"
          padding="sm"
          spacing="md"
        >
          <div className="modal-item-content">
            <div className="modal-item-icon">
              {organizerProfile?.organizerImage ? (
                <img 
                  src={organizerProfile.organizerImage} 
                  alt={organizerProfile.organizer} 
                  className="event-modal-organizer-avatar"
                />
              ) : (
                <div className="event-modal-organizer-avatar-fallback">
                  {organizerProfile?.organizer?.[0] || 'O'}
                </div>
              )}
            </div>
            <div className="event-modal-organizer-info">
              <span className="event-modal-organizer-name">
                {organizerProfile?.organizer}
              </span>
              {organizerProfile?.subtitle && (
                <span className="event-modal-organizer-subtitle">
                  {organizerProfile.subtitle}
                </span>
              )}
            </div>
          </div>
        </ContentBlock>

        {/* Bloc description */}
        {formData.description && (
          <ContentBlock>
            <div className="modal-description-container">
              <p className="modal-description">
                {formData.description}
              </p>
            </div>
          </ContentBlock>
        )}
      </>
    );

    return (
      <div className={showModal ? 'event-modal' : 'event-card'}>
        {commonContent}
      </div>
    );
  };

  return (
    <div className="preview-step">
      

      <div className="preview-container">
        {renderPreview()}
      </div>

      <NotificationBubble
        show={showShareNotification}
        message="Link copied! ✨"
        type="success"
        duration={1500}
        onHide={() => setShowShareNotification(false)}
      />
    </div>
  );
};

export default PreviewStep;
