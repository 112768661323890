import React from 'react';
import '../../../styles/components/map-marker.css';

export interface MapMarkerProps {
  size?: 'sm' | 'md' | 'lg';
  isSelected?: boolean;
  isCluster?: boolean;
  isFavorite?: boolean;
  count?: number;
  logo?: string;
  className?: string;
}

export const MapMarker: React.FC<MapMarkerProps> = ({
  size,
  isSelected = false,
  isCluster = false,
  isFavorite = false,
  count,
  logo,
  className = ''
}) => {
  const classes = [
    'map-marker',
    size && `marker-${size}`,
    isSelected && 'marker-selected',
    isCluster && 'marker-cluster',
    isFavorite && 'marker-favorite',
    className
  ].filter(Boolean).join(' ');

  return (
    <div className={classes}>
      <div className="marker-content">
        {logo ? (
          <img src={logo} alt="Marker logo" />
        ) : count ? (
          count
        ) : null}
      </div>
    </div>
  );
};

interface MarkerGroupProps {
  children: React.ReactNode;
  className?: string;
}

export const MarkerGroup: React.FC<MarkerGroupProps> = ({ children, className = '' }) => {
  return (
    <div className={`marker-group ${className}`} style={{ display: 'flex', gap: '1rem' }}>
      {children}
    </div>
  );
}; 