import React from 'react';
import '../../../styles/components/GlassPanel.css';

interface GlassPanelProps {
  variant?: 'light' | 'dark';
  blur?: 'sm' | 'md' | 'lg';
  className?: string;
  children: React.ReactNode;
}

export const GlassPanel: React.FC<GlassPanelProps> = ({
  variant = 'light',
  blur = 'md',
  className,
  children
}) => {
  return (
    <div 
      className={`glass-panel glass-panel--${variant} glass-panel--blur-${blur} ${className || ''}`}
    >
      {children}
    </div>
  );
};
