import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useAnimatedList } from '../../../../hooks/useAnimatedList';
import './AnimatedList.css';

interface AnimatedListProps {
  children: React.ReactNode[];
}

const AnimatedList: React.FC<AnimatedListProps> = ({ children }) => {
  // Utilisons l'ID approprié selon le type d'événement
  const items = React.Children.map(children, (child) => {
    const eventProps = (child as React.ReactElement).props;
    
    // Pour les événements récurrents, on utilise seriesId
    if (eventProps.mainEvent) {
      return {
        id: eventProps.mainEvent.seriesId || `series-${eventProps.mainEvent.id}`,
        content: child
      };
    }
    
    // Pour les événements simples, on utilise event.id
    if (eventProps.event) {
      return {
        id: eventProps.event.id,
        content: child
      };
    }

    // Fallback au cas où
    return {
      id: `item-${Math.random()}`,
      content: child
    };
  }) || [];

  const { animatedItems } = useAnimatedList(items, {
    exitDuration: 300
  });

  return (
    <motion.div className="animated-list">
      <AnimatePresence initial={false}>
        {animatedItems.map((item) => (
          <motion.div
            key={item.id}
            layout
            initial={{ x: -100 }}
            animate={{ x: 0 }}
            transition={{
              layout: {
                type: "spring",
                bounce: 0,
                duration: 0.3
              }
            }}
            style={{ position: 'relative' }}
          >
            {item.data.content}
          </motion.div>
        ))}
      </AnimatePresence>
      <div className="list-bottom-spacer" aria-hidden="true" />
    </motion.div>
  );
}; 

export { AnimatedList };