import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import EventForm from './EventForm';
import { eventService } from '../../../firebase/event.service';
import { Event } from '../../../types/event.types';
import './EventForm.css';

interface EventFormContainerProps {
  event?: Event;
  organizerId: string;
  onClose: () => void;
  onSubmit?: (eventData: Omit<Event, 'id'> & { id?: string }) => Promise<void>;
}

const EventFormContainer: React.FC<EventFormContainerProps> = ({ 
  event, 
  organizerId,
  onClose,
  onSubmit 
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    document.body.classList.add('modal-open');
    return () => {
      document.body.classList.remove('modal-open');
    };
  }, []);

  // Log les props reçues
  console.log('EventFormContainer mounted with props:', {
    hasEvent: !!event,
    eventId: event?.id,
    organizerId
  });

  const handleEventSubmit = async (
    eventData: Partial<Omit<Event, 'id'>> & { id?: string }
  ) => {
    const loadingToast = toast.loading(
      eventData.status === 'draft' ? 'Sauvegarde du brouillon...' : 'Publication de l\'événement...'
    );
  
    try {
      const eventWithOrganizer = {
        ...eventData,
        organizerId
      };
      
      if (event?.id) {
        // Mise à jour d'un événement existant
        const { id, ...updateData } = eventWithOrganizer;
        await eventService.updateEvent(event.id, updateData);
      } else {
        // Création d'un nouvel événement
        await eventService.createEvent(eventWithOrganizer);
      }
      
      toast.dismiss(loadingToast);
      toast.success(
        eventData.status === 'draft' 
          ? 'Brouillon sauvegardé avec succès !' 
          : 'Événement publié avec succès !'
      );
  
      // Fermer le modal et rediriger
      onClose();
      navigate('/organizer');
      
    } catch (error) {
      toast.dismiss(loadingToast);
      toast.error('Erreur lors de la sauvegarde de l\'événement');
      console.error('Erreur:', error);
    }
  };

  return (
    <div 
      className="event-form-overlay" 
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          console.log('Closing modal via overlay click');
          onClose();
        }
      }}
    >
      <div className="event-form-modal">
        <EventForm 
          event={event}
          organizerId={organizerId}
          onSubmit={handleEventSubmit}
          onClose={onClose}
        />
      </div>
    </div>
  );
};

export default EventFormContainer;