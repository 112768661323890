import React, { useState } from 'react';
import StatusMessage from '../primitives/StatusMessage';
import NotificationBubble from '../primitives/NotificationBubble';
import Button from '../primitives/Button';

interface CodeSection {
  id: string;
  isCollapsed: boolean;
}

interface Message {
  id: number;
  text: string;
  type: 'success' | 'error' | 'info';
}

// Tableau de blagues pour les messages informatifs
const funnyMessages = [
  "Why do programmers prefer dark mode? Because light attracts bugs! 🌚",
  "There are 10 types of people in the world: those who understand binary and those who don't! 🤓",
  "A bug is not a bug, it's an undocumented feature! 🐛",
  "Why did the developer go broke? Because he used up all his cache! 💸",
  "How does a developer open a door? git pull! 🚪",
  "Why don't programmers like nature? Too many bugs! 🌳",
  "What does a developer say when stuck? I'm looking for an exit() function! 🚶",
  "What do you call a developer who doesn't comment their code? A dev in denial! 📝",
  "What's a snake's favorite programming language? Python! 🐍",
  "Why did the developer quit their job? Not enough bytes! 💾",
  "How does a developer drink coffee? In Java! ☕",
  "What does a hungry developer eat? Cookies! 🍪",
  "Why is code like a joke? Not everyone gets it! 😅",
  "How does a developer stay fit? By doing push() ups! 💪",
  "What do developers say to each other? See you at merge time! 🤝"
];

const successMessages = [
  "Awesome! That's a total success! 🌟",
  "Mission accomplished brilliantly! 🎯",
  "Perfect! Everything went as planned! ✨",
  "Great job! Keep it up! 🚀",
  "That's in the box! 📦"
];

const errorMessages = [
  "Oops! Something went wrong... 😅",
  "Houston, we have a problem! 🛸",
  "Error 418: I'm a teapot! ☕",
  "The server hamster is tired... 🐹",
  "The matrix has a bug! 🕷️"
];

const StatusSection: React.FC = () => {
  const [collapsedSections, setCollapsedSections] = React.useState<CodeSection[]>([
    { id: 'success', isCollapsed: true },
    { id: 'error', isCollapsed: true },
    { id: 'info', isCollapsed: true },
    { id: 'bubble', isCollapsed: true }
  ]);

  const [messages, setMessages] = useState<Message[]>([]);
  const [showBubble, setShowBubble] = useState(false);
  const [bubbleMessage, setBubbleMessage] = useState('');
  const [bubbleType, setBubbleType] = useState<'success' | 'info' | 'error'>('success');

  const toggleSection = (sectionId: string) => {
    setCollapsedSections(prev => prev.map(section => 
      section.id === sectionId 
        ? { ...section, isCollapsed: !section.isCollapsed }
        : section
    ));
  };

  const isSectionCollapsed = (sectionId: string) => {
    return collapsedSections.find(section => section.id === sectionId)?.isCollapsed ?? true;
  };

  const getRandomMessage = (array: string[]) => {
    return array[Math.floor(Math.random() * array.length)];
  };

  const handleShowSuccess = () => {
    const newMessage: Message = {
      id: Date.now(),
      text: getRandomMessage(successMessages),
      type: 'success'
    };
    setMessages(prev => [...prev, newMessage]);
  };

  const handleShowError = () => {
    const newMessage: Message = {
      id: Date.now(),
      text: getRandomMessage(errorMessages),
      type: 'error'
    };
    setMessages(prev => [...prev, newMessage]);
  };

  const handleShowInfo = () => {
    const newMessage: Message = {
      id: Date.now(),
      text: getRandomMessage(funnyMessages),
      type: 'info'
    };
    setMessages(prev => [...prev, newMessage]);
  };

  const handleDismiss = (id: number) => {
    setMessages(prev => prev.filter(msg => msg.id !== id));
  };

  const handleShowBubble = (type: 'success' | 'info' | 'error') => {
    setBubbleType(type);
    setBubbleMessage(
      type === 'success' ? 'Successfully copied! ✨' :
      type === 'info' ? 'Link shared! 🔗' :
      'Action not possible! 🚫'
    );
    setShowBubble(true);
  };

  return (
    <section className="component-section">
      {/* Single StatusMessage component for all messages */}
      <StatusMessage 
        messages={messages}
        onDismiss={handleDismiss}
      />

      {/* NotificationBubble component */}
      <NotificationBubble
        show={showBubble}
        message={bubbleMessage}
        type={bubbleType}
        onHide={() => setShowBubble(false)}
      />

      {/* Success Message */}
      <div className="control-type-container">
        <div className="control-group">
          <h4>Success Message</h4>
          <p className="control-description">
            Confirmation message to inform the user that an action was successful.
          </p>
          <div className="control-preview">
            <Button onClick={handleShowSuccess}>Show Success Message</Button>
          </div>
        </div>
        <div className="component-code">
          <div className={`code-example ${isSectionCollapsed('success') ? 'collapsed' : ''}`}>
            <h3 onClick={() => toggleSection('success')}>Code</h3>
            <pre>
{`<StatusMessage 
  message="Success message!" 
  type="success" 
/>`}
            </pre>
          </div>
        </div>
      </div>

      {/* Error Message */}
      <div className="control-type-container">
        <div className="control-group">
          <h4>Error Message</h4>
          <p className="control-description">
            Error message to inform the user that something went wrong.
          </p>
          <div className="control-preview">
            <Button onClick={handleShowError} variant="danger">Show Error Message</Button>
          </div>
        </div>
        <div className="component-code">
          <div className={`code-example ${isSectionCollapsed('error') ? 'collapsed' : ''}`}>
            <h3 onClick={() => toggleSection('error')}>Code</h3>
            <pre>
{`<StatusMessage 
  message="Error message!" 
  type="error" 
/>`}
            </pre>
          </div>
        </div>
      </div>

      {/* Info Message */}
      <div className="control-type-container">
        <div className="control-group">
          <h4>Info Message</h4>
          <p className="control-description">
            Informative message with a touch of humor to brighten users' day.
          </p>
          <div className="control-preview">
            <Button onClick={handleShowInfo} variant="secondary">Show Random Joke</Button>
          </div>
        </div>
        <div className="component-code">
          <div className={`code-example ${isSectionCollapsed('info') ? 'collapsed' : ''}`}>
            <h3 onClick={() => toggleSection('info')}>Code</h3>
            <pre>
{`<StatusMessage 
  message="Developer joke!" 
  type="info" 
/>`}
            </pre>
          </div>
        </div>
      </div>

      {/* Notification Bubble Section */}
      <div className="control-type-container">
        <div className="control-group">
          <h4>Notification Bubble</h4>
          <p className="control-description">
            Light notification bubble for quick feedback (copy, share, etc.).
          </p>
          <div className="control-preview">
            <div className="button-sizes">
              <Button onClick={() => handleShowBubble('success')}>
                Copy
              </Button>
              <Button onClick={() => handleShowBubble('info')} variant="secondary">
                Share
              </Button>
              <Button onClick={() => handleShowBubble('error')} variant="danger">
                Error
              </Button>
            </div>
          </div>
        </div>
        <div className="component-code">
          <div className={`code-example ${isSectionCollapsed('bubble') ? 'collapsed' : ''}`}>
            <h3 onClick={() => toggleSection('bubble')}>Code</h3>
            <pre>
{`const [showBubble, setShowBubble] = useState(false);

<NotificationBubble
  show={showBubble}
  message="Successfully copied! ✨"
  type="success"
  duration={3000}
  onHide={() => setShowBubble(false)}
/>`}
            </pre>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StatusSection;