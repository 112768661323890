import React from 'react';
import { Marker, Popup } from 'react-leaflet';
import { MapMarker } from './MapMarker';
import L from 'leaflet';
import './LeafletMarker.css';

interface LeafletMarkerProps {
  position: [number, number];
  size?: 'sm' | 'md' | 'lg';
  isSelected?: boolean;
  isFavorite?: boolean;
  isCluster?: boolean;
  count?: number;
  onClick?: () => void;
}

export const LeafletMarker: React.FC<LeafletMarkerProps> = ({
  position,
  size = 'md',
  isSelected = false,
  isFavorite = false,
  isCluster = false,
  count,
  onClick
}) => {
  const customIcon = L.divIcon({
    className: 'custom-marker-icon',
    html: `
      <div class="map-marker marker-${size} ${isSelected ? 'marker-selected' : ''} ${isFavorite ? 'marker-favorite' : ''} ${isCluster ? 'marker-cluster' : ''}">
        <div class="marker-content">
          ${count ? count : ''}
        </div>
      </div>
    `,
    iconSize: size === 'lg' ? [32, 32] : size === 'md' ? [24, 24] : [16, 16],
    iconAnchor: size === 'lg' ? [16, 32] : size === 'md' ? [12, 24] : [8, 16],
  });

  return (
    <Marker 
      position={position} 
      icon={customIcon}
      eventHandlers={{ click: onClick }}
    />
  );
};

interface UserLocationMarkerProps {
  position: [number, number];
}

export const UserLocationMarker: React.FC<UserLocationMarkerProps> = ({
  position,
}) => {
  const userIcon = L.divIcon({
    className: 'custom-marker-icon',
    html: '<div class="user-location-marker"><div class="user-location-dot"></div></div>',
    iconSize: [12, 12],
    iconAnchor: [6, 6],
  });

  return (
    <Marker 
      position={position} 
      icon={userIcon}
    >
      <Popup>You are here</Popup>
    </Marker>
  );
}; 