import React, { useState } from 'react';
import SearchBar from '../primitives/SearchBar';

interface CodeSection {
  id: string;
  isCollapsed: boolean;
}

const SearchBarSection: React.FC = () => {
  const [query, setQuery] = useState('');
  const [collapsedSections, setCollapsedSections] = useState<CodeSection[]>([
    { id: 'code', isCollapsed: true },
    { id: 'props', isCollapsed: true }
  ]);

  const toggleSection = (sectionId: string) => {
    setCollapsedSections(prev => prev.map(section => 
      section.id === sectionId 
        ? { ...section, isCollapsed: !section.isCollapsed }
        : section
    ));
  };

  const isSectionCollapsed = (sectionId: string) => {
    return collapsedSections.find(section => section.id === sectionId)?.isCollapsed ?? true;
  };

  return (
    <section className="component-section">
      <div className="control-type-container">
        <div className="control-group">
          <h4>Search Bar</h4>
          <p className="control-description">
            Barre de recherche moderne et accessible, basée sur le composant Input.
            Intègre une icône de recherche et un style cohérent avec le Design System.
          </p>

          <div className="control-preview">
            <SearchBar
              query={query}
              onQueryChange={setQuery}
              placeholder="Search..."
            />
          </div>
        </div>

        <div className="component-documentation">
          <div className="code-example">
            <h3 onClick={() => toggleSection('code')}>Code</h3>
            {!isSectionCollapsed('code') && (
              <pre>
{`const [query, setQuery] = useState('');

<SearchBar
  query={query}
  onQueryChange={setQuery}
  placeholder="Search..."
/>`}
              </pre>
            )}
          </div>

          <div className="code-example">
            <h3 onClick={() => toggleSection('props')}>Props</h3>
            {!isSectionCollapsed('props') && (
              <div className="props-table">
                <table>
                  <thead>
                    <tr>
                      <th>Prop</th>
                      <th>Type</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>query</td>
                      <td>string</td>
                      <td>Texte de recherche actuel</td>
                    </tr>
                    <tr>
                      <td>onQueryChange</td>
                      <td>{`(text: string) => void`}</td>
                      <td>Fonction pour mettre à jour le texte de recherche</td>
                    </tr>
                    <tr>
                      <td>placeholder</td>
                      <td>string?</td>
                      <td>Texte d'aide affiché quand le champ est vide</td>
                    </tr>
                    <tr>
                      <td>className</td>
                      <td>string?</td>
                      <td>Classes CSS additionnelles</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SearchBarSection; 