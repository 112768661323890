import React, { useState, useMemo } from 'react';
import { Icon } from './Icon';
import Button from './Button';
import { useFavoriteState } from '../../../hooks/useFavoriteState';
import { Event } from '../../../types/event.types';
import '../../../styles/components/favorite-button.css';

interface FavoriteButtonProps {
  id: string;
  seriesId?: string;
  expiresAt?: Date;
  seriesEvents?: Event[];
  onFavoriteChange?: (isActive: boolean) => void;
  size?: 'sm' | 'md' | 'lg';
  className?: string;
  variant?: 'icon' | 'button';
  disabled?: boolean;
}

const FavoriteButton: React.FC<FavoriteButtonProps> = ({
  id,
  seriesId,
  expiresAt,
  seriesEvents,
  onFavoriteChange,
  size = 'md',
  className = '',
  variant = 'icon',
  disabled = false
}) => {
  const storageId = seriesId || id;
  
  // Calculer la date d'expiration en fonction des événements de la série
  const calculatedExpiresAt = useMemo(() => {
    if (seriesEvents?.length) {
      // Filtrer les événements futurs et trouver le dernier
      const futureEvents = seriesEvents
        .filter(event => event.datetime && event.datetime > new Date())
        .sort((a, b) => {
          if (!a.datetime || !b.datetime) return 0;
          return b.datetime.getTime() - a.datetime.getTime();
        });
      
      // Utiliser la date du dernier événement futur ou la date fournie
      return futureEvents[0]?.datetime || expiresAt;
    }
    return expiresAt;
  }, [seriesEvents, expiresAt]);
  
  const { isFavorite, toggleFavorite } = useFavoriteState(
    storageId,
    calculatedExpiresAt,
    onFavoriteChange
  );

  const handleClick = (e?: React.MouseEvent) => {
    e?.stopPropagation();
    toggleFavorite(!isFavorite);

    // Annoncer le changement pour l'accessibilité
    const message = !isFavorite ? 'Added to favorites' : 'Removed from favorites';
    const announcement = document.createElement('div');
    announcement.setAttribute('role', 'status');
    announcement.setAttribute('aria-live', 'polite');
    announcement.className = 'sr-only';
    announcement.textContent = message;
    document.body.appendChild(announcement);
    setTimeout(() => document.body.removeChild(announcement), 1000);
  };

  if (variant === 'button') {
    return (
      <Button 
        variant={isFavorite ? "primary" : "outline"}
        size={size}
        onClick={handleClick}
        className={`${className} favorite-button-variant ${isFavorite ? 'active' : ''}`}
      >
        <Icon 
          name="Heart" 
          size={20}
          className={isFavorite ? 'filled-heart' : ''}
        />
        {isFavorite ? 'Saved' : 'Save'}
      </Button>
    );
  }

  const classes = [
    'favorite-button',
    isFavorite ? 'active' : '',
    `favorite-button-${size}`,
    className
  ].filter(Boolean).join(' ');

  const iconSize = size === 'sm' ? 16 : size === 'lg' ? 24 : 20;

  return (
    <button 
      className={classes}
      onClick={handleClick}
      type="button"
      aria-label={isFavorite ? 'Remove from favorites' : 'Add to favorites'}
      aria-pressed={isFavorite}
      disabled={disabled}
    >
      <Icon 
        name="Heart"
        size={iconSize}
        className={isFavorite ? 'filled-heart' : ''}
      />
    </button>
  );
};

export default FavoriteButton; 