export const jokes = [
  "Tip: Swipe right to favorite, left to dismiss! 👆❤️",
  "Quick guide: Right swipe = Favorite, Left swipe = Skip ✨",
  "Pro tip: Save events with a right swipe, skip with left! 🎯",
  "Navigate like a pro: Right for favorites, left to pass 🌟",
  "Simple gestures: Swipe right to save, left to dismiss ⚡",
  "Your event compass: Right = Love it, Left = Next one! 🧭",
  "Like dating apps: Right swipe saves, left swipe skips! 💫",
  "Easy navigation: Right to favorite, left to move on ✨",
  "Discover events: Swipe right to keep, left to pass! 🎭",
  "Local adventures: Save with right swipes, skip with left! 🌍"
];


/*export const jokes = [
   "Building something special for you ! Like a good trip, it gets better every day 🌟",
  "Your next adventure is cooking! App still in progress 🚀",
 "Making local events easier to discover - one feature at a time ✨",
  "Good things take time - work in progress - just like planning the perfect trip! 🌍",
  "Crafting your future favorite event app - stay tuned! 🎪",
  "Pack your patience - awesome features incoming! ✈️",
  "Every journey starts with a first step - we're on our way! 🚶‍♂️",
  "Like a local's secret spots, great features are worth the wait 🗺️",
  "Building your passport to local experiences - almost there! 🎭",
  "Rome wasn't built in a day, neither is your perfect event companion 🏛️",
];*/
