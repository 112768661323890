import React from 'react';
import { MonkeyCursor } from './MonkeyCursor';
import { MonkeyProps } from '../types/monkey.types';
import '../../../styles/components/buttons.css';

type ButtonVariant = 'primary' | 'secondary' | 'outline' | 'ghost' | 'danger';
type ButtonSize = 'sm' | 'md' | 'lg';
type ButtonPosition = 'default' | 'bottom';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, MonkeyProps {
    variant?: ButtonVariant;
    size?: ButtonSize;
    position?: ButtonPosition;
    children: React.ReactNode;
}

// Définir les offsets pour chaque taille de bouton et type de singe
const MONKEY_OFFSETS = {
  walking: {
    sm: { x: 10, y: 1 },    // Plus petit offset pour petits boutons
    md: { x: 4, y: -2 },    // Offset moyen pour boutons moyens
    lg: { x: 3, y: 9 },    // Grand offset pour grands boutons
  },
  hanging: {
    sm: { x: 10, y: -25 },  // Suspendu au-dessus des petits boutons
    md: { x: 4, y: -30 },   // Suspendu au-dessus des boutons moyens
    lg: { x: 3, y: -35 },   // Suspendu au-dessus des grands boutons
  }
};

const Button: React.FC<ButtonProps> = ({ 
    variant = 'primary',
    size = 'md',
    position = 'default',
    children,
    className,
    disabled,
    // Props Monkey avec valeurs par défaut
    showMonkey = false,
    monkeyVariant = 'singeOrange',
    monkeyType = 'walking',
    monkeyLookAt = 'right',
    ...props
}) => {
    const buttonClasses = [
        'btn',
        `btn-${variant}`,
        size !== 'md' && `btn-${size}`,
        position === 'bottom' && 'btn-bottom',
        className
    ].filter(Boolean).join(' ');

    // Récupérer l'offset correspondant à la taille et au type du singe
    const monkeyOffset = MONKEY_OFFSETS[monkeyType][size];

    return (
        <button 
            className={buttonClasses}
            disabled={disabled}
            {...props}
        >
            {children}
            {showMonkey && (
                <MonkeyCursor
                    showMonkey
                    monkeyVariant={monkeyVariant}
                    monkeyType={monkeyType}
                    monkeyLookAt={monkeyLookAt}
                    offset={monkeyOffset}
                    size={size}
                />
            )}
        </button>
    );
};

export default Button;