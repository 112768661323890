import { collection, doc, setDoc, updateDoc, getDoc, getDocs, query, where, Timestamp, deleteDoc } from 'firebase/firestore';
import { db } from './config';
import { EventSeries, SeriesEvent } from '../types/eventSeries.types';
import { formatDate } from '../utils/dateUtils';
import { calculateEndTimeString } from './event.service';

const SERIES_COLLECTION = 'eventSeries';
const EVENTS_COLLECTION = 'events';

export const eventSeriesService = {
  // Créer une nouvelle série d'événements
  async createEventSeries(seriesData: Omit<EventSeries, 'id'>): Promise<string> {
    try {
      // Vérifier si c'est un brouillon ou un événement à publier
      const isPublishing = seriesData.status === 'active';
      
      // Validation de base (même pour les brouillons)
      if (!seriesData.frequency || !seriesData.startDate || !seriesData.time) {
        throw new Error('Missing required fields for recurring event');
      }
      
      // Validation supplémentaire seulement pour la publication (pas pour les brouillons)
      if (isPublishing) {
        // Ajout de la validation explicite pour endTime
        if (!seriesData.endTime) {
          throw new Error('End time is required for recurring events');
        }

        // Validation spécifique selon le type de fin
        if (seriesData.endType === 'occurrences' && !seriesData.occurrences) {
          throw new Error('Number of occurrences required when endType is occurrences');
        }
        if (seriesData.endType === 'date' && !seriesData.endDate) {
          throw new Error('End date required when endType is date');
        }
      }
      
      // Capture explicite du statut avant nettoyage
      const requestedStatus = seriesData.status || 'draft';
      console.log('🔍 Statut reçu avant nettoyage:', requestedStatus);

      // Nettoyage plus strict des données
      const cleanedData = Object.entries(seriesData).reduce((acc, [key, value]) => {
        // Ignorer explicitement les valeurs undefined ou null
        if (value === undefined || value === null) {
          return acc;
        }

        // Traitement spécial pour occurrences
        if (key === 'occurrences') {
          // Ne garder occurrences que si endType est 'occurrences'
          if (seriesData.endType === 'occurrences' && typeof value === 'number') {
            acc[key] = value;
          }
          return acc;
        }

        // Pour les tableaux, vérifier qu'ils ne sont pas vides
        if (Array.isArray(value)) {
          if (value.length > 0) {
            acc[key] = value;
          }
          return acc;
        }

        // Pour les objets, vérifier qu'ils ont des propriétés
        if (typeof value === 'object') {
          if (Object.keys(value).length > 0) {
            acc[key] = value;
          }
          return acc;
        }

        // Pour les strings, vérifier qu'elles ne sont pas vides
        if (typeof value === 'string' && value.trim() === '') {
          return acc;
        }

        acc[key] = value;
        return acc;
      }, {} as Record<string, any>);

      // Restaurer explicitement le statut après nettoyage
      cleanedData.status = requestedStatus;
      console.log('🔍 Statut forcé après nettoyage:', cleanedData.status);

      const seriesRef = doc(collection(db, SERIES_COLLECTION));
      const seriesId = seriesRef.id;
      
      const firestoreData = {
        ...cleanedData,
        id: seriesId,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now()
      };

      console.log('Données nettoyées avant sauvegarde:', firestoreData);

      // Si c'est un brouillon, on ne valide pas les données obligatoires
      if (seriesData.status === 'draft') {
        await setDoc(seriesRef, firestoreData);
        return seriesId;
      }

      // Pour une publication, on vérifie toutes les données obligatoires
      if (!this.validateSeriesData(firestoreData)) {
        throw new Error('Missing required fields for publishing');
      }

      await setDoc(seriesRef, firestoreData);
      console.log("🔄 Série maître créée, début de génération des événements individuels");
      try {
        await this._generateSeriesEvents(seriesId);
        console.log("🔄 Événements individuels générés avec succès");
      } catch (error) {
        console.error("🔄 Erreur lors de la génération des événements:", error);
        throw error;
      }
      
      return seriesId;
    } catch (error) {
      console.error('Erreur lors de la création de la série:', error);
      throw error;
    }
  },

  // Générer les dates pour tous les événements de la série
  _generateEventDates(series: EventSeries): Date[] {
    console.log('Génération des dates avec:', {
      startDate: series.startDate,
      time: series.time,
      endTime: series.endTime,
      frequency: series.frequency,
      weekDays: series.weekDays,
      endType: series.endType,
      endDate: series.endDate,
      occurrences: series.occurrences
    });

    const dates: Date[] = [];
    const startDate = new Date(series.startDate + 'T' + series.time);
    
    if (series.frequency === 'daily') {
      let currentDate = new Date(startDate);
      const endDate = series.endType === 'date' 
        ? new Date(series.endDate + 'T' + series.time)
        : null;
      
      let occurrences = 0;
      while (
        (endDate && currentDate <= endDate) || 
        (series.occurrences && occurrences < series.occurrences)
      ) {
        dates.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
        occurrences++;
      }
    } else if (series.frequency === 'weekly' && series.weekDays?.length) {
      const weekDayIndices = series.weekDays.map(day => {
        const dayMap = {
          'sunday': 0, 'monday': 1, 'tuesday': 2, 'wednesday': 3,
          'thursday': 4, 'friday': 5, 'saturday': 6
        };
        return dayMap[day as keyof typeof dayMap];
      });

      let currentDate = new Date(startDate);
      const endDate = series.endType === 'date'
        ? new Date(series.endDate + 'T' + series.time)
        : null;
      
      let occurrences = 0;
      while (
        (endDate && currentDate <= endDate) || 
        (series.occurrences && occurrences < series.occurrences)
      ) {
        if (weekDayIndices.includes(currentDate.getDay())) {
          dates.push(new Date(currentDate));
          occurrences++;
        }
        currentDate.setDate(currentDate.getDate() + 1);
      }
    }

    return dates;
  },

  // Générer et sauvegarder tous les événements d'une série
  async _generateSeriesEvents(seriesId: string): Promise<void> {
    try {
      console.log('🚨 La fonction _generateSeriesEvents est bien appelée!');
      console.log('🔄 Début _generateSeriesEvents avec seriesId:', seriesId);
      const seriesDoc = await getDoc(doc(db, SERIES_COLLECTION, seriesId));
      if (!seriesDoc.exists()) {
        console.error('🔄 Série non trouvée:', seriesId);
        return;
      }

      // Récupérer la série et forcer son statut à 'active'
      let series = seriesDoc.data() as EventSeries;
      console.log('🔄 Données de série récupérées:', series);
      
      // Forcer le statut active pour la série principale
      if (series.status !== 'active') {
        console.log('🔄 Modification du statut de la série de', series.status, 'à active');
        series = { ...series, status: 'active' as 'active' };
        // Mettre à jour la série principale
        await updateDoc(doc(db, SERIES_COLLECTION, seriesId), { status: 'active' });
      }
      
      const dates = this._generateEventDates(series);
      console.log('🔄 Dates générées:', dates);

      if (dates.length === 0) {
        console.error('🔄 Aucune date générée');
        return;
      }

      console.log(`🔄 Nombre d'événements à générer: ${dates.length}`);
      console.log("🔄 Début génération des événements individuels");

      const batch = [];
      for (const date of dates) {
        console.log(`🔄 Création événement pour date: ${date}`);
        const eventRef = doc(collection(db, EVENTS_COLLECTION));
        
        // Créer le Timestamp pour endTime
        if (!series.endTime) {
          console.error('🔄 endTime manquant pour la série:', seriesId);
          return;
        }

        const endTimeISO = calculateEndTimeString(date, series.endTime);
        console.log(`🔄 endTimeISO calculé: ${endTimeISO}`);

        const eventData = {
          ...series,
          id: eventRef.id,
          seriesId,
          datetime: Timestamp.fromDate(date),
          endTime: endTimeISO,
          isException: false,
          status: 'active' as 'active' // Forcer le statut à active ici aussi
        };
        console.log('🔄 Données pour événement individuel (avec statut forcé):', eventData);
        batch.push(setDoc(eventRef, eventData));
      }

      await Promise.all(batch);
      console.log('🔄 Fin génération des événements individuels');
    } catch (error) {
      console.error('🔄 Erreur lors de la génération des événements:', error);
      throw error;
    }
  },

  // Mettre à jour une série et réorganiser ses événements
  async updateEventSeries(seriesId: string, updates: Partial<EventSeries>): Promise<void> {
    try {
      console.log('Début de mise à jour de la série', seriesId);
      console.log('Données de mise à jour:', updates);
      
      // Nettoyer les valeurs undefined qui causent l'erreur avec Firestore
      const cleanedUpdates = Object.entries(updates).reduce((acc, [key, value]) => {
        // Ne pas inclure les valeurs undefined dans les mises à jour
        if (value !== undefined) {
          acc[key] = value;
        }
        return acc;
      }, {} as Record<string, any>);
      
      // 1. Mettre à jour la série (avec les données nettoyées)
      const seriesRef = doc(db, SERIES_COLLECTION, seriesId);
      await updateDoc(seriesRef, {
        ...cleanedUpdates,
        updatedAt: Timestamp.now()
      });

      // 2. Récupérer la série mise à jour
      const seriesDoc = await getDoc(seriesRef);
      if (!seriesDoc.exists()) return;
      const seriesData = seriesDoc.data();
      const updatedSeries = { ...seriesData, ...updates } as EventSeries;

      // 3. Récupérer tous les événements existants de la série
      const eventsQuery = query(
        collection(db, EVENTS_COLLECTION),
        where('seriesId', '==', seriesId)
      );
      const eventsSnapshot = await getDocs(eventsQuery);
      
      // Séparer les événements modifiés et non modifiés
      const modifiedEvents = eventsSnapshot.docs
        .filter(doc => doc.data().isException)
        .map(doc => ({
          ...doc.data(),
          id: doc.id,
          datetime: doc.data().datetime.toDate()
        }));
      
      const unmodifiedEvents = eventsSnapshot.docs
        .filter(doc => !doc.data().isException)
        .map(doc => ({
          ...doc.data(),
          id: doc.id,
          datetime: doc.data().datetime.toDate()
        }))
        .sort((a, b) => a.datetime.getTime() - b.datetime.getTime());

      // 4. Générer les nouvelles dates
      const newDates = this._generateEventDates(updatedSeries);

      // 5. Filtrer les dates qui ont déjà des événements modifiés
      const modifiedDates = new Set(modifiedEvents.map(event => 
        event.datetime.toISOString()
      ));
      const availableDates = newDates.filter(date => 
        !modifiedDates.has(date.toISOString())
      );

      // 6. Mettre à jour les événements non modifiés existants
      const batch = [];
      const baseUpdates = {
        title: updates.title || updatedSeries.title,
        description: updates.description || updatedSeries.description,
        location: updates.location || updatedSeries.location,
        locationName: updates.locationName || updatedSeries.locationName,
        latitude: updates.latitude || updatedSeries.latitude,
        longitude: updates.longitude || updatedSeries.longitude,
        tags: updates.tags || updatedSeries.tags,
        image: updates.image || updatedSeries.image,
        prices: updates.prices || updatedSeries.prices,
        status: updates.status || updatedSeries.status,
        organizerContact: updates.organizerContact || updatedSeries.organizerContact,
        ticketingUrl: updates.ticketingUrl || updatedSeries.ticketingUrl,
        updatedAt: Timestamp.now()
      };

      // Réutiliser les événements existants pour les nouvelles dates
      const reusedEvents = Math.min(unmodifiedEvents.length, availableDates.length);
      for (let i = 0; i < reusedEvents; i++) {
        const event = unmodifiedEvents[i];
        batch.push(updateDoc(doc(db, EVENTS_COLLECTION, event.id), {
          ...baseUpdates,
          datetime: Timestamp.fromDate(availableDates[i])
        }));
      }

      // 7. Supprimer les événements non modifiés en excès
      for (let i = reusedEvents; i < unmodifiedEvents.length; i++) {
        batch.push(deleteDoc(doc(db, EVENTS_COLLECTION, unmodifiedEvents[i].id)));
      }

      // 8. Créer de nouveaux événements si nécessaire
      for (let i = reusedEvents; i < availableDates.length; i++) {
        const eventRef = doc(collection(db, EVENTS_COLLECTION));
        const eventData = {
          ...updatedSeries,
          id: eventRef.id,
          seriesId,
          datetime: Timestamp.fromDate(availableDates[i]),
          isException: false,
          createdAt: Timestamp.now(),
          updatedAt: Timestamp.now()
        };
        batch.push(setDoc(eventRef, eventData));
      }

      // 9. Mettre à jour les événements modifiés avec les nouveaux champs de base
      modifiedEvents.forEach(event => {
        batch.push(updateDoc(doc(db, EVENTS_COLLECTION, event.id), baseUpdates));
      });

      // Journalisation détaillée
      console.log(`Mise à jour de ${batch.length} événements`);
      
      // Exécution en lots plus petits pour éviter les dépassements de quota
      const batchSize = 20;
      for (let i = 0; i < batch.length; i += batchSize) {
        const currentBatch = batch.slice(i, i + batchSize);
        await Promise.all(currentBatch);
        console.log(`Lot ${i/batchSize + 1} terminé`);
      }
      
      console.log('Mise à jour de la série terminée avec succès');
    } catch (error) {
      console.error('Erreur lors de la mise à jour de la série:', error);
      console.error('Détails de l\'erreur:', error instanceof Error ? error.message : String(error));
      throw error;
    }
  },

  // Mettre à jour un événement individuel
  async updateSingleEvent(eventId: string, updates: Partial<SeriesEvent>): Promise<void> {
    const eventRef = doc(db, EVENTS_COLLECTION, eventId);
    const eventDoc = await getDoc(eventRef);
    
    if (!eventDoc.exists()) return;
    
    const eventData = eventDoc.data();
    const datetime = eventData.datetime.toDate();
    
    // Si c'est la première modification individuelle, sauvegarder les données originales
    if (!eventData.isException) {
      await updateDoc(eventRef, {
        ...updates,
        isException: true,
        originalData: {
          title: eventData.title,
          description: eventData.description,
          location: eventData.location,
          locationName: eventData.locationName, 
          price: eventData.price,
          ticketingUrl: eventData.ticketingUrl,
          datetime
        }
      });
    } else {
      await updateDoc(eventRef, updates);
    }
  },

  validateSeriesData(data: any): boolean {
    const requiredFields = [
      'title',
      'frequency',
      'startDate',
      'time',
      'endTime',
      'location',
      'organizerId'
    ];
    return requiredFields.every(field => data[field]);
  },

  async getEventSeriesById(seriesId: string): Promise<EventSeries | null> {
    try {
      const seriesRef = doc(db, SERIES_COLLECTION, seriesId);
      const seriesDoc = await getDoc(seriesRef);
      
      if (!seriesDoc.exists()) {
        return null;
      }
      
      const data = seriesDoc.data();
      return {
        ...data,
        id: seriesDoc.id,
        createdAt: data.createdAt.toDate(),
        updatedAt: data.updatedAt.toDate()
      } as EventSeries;
    } catch (error) {
      console.error('Erreur lors de la récupération de la série:', error);
      throw error;
    }
  }
};
