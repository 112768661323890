import React, { useEffect, useState } from 'react';
import { Icon, IconName } from '../../primitives/Icon';
import BaseDrawer from '../Drawer/BaseDrawer';
import './MenuDrawer.css';
import LogoSVG from '../../primitives/LogoSVG';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../contexts/AuthContext';
import { toast } from 'react-hot-toast';

interface MenuDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  isOrganizer?: boolean;
  onOrganizerClick?: () => void;
  onContactClick?: () => void;
  onIdeaClick?: () => void;
  onAmbassadorClick?: () => void;
}

interface MenuItem {
  icon: IconName;
  label: string;
  onClick?: () => void;
  highlight?: boolean;
}

const MenuDrawer: React.FC<MenuDrawerProps> = ({
  isOpen,
  onClose,
  isOrganizer = false,
  onOrganizerClick,
  onContactClick,
  onIdeaClick,
  onAmbassadorClick
}) => {
  const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
  const [showInstall, setShowInstall] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [isNavigating, setIsNavigating] = useState(false);
  const [navigationError, setNavigationError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    const handleBeforeInstallPrompt = (e: any) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setShowInstall(true);
      console.log('Installation available!');
    };

    if (window.matchMedia('(display-mode: standalone)').matches) {
      console.log('App already installed');
      setShowInstall(false);
    } else {
      console.log('App not yet installed');
      setShowInstall(true);
    }

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  useEffect(() => {
    const handleOnlineStatus = () => {
      if (navigator.onLine) {
        setNavigationError(null);
      }
    };

    window.addEventListener('online', handleOnlineStatus);
    window.addEventListener('offline', () => {
      setNavigationError("You are offline. Some features may be limited.");
    });

    return () => {
      window.removeEventListener('online', handleOnlineStatus);
      window.removeEventListener('offline', () => {});
    };
  }, []);

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      try {
        await deferredPrompt.prompt();
        const choiceResult = await deferredPrompt.userChoice;
        
        if (choiceResult.outcome === 'accepted') {
          console.log('App installed');
        }
        setDeferredPrompt(null);
      } catch (error) {
        console.error('Installation error:', error);
      }
    } else {
      alert('Ready to have our friendly monkey in your pocket? 🐒\n\niOS: Tap the "Share" button, then "Add to Home Screen"\n\nAndroid: Open browser menu and tap "Install App"');
    }
  };

  const menuItems: MenuItem[] = [
    {
      icon: 'UserCircle',
      label: 'Organizer Space',
      onClick: () => {
        // Fermer d'abord le drawer
        onClose();

        // Utiliser un timeout pour s'assurer que le drawer est fermé
        setTimeout(() => {
          if (user?.uid) {
            navigate('/organizer', { 
              replace: true,
              state: { from: window.location.pathname }
            });
          } else {
            sessionStorage.setItem('redirectAfterLogin', '/organizer');
            navigate('/login', { 
              replace: true,
              state: { from: window.location.pathname }
            });
          }
        }, 100);
      }
    },
    {
      icon: 'Users',
      label: 'Ambassador Program',
      onClick: onAmbassadorClick
    },
    {
      icon: 'Instagram',
      label: 'Follow us on Instagram',
      onClick: () => window.open('https://www.instagram.com/what2do.today/', '_blank')
    },
    {
      icon: 'MessageSquare',
      label: 'Contact & Feedback',
      onClick: () => setShowContactModal(true)
    },
    {
      icon: 'Download',
      label: 'Get the App',
      onClick: handleInstallClick,
      highlight: true
    }
  ];

  const handleCloseModal = () => {
    setShowContactModal(false);
    if (window.history.state === null) {
      window.history.back();
    }
  };

  useEffect(() => {
    if (showContactModal) {
      window.history.pushState('modal', '', window.location.pathname);

      const handlePopState = () => {
        setShowContactModal(false);
      };

      window.addEventListener('popstate', handlePopState);

      return () => {
        window.removeEventListener('popstate', handlePopState);
      };
    }
  }, [showContactModal]);

 

  return (
    <>
      <BaseDrawer
        isOpen={isOpen}
        onClose={onClose}
        className="menu-drawer"
      >
        <div className="menu-drawer-header">
          <h2>Menu</h2>
        </div>
        <div className="menu-drawer-content">
          {navigationError && (
            <div className="menu-drawer-error">
              {navigationError}
            </div>
          )}
          {menuItems.map((item, index) => (
            <button
              key={index}
              className={`menu-item ${item.highlight ? 'menu-item--highlight' : ''} ${
                isNavigating ? 'menu-item--loading' : ''
              }`}
              onClick={item.onClick}
              disabled={isNavigating}
              aria-label={item.label}
            >
              <Icon name={item.icon} size={24} />
              <span>{item.label}</span>
              {isNavigating && <span className="menu-item__spinner" />}
            </button>
          ))}
        </div>
      </BaseDrawer>

      {showContactModal && (
        <div 
          className="menu-drawer-modal" 
          onClick={handleCloseModal}
        >
          <div className="menu-drawer-modal__content" onClick={e => e.stopPropagation()}>
            <h3 className="menu-drawer-modal__title">
              Get in touch! <LogoSVG variant="singeOrange" size="lg"  />
            </h3>
            <div className="menu-drawer-modal__buttons">
              <button 
                className="menu-drawer-modal__button menu-drawer-modal__button--email"
                onClick={() => window.location.href = "mailto:what2dotodaypro@gmail.com"}
              >
                <Icon name="Mail" size={24} />
                Send us an email
              </button>
              <button 
                className="menu-drawer-modal__button menu-drawer-modal__button--feedback"
                onClick={() => window.open("https://forms.gle/WsLPhinC1fS5eYJcA", "_blank")}
              >
                <Icon name="MessageSquare" size={24} />
                Share your feedback
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MenuDrawer; 