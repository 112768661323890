import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';
import '../../../styles/components/binary-toggle.css';

interface BinaryToggleProps {
    leftLabel: string;
    rightLabel: string;
    value: boolean;
    onChange: (value: boolean) => void;
    disabled?: boolean;
    className?: string;
}

const BinaryToggle: React.FC<BinaryToggleProps> = ({ 
    leftLabel,
    rightLabel,
    value,
    onChange,
    disabled = false,
    className = ''
}) => {
    const [isPressed, setIsPressed] = useState(false);
    
    // Animation avec react-spring
    const [{ scale }, api] = useSpring(() => ({
        scale: 1,
        config: {
            tension: 300,
            friction: 20
        }
    }));

    // Gestion des événements tactiles
    const handleTouchStart = () => {
        if (disabled) return;
        setIsPressed(true);
        api.start({ scale: 0.98 });
    };

    const handleTouchEnd = () => {
        if (disabled) return;
        setIsPressed(false);
        api.start({ scale: 1 });
        onChange(!value);
    };

    // Gestion des événements souris
    const handleMouseDown = () => {
        if (disabled) return;
        setIsPressed(true);
        api.start({ scale: 0.98 });
    };

    const handleMouseUp = () => {
        if (disabled) return;
        setIsPressed(false);
        api.start({ scale: 1 });
        onChange(!value);
    };

    const toggleName = `binary-toggle-${className}`;
    const classes = [
        'binary-toggle-container',
        disabled && 'disabled',
        isPressed && 'pressed',
        className
    ].filter(Boolean).join(' ');

    return (
        <animated.div 
            className={classes}
            style={{
                transform: scale.to(s => `scale(${s})`)
            }}
            role="switch"
            aria-checked={value}
            aria-disabled={disabled}
            tabIndex={disabled ? -1 : 0}
            onKeyDown={(e) => {
                if (disabled) return;
                if (e.key === 'Enter' || e.key === ' ') {
                    e.preventDefault();
                    onChange(!value);
                }
            }}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            onTouchCancel={() => {
                setIsPressed(false);
                api.start({ scale: 1 });
            }}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseLeave={() => {
                if (isPressed) {
                    setIsPressed(false);
                    api.start({ scale: 1 });
                }
            }}
        >
            <label className="binary-toggle-option">
                <input
                    type="radio"
                    name={toggleName}
                    checked={!value}
                    onChange={() => !disabled && onChange(false)}
                    disabled={disabled}
                />
                <span className="binary-toggle-label">{leftLabel}</span>
            </label>
            <label className="binary-toggle-option">
                <input
                    type="radio"
                    name={toggleName}
                    checked={value}
                    onChange={() => !disabled && onChange(true)}
                    disabled={disabled}
                />
                <span className="binary-toggle-label">{rightLabel}</span>
            </label>
        </animated.div>
    );
};

export default BinaryToggle;