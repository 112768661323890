import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { Event } from '../../types/event.types';
import { usePublicEvents } from '../../hooks/usePublicEvents';
import { useEventFilters } from '../../hooks/useEventFilters';
import { useGeolocation } from '../../utils/hooks/useGeolocation';
import EventCard from '../../components/event/card';
import RecurringEventCard from '../../components/event/RecurringEventCard';
import { BottomNav, type IconName, type Filters } from '../DesignSystem';
import './FavoritesPage.css';
import { useNavigation } from '../../navigation/hooks/useNavigation';
import { Icon, Button, LogoSVG } from '../DesignSystem';

const FavoritesPage: React.FC = () => {
  const navigate = useNavigate();
  const { location, loading: geoLoading } = useGeolocation();
  const [manualLocation, setManualLocation] = useState<{ 
    latitude: number; 
    longitude: number; 
  } | undefined>();

  // Calculer effectiveLocation une seule fois et le mémoriser
  const effectiveLocation = useMemo(() => {
    return manualLocation || (location && !location.error ? {
      latitude: location.latitude,
      longitude: location.longitude
    } : undefined);
  }, [manualLocation, location]); // Dépendances explicites

  // Passer les coordonnées à usePublicEvents
  const { events, loading: eventsLoading, error: eventsError } = usePublicEvents({
    coords: effectiveLocation
  });

  // Réduire les logs à des moments clés seulement
  useEffect(() => {
    if (events?.length) {
      console.log('FavoritesPage - Initial load complete:', {
        eventsCount: events.length,
        location: effectiveLocation
      });
    }
  }, [events?.length]); // Ne logger qu'au changement du nombre d'événements

  const { getNavigationItems } = useNavigation('public');

  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [searchText, setSearchText] = useState('');
  const [searchCity, setSearchCity] = useState('');
  const [dateFilter, setDateFilter] = useState<string[]>([]);
  const [timeFilter, setTimeFilter] = useState<string[]>([]);
  const [distance, setDistance] = useState(5);
  const [activityQuery, setActivityQuery] = useState("");

  // Filtrer les événements favoris
  const favoriteEvents = useMemo(() => {
    if (!events) return [];
    return events.filter(event => {
      try {
        // Vérifier d'abord avec l'ID de la série si c'est un événement récurrent
        if (event.seriesId) {
          const seriesFavorite = localStorage.getItem(`favorite-${event.seriesId}`);
          if (seriesFavorite) {
            const favoriteData = JSON.parse(seriesFavorite);
            const expirationDate = new Date(favoriteData.expiresAt);
            if (favoriteData.isFavorite && !isNaN(expirationDate.getTime()) && expirationDate > new Date()) {
              return true;
            }
          }
        }

        // Sinon vérifier avec l'ID de l'événement
        const savedFavorite = localStorage.getItem(`favorite-${event.id}`);
        if (!savedFavorite) return false;
        
        const favoriteData = JSON.parse(savedFavorite);
        const expirationDate = new Date(favoriteData.expiresAt);
        
        // Vérifier que la date est valide et future
        return favoriteData.isFavorite && 
               !isNaN(expirationDate.getTime()) && 
               expirationDate > new Date();
      } catch (error) {
        console.error('Error checking favorite status:', error);
        return false;
      }
    });
  }, [events]);

  // Appliquer les filtres sur les favoris
  const filteredEvents = useEventFilters(favoriteEvents, {
    searchText,
    searchCity,
    selectedTags,
    dateFilter,
    timeFilter
  });

  // Grouper les événements récurrents
  const groupedEvents = useMemo(() => {
    const grouped = {
      single: [] as Event[],
      recurring: {} as { [key: string]: { mainEvent: Event; events: Event[] } }
    };

    filteredEvents.forEach(event => {
      if (event.seriesId) {
        if (!grouped.recurring[event.seriesId]) {
          grouped.recurring[event.seriesId] = {
            mainEvent: event,
            events: []
          };
        }
        grouped.recurring[event.seriesId].events.push(event);
      } else {
        grouped.single.push(event);
      }
    });

    return grouped;
  }, [filteredEvents]);

  // Nouveau handleEventClick
  const handleEventClick = useCallback((event: Event, seriesEvents?: Event[]) => {
    if (event.seriesId) {
      navigate(`/favorites/series/${event.seriesId}`, {
        state: {
          returnTo: '/favorites',
          userLocation: undefined,
          event: event,
          seriesEvents: seriesEvents  // Important : passer les événements de la série
        }
      });
    } else {
      navigate(`/favorites/event/${event.id}`, {
        state: {
          returnTo: '/favorites',
          userLocation: undefined,
          event: event
        }
      });
    }
  }, [navigate]);

  const handleFiltersApply = (filters: Filters) => {
    setSearchCity(filters.where.city || '');
    setDateFilter(filters.when.dates);
    setTimeFilter(filters.when.times);
    setSelectedTags(filters.what.tags);
  };

  // Compter les favoris correctement (1 par série)
  const favoritesCount = useMemo(() => {
    return Object.keys(groupedEvents.recurring).length + groupedEvents.single.length;
  }, [groupedEvents]);

  const navigationItems = getNavigationItems({
    viewMode: 'list',
    favoritesCount: favoritesCount  // Utiliser le nouveau comptage
  });

  if (eventsLoading) {
    return (
      <div className="favorites__loading-screen">
        <div className="favorites__loading-content">
          <h2 className="favorites__loading-title">Loading your favorites...</h2>
          
          <div className="favorites__loading-joke">
            Good things come to those who wait... but your favorite events come faster!
          </div>
          
          <div className="favorites__loading-logo">
            <LogoSVG 
              variant="singeTicketVert"
              size="auto"
              padding={16}
              color="var(--primary-color)"
              className="favorites__loading-logo-image"
            />
          </div>
          
          <div className="favorites__loading-spinner"></div>
        </div>
      </div>
    );
  }

  if (eventsError) {
    return <div>Error: {eventsError}</div>;
  }

  return (
    <div className="favorites-page">
     
      <div className="favorites__content">
        {filteredEvents.length === 0 ? (
          <div className="favorites__empty-state">
            <div className="favorites__empty-state-content">
              
              <h2>No favorites yet!</h2>
              <p>
    Discover amazing events and swipe right to add them to your favorites! ✨
  </p>
    <p>
    You can also swipe event cards and modals to quickly like or dismiss them. ✨
  </p>
              <Button 
                variant="primary"
                onClick={() => navigate('/')}
                className="empty-state__button"
              >
                Explore events
              </Button>
              <LogoSVG 
              className='favorites__empty-state-icon'
                variant="rouge"
                size="auto"
                padding={16}
                color="var(--primary-color)"
              />
            </div>
          </div>
        ) : (
          <div className="favorites__list">
            {Object.entries(groupedEvents.recurring).map(([seriesId, group]) => (
              <RecurringEventCard
                key={seriesId}
                mainEvent={group.mainEvent}
                seriesEvents={group.events}
                onClick={handleEventClick}
                userLocation={effectiveLocation}
                disableSwipe={true}
              />
            ))}
            {groupedEvents.single.map(event => (
              <EventCard
                key={event.id}
                event={event}
                onClick={handleEventClick}
                userLocation={effectiveLocation}
                disableSwipe={true}
              />
            ))}
          </div>
        )}

        <BottomNav 
          items={navigationItems}
          filterProps={{
            activityQuery,
            onActivityQueryChange: setActivityQuery,
            searchCity,
            setSearchCity,
            distance,
            onDistanceChange: setDistance,
            selectedDates: dateFilter,
            onDateChange: setDateFilter,
            selectedTimes: timeFilter,
            onTimeChange: setTimeFilter,
            selectedTags,
            onTagSelect: (tagId: string) => {
              setSelectedTags(prev => 
                prev.includes(tagId) 
                  ? prev.filter(id => id !== tagId)
                  : [...prev, tagId]
              );
            },
            onReset: () => {
              setSearchCity("");
              setDateFilter([]);
              setTimeFilter([]);
              setSelectedTags([]);
              setActivityQuery("");
            },
            onLocationChange: setManualLocation,
            currentLocation: effectiveLocation,
          }}
        />
      </div>

      {/* Ajout de l'Outlet pour les routes enfants */}
      <Outlet />
    </div>
  );
};

export default FavoritesPage; 