import { useState, useEffect, useCallback, useMemo } from 'react';
import { Event } from '../types/event.types';
import { eventService } from '../firebase/event.service';
import { db } from '../firebase/config';
import {
  collection,
  query,
  onSnapshot,
  where,
  QuerySnapshot,
  DocumentData,
  FirestoreError,
  Timestamp,
  doc as firestoreDoc,
  getDoc,
  getDocs,
  doc
} from 'firebase/firestore';
import { sortEvents } from '../utils/eventSorting';
import { formatDateTime } from '../utils/dateFormatter';

const EVENTS_COLLECTION = 'events';
const ORGANIZERS_COLLECTION = 'organizers';

interface TextSearchConfig {
  searchText: string;
  weights: {
    title: number;
    organizer: number;
    description: number;
  };
}

interface OrganizerData {
  organizer: string;
  organizerImage: string;
  organizerContact: string;
  location?: string;
  locationName?: string;
  latitude?: number;
  longitude?: number;
}

// Fonction utilitaire pour convertir les timestamps Firestore
const convertTimestamps = (data: any): any => {
  if (data instanceof Timestamp) {
    return data.toDate();
  }
  
  if (Array.isArray(data)) {
    return data.map(item => convertTimestamps(item));
  }
  
  if (typeof data === 'object' && data !== null) {
    const converted: { [key: string]: any } = {};
    Object.keys(data).forEach(key => {
      converted[key] = convertTimestamps(data[key]);
    });
    return converted;
  }
  
  return data;
};

// Hook pour la page organisateur
export const useOrganizerEvents = (organizerId: string) => {
  const [events, setEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!organizerId) {
      setEvents([]);
      setLoading(false);
      return;
    }

    const eventsRef = collection(db, EVENTS_COLLECTION);

    // Query pour tous les événements de l'organisateur
    const eventsQuery = query(
      eventsRef,
      where('organizerId', '==', organizerId)
    );

    const unsubscribe = onSnapshot(
      eventsQuery,
      async (snapshot: QuerySnapshot<DocumentData>) => {
        try {
          const eventsList = await Promise.all(
            snapshot.docs.map(async (docSnapshot) => {
              const data = docSnapshot.data();
              
              // Récupérer les informations de l'organisateur
              let organizer = '';
              let organizerImage = '';
              let defaultOrganizerContact = '';
              
              if (data.organizerId) {
                const organizerRef = firestoreDoc(db, ORGANIZERS_COLLECTION, data.organizerId);
                const organizerDoc = await getDoc(organizerRef);
                if (organizerDoc.exists()) {
                  const organizerData = organizerDoc.data() as OrganizerData;
                  organizer = organizerData.organizer || '';
                  organizerImage = organizerData.organizerImage || '';
                  defaultOrganizerContact = organizerData.organizerContact || '';
                }
              }

              // Convertir les timestamps
              const datetime = data.datetime instanceof Timestamp ? data.datetime.toDate() : new Date(data.datetime);
              const createdAt = data.createdAt instanceof Timestamp ? data.createdAt.toDate() : data.createdAt;
              const updatedAt = data.updatedAt instanceof Timestamp ? data.updatedAt.toDate() : data.updatedAt;

              return {
                ...data,
                id: docSnapshot.id,
                organizerId: organizerId,
                organizer,
                organizerImage,
                organizerContact: data.organizerContact || '',
                title: data.title || '',
                description: data.description || '',
                location: data.location || '',
                locationName: data.locationName || '',
                latitude: data.latitude,
                longitude: data.longitude,
                tags: data.tags || [],
                views: data.views || 0,
                status: data.status || 'active',
                datetime,
                createdAt,
                updatedAt,
                prices: data.prices || [],
                currency: data.currency,
                seriesId: data.seriesId,
                image: data.image,
                defaultOrganizerContact,
                endTime: data.endTime,
              } as Event;
            })
          );

          // Trier les événements par date
          const sortedEvents = eventsList.sort((a: Event, b: Event) => {
            if (!a.datetime || !b.datetime) return 0;
            return a.datetime.getTime() - b.datetime.getTime();
          });

          setEvents(sortedEvents);
        } catch (err) {
          console.error('Erreur lors du chargement des événements:', err);
          setError(err instanceof Error ? err.message : 'Une erreur est survenue');
        } finally {
          setLoading(false);
        }
      }
    );

    return () => unsubscribe();
  }, [organizerId]);

  const getActiveEvents = useCallback(() => 
    events.filter(event => {
      if (!event.datetime) return false;
      const now = new Date();
      return new Date(event.datetime) >= now && event.status === 'active';
    })
  , [events]);

  const getPastEvents = useCallback(() => 
    events.filter(event => {
      if (!event.datetime) return false;
      const now = new Date();
      return new Date(event.datetime) < now && event.status === 'active';
    })
  , [events]);

  const getDraftEvents = useCallback(() => 
    events.filter(event => event.status === 'draft')
  , [events]);

  // Grouper les événements récurrents par série
  const getEventsBySeries = useCallback(() => {
    const seriesMap = new Map<string, Event[]>();
    
    events.forEach(event => {
      if (event.seriesId) {
        const seriesEvents = seriesMap.get(event.seriesId) || [];
        seriesEvents.push(event);
        seriesMap.set(event.seriesId, seriesEvents);
      }
    });

    return Array.from(seriesMap.values());
  }, [events]);

  return {
    events,
    activeEvents: getActiveEvents(),
    pastEvents: getPastEvents(),
    draftEvents: getDraftEvents(),
    eventSeries: getEventsBySeries(),
    loading,
    error
  };
};

// Hook pour la page d'accueil (visiteurs)
export const usePublicEventsLegacy = () => {
  const [events, setEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchConfig, setSearchConfig] = useState<TextSearchConfig>({
    searchText: '',
    weights: {
      title: 1.0,
      organizer: 0.8,
      description: 0.5
    }
  });

  // Fonction de recherche textuelle
  const filterEventsByText = useCallback((eventsToFilter: Event[], config: TextSearchConfig) => {
    if (!config.searchText.trim()) return eventsToFilter;

    const searchTerms = config.searchText.toLowerCase().trim().split(/\s+/);

    return eventsToFilter.filter(event => {
      return searchTerms.every(term => {
        const titleMatch = event.title.toLowerCase().includes(term) ? config.weights.title : 0;
        const organizerMatch = (event.organizer || '').toLowerCase().includes(term) ? config.weights.organizer : 0;
        const descriptionMatch = (event.description?.toLowerCase().includes(term)) ? config.weights.description : 0;

        return (titleMatch + organizerMatch + descriptionMatch) > 0;
      });
    });
  }, []);

  // Combinaison avec la logique de tri existante
  const filteredAndSortedEvents = useMemo(() => {
    const textFiltered = filterEventsByText(events, searchConfig);
    return sortEvents(textFiltered, 'list');
  }, [events, searchConfig, filterEventsByText]);

  useEffect(() => {
    const eventsRef = collection(db, EVENTS_COLLECTION);
    const now = new Date();

    // 1. D'abord, on charge les événements futurs
    const futureEventsQuery = query(
      eventsRef,
      where('datetime', '>=', now),
      where('status', '==', 'active')
    );

    const unsubscribe = onSnapshot(
      futureEventsQuery,
      async (snapshot: QuerySnapshot<DocumentData>) => {
        try {
          // Collecter les seriesIds des événements futurs
          const seriesIds = new Set<string>();
          snapshot.docs.forEach(doc => {
            const data = doc.data();
            if (data.seriesId) {
              seriesIds.add(data.seriesId);
            }
          });

          // Si on a des séries, charger tous leurs événements
          let seriesEvents: DocumentData[] = [];
          if (seriesIds.size > 0) {
            // On charge TOUS les événements des séries actives
            const seriesEventsQuery = query(
              eventsRef,
              where('seriesId', 'in', Array.from(seriesIds))
            );
            const seriesSnapshot = await getDocs(seriesEventsQuery);
            
            // On filtre pour ne garder que les événements actifs ou modifiés
            seriesEvents = seriesSnapshot.docs.filter(doc => {
              const data = doc.data();
              return data.status === 'active' || data.isException;
            });

            console.log('Series events loaded:', seriesEvents.length);
          }

          // Combiner les événements futurs et les événements de série
          const allDocs = [...snapshot.docs, ...seriesEvents];
          const uniqueDocs = Array.from(new Set(allDocs.map(doc => doc.id)))
            .map(id => allDocs.find(doc => doc.id === id)!);

          const eventsList = await Promise.all(
            uniqueDocs.map(async (docSnapshot) => {
              const data = docSnapshot.data();
              const convertedData = convertTimestamps(data);

              // Charger les infos de l'organisateur
              let organizer = '';
              let organizerImage = '';
              let organizerSubtitle = '';
              
              if (data.organizerId) {
                const organizerRef = doc(db, 'organizers', data.organizerId);
                const organizerDoc = await getDoc(organizerRef);
                if (organizerDoc.exists()) {
                  const organizerData = organizerDoc.data();
                  organizer = organizerData.organizer || '';
                  organizerImage = organizerData.organizerImage || '';
                  organizerSubtitle = organizerData.subtitle || '';
                }
              }

              return {
                id: docSnapshot.id,
                organizerId: data.organizerId || '',
                organizer,
                organizerImage,
                organizerSubtitle,
                title: data.title || '',
                description: data.description || '',
                location: data.location || '',
                locationName: data.locationName || '',
                latitude: data.latitude,
                longitude: data.longitude,
                tags: data.tags || [],
                views: data.views || 0,
                status: data.status || 'active',
                datetime: convertedData.datetime,
                createdAt: convertedData.createdAt,
                updatedAt: convertedData.updatedAt,
                prices: data.prices || [],
                currency: data.currency,
                seriesId: data.seriesId,
                image: data.image,
                endTime: data.endTime,
              } as Event;
            })
          );

          setEvents(eventsList);
        } catch (err) {
          setError(err instanceof Error ? err.message : 'Une erreur est survenue');
        } finally {
          setLoading(false);
        }
      }
    );

    return () => unsubscribe();
  }, []);

  return { 
    events: filteredAndSortedEvents, 
    loading, 
    error,
    searchConfig,
    setSearchConfig
  };
};
