import { useState, useEffect } from 'react';
import { MonkeyType, MonkeyVariant, MonkeyProps, MONKEY_TYPES } from '../pages/DesignSystem/types/monkey.types';

interface UseRandomMonkeyConfig {
  mode?: 'single' | 'double' | 'atLeastOne';
  probability?: number;
  enabled?: boolean;
  triggerChange?: boolean;
  availablePositions?: number[];
}

interface MonkeyState {
  isVisible: boolean;
  variant: MonkeyVariant;
  lookAt: 'left' | 'right';
  position: 'position1' | 'position2';
  monkeyType: MonkeyType;
  componentIndex: number;
}

interface MonkeysResult {
  monkeys: MonkeyState[];
  totalMonkeys: number;
}

/**
 * Hook pour gérer l'apparition aléatoire des singes dans l'interface.
 * 
 * Utilisation :
 * ```typescript
 * const { getMonkeyProps } = useRandomMonkey({
 *   mode: 'atLeastOne',
 *   availablePositions: [0, 1, 2]
 * });
 * 
 * // Dans le JSX :
 * <MonkeyCursor
 *   {...getMonkeyProps(0, true)}
 *   monkeyType="walking"  // Pour forcer un type spécifique
 * />
 * ```
 * 
 * Pour ajouter un nouveau type de singe :
 * 1. Mettre à jour monkey.types.ts avec le nouveau type et ses variantes
 * 2. Ajouter les assets dans LogoSVG.tsx
 * 3. Adapter les offsets dans les composants qui utilisent ce type
 */
export const useRandomMonkey = ({
  mode = 'single',
  probability = 0.6,
  enabled = true,
  triggerChange = false,
  availablePositions = [0, 1, 2]
}: UseRandomMonkeyConfig = {}) => {
  // Helper pour obtenir une variante aléatoire selon le type
  const getRandomVariantForType = (monkeyType: MonkeyType): MonkeyVariant => {
    const variants = MONKEY_TYPES[monkeyType];
    return variants[Math.floor(Math.random() * variants.length)];
  };

  const generateStates = (): MonkeysResult => {
    if (!enabled) return {
      monkeys: [],
      totalMonkeys: 0
    };

    let numberOfMonkeys = 0;
    if (mode === 'single') {
      numberOfMonkeys = Math.random() < probability ? 1 : 0;
    } else if (mode === 'double') {
      numberOfMonkeys = Math.random() < probability && Math.random() < 0.5 ? 2 : 0;
    } else if (mode === 'atLeastOne') {
      const rand = Math.random();
      if (rand < 0.5) {        // 50% pour 1 singe
        numberOfMonkeys = 1;
      } else if (rand < 0.8) { // 30% pour 2 singes
        numberOfMonkeys = 2;
      } else {                 // 20% pour 3 singes
        numberOfMonkeys = 3;
      }
    }

    numberOfMonkeys = Math.min(numberOfMonkeys, availablePositions.length);

    const shuffledPositions = [...availablePositions]
      .sort(() => Math.random() - 0.5)
      .slice(0, numberOfMonkeys);

    const monkeys = shuffledPositions.map(componentIndex => {
      const monkeyType: MonkeyType = Math.random() > 0.5 ? 'walking' : 'hanging';
      const lookAt: 'left' | 'right' = Math.random() > 0.5 ? 'left' : 'right';
      
      return {
        isVisible: true,
        variant: getRandomVariantForType(monkeyType),
        lookAt,
        position: Math.random() > 0.5 ? 'position1' : 'position2',
        monkeyType,
        componentIndex
      } as const;
    });

    return {
      monkeys,
      totalMonkeys: numberOfMonkeys
    };
  };

  const [state, setState] = useState<MonkeysResult>(generateStates);

  useEffect(() => {
    setState(generateStates());
  }, [triggerChange]);

  const getMonkeyProps = (index: number, needsPosition: boolean = true): MonkeyProps => {
    const monkey = state.monkeys.find(m => m.componentIndex === index);
    
    return {
      showMonkey: state.monkeys.some(m => m.componentIndex === index),
      monkeyVariant: monkey?.variant ?? 'singeOrange',
      monkeyLookAt: monkey?.lookAt ?? 'right',
      monkeyType: monkey?.monkeyType ?? 'walking',
      ...(needsPosition && {
        monkeyPosition: monkey?.position ?? 'position1'
      })
    };
  };

  return {
    ...state,
    getMonkeyProps
  };
}; 