import { IconName } from '../pages/DesignSystem';

export interface NavItemConfig {
  icon: IconName;
  label: string;
  path: string;
}

export const PUBLIC_NAV_ITEMS: Record<string, NavItemConfig> = {
  list: {
    icon: 'List',
    label: 'List view',
    path: '/'
  },
  map: {
    icon: 'Map',
    label: 'Map view',
    path: '/'
  },
  filter: {
    icon: 'Filter',
    label: 'Filters',
    path: '/filter'
  },
  favorites: {
    icon: 'Heart',
    label: 'Favorites',
    path: '/favorites'
  },
  menu: {
    icon: 'Menu',
    label: 'Menu',
    path: '/menu'
  }
};

export const ORGANIZER_NAV_ITEMS: Record<string, NavItemConfig> = {
  events: {
    icon: 'Calendar',
    label: 'Events',
    path: '/organizer/events'
  },
  stats: {
    icon: 'BarChart',
    label: 'Statistics',
    path: '/organizer/stats'
  },
  profile: {
    icon: 'User',
    label: 'Profile',
    path: '/organizer/profile'
  },
  settings: {
    icon: 'Settings',
    label: 'Settings',
    path: '/organizer/settings'
  }
}; 