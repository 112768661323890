import React, { useState, useCallback, useEffect } from 'react';
import { Autocomplete } from '@react-google-maps/api';
import { OrganizerProfile } from '../../../types/organizer.types';
import { organizerService } from '../../../firebase/organizer.service';
import { cloudinaryService } from '../../../services/cloudinary.service';
import { useAuth } from '../../../contexts/AuthContext';
import { 
  ContentBlock,
  Button,
  Input,
  Textarea,
  FileInput
} from '../../../pages/DesignSystem';
import './ProfileEditForm.css';
import { useGoogleMaps } from '../../../contexts/GoogleMapsContext';

// Vérifier si l'API Google Maps est chargée
const isGoogleMapsLoaded = () => {
  return !!(window.google && window.google.maps && window.google.maps.places);
};

interface ProfileEditFormProps {
  profile: OrganizerProfile | null;
  onClose: () => void;
  onSave: (updatedProfile: OrganizerProfile) => void;
}

const ProfileEditForm: React.FC<ProfileEditFormProps> = ({ 
  profile, 
  onClose,
  onSave
}) => {
  const { user } = useAuth();
  const [formData, setFormData] = useState<Partial<OrganizerProfile>>(profile || {});
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [isSaving, setIsSaving] = useState(false);
  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);
  const [selectedPlace, setSelectedPlace] = useState<google.maps.places.PlaceResult | null>(null);
  const { isLoaded } = useGoogleMaps();

  // Gestion des champs texte et textarea
  const handleInputChange = (name: string) => (value: string | React.ChangeEvent<HTMLInputElement>) => {
    const newValue = typeof value === 'string' ? value : value.target.value;
    setFormData(prev => ({ ...prev, [name]: newValue }));
    
    // Effacer l'erreur quand l'utilisateur modifie le champ
    if (errors[name]) {
      setErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors[name];
        return newErrors;
      });
    }
  };

  // Ajouter cette fonction après handleInputChange
  const handleWebsiteChange = (value: string | React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = typeof value === 'string' ? value : value.target.value;
    
    // Si le champ est vide ou contient uniquement "https://", gardez "https://"
    if (!inputValue || inputValue === 'https://') {
      setFormData(prev => ({ ...prev, website: 'https://' }));
    } 
    // Si l'utilisateur a saisi quelque chose sans le préfixe, ajoutez-le
    else if (!inputValue.startsWith('http://') && !inputValue.startsWith('https://')) {
      setFormData(prev => ({ ...prev, website: `https://${inputValue}` }));
    } 
    // Sinon, utilisez la valeur telle quelle
    else {
      setFormData(prev => ({ ...prev, website: inputValue }));
    }
    
    // Effacer l'erreur
    if (errors.website) {
      setErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors.website;
        return newErrors;
      });
    }
  };

  // Fonction pour charger l'autocomplete
  const onAutocompleteLoad = (autocomplete: google.maps.places.Autocomplete) => {
    setAutocomplete(autocomplete);
  };

  // Fonction appelée quand un lieu est sélectionné
  const onPlaceSelected = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      setSelectedPlace(place);
      
      setFormData(prev => {
        const updates: Partial<OrganizerProfile> = {};
        
        // Stocker l'adresse complète dans location
        if (place.formatted_address) {
          updates.location = place.formatted_address;
        }
        
        // Stocker le nom du lieu dans locationName
        if (place.name) {
          updates.locationName = place.name;
        }
        
        // Stocker les coordonnées
        if (place.geometry?.location) {
          updates.latitude = place.geometry.location.lat();
          updates.longitude = place.geometry.location.lng();
        }
        
        return { ...prev, ...updates };
      });
    }
  };

  // Traitement de l'image d'avatar
  const handleAvatarUpload = (files: FileList | null) => {
    const file = files?.[0];
    if (file) {
      // Créer une URL temporaire pour la prévisualisation
      const tempUrl = URL.createObjectURL(file);
      setFormData(prev => ({ ...prev, organizerImage: tempUrl, avatarUploading: true }));
      
      // Upload vers Cloudinary
      cloudinaryService.uploadImage(file)
        .then(cloudinaryUrl => {
          if (cloudinaryUrl) {
            setFormData(prev => ({ 
              ...prev, 
              organizerImage: cloudinaryUrl, 
              avatarUploading: false 
            }));
          } else {
            console.error('No URL received from Cloudinary for avatar');
            setFormData(prev => ({ ...prev, avatarUploading: false }));
          }
          URL.revokeObjectURL(tempUrl);
        })
        .catch(error => {
          console.error('Error uploading avatar:', error);
          setFormData(prev => ({ 
            ...prev, 
            avatarUploading: false 
          }));
          URL.revokeObjectURL(tempUrl);
        });
    }
  };

  // Traitement des images de galerie
  const handleGalleryImageUpload = (files: FileList | null) => {
    if (!files || files.length === 0) return;

    const file = files[0]; // On ne prend que le premier fichier
    
    // Créer une URL temporaire pour la prévisualisation
    const tempUrl = URL.createObjectURL(file);
    
    // IMPORTANT: Eviter les mutations directes, utiliser un nouveau tableau
    setFormData(prev => {
      const newGalleryImages = [...(prev.galleryImages || []), tempUrl];
      return { 
        ...prev, 
        galleryImages: newGalleryImages, 
        galleryUploading: true 
      };
    });
    
    // Upload vers Cloudinary
    cloudinaryService.uploadImage(file)
      .then(cloudinaryUrl => {
        if (cloudinaryUrl) {
          // Utiliser setState avec callback pour avoir la dernière valeur
          setFormData(prev => {
            // IMPORTANT: Créer un nouveau tableau pour éviter les mutations
            const updatedImages = (prev.galleryImages || []).map(img => 
              img === tempUrl ? cloudinaryUrl : img
            );
            
            return { 
              ...prev, 
              galleryImages: updatedImages, 
              galleryUploading: false 
            };
          });
        } else {
          console.error('No URL received from Cloudinary for gallery image');
          setFormData(prev => {
            const filteredImages = (prev.galleryImages || []).filter(img => img !== tempUrl);
            return { 
              ...prev, 
              galleryImages: filteredImages,
              galleryUploading: false 
            };
          });
        }
        URL.revokeObjectURL(tempUrl);
      })
      .catch(error => {
        console.error('Error uploading gallery image:', error);
        setFormData(prev => {
          const filteredImages = (prev.galleryImages || []).filter(img => img !== tempUrl);
          return { 
            ...prev, 
            galleryImages: filteredImages,
            galleryUploading: false 
          };
        });
        URL.revokeObjectURL(tempUrl);
      });
  };

  // Gérer la suppression d'une image de la galerie
  const handleRemoveGalleryImage = (imageUrl: string) => {
    const updatedGalleryImages = (formData.galleryImages || []).filter(img => img !== imageUrl);
    setFormData(prev => ({ ...prev, galleryImages: updatedGalleryImages }));
  };

  // Validation du formulaire avant soumission
  const validateForm = (): boolean => {
    const newErrors: Record<string, string> = {};
    
    // Validation des champs obligatoires
    if (!formData.organizer?.trim()) {
      newErrors.organizer = 'Organizer name is required';
    }
    
    if (!formData.location?.trim()) {
      newErrors.location = 'Location is required';
    }
    
    // Validation du format email
    if (formData.email && !/^\S+@\S+\.\S+$/.test(formData.email)) {
      newErrors.email = 'Please enter a valid email address';
    }

    // Validation du format URL
    if (formData.website && !/^https?:\/\/\S+\.\S+/.test(formData.website)) {
      newErrors.website = 'Please enter a valid URL starting with http:// or https://';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Gérer la soumission du formulaire
  const handleSubmit = async () => {
    if (!validateForm()) {
      setErrors(prev => ({ ...prev, submit: 'Please correct the errors above' }));
      return;
    }
    
    setIsSaving(true);

    try {
      // Afficher les données avant la sauvegarde
      console.log('Saving profile data:', formData);
      console.log('Gallery images:', formData.galleryImages);
      
      const updatedProfile = await organizerService.updateProfile(user?.uid || '', formData);
      onSave(updatedProfile);
      onClose();
    } catch (error) {
      console.error('Error saving profile:', error);
      setErrors({ submit: 'An error occurred while saving the profile.' });
    } finally {
      setIsSaving(false);
    }
  };

  // Gérer le bouton Annuler
  const handleCancel = () => {
    onClose();
  };

  // Ajoutez ce code de débogage juste après les déclarations d'état
  useEffect(() => {
    console.log("🔍 ProfileEditForm - Google Maps chargé:", isGoogleMapsLoaded());
    
    if (isGoogleMapsLoaded()) {
      console.log("✅ ProfileEditForm - API Google Maps disponible");
      console.log("🌐 window.google existe:", !!window.google);
      console.log("🗺️ google.maps existe:", !!(window.google && window.google.maps));
      console.log("📍 places API existe:", !!(window.google && window.google.maps && window.google.maps.places));
      console.log("🔑 Autocomplete existe:", !!(window.google && window.google.maps && window.google.maps.places && window.google.maps.places.Autocomplete));
    } else {
      console.log("❌ ProfileEditForm - API Google Maps non disponible");
    }
  }, []);

  return (
    <div className="profile-edit">
      <div className="profile-edit__header">
        <h1 className="profile-edit__title">Edit Your Profile</h1>
        <p className="profile-edit__subtitle">
          Update your information to make it easier for people to discover and connect with you
        </p>
      </div>

      <div className="profile-edit__form">
        {/* Basic Information Section */}
        <ContentBlock title="Basic Information" spacing="md">
          <div className="profile-edit__group">
            <label className="profile-edit__label">
              Name *
              <span className="profile-edit__label-hint">
                (Your name or organization name)
              </span>
            </label>
            <Input
              id="organizer-name"
              name="organizer"
              type="text"
              value={formData.organizer || ''}
              onChange={handleInputChange('organizer')}
              error={errors.organizer}
              placeholder="Your organization name"
              icon="User"
              required
            />
          </div>

          <div className="profile-edit__group">
            <label className="profile-edit__label">
              Tagline
              <span className="profile-edit__label-hint">
                (A short bio like your Instagram profile - show your vibe!)
              </span>
            </label>
            <Input
              id="organizer-subtitle"
              name="subtitle"
              type="text"
              value={formData.subtitle || ''}
              onChange={handleInputChange('subtitle')}
              error={errors.subtitle}
              placeholder="e.g. 'The best Latin parties in Paris'"
              icon="Type"
              helper="💡 Tip: Keep it short and catchy, around 60 characters"
            />
          </div>

          <div className="profile-edit__group">
            <label className="profile-edit__label">
              Description
              <span className="profile-edit__label-hint">
                (Tell your story and what makes you unique! ✨)
              </span>
            </label>
            <Textarea
              id="organizer-description"
              name="description"
              value={formData.description || ''}
              onChange={handleInputChange('description')}
              error={errors.description}
              placeholder="Share what you're all about - your mission, vibe, history, or what makes your space special..."
              rows={5}
              helper="💡 Pro tip: A great description helps connect with potential attendees before they discover your events!"
            />
          </div>

          <div className="profile-edit__group">
            <label className="profile-edit__label">
              Location *
              <span className="profile-edit__label-hint">
                (Your home base - we'll use this as default for your events! 📍)
              </span>
            </label>
            {isLoaded ? (
              <Autocomplete
                onLoad={onAutocompleteLoad}
                onPlaceChanged={onPlaceSelected}
              >
                <Input
                  id="organizer-location"
                  name="location"
                  type="text"
                  value={formData.location || ''}
                  onChange={handleInputChange('location')}
                  error={errors.location}
                  placeholder="Search for your location"
                  icon="MapPin"
                  required
                />
              </Autocomplete>
            ) : (
              <Input
                id="organizer-location"
                name="location"
                type="text"
                value={formData.location || ''}
                onChange={handleInputChange('location')}
                error={errors.location}
                placeholder="Enter your location"
                icon="MapPin"
                required
              />
            )}
            {/* Afficher les informations sur le lieu sélectionné */}
            {selectedPlace && (
              <div className="profile-edit__selected-place">
                <small>Full address: {selectedPlace.formatted_address}</small>
              </div>
            )}
          </div>
        </ContentBlock>

        {/* Contact Information Section */}
        <ContentBlock title="Contact Information" spacing="md">
          <div className="profile-edit__group">
            <label className="profile-edit__label">
              Email
              <span className="profile-edit__label-hint">
                (Your business email for inquiries)
              </span>
            </label>
            <Input
              id="organizer-email"
              name="email"
              type="email"
              value={formData.email || ''}
              onChange={handleInputChange('email')}
              error={errors.email}
              placeholder="contact@yourorganization.com"
              icon="Mail"
            />
          </div>

          <div className="profile-edit__group">
            <label className="profile-edit__label">
              Phone Number
              <span className="profile-edit__label-hint">
                (Optional contact number for attendees)
              </span>
            </label>
            <Input
              id="organizer-phone"
              name="phone"
              type="tel"
              value={formData.phone || ''}
              onChange={handleInputChange('phone')}
              error={errors.phone}
              placeholder="+33 6 12 34 56 78"
              icon="Phone"
            />
          </div>

          <div className="profile-edit__group">
            <label className="profile-edit__label">
              Website
              <span className="profile-edit__label-hint">
                (Your personal or organization website)
              </span>
            </label>
            <Input
              id="organizer-website"
              name="website"
              type="url"
              value={formData.website || 'https://'}
              onChange={handleWebsiteChange}
              error={errors.website}
              placeholder="https://www.yourwebsite.com"
              icon="Globe"
              helper="💡 Tip: Start typing and we'll add https:// for you"
            />
          </div>

          <div className="profile-edit__group">
            <label className="profile-edit__label">
              Instagram Username
              <span className="profile-edit__label-hint">
                (Without the @ symbol)
              </span>
            </label>
            <Input
              id="organizer-instagram"
              name="instagram"
              type="text"
              value={formData.instagram || ''}
              onChange={handleInputChange('instagram')}
              error={errors.instagram}
              placeholder="your instagram"
              icon="Instagram"
              helper="💡 Tip: Just enter your username, not the full URL"
            />
          </div>
        </ContentBlock>

        {/* Profile Images Section */}
        <ContentBlock title="Profile Images" spacing="md">
          <div className="profile-edit__group">
            <label className="profile-edit__label">
              Profile Picture
              <span className="profile-edit__label-hint">
                (This will be displayed as your avatar)
              </span>
            </label>
            <FileInput
              id="organizer-avatar"
              name="avatar"
              accept="image/*"
              onChange={handleAvatarUpload}
              helper="💡 Tip: Use a clear, recognizable image. A square image works best. Maximum 5MB."
              dragAndDrop
            />
            {formData.organizerImage && (
              <div className="profile-edit__avatar-preview">
                <img src={formData.organizerImage} alt="Avatar Preview" />
                {formData.avatarUploading && <div className="profile-edit__upload-overlay">Uploading...</div>}
              </div>
            )}
          </div>

          <div className="profile-edit__group">
            <label className="profile-edit__label">
              Gallery Images
              <span className="profile-edit__label-hint">
                (Showcase your events or space with beautiful images)
              </span>
            </label>
            <FileInput
              id="organizer-gallery"
              name="gallery"
              accept="image/*"
              onChange={handleGalleryImageUpload}
              helper="💡 Tip: Add up to 5 high-quality images that represent your events. Landscape orientation works best."
              dragAndDrop
              disabled={(formData.galleryImages?.length || 0) >= 5 || formData.galleryUploading}
            />
            
            {/* Prévisualisation des images SÉPARÉE */}
            {formData.galleryImages && formData.galleryImages.length > 0 && (
              <div className="profile-edit__gallery-preview">
                {formData.galleryImages.map((image, index) => (
                  <div key={`gallery-${index}-${image.substring(0, 8)}`} className="profile-edit__gallery-item">
                    <img src={image} alt={`Gallery ${index + 1}`} />
                    <button 
                      type="button"
                      className="profile-edit__gallery-remove"
                      onClick={() => handleRemoveGalleryImage(image)}
                      aria-label="Remove image"
                    >
                      ✕
                    </button>
                  </div>
                ))}
              </div>
            )}
            {formData.galleryUploading && (
              <div className="profile-edit__gallery-upload-indicator">
                Uploading image... Please wait
              </div>
            )}
          </div>
        </ContentBlock>

        {/* Message d'erreur global */}
        {errors.submit && (
          <div className="profile-edit__error-message">
            ⚠️ {errors.submit}
          </div>
        )}

        {/* Action Buttons */}
        <div className="profile-edit__actions">
          <Button
            variant="secondary"
            size="md"
            onClick={handleCancel}
            disabled={isSaving}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            size="md"
            onClick={handleSubmit}
            disabled={isSaving}
          >
            {isSaving ? 'Saving...' : 'Save Profile'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProfileEditForm; 