// Types de comportements des singes
export type MonkeyType = 'walking' | 'hanging';

// Variantes disponibles des singes
export type MonkeyVariant = 
  | 'singeOrange'          // Singe qui marche - Orange
  | 'singeVert'            // Singe qui marche - Vert
  | 'singeSuspenduOrange'  // Singe suspendu - Orange
  | 'singeSuspenduVert';   // Singe suspendu - Vert

// Configuration des variantes par type
export const MONKEY_TYPES = {
  walking: ['singeOrange', 'singeVert'],
  hanging: ['singeSuspenduOrange', 'singeSuspenduVert']
} as const;

// Props de base pour les composants avec singes
export interface MonkeyProps {
  showMonkey?: boolean;
  monkeyVariant?: MonkeyVariant;
  monkeyType?: MonkeyType;
  monkeyLookAt?: 'left' | 'right';
  monkeyPosition?: 'position1' | 'position2';
}

/**
 * Guide d'ajout d'un nouveau type de singe :
 * 
 * 1. Ajouter le nouveau type dans MonkeyType si c'est un nouveau comportement
 *    exemple: export type MonkeyType = 'walking' | 'hanging' | 'jumping';
 * 
 * 2. Ajouter les nouvelles variantes dans MonkeyVariant
 *    exemple: | 'singeJumpOrange' | 'singeJumpVert'
 * 
 * 3. Mettre à jour MONKEY_TYPES avec les nouvelles variantes
 *    exemple: jumping: ['singeJumpOrange', 'singeJumpVert']
 * 
 * 4. Ajouter les assets correspondants dans LogoSVG.tsx
 * 
 * 5. Mettre à jour les offsets dans les composants si nécessaire
 */
