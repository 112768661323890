import React, { useState } from 'react';
import { singleEvent, multipleEvents } from '../patterns/MapDrawer/mockData';
import PreviewCardMap from '../../../components/event/PreviewCardMap';
import './MapDrawerSection.css';

const MapDrawerSection = () => {
  const [isMapDrawerSingleOpen, setIsMapDrawerSingleOpen] = useState(false);
  const [isMapDrawerMultiOpen, setIsMapDrawerMultiOpen] = useState(false);

  const handleEventClick = (event: any) => {
    console.log('Event clicked:', event);
  };

  return (
    <section className="subsection map-drawer-demo">
      <h4>Map Drawer</h4>
      <p>
        Un drawer spécialement conçu pour afficher les événements sur la carte.
        Il gère automatiquement l'animation, le scroll et l'accessibilité.
      </p>

      <div className="showcase-grid">
        <div className="showcase-item">
          <h5>Single Event</h5>
          <p>Affichage d'un événement unique avec tous les détails.</p>
          <div className="demo-container">
            <button onClick={() => setIsMapDrawerSingleOpen(!isMapDrawerSingleOpen)}>
              {isMapDrawerSingleOpen ? 'Close' : 'Open'} Single Event Drawer
            </button>
            <PreviewCardMap
              events={[singleEvent]}
              location={singleEvent.location || ''}
              isOpen={isMapDrawerSingleOpen}
              onClose={() => setIsMapDrawerSingleOpen(false)}
              onEventClick={handleEventClick}
              isSingleEvent={true}
            />
          </div>
        </div>

        <div className="showcase-item">
          <h5>Multiple Events</h5>
          <p>Liste d'événements à la même localisation avec scroll.</p>
          <div className="demo-container">
            <button onClick={() => setIsMapDrawerMultiOpen(!isMapDrawerMultiOpen)}>
              {isMapDrawerMultiOpen ? 'Close' : 'Open'} Multiple Events Drawer
            </button>
            <PreviewCardMap
              events={multipleEvents}
              location={multipleEvents[0].location || ''}
              isOpen={isMapDrawerMultiOpen}
              onClose={() => setIsMapDrawerMultiOpen(false)}
              onEventClick={handleEventClick}
            />
          </div>
        </div>
      </div>

      <div className="documentation">
        <h5>Utilisation</h5>
        <pre>
          <code>{`
// Single Event
<PreviewCardMap
  events={[event]}
  location={location}
  isOpen={isOpen}
  onClose={handleClose}
  onEventClick={handleEventClick}
  isSingleEvent={true}
/>

// Multiple Events
<PreviewCardMap
  events={events}
  location={location}
  isOpen={isOpen}
  onClose={handleClose}
  onEventClick={handleEventClick}
/>
          `}</code>
        </pre>

        <h5>Props</h5>
        <ul>
          <li><code>events</code>: Liste des événements à afficher</li>
          <li><code>location</code>: Localisation des événements</li>
          <li><code>isOpen</code>: État d'ouverture du drawer</li>
          <li><code>onClose</code>: Callback de fermeture</li>
          <li><code>onEventClick</code>: Callback lors du clic sur un événement</li>
          <li><code>isSingleEvent</code>: Mode événement unique (optionnel)</li>
        </ul>
      </div>
    </section>
  );
};

export default MapDrawerSection; 