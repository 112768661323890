import { useState, useEffect } from 'react';
import { EventSeries } from '../types/eventSeries.types';
import { Event } from '../types/event.types';
import { db } from '../firebase/config';
import { doc, getDoc, DocumentData } from 'firebase/firestore';
import { formatDateTime } from '../utils/dateFormatter';

const DAYS_ORDER = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

export const useEventSeries = (seriesId?: string, previewData?: EventSeries) => {
  const [series, setSeries] = useState<EventSeries | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const loadSeries = async () => {
      if (!seriesId) {
        setLoading(false);
        return;
      }

      try {
        const docRef = doc(db, 'eventSeries', seriesId);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          const data = docSnap.data() as DocumentData;
          setSeries({ id: docSnap.id, ...data } as EventSeries);
        }
      } catch (err) {
        setError(err as Error);
      } finally {
        setLoading(false);
      }
    };

    loadSeries();
  }, [seriesId]);

  const calculateEndDate = () => {
    if (!series) return undefined;

    if (series.endType === 'date' && series.endDate) {
      return new Date(series.endDate);
    } else if (series.endType === 'occurrences' && series.occurrences && series.startDate) {
      const startDate = new Date(series.startDate);
      let endDate = new Date(startDate);
      
      if (series.frequency === 'daily') {
        endDate.setDate(startDate.getDate() + series.occurrences - 1);
      } else if (series.frequency === 'weekly' && series.weekDays) {
        const weeksNeeded = Math.ceil(series.occurrences / series.weekDays.length);
        endDate.setDate(startDate.getDate() + (weeksNeeded * 7) - 1);
      }
      
      return endDate;
    }
    return undefined;
  };

  const formatRecurrencePattern = () => {
    const seriesData = previewData || series;
    if (!seriesData) return '';

    const formatTime = (time: string) => {
      const [hours, minutes] = time.split(':');
      return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
    };

    const capitalizeFirstLetter = (str: string) => {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    let pattern = '';

    if (seriesData.frequency === 'daily') {
      pattern = 'Every day';
    } else if (seriesData.frequency === 'weekly' && seriesData.weekDays && seriesData.weekDays.length > 0) {
      const orderedDays = DAYS_ORDER.map(capitalizeFirstLetter);
      const selectedDays = seriesData.weekDays
        .map(day => day.toLowerCase())
        .sort((a, b) => DAYS_ORDER.indexOf(a) - DAYS_ORDER.indexOf(b))
        .map(capitalizeFirstLetter);

      if (selectedDays.length === 1) {
        pattern = `Every ${selectedDays[0]}`;
      } else if (selectedDays.length === 7) {
        pattern = 'Every day';
      } else if (selectedDays.length >= 5) {
        // Trouver les jours non sélectionnés
        const exceptDays = orderedDays.filter(day => !selectedDays.includes(day));
        pattern = `Every day except ${exceptDays.join(' and ')}`;
      } else {
        const lastDay = selectedDays[selectedDays.length - 1];
        const otherDays = selectedDays.slice(0, -1);
        pattern = `Every ${otherDays.join(', ')} and ${lastDay}`;
      }
    }

    // Ajouter l'heure de début et de fin
    if (seriesData.time) {
      pattern += ` at ${formatTime(seriesData.time)}`;
      if (seriesData.endTime) {
        pattern += ` - ${formatTime(seriesData.endTime)}`;
      }
    }

    // Ajouter la date de fin
    const endDate = calculateEndDate();
    if (endDate) {
      pattern += ` until ${formatDateTime(endDate).date}`;
    }

    return pattern;
  };

  return {
    series,
    loading,
    error,
    formatRecurrencePattern,
    endDate: calculateEndDate()
  };
};
