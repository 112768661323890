import React from 'react';
import { Icon } from '../../pages/DesignSystem';
import './SwipeHeader.css';

interface SwipeHeaderProps {
  onUndo: () => void;
}

const SwipeHeader: React.FC<SwipeHeaderProps> = ({ onUndo }) => {
  return (
    <h2 className="swipe-header">
      <div className="swipe-header__instructions">
        <span className="swipe-header__instruction">
        <span>dismiss </span>
         <Icon name="ArrowLeft" size={16} />
         
        </span>
        <span>swipe</span>
        <span className="swipe-header__instruction">
          <Icon name="ArrowRight" size={16} />
          <span>  favorite</span>
        </span>
      </div>
      <button 
        className="swipe-header__undo"
        onClick={onUndo}
        aria-label="Undo last action"
      >
        <Icon name="RotateCcw" size={16} />
        <span>Undo</span>
      </button>
    </h2>
  );
};

export default SwipeHeader; 