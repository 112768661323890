import React, { useState, useEffect } from 'react';
import LogoSVG from '../../pages/DesignSystem/primitives/LogoSVG';
import LocationFilter from '../../pages/DesignSystem/patterns/LocationFilter';
import { jokes } from './jokes';
import './LoadingScreen.css';
import { geoEventQueries } from '../../firebase/geoEventQueries';

interface LoadingScreenProps {
  isGeolocating?: boolean;
  isLoadingEvents?: boolean;
  onLocationSelect?: (location: { latitude: number; longitude: number }) => void;
  geoError?: string;
  currentLocation?: {
    latitude: number;
    longitude: number;
    cityName?: string;
  };
  onEventsLoaded?: () => void;
}

const LoadingScreen: React.FC<LoadingScreenProps> = ({ 
  isGeolocating = true,
  isLoadingEvents = false,
  onLocationSelect,
  geoError,
  currentLocation,
  onEventsLoaded
}) => {
  const [loadingError, setLoadingError] = useState<string | null>(null);

  // Utiliser la même blague que celle du shell
  const joke = (window as any).__PRELOADED_JOKE__ || jokes[0];

  const handleLocationSelect = async (location: { latitude: number; longitude: number }) => {
    try {
      // 1. Montrer l'écran de chargement des événements
      if (onLocationSelect) {
        onLocationSelect(location);
      }

      // 2. Précharger les événements
      console.log('LoadingScreen: Préchargement des événements pour', location);
      const events = await geoEventQueries.getEventsInZone(location);
      
      // 3. Notifier que les événements sont chargés
      if (onEventsLoaded) {
        onEventsLoaded();
      }
    } catch (error) {
      console.error('Erreur lors du chargement des événements:', error);
      setLoadingError('Une erreur est survenue lors du chargement des événements. Veuillez réessayer.');
    }
  };

  // Message plus précis selon l'état
  const loadingMessage = isGeolocating 
    ? "Finding your location..." 
    : isLoadingEvents && currentLocation?.cityName
      ? `Loading events near ${currentLocation.cityName}...`
    : isLoadingEvents
      ? "Loading events near you..."
      : "Where do you want to explore?";

  return (
    <div className="loading-screen">
      <div className="loading-screen__content">
        {/* Loading title */}
        <h2 className="loading-screen__title">
          {loadingMessage}
        </h2>

        {/* LocationFilter ou Blague */}
        {isGeolocating || isLoadingEvents ? (
          <div className="loading-screen__joke">
            {joke}
          </div>
        ) : (
          <div className="loading-screen__location">
            {(geoError || loadingError) && (
              <p className="loading-screen__error">
                {geoError || loadingError}
              </p>
            )}
            <LocationFilter 
              onLocationChange={handleLocationSelect}
              isLoading={false}
              currentLocation={currentLocation || null}
            />
          </div>
        )}

        {/* Logo EventConnect */}
        <div className="loading-screen__logo">
          <LogoSVG 
            variant="eventConnect"
            size="auto"
            padding={0}
            color="var(--primary-color)"
          />
        </div>

        {/* Spinner uniquement pendant le chargement des events */}
        {(isGeolocating || isLoadingEvents) && <div className="loading-screen__spinner" />}
      </div>
    </div>
  );
};

export default LoadingScreen;
