import { collection, query, where, orderBy, getDocs } from 'firebase/firestore';
import { db } from './config';
import { Timestamp } from 'firebase/firestore';

export const testIndexes = async () => {
  const eventsRef = collection(db, 'events');
  
  try {
    // Test 1: Index pour les événements actifs avec géolocalisation
    const geoQuery = query(
      eventsRef,
      where('status', '==', 'active'),
      where('latitude', '>=', 0),
      where('latitude', '<=', 1),
      orderBy('latitude'),
      orderBy('datetime')
    );
    await getDocs(geoQuery);

    // Test 2: Index pour les événements d'une série
    const seriesQuery = query(
      eventsRef,
      where('seriesId', '!=', null),
      orderBy('seriesId'),
      orderBy('datetime')
    );
    await getDocs(seriesQuery);

    // Test 3: Index pour les événements par organisateur
    const organizerQuery = query(
      eventsRef,
      where('organizerId', '!=', null),
      orderBy('organizerId'),
      orderBy('datetime')
    );
    await getDocs(organizerQuery);

    // Test des nouveaux index nécessaires
    const newGeoQuery = query(
      eventsRef,
      where('status', '==', 'active'),
      where('latitude', '>=', 0),
      where('latitude', '<=', 1),
      where('endTime', '>=', Timestamp.now()),
      orderBy('endTime'),
      orderBy('datetime')
    );
    await getDocs(newGeoQuery);

    console.log('✅ Tous les tests d\'index ont été lancés');
  } catch (error) {
    console.error('🚨 Erreur lors des tests d\'index:', error);
    console.log('👉 Suivez les liens dans la console Firebase pour créer les index manquants');
  }
}; 