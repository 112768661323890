import { useState, useCallback, useEffect } from 'react';
import { Event } from '../types/event.types';

export const useFavoriteState = (
  id: string,
  endTime?: Date | string,
  onFavoriteChange?: (isFavorite: boolean) => void
) => {
  // État local du favori
  const [isFavorite, setIsFavorite] = useState(() => {
    try {
      const savedFavorite = localStorage.getItem(`favorite-${id}`);
      if (!savedFavorite) return false;
      
      const favoriteData = JSON.parse(savedFavorite);
      const expirationDate = new Date(favoriteData.expiresAt);
      
      // Vérifier si la date d'expiration est valide
      if (isNaN(expirationDate.getTime())) return false;
      
      return favoriteData.isFavorite && expirationDate > new Date();
    } catch {
      return false;
    }
  });

  // Mettre à jour l'état quand le localStorage change
  useEffect(() => {
    const handleFavoritesUpdate = () => {
      try {
        const savedFavorite = localStorage.getItem(`favorite-${id}`);
        if (!savedFavorite) {
          setIsFavorite(false);
          return;
        }
        
        const favoriteData = JSON.parse(savedFavorite);
        const expirationDate = new Date(favoriteData.expiresAt);
        
        // Vérifier si la date d'expiration est valide
        if (isNaN(expirationDate.getTime())) {
          setIsFavorite(false);
          return;
        }
        
        setIsFavorite(favoriteData.isFavorite && expirationDate > new Date());
      } catch {
        setIsFavorite(false);
      }
    };

    window.addEventListener('favoritesUpdated', handleFavoritesUpdate);
    return () => window.removeEventListener('favoritesUpdated', handleFavoritesUpdate);
  }, [id]);

  // Fonction pour mettre à jour le favori
  const toggleFavorite = useCallback((newValue: boolean) => {
    setIsFavorite(newValue);
    
    try {
      let expirationDate: Date;
      
      // Si endTime est au format ISO (YYYY-MM-DDTHH:mm)
      if (typeof endTime === 'string' && endTime.includes('T')) {
        expirationDate = new Date(endTime);
      } 
      // Si endTime est une Date
      else if (endTime instanceof Date) {
        expirationDate = endTime;
      } 
      // Fallback à la date actuelle + 1 jour
      else {
        expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 1);
      }

      // S'assurer que la date d'expiration est valide
      if (isNaN(expirationDate.getTime())) {
        console.warn('Invalid expiration date, using fallback');
        expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 1);
      }

      // Mise à jour du localStorage pour les favoris
      localStorage.setItem(`favorite-${id}`, JSON.stringify({
        isFavorite: newValue,
        expiresAt: expirationDate.toISOString()
      }));

      // Si on retire des favoris, on doit aussi retirer de la hiddenList
      if (!newValue) {
        const hiddenEvents = JSON.parse(localStorage.getItem('hiddenEvents') || '[]');
        const updatedHiddenEvents = hiddenEvents.filter(
          (event: any) => event.eventId !== id || event.reason !== 'favorited'
        );
        localStorage.setItem('hiddenEvents', JSON.stringify(updatedHiddenEvents));
      }

      if (onFavoriteChange) {
        onFavoriteChange(newValue);
      }

      window.dispatchEvent(new CustomEvent('favoritesUpdated'));
    } catch (error) {
      console.error('Error updating favorite state:', error);
    }
  }, [id, endTime, onFavoriteChange]);

  return {
    isFavorite,
    toggleFavorite
  };
}; 