import React from 'react';
import './ContentBlock.css';
import { GlassPanel } from '../../primitives/GlassPanel';
import { Icon, IconName } from '../../primitives/Icon';

interface ContentBlockProps {
  title?: string;
  subtitle?: string;
  icon?: IconName;
  variant?: 'light' | 'dark';
  padding?: '0' | 'xs' | 'sm' | 'md' | 'lg';
  size?: 'auto' | 'full' | 'sm' | 'md' | 'lg' | 'xl';
  maxWidth?: string;
  minWidth?: string;
  centered?: boolean;
  className?: string;
  onClick?: (e: React.MouseEvent) => void;
  scrollable?: boolean;
  maxHeight?: string;
  scrollBehavior?: 'smooth' | 'auto';
  spacing?: '0' | 'xs' | 'sm' | 'md' | 'lg';
  children: React.ReactNode;
}

export const ContentBlock: React.FC<ContentBlockProps> = ({
  title,
  subtitle,
  icon,
  variant = 'light',
  padding = 'md',
  size = 'auto',
  maxWidth,
  minWidth,
  centered = false,
  className,
  onClick,
  scrollable = false,
  maxHeight,
  scrollBehavior = 'smooth',
  spacing = '0',
  children
}) => {
  return (
    <div 
      className={`content-block content-block--${size} content-block--spacing-${spacing} ${centered ? 'content-block--centered' : ''} ${className || ''}`}
      style={{
        maxWidth,
        minWidth
      }}
      onClick={onClick}
    >
      <GlassPanel variant={variant}>
        <div className={`content-block__inner content-block__inner--padding-${padding}`}>
          {(title || icon) && (
            <div className="content-block__header">
              {icon && <Icon name={icon} className="content-block__icon" />}
              <div className="content-block__titles">
                {title && <h3 className="content-block__title">{title}</h3>}
                {subtitle && <p className="content-block__subtitle">{subtitle}</p>}
              </div>
            </div>
          )}
          <div 
            className={`content-block__content ${scrollable ? 'content-block__content--scrollable' : ''}`}
            style={{
              maxHeight: scrollable ? (maxHeight || '200px') : undefined,
              scrollBehavior
            }}
          >
            {children}
          </div>
        </div>
      </GlassPanel>
    </div>
  );
};
