import { Event } from '../types/event.types';

// Types pour nos règles
interface SortingRule {
  name: string;
  weight: number;
  calculate: (event: Event, userLocation?: { latitude: number; longitude: number }) => number;
}

interface ViewConfig {
  rules: SortingRule[];
  options: {
    maxDistance: number; 
    userMaxDistance?: number;    // en km
    maxEvents: number;      // nombre max d'événements
    maxDays: number;        // horizon temporel en jours
  };
}

// Fonctions utilitaires
export const calculateDistance = (lat1: number, lon1: number, lat2: number, lon2: number): number => {
  const R = 6371; // Rayon de la Terre en km
  const dLat = (lat2 - lat1) * Math.PI / 180;
  const dLon = (lon2 - lon1) * Math.PI / 180;
  const a = 
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
    Math.sin(dLon/2) * Math.sin(dLon/2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  return R * c;
};

// Fonction pour formater la distance avec des descriptions contextuelles
export const formatDistance = (distance: number): string => {
  // Convertir en mètres pour les petites distances
  const meters = distance * 1000;

  // Arrondir à 100m près pour les distances < 1km
  const roundedMeters = Math.round(meters / 100) * 100;

  if (distance < 0.1) {
    return ' • < 100m';
  } else if (distance < 1) {
    return ` • ${(distance * 1000).toFixed(0)}m`;
  } else {
    return ` • ${distance.toFixed(1)}km`;
  }
};

// Fonction pour ouvrir dans Maps
export const openInMaps = (latitude: number, longitude: number, address: string) => {
  // Vérifier si on est sur mobile
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  // Encoder l'adresse pour l'URL
  const encodedAddress = encodeURIComponent(address);
  
  // Sur mobile, essayer d'ouvrir l'app native Maps
  if (isMobile) {
    // Pour iOS
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      window.location.href = `maps://maps.apple.com/?q=${encodedAddress}&ll=${latitude},${longitude}`;
      return;
    }
    // Pour Android
    if (/Android/i.test(navigator.userAgent)) {
      window.location.href = `geo:${latitude},${longitude}?q=${encodedAddress}`;
      return;
    }
  }
  
  // Par défaut, ouvrir Google Maps dans un nouvel onglet
  const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
  window.open(googleMapsUrl, '_blank');
};

// Configuration pour chaque vue
export const viewConfigs: Record<'list' | 'map', ViewConfig> = {
  list: {
    rules: [
      {
        name: 'distance',
        weight: 0.4,
        calculate: (event, userLocation) => {
          if (!userLocation || !event.latitude || !event.longitude) return 0;
          const distance = calculateDistance(
            userLocation.latitude,
            userLocation.longitude,
            event.latitude,
            event.longitude
          );
          return Math.max(0, 1 - distance / 10000); // Score inversement proportionnel à la distance
        }
      },
      {
        name: 'time',
        weight: 0.4,
        calculate: (event) => {
          if (!event.datetime) return 0;
          const now = new Date();
          const eventDate = new Date(event.datetime);
          const daysUntil = (eventDate.getTime() - now.getTime()) / (1000 * 3600 * 24);
          return Math.max(0, 1 - daysUntil / 10); // Priorité aux événements proches dans le temps
        }
      },
      {
        name: 'popularity',
        weight: 0.2,
        calculate: (event) => {
          return Math.min(1, (event.views || 0) / 100); // Score basé sur les vues
        }
      }
    ],
    options: {
      maxDistance: 5,     // Aligné avec FilterDrawer
      maxEvents: 50,
      maxDays: 30
    }
  },
  map: {
    rules: [
      {
        name: 'distance',
        weight: 0.6,
        calculate: (event, userLocation) => {
          if (!userLocation || !event.latitude || !event.longitude) return 0;
          const distance = calculateDistance(
            userLocation.latitude,
            userLocation.longitude,
            event.latitude,
            event.longitude
          );
          return Math.max(0, 1 - distance / 10000); 
        }
      },
      {
        name: 'time',
        weight: 0.4,
        calculate: (event) => {
          if (!event.datetime) return 0;
          const now = new Date();
          const eventDate = new Date(event.datetime);
          const daysUntil = (eventDate.getTime() - now.getTime()) / (1000 * 3600 * 24);
          return Math.max(0, 1 - daysUntil / 7);
        }
      }
    ],
    options: {
      maxDistance: 5,     // Aligné avec FilterDrawer
      maxEvents: 30,
      maxDays: 30
    }
  }
};

// Fonction principale de tri
export const sortEvents = (
  events: Event[],
  viewMode: 'list' | 'map',
  userLocation?: { latitude: number; longitude: number },
  userMaxDistance?: number 
): Event[] => {
  const config = viewConfigs[viewMode];
  const effectiveMaxDistance = userMaxDistance || config.options.maxDistance;

  return events
    .filter(event => {
      if (!event.datetime || !event.latitude || !event.longitude) return false;
      
      if (userLocation) {
        const distance = calculateDistance(
          userLocation.latitude,
          userLocation.longitude,
          event.latitude,
          event.longitude
        );
        if (viewMode === 'list' && userMaxDistance && distance > userMaxDistance) {
          return false;
      }
    }

      const now = new Date();
      const eventDate = new Date(event.datetime);
      const daysUntil = (eventDate.getTime() - now.getTime()) / (1000 * 3600 * 24);
      
      return daysUntil <= config.options.maxDays;
    })
    .sort((a, b) => {
      let scoreA = 0;
      let scoreB = 0;

      config.rules.forEach(rule => {
        scoreA += rule.calculate(a, userLocation) * rule.weight;
        scoreB += rule.calculate(b, userLocation) * rule.weight;
      });

      return scoreB - scoreA;
    })
    .slice(0, config.options.maxEvents);
};
