import React, { useState, useCallback, useMemo } from 'react';
import { Event } from '../../types/event.types';
import { useNavigate, useLocation } from 'react-router-dom';
import './card.css';
import './RecurringEventCard.css';
import { useEventSeries } from '../../hooks/useEventSeries';
import { formatDateTime } from '../../utils/dateFormatter';
import { useSwipeCard } from '../../hooks/useSwipeCard';
import { motion } from 'framer-motion';
import { useFavoriteState } from '../../hooks/useFavoriteState';
import { 
  Tag, 
  Icon, 
  Button, 
  FavoriteButton,
  ShareButton,
  NotificationBubble,
  NoImageTags,
  DateIcon,
  ContentBlock,
  LogoSVG
} from '../../pages/DesignSystem';
import { SwipeableCard } from '../../pages/DesignSystem/patterns/Modal/BaseModal';
import { openInMaps } from '../../utils/eventSorting';
import { useDistance } from '../../hooks/useDistance';

interface RecurringEventCardProps {
  mainEvent: Event;
  seriesEvents: Event[];
  userLocation?: { latitude: number; longitude: number };
  isMapView?: boolean;
  onDismiss?: (eventId: string) => void;
  onFavorite?: (eventId: string) => void;
  onClick?: (event: Event, seriesEvents: Event[]) => void;
  disableSwipe?: boolean;
}

const RecurringEventCard: React.FC<RecurringEventCardProps> = ({ 
  mainEvent, 
  seriesEvents,
  userLocation,
  isMapView = false,
  onDismiss,
  onFavorite,
  onClick,
  disableSwipe = false
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const { series, formatRecurrencePattern } = useEventSeries(mainEvent.seriesId);
  const [showNotif, setShowNotif] = useState(false);

  const sortedEvents = useMemo(() => {
    const now = new Date();
    return seriesEvents
      .filter(event => {
        if (!event.datetime) return false;
        const eventDate = new Date(event.datetime);
        return eventDate > now;
      })
      .sort((a, b) => {
        if (!a.datetime || !b.datetime) return 0;
        const dateA = new Date(a.datetime);
        const dateB = new Date(b.datetime);
        return dateA.getTime() - dateB.getTime();
      });
  }, [seriesEvents]);

  // Calculer la dernière date de la série
  const lastEvent = sortedEvents[sortedEvents.length - 1];
  const expiresAt = lastEvent?.datetime || mainEvent.datetime;

  const { toggleFavorite } = useFavoriteState(
    mainEvent.seriesId || mainEvent.id,
    expiresAt  // Utiliser la date du dernier événement
  );

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(mainEvent, sortedEvents);
    } else {
      const currentPath = location.pathname;
      const basePath = currentPath.startsWith('/favorites') ? '/favorites' : '';
      
      navigate(`${basePath}/series/${mainEvent.seriesId}`, {
        state: {
          isMapView,
          userLocation,
          seriesEvents: sortedEvents,
          event: mainEvent,
          returnToMulti: false,
          returnTo: currentPath
        }
      });
    }
  }, [mainEvent, sortedEvents, onClick, navigate, isMapView, userLocation, location.pathname]);

  const handleKeyPress = useCallback((e: React.KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      handleClick();
    }
  }, [handleClick]);

  const distance = useDistance(userLocation, {
    latitude: mainEvent.latitude,
    longitude: mainEvent.longitude
  });

  const handleOpenMaps = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    if (mainEvent.latitude && mainEvent.longitude) {
      openInMaps(mainEvent.latitude, mainEvent.longitude, mainEvent.locationName || 'Event');
    }
  }, [mainEvent.latitude, mainEvent.longitude, mainEvent.locationName]);

  const handleFavorite = (newValue: boolean) => {
    toggleFavorite(newValue);
    if (onFavorite && newValue) {
      onFavorite(mainEvent.id);
    }
  };

  return (
    <SwipeableCard
      onLike={() => {
        handleFavorite(true);
      }}
      onDismiss={() => {
        if (onDismiss) {
          onDismiss(mainEvent.seriesId || mainEvent.id);
        }
      }}
      eventId={mainEvent.id}
      seriesId={mainEvent.seriesId}
      disableSwipe={disableSwipe}
    >
      <div 
        className="event-card"
        onClick={handleClick}
        onKeyPress={handleKeyPress}
        tabIndex={0}
        role="button"
        aria-label={`View details for ${mainEvent.title}`}
      >
        {/* Header */}
        <div className="event-card__header">
          <div className="event-card__tags">
            
            {mainEvent.tags?.map(tag => (
              <Tag 
                key={tag} 
                label={tag} 
                type="activity"
                size="md"
              />
            ))}
          </div>
          <div className="event-card__actions" onClick={e => e.stopPropagation()}>
            <ShareButton
              shareData={{
                title: mainEvent.title,
                text: `what2do.today\nCome discover ${mainEvent.title}`,
                url: window.location.href
              }}
              variant="icon"
              size="lg"
            />
            <FavoriteButton
              id={mainEvent.id}
              seriesId={mainEvent.seriesId}
              expiresAt={mainEvent.datetime}
              seriesEvents={sortedEvents}
              onFavoriteChange={handleFavorite}
              size="lg"
            />
          </div>
        </div>

        {/* Main Content */}
        <div className="event-card__main">
          <div className="event-card__image">
            {mainEvent.image ? (
              <img 
                src={mainEvent.image}
                alt={mainEvent.title}
                className={`event-image ${imageLoaded ? 'loaded' : ''}`}
                loading="lazy"
                onLoad={() => setImageLoaded(true)}
              />
            ) : (
              <LogoSVG 
                variant="eventConnect"
                size="auto"
                padding={16}
                color="var(--primary-dark)"
                className="event-fallback-logo"
              />
            )}
          </div>

          <div className="event-card__info">
            <h3 className="event-card__title">{mainEvent.title}</h3>
            <ContentBlock>
              <div className="event-card__datetime">
                <div className="event-card__time-info">
                  <span className="recurrence-pattern">
                    {formatRecurrencePattern()}
                  </span>
                  
                </div>
              </div>
            </ContentBlock>
          </div>
        </div>

        {/* Footer */}
        <div className="event-card__footer">
          {sortedEvents.length > 0 && (
            <ContentBlock padding="sm">
              <div 
                className="event-card__dates interactive"
                onClick={e => e.stopPropagation()}
                onTouchStart={e => e.stopPropagation()}
                onTouchMove={e => e.stopPropagation()}
              >
                <button 
                  className="event-card__dates-toggle interactive"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsExpanded(!isExpanded);
                  }}
                >
                  <Icon name={isExpanded ? "ChevronUp" : "ChevronDown"} size={16} />
                  {isExpanded ? "Hide dates" : "Show all dates"}
                </button>
                <div 
                  className={`event-card__dates-list interactive ${isExpanded ? 'event-card__dates-list--expanded' : ''}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsExpanded(false);
                  }}
                >
                  {sortedEvents.map((event) => (
                    <div 
                      key={event.id} 
                      className="event-card__date-item interactive"
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsExpanded(false);
                      }}
                    >
                      <div className="event-card__date-info">
                        <span className="event-card__date">
                          {formatDateTime(event.datetime, event.timezone).date}
                        </span>
                        <span className="event-card__time">
                          {formatDateTime(event.datetime, event.timezone).time}
                        </span>
                      </div>
                      <span className={`event-card__status event-card__status--${event.isException ? 'modified' : 'upcoming'}`}>
                        {event.isException ? 'Modified' : 'Upcoming'}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </ContentBlock>
          )}

 {/* Prix multiples */}
 {mainEvent.prices && mainEvent.prices.length > 0 && mainEvent.prices.some(price => price.amount > 0) && (
            <ContentBlock>

                  <span className="modal-item-content">
                  <span className="modal-item-icon">
                   <Icon name="Ticket" size={32} />
                  </span>
                    <span className="price-list">
                      {mainEvent.prices.map((price, index) => (
                        <span key={index} className="price-item">
                          {price.amount === 0 ? (
                            <span className="price-free">Free</span>
                          ) : (
                            <span className="price-amount">
                              {price.amount} {price.currency === 'EUR' ? '€' : price.currency}
                            </span>
                          )}
                          {price.description && (
                            <span className="price-description">
                              {' - '}{price.description}
                            </span>
                          )}
                        </span>
                      ))}
                    </span>
                  </span>
               
            </ContentBlock>
          )}

          <ContentBlock>
            <div className="event-card__location">
              <div className="modal-item-icon">
                <Icon name="MapPinned" size={32}/>
              </div>
              <div className="event-card__location-content">
                <div className="event-card__location-details">
                <div className="event-card__location-top-line">
                  <span className="event-card__location-name">{mainEvent.locationName}</span>
                  {distance && (
                    <span className="event-card__location-distance">{distance}</span>
                  )}
                </div>
                {mainEvent.latitude && mainEvent.longitude && (
                  <Button 
                    variant="outline"
                    size="sm"
                    onClick={handleOpenMaps}
                    className="event-card__location-button"
                  >
                    Get directions
                  </Button>
                )}
                </div>
              </div>
            </div>
          </ContentBlock>
        </div>
      </div>

      <NotificationBubble
        show={showNotif}
        message="Contact copied! ✨"
        type="success"
        duration={3000}
        onHide={() => setShowNotif(false)}
      />
    </SwipeableCard>
  );
};

export default RecurringEventCard;
