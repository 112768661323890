import { useState, useEffect } from 'react';

export const useOfflineStatus = () => {
  const [isOffline, setIsOffline] = useState(!navigator.onLine);
  const [hasCache, setHasCache] = useState(false);

  useEffect(() => {
    // Vérifier si on a du cache
    const checkCache = async () => {
      try {
        const cache = await caches.open('firestore-data');
        const keys = await cache.keys();
        setHasCache(keys.length > 0);
      } catch (error) {
        console.error('Erreur lors de la vérification du cache:', error);
        setHasCache(false);
      }
    };

    // Listeners pour le statut online/offline
    const handleOnline = () => setIsOffline(false);
    const handleOffline = () => setIsOffline(true);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    checkCache();

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return { isOffline, hasCache };
}; 