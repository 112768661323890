import React, { useEffect, useState, useCallback, useRef } from 'react';
import './Drawer.css';

interface BaseDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  children: React.ReactNode;
  className?: string;
  hasOverlay?: boolean;
}

interface DrawerState {
  isDragging: boolean;
  startY: number;
  currentY: number;
  velocity: number;
  lastTime: number;
}

const BaseDrawer: React.FC<BaseDrawerProps> = ({
  isOpen,
  onClose,
  title,
  children,
  className = '',
  hasOverlay = true
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [isFullyOpen, setIsFullyOpen] = useState(false);
  const drawerRef = useRef<HTMLDivElement>(null);
  
  const [drawerState, setDrawerState] = useState<DrawerState>({
    isDragging: false,
    startY: 0,
    currentY: 0,
    velocity: 0,
    lastTime: 0
  });
  
  // Gestion de l'ouverture/fermeture
  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
      setIsClosing(false);
      setIsReady(false);
      setIsFullyOpen(false);
      
      const mountingDelay = setTimeout(() => {
        requestAnimationFrame(() => {
          setIsReady(true);
          requestAnimationFrame(() => {
            setIsFullyOpen(true);
          });
        });
      }, 50);

      return () => clearTimeout(mountingDelay);
    } else {
      setIsClosing(true);
      setIsFullyOpen(false);
      const timer = setTimeout(() => {
        setIsVisible(false);
        setIsReady(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  // Gestion du scroll du body
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  // Gestionnaire de touchstart
  const handleTouchStart = useCallback((e: React.TouchEvent) => {
    const touch = e.touches[0];
    
    setDrawerState(prev => ({
      ...prev,
      isDragging: true,
      startY: touch.clientY,
      currentY: touch.clientY,
      lastTime: Date.now()
    }));

    if (drawerRef.current) {
      drawerRef.current.classList.add('drawer--dragging');
    }
  }, []);

  // Gestionnaire de touchmove
  const handleTouchMove = useCallback((e: React.TouchEvent) => {
    if (!drawerState.isDragging || !drawerRef.current) return;
    
    const touch = e.touches[0];
    const currentTime = Date.now();
    const deltaTime = currentTime - drawerState.lastTime;
    const deltaY = touch.clientY - drawerState.currentY;
    
    const velocity = deltaY / deltaTime;
    const totalDeltaY = touch.clientY - drawerState.startY;
    
    drawerRef.current.style.setProperty('--drag-offset', `${Math.max(0, totalDeltaY)}px`);
    
    setDrawerState(prev => ({
      ...prev,
      currentY: touch.clientY,
      velocity,
      lastTime: currentTime
    }));
  }, [drawerState]);

  // Gestionnaire de touchend
  const handleTouchEnd = useCallback(() => {
    if (!drawerState.isDragging || !drawerRef.current) return;

    const totalDeltaY = drawerState.currentY - drawerState.startY;
    const shouldClose = totalDeltaY > 100 || drawerState.velocity > 0.5;

    drawerRef.current.style.removeProperty('--drag-offset');
    drawerRef.current.classList.remove('drawer--dragging');

    setDrawerState(prev => ({
      ...prev,
      isDragging: false,
      velocity: 0
    }));

    if (shouldClose) {
      onClose();
    }
  }, [drawerState, onClose]);

  if (!isVisible) return null;

  return (
    <>
      {hasOverlay && (
        <div 
          className={`drawer-overlay ${isOpen ? 'drawer-overlay--visible' : ''}`}
          onClick={onClose}
          aria-hidden="true"
        />
      )}
      <div className={`drawer-container ${className}`}>
        <div 
          ref={drawerRef}
          className={`drawer ${isReady ? 'drawer--ready' : ''} ${isFullyOpen ? 'drawer--open' : ''} ${isClosing ? 'drawer--closing' : ''}`}
          role="dialog"
          aria-modal="true"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          {title && (
            <div className="drawer-header">
              <h2 className="drawer-title">{title}</h2>
            </div>
          )}
          <div className="drawer-content">
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default BaseDrawer; 