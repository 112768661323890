import React, { useEffect, useState, useMemo, useContext } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { usePublicEvents } from '../../hooks/usePublicEvents';
import EventModal from './EventModal';
import PreviewCardMap from './PreviewCardMap';
import { Event } from '../../types/event.types';
import { geoEventQueries } from '../../firebase/geoEventQueries';
import { useEventContext } from '../../contexts/EventContext';
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { useEventSeries } from '../../hooks/useEventSeries';

interface LocationState {
  userLocation?: { latitude: number; longitude: number };
  multiEvents?: Event[];
  returnToMulti?: boolean;
  returnToSeries?: boolean;
  seriesId?: string;
  location?: string;
  isMapView?: boolean;
  returnTo?: string;
  event?: Event; // Pour les événements déjà chargés
  seriesEvents?: Event[];
}

const mergeSeriesWithEvent = async (event: Event): Promise<Event> => {
  if (!event.seriesId) return event;
  
  try {
    console.log('🔄 Merging event:', {
      eventId: event.id,
      seriesId: event.seriesId,
      isException: event.isException
    });
    
    const seriesDoc = await getDoc(doc(db, 'eventSeries', event.seriesId));
    if (!seriesDoc.exists()) return event;
    
    const seriesData = seriesDoc.data();
    console.log('✅ Series data loaded:', {
      seriesId: event.seriesId,
      hasData: !!seriesData
    });
    
    // Fusionner les données en priorisant les exceptions
    return {
      ...seriesData, // Données de base de la série
      ...event,      // Données spécifiques de l'événement
      // Garder les champs spécifiques de l'événement
      datetime: event.datetime,
      endTime: event.endTime,
      isException: event.isException,
      // Assurer que ces champs sont présents
      description: event.isException ? event.description : seriesData.description,
      prices: event.isException ? event.prices : seriesData.prices,
      ticketingUrl: event.isException ? event.ticketingUrl : seriesData.ticketingUrl,
    } as Event;
  } catch (error) {
    console.error('❌ Merge error:', error);
    return event;
  }
};

const EventModalRoute: React.FC = () => {
  const { id, seriesId } = useParams<{ id?: string; seriesId?: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const { events } = usePublicEvents();
  const locationState = location.state as LocationState;
  const [fullEvent, setFullEvent] = useState<Event | null>(locationState?.event || null);
  const [loading, setLoading] = useState(!locationState?.event);
  const { series } = useEventSeries(seriesId || fullEvent?.seriesId);

  const seriesEvents = useMemo(() => {
    console.log('🔄 seriesEvents calculation:', {
      hasLocationStateEvents: !!locationState?.seriesEvents,
      locationStateEventsCount: locationState?.seriesEvents?.length || 0,
      hasEvents: !!events,
      hasSeries: !!series,
      seriesId: seriesId || fullEvent?.seriesId
    });

    if (locationState?.seriesEvents && locationState.seriesEvents.length > 0) {
      return locationState.seriesEvents;
    }

    if (!events || !series) return [];
    const filtered = events.filter(e => e.seriesId === (seriesId || fullEvent?.seriesId));
    
    console.log('📦 Series events found:', {
      count: filtered.length,
      events: filtered,
      seriesId: seriesId || fullEvent?.seriesId
    });
    return filtered;
  }, [events, series, seriesId, fullEvent?.seriesId, locationState?.seriesEvents]);

  // Combiner les données de l'événement avec celles de la série
  const enrichedEvent = useMemo(() => {
    if (!fullEvent || !series) return fullEvent;

    // Utiliser uniquement les propriétés disponibles dans la série
    const seriesData = {
      ...series,              // Données de base de la série
      ...fullEvent,          // Données spécifiques de l'événement
      // Garder les champs spécifiques de l'événement
      datetime: fullEvent.datetime,
      endTime: fullEvent.endTime,
      // Utiliser les données de l'événement si c'est une exception, sinon celles de la série
      description: fullEvent.isException ? fullEvent.description : series.description,
      ticketingUrl: fullEvent.isException ? fullEvent.ticketingUrl : series.ticketingUrl,
      prices: fullEvent.isException ? fullEvent.prices : series.prices,
      // Garder les données de l'organisateur de l'événement
      organizer: fullEvent.organizer,
      organizerImage: fullEvent.organizerImage,
      organizerId: fullEvent.organizerId || series.organizerId,
      organizerSubtitle: fullEvent.organizerSubtitle
    };

    console.log('🔄 Enriching event with series data:', {
      eventId: fullEvent.id,
      hasSeriesData: !!series,
      isException: fullEvent.isException,
      organizerId: seriesData.organizerId
    });

    return seriesData;
  }, [fullEvent, series]);

  // Ajouter un effet pour charger les données de l'organisateur si nécessaire
  useEffect(() => {
    const loadOrganizerData = async () => {
      if (!enrichedEvent?.organizerId || enrichedEvent.organizer) return;

      try {
        const organizerData = await geoEventQueries.processOrganizerData(enrichedEvent.organizerId);
        setFullEvent(prev => prev ? {
          ...prev,
          organizer: organizerData.organizer,
          organizerImage: organizerData.organizerImage,
          organizerSubtitle: organizerData.organizerSubtitle,
          organizerContact: organizerData.organizerContact
        } : prev);
      } catch (error) {
        console.error('Error loading organizer data:', error);
      }
    };

    loadOrganizerData();
  }, [enrichedEvent?.organizerId]);

  useEffect(() => {
    const loadEvent = async () => {
      const currentSeriesEvents = seriesEvents || [];
      console.log('🔄 Loading event...', { 
        id, 
        seriesId, 
        hasSeriesEvents: currentSeriesEvents.length,
        fullEvent
      });

      try {
        if (seriesId && currentSeriesEvents.length > 0) {
          const firstEvent = currentSeriesEvents[0];
          console.log('📥 Loading series event:', {
            eventId: firstEvent.id,
            event: firstEvent
          });
          setFullEvent(firstEvent);
          setLoading(false);
        } else if (id) {
          const loadedEvent = await geoEventQueries.getEventDetails(id);
          console.log('📥 Loaded single event:', loadedEvent);
          if (loadedEvent) {
            setFullEvent(loadedEvent);
          }
        }
      } catch (error) {
        console.error('❌ Error:', error);
      } finally {
        setLoading(false);
      }
    };

    loadEvent();
  }, [id, seriesId, seriesEvents]);

  useEffect(() => {
    const handlePopState = () => handleClose();
    window.addEventListener('popstate', handlePopState);
    return () => window.removeEventListener('popstate', handlePopState);
  }, [locationState]);

  const handleClose = () => {
    // Si on vient des favoris, on y retourne
    if (locationState?.returnTo === '/favorites') {
      navigate('/favorites');
      return;
    }

    // Si on est en vue carte (isMapView est true)
    if (locationState?.isMapView) {
      // Retour à PreviewCardMap en préservant l'état précédent
      navigate(-1);
      return;
    }

    // Par défaut (vue liste), on retourne à la homepage
    navigate('/');
  };

  const handleMultiEventClick = (selectedEvent: Event) => {
    navigate(`/event/${selectedEvent.id}`, {
      state: {
        userLocation: locationState?.userLocation,
        returnToMulti: true,
        multiEvents: locationState?.multiEvents,
        location: locationState?.location
      }
    });
  };

  const handleDismiss = (eventId: string) => {
    console.log('🎯 Modal Dismiss called with:', eventId);
    const idToHide = fullEvent?.seriesId || eventId;
    console.log('🎯 Using idToHide:', idToHide);
    
    const newHiddenEvent = {
      eventId: idToHide,
      reason: 'dismissed',
      timestamp: Date.now()
    };
    
    try {
      const stored = JSON.parse(localStorage.getItem('hiddenEvents') || '[]');
      localStorage.setItem('hiddenEvents', JSON.stringify([...stored, newHiddenEvent]));
      window.dispatchEvent(new CustomEvent('hiddenEventsUpdated'));
    } catch (error) {
      console.error('Error updating localStorage:', error);
    }
    
    handleClose();
  };

  if (location.pathname.startsWith('/events/') && locationState?.multiEvents) {
    const currentEvent = events?.find(e => e.id === id);
    const seriesEvents = currentEvent?.seriesId 
      ? events?.filter(e => e.seriesId === currentEvent.seriesId)
      : undefined;

    return (
      <PreviewCardMap
        events={locationState.multiEvents}
        location={locationState.location || ''}
        isOpen={true}
        onClose={() => navigate('/', { replace: true })}
        onEventClick={handleMultiEventClick}
        currentEvent={currentEvent}
        seriesEvents={seriesEvents}
      />
    );
  }

  if (enrichedEvent && !loading) {
    return (
      <EventModal
        event={enrichedEvent}  // Utiliser l'événement enrichi
        isOpen={true}
        onClose={handleClose}
        onDismiss={handleDismiss}
        userLocation={locationState?.userLocation}
        isRecurring={!!enrichedEvent.seriesId}
        seriesEvents={seriesEvents}
      />
    );
  }

  if (loading) {
    return null;
  }

  return null;
};

export default EventModalRoute;
