import { Event } from '../types/event.types';
import { FormData } from '../components/organizer/EventForm/types';



// Nettoie les données du formulaire pour un événement unique
export const cleanEventData = (formData: FormData): Partial<Event> => {
  const cleanedData: Partial<Event> = {
    organizerId: formData.organizerId,
    organizerContact: formData.organizerContact,
    title: formData.title.trim(),
    description: formData.description?.trim(),
    location: formData.location?.trim(),
    locationName: formData.locationName?.trim(),
    latitude: formData.latitude,
    longitude: formData.longitude,
    tags: formData.tags,
    image: formData.image,
    endTime: formData.endTime?.trim(),
    ticketingUrl: formData.ticketingUrl?.trim(),
    prices: (formData.prices || [])
      .map(price => ({
        amount: Number(price.amount),
        description: price.description.trim(),
        currency: price.currency
      }))
      .filter(price => !isNaN(price.amount) && price.amount >= 0 && price.currency),
    status: formData.status
  };

  // Liste des champs obligatoires qui ne doivent pas être supprimés
  const requiredFields = ['organizerId', 'title', 'status'] as const;

  // Supprimer les champs vides ou undefined de manière typée
  (Object.keys(cleanedData) as Array<keyof Event>).forEach(key => {
    if (!requiredFields.includes(key as typeof requiredFields[number]) && 
        (cleanedData[key] === undefined || cleanedData[key] === '')) {
      delete cleanedData[key];
    }
  });

  // Si prices est un tableau vide, le supprimer
  if (cleanedData.prices?.length === 0) {
    delete cleanedData.prices;
  }

  return cleanedData;
};

// Vérifie si un événement est passé
export const isEventPast = (datetime: Date): boolean => {
  const now = new Date();
  return datetime < now;
};

/**
 * Trie les événements par date
 */
export const sortEventsByDate = (events: Event[]): Event[] => {
  return [...events].sort((a, b) => {
    if (!a.datetime || !b.datetime) return 0;
    return a.datetime.getTime() - b.datetime.getTime();
  });
};

/**
 * Filtre les événements à venir
 */
export const filterUpcomingEvents = (events: Event[]): Event[] => {
  const now = new Date();
  return events.filter(event => {
    if (!event.datetime) return false;
    return event.datetime > now;
  });
};
