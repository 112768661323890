import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Button from '../primitives/Button';
import { Icon } from '../primitives/Icon';
import { Input } from '../primitives/Input';
import { CurrencyInput } from '../primitives/CurrencyInput';

interface Price {
  amount: number;
  currency: string;
  description?: string;
}

interface MultiPriceInputProps {
  id: string;
  prices: Price[];
  onChange: (prices: Price[]) => void;
  label?: string;
  helper?: string;
  error?: string;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  userLocation?: {
    latitude: number;
    longitude: number;
    city?: string;
  };
}

export const MultiPriceInput: React.FC<MultiPriceInputProps> = ({
  id,
  prices,
  onChange,
  label,
  helper,
  error,
  required = false,
  disabled = false,
  className = '',
  userLocation,
}) => {
  const handlePriceChange = (index: number, field: keyof Price, value: any) => {
    console.log('MultiPriceInput - handlePriceChange:', { 
      index, 
      field, 
      value,
      valueType: typeof value 
    });
    
    const newPrices = [...prices];
    if (field === 'amount') {
      newPrices[index] = {
        ...newPrices[index],
        amount: Number(value)
      };
    } else if (field === 'currency') {
      newPrices[index] = {
        ...newPrices[index],
        currency: value,
        amount: newPrices[index].amount  // Keep the existing amount!
      };
    } else {
      newPrices[index] = {
        ...newPrices[index],
        [field]: value
      };
    }
    
    console.log('MultiPriceInput - New price object:', newPrices[index]);
    onChange(newPrices);
  };

  const handleAddPrice = () => {
    if (prices.length < 3) {
      const newPrice: Price = {
        amount: 0,
        currency: prices[0]?.currency || '', // Réutilise la devise du premier prix
        description: '',
      };
      onChange([...prices, newPrice]);
    }
  };

  const handleDeletePrice = (index: number) => {
    const newPrices = prices.filter((_, i) => i !== index);
    onChange(newPrices);
  };

  const containerClasses = [
    'multi-price-input-container',
    className,
    disabled ? 'is-disabled' : '',
  ].filter(Boolean).join(' ');

  return (
    <div className={containerClasses}>
      {label && (
        <label className="multi-price-input-label">
          {label}
          {required && <span className="required-mark">*</span>}
        </label>
      )}

      <TransitionGroup>
        {prices.map((price, index) => (
          <CSSTransition
            key={index}
            timeout={200}
            classNames="price-section"
          >
            <div className="price-section">
              <div className="price-section-header">
                <span className="price-section-title">
                  {index === 0 ? 'Main price' : `Alternative price  ${index}`}
                </span>
                {prices.length > 1 && (
                  <Button
                    variant="ghost"
                    onClick={() => handleDeletePrice(index)}
                    className="delete-price-button"
                    aria-label="Delete this price"
                    disabled={disabled}
                  >
                    <Icon name="Trash2" size={16} />
                  </Button>
                )}
              </div>

              <div className="price-fields-container">
                <CurrencyInput
                  id={`${id}-price-${index}`}
                  value={{
                    amount: price.amount,
                    currency: price.currency,
                  }}
                  onChange={({ amount, currency }) => {
                    const newPrices = [...prices];
                    newPrices[index] = {
                      ...newPrices[index],
                      amount: Number(amount),
                      currency: currency,
                      description: newPrices[index].description
                    };
                    onChange(newPrices);
                  }}
                  disabled={disabled}
                  required={required}
                  userLocation={userLocation}
                />
              </div>

              {prices.length > 1 && (
                <Input
                  id={`${id}-description-${index}`}
                  name={`${id}-description-${index}`}
                  value={price.description || ''}
                  onChange={(value: string) => handlePriceChange(index, 'description', value)}
                  placeholder="Price description (required)"
                  maxLength={50}
                  required
                  disabled={disabled}
                />
              )}
            </div>
          </CSSTransition>
        ))}
      </TransitionGroup>

      {prices.length < 3 && (
        <Button
          variant="ghost"
          onClick={handleAddPrice}
          className="add-price-button"
          disabled={disabled}
        >
          <Icon name="Plus" size={16} />
          Add another price
        </Button>
      )}

      {helper && !error && (
        <p className="multi-price-input-helper">{helper}</p>
      )}
      
      {error && (
        <p className="multi-price-input-error" role="alert">{error}</p>
      )}
    </div>
  );
};

export default MultiPriceInput;