import { collection, query, where, getDocs, Timestamp, doc, getDoc, getDocsFromCache, getDocFromCache, QuerySnapshot, DocumentSnapshot, Query, DocumentData, orderBy } from 'firebase/firestore';
import { db } from './config';
import { Event } from '../types/event.types';
import { calculateDistance } from '../utils/eventSorting';

const EVENTS_COLLECTION = 'events';
const ORGANIZERS_COLLECTION = 'organizers';
const ZONE_SIZE = 5; // km

// Types pour la distinction minimal/full
type MinimalEvent = Pick<Event, 
  | 'id' 
  | 'title' 
  | 'datetime' 
  | 'endTime' 
  | 'endDateTime' 
  | 'location' 
  | 'locationName' 
  | 'latitude' 
  | 'longitude' 
  | 'image' 
  | 'tags' 
  | 'status'
  | 'prices'
  | 'organizerId'
  | 'seriesId'
>;

/**
 * Service pour les requêtes géolocalisées optimisées avec cache natif Firestore
 */
export const geoEventQueries = {
  /**
   * Construit la requête géographique
   */
  buildGeoQuery(center: { latitude: number; longitude: number }, radius: number): Query<DocumentData> {
    const latRange = radius / 111.32;
    const lonRange = radius / (111.32 * Math.cos(center.latitude * Math.PI / 180));
    const now = Timestamp.now();

    const eventsRef = collection(db, EVENTS_COLLECTION);
    
    return query(
      eventsRef,
      where('status', '==', 'active'),
      where('latitude', '>=', center.latitude - latRange),
      where('latitude', '<=', center.latitude + latRange),
      orderBy('latitude'),  // Ajouté pour correspondre à l'index
      orderBy('datetime'),
      orderBy('endTime'),   // Ajouté pour correspondre à l'index
      where('datetime', '>=', now),
      where('endTime', '>=', now.toDate().toISOString())
    );
  },

  /**
   * Récupère les événements dans une zone spécifique
   * Utilise le cache natif quand possible
   */
  async getEventsInZone(
    center: { latitude: number; longitude: number },
    radius: number = 5,
    hiddenEventIds: string[] = [],
    cacheOnly: boolean = false
  ): Promise<MinimalEvent[]> {
    try {
      const geoQuery = this.buildGeoQuery(center, radius);

      // Utiliser l'objet options directement
      const snapshot = await getDocs(geoQuery);

      if (snapshot.metadata.fromCache) {
        console.log('🔄 Vérification des nouveaux événements en arrière-plan');
        
        // Spécifier source: 'server' directement
        getDocs(geoQuery).then(freshSnapshot => {
          if (freshSnapshot.metadata.hasPendingWrites) {
            window.dispatchEvent(new CustomEvent('eventsUpdated', {
              detail: {
                hasNewEvents: freshSnapshot.size > snapshot.size,
                updatedEvents: this.processQuerySnapshot(
                  freshSnapshot, 
                  center.latitude, 
                  center.longitude, 
                  radius, 
                  hiddenEventIds
                )
              }
            }));
          }
        });
      }

      return this.processQuerySnapshot(
        snapshot, 
        center.latitude, 
        center.longitude, 
        radius, 
        hiddenEventIds
      );

    } catch (error) {
      console.error('🚨 Erreur lors de la recherche géographique:', error);
      return [];
    }
  },

  /**
   * Traite les résultats de la requête
   */
  processQuerySnapshot(
    snapshot: QuerySnapshot,
    lat: number,
    lon: number,
    radius: number,
    hiddenEventIds: string[]
  ): MinimalEvent[] {
    console.log('🎯 Nombre d\'événements trouvés:', snapshot.size);
    
    const filteredEvents = snapshot.docs
      .map(doc => {
        const data = doc.data();
        
        // Traitement de endTime
        let endTimeStr = '';
        let endDateTime = '';
        
        if (data.endTime) {
          if (data.endTime instanceof Timestamp) {
            const date = data.endTime.toDate();
            endTimeStr = date.toLocaleTimeString('fr-FR', { 
              hour: '2-digit', 
              minute: '2-digit', 
              hour12: false 
            });
            endDateTime = date.toISOString();
          } else if (typeof data.endTime === 'string') {
            if (data.endTime.includes('T')) {
              // Format ISO
              const date = new Date(data.endTime);
              endTimeStr = date.toLocaleTimeString('fr-FR', { 
                hour: '2-digit', 
                minute: '2-digit', 
                hour12: false 
              });
              endDateTime = data.endTime;
            } else {
              // Format HH:mm
              endTimeStr = data.endTime;
              const [hours, minutes] = data.endTime.split(':');
              const endDate = new Date(data.datetime.toDate());
              endDate.setHours(parseInt(hours), parseInt(minutes));
              endDateTime = endDate.toISOString();
            }
          }
        }

        const minimalEvent: MinimalEvent = {
          id: doc.id,
          title: data.title || '',
          datetime: data.datetime instanceof Timestamp ? data.datetime.toDate() : new Date(data.datetime),
          endTime: endTimeStr,  // Format HH:mm pour les composants
          endDateTime: endDateTime,  // Format ISO pour le calcul de durée
          location: data.location || '',
          locationName: data.locationName || '',
          latitude: data.latitude,
          longitude: data.longitude,
          image: data.image || '',
          tags: data.tags || [],
          status: data.status || 'active',
          prices: data.prices || [],
          organizerId: data.organizerId || '',
          seriesId: data.seriesId || ''
        };
        return minimalEvent;
      })
      .filter(event => {
        if (!event.latitude || !event.longitude) {
          console.log('🚨 Event sans coordonnées:', event.id);
          return false;
        }
        if (hiddenEventIds.includes(event.id)) {
          console.log('🚫 Event masqué:', event.id);
          return false;
        }
        
        const distance = calculateDistance(
          lat, lon,
          event.latitude,
          event.longitude
        );
        const isInRange = distance <= radius;
        if (!isInRange) {
          console.log('📍 Event hors zone:', { eventId: event.id, distance });
        }
        return isInRange;
      });

    console.log('🎯 Événements filtrés:', filteredEvents.length);
    return filteredEvents;
  },

  /**
   * Récupère les détails complets d'un événement
   * Utilise le cache natif quand possible
   */
  async getEventDetails(eventId: string): Promise<Event | null> {
    try {
      const eventRef = doc(db, EVENTS_COLLECTION, eventId);
      const eventDoc = await getDoc(eventRef);
      
      if (!eventDoc.exists()) {
        return null;
      }

      const data = eventDoc.data();
      
      // Récupérer les données de l'organisateur
      let organizerData = await this.processOrganizerData(data.organizerId);

      // Convertir les timestamps
      const datetime = data.datetime instanceof Timestamp ? data.datetime.toDate() : new Date(data.datetime);
      const createdAt = data.createdAt instanceof Timestamp ? data.createdAt.toDate() : data.createdAt;
      const updatedAt = data.updatedAt instanceof Timestamp ? data.updatedAt.toDate() : data.updatedAt;
      const seriesEndDate = data.seriesEndDate instanceof Timestamp ? data.seriesEndDate.toDate() : data.seriesEndDate;

      // Si c'est un événement de série, s'assurer que les propriétés de série ont des valeurs par défaut
      const isSeriesEvent = !!data.seriesId;
      const seriesDefaults = isSeriesEvent ? {
        seriesFrequency: data.seriesFrequency || 'weekly',
        seriesEndType: data.seriesEndType || 'date',
        weekDays: data.weekDays || [],
        time: data.time || datetime.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' }),
        seriesEndDate,
        seriesOccurrences: data.seriesOccurrences || undefined
      } : {};

      // Construire l'objet Event avec toutes les propriétés
      const eventData = {
        id: eventDoc.id,
        organizerId: data.organizerId || '',
        organizer: organizerData.organizer,
        organizerImage: organizerData.organizerImage,
        organizerSubtitle: organizerData.organizerSubtitle,
        organizerContact: organizerData.organizerContact,
        title: data.title || '',
        description: data.description || '',
        location: data.location || '',
        locationName: data.locationName || '',
        latitude: data.latitude,
        longitude: data.longitude,
        tags: data.tags || [],
        views: data.views || 0,
        status: data.status || 'active',
        datetime: data.datetime instanceof Timestamp ? data.datetime.toDate() : new Date(data.datetime),
        createdAt,
        updatedAt,
        prices: data.prices || [],
        image: data.image,
        endTime: data.endTime instanceof Timestamp 
          ? data.endTime.toDate().toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit', hour12: false })
          : data.endTime,
        ticketingUrl: data.ticketingUrl,
        // Propriétés de série
        seriesId: data.seriesId,
        weekDays: data.weekDays || [],
        seriesFrequency: data.seriesFrequency || 'weekly',
        seriesEndType: data.seriesEndType || 'date',
        time: data.time || datetime.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' }),
        seriesEndDate,
        seriesOccurrences: data.seriesOccurrences
      };

      return eventData as Event;
    } catch (error) {
      console.error('Error getting event details:', error);
      return null;
    }
  },

  /**
   * Traite les données d'un organisateur
   */
  processOrganizerData(orgId: string): Promise<{
    organizer: string;
    organizerImage: string;
    organizerSubtitle: string;
    organizerContact: string;
  }> {
    return new Promise(async (resolve, reject) => {
      try {
        const organizerRef = doc(db, ORGANIZERS_COLLECTION, orgId);
        const organizerDoc = await getDoc(organizerRef);
        
        if (!organizerDoc.exists()) {
          reject(new Error('Organizer document does not exist'));
          return;
        }

        const orgData = organizerDoc.data();
        
        resolve({
          organizer: orgData.organizer || '',
          organizerImage: orgData.organizerImage || '',
          organizerSubtitle: orgData.subtitle || '',
          organizerContact: orgData.organizerContact || ''
        });
      } catch (error) {
        reject(error);
      }
    });
  },

  /**
   * Récupère les événements dans plusieurs zones adjacentes
   */
  async getEventsInAdjacentZones(
    center: { latitude: number; longitude: number },
    radius: number = ZONE_SIZE
  ): Promise<MinimalEvent[]> {
    // Calculer les zones adjacentes
    const zones = [
      { latitude: center.latitude, longitude: center.longitude },
      { latitude: center.latitude + ZONE_SIZE/111.32, longitude: center.longitude },
      { latitude: center.latitude - ZONE_SIZE/111.32, longitude: center.longitude },
      { latitude: center.latitude, longitude: center.longitude + ZONE_SIZE/(111.32 * Math.cos(center.latitude * Math.PI / 180)) },
      { latitude: center.latitude, longitude: center.longitude - ZONE_SIZE/(111.32 * Math.cos(center.latitude * Math.PI / 180)) }
    ];

    // Récupérer les événements de toutes les zones
    const eventsPromises = zones.map(zone => this.getEventsInZone(zone, radius));
    const zoneEvents = await Promise.all(eventsPromises);

    // Dédupliquer les événements
    const uniqueEvents = new Map<string, MinimalEvent>();
    zoneEvents.flat().forEach(event => {
      if (!uniqueEvents.has(event.id)) {
        uniqueEvents.set(event.id, event);
      }
    });

    return Array.from(uniqueEvents.values());
  }
}; 