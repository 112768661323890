import React, { useCallback, useState, useEffect } from 'react';
import { Input } from './Input';
import { MonkeyProps } from '../types/monkey.types';
import '../../../styles/components/search-bar.css';

export interface SearchBarProps extends MonkeyProps {
  query: string;
  onQueryChange: (query: string) => void;
  placeholder?: string;
  className?: string;
  debounceMs?: number;
}

const SearchBar: React.FC<SearchBarProps> = React.memo(({
  query,
  onQueryChange,
  placeholder = "Search ...",
  className = '',
  debounceMs = 300,
  // Monkey props with default values
  showMonkey = false,
  monkeyVariant = 'singeOrange',
  monkeyType = 'walking',
  monkeyLookAt = 'right',
  monkeyPosition = 'position1'
}) => {
  // Local state for immediate response
  const [localQuery, setLocalQuery] = useState(query);

  // Memoized change handler
  const handleChange = useCallback((value: string) => {
    setLocalQuery(value);
  }, []);

  // Effect for debouncing
  useEffect(() => {
    const timer = setTimeout(() => {
      if (localQuery !== query) {
        onQueryChange(localQuery);
      }
    }, debounceMs);

    return () => clearTimeout(timer);
  }, [localQuery, debounceMs, onQueryChange, query]);

  // Update local state when query prop changes
  useEffect(() => {
    setLocalQuery(query);
  }, [query]);

  return (
    <div className={`search-bar-container ${className}`}>
      <Input
        id="search-input"
        name="search"
        type="text"
        className="search-bar-input"
        value={localQuery}
        onChange={handleChange}
        placeholder={placeholder}
        icon="Search"
        showMonkey={showMonkey}
        monkeyVariant={monkeyVariant}
        monkeyType={monkeyType}
        monkeyLookAt={monkeyLookAt}
        monkeyPosition={monkeyPosition}
      />
    </div>
  );
});

// Add displayName to help with debugging
SearchBar.displayName = 'SearchBar';

export default SearchBar; 