import React, { useState, useRef, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DateTimeFilters.css';
import { DateFilter, TimeFilter, DATE_FILTERS, TIME_FILTERS } from '../../../../types/event.types';
import { formatDate } from '../../../../utils/dateUtils';
import { Icon } from '../../primitives/Icon';
import { Tag } from '../../primitives/Tags';

interface DateTimeFiltersProps {
  onDateFilterChange: (values: string[]) => void;
  onTimeFilterChange: (values: string[]) => void;
  selectedDates: string[];
  selectedTimes: string[];
}

const DateTimeFilters: React.FC<DateTimeFiltersProps> = ({ 
  onDateFilterChange, 
  onTimeFilterChange,
  selectedDates = [],
  selectedTimes = []
}) => {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [customDate, setCustomDate] = useState<Date | null>(null);
  const [isDateGroupAtEnd, setIsDateGroupAtEnd] = useState(false);
  const [isTimeGroupAtEnd, setIsTimeGroupAtEnd] = useState(false);
  const [isDateGroupAtStart, setIsDateGroupAtStart] = useState(true);
  const [isTimeGroupAtStart, setIsTimeGroupAtStart] = useState(true);
  
  const dateGroupRef = useRef<HTMLDivElement>(null);
  const timeGroupRef = useRef<HTMLDivElement>(null);

  const checkIfAtEnd = (element: HTMLElement) => {
    const isAtEnd = Math.abs(
      element.scrollWidth - element.clientWidth - element.scrollLeft
    ) < 1;
    return isAtEnd;
  };

  const checkIfAtStart = (element: HTMLElement) => {
    return element.scrollLeft <= 1;
  };

  const handleScroll = (
    element: HTMLElement, 
    setIsAtEnd: (isAtEnd: boolean) => void,
    setIsAtStart: (isAtStart: boolean) => void
  ) => {
    setIsAtEnd(checkIfAtEnd(element));
    setIsAtStart(checkIfAtStart(element));
  };

  useEffect(() => {
    const dateGroup = dateGroupRef.current;
    const timeGroup = timeGroupRef.current;

    if (dateGroup) {
      handleScroll(dateGroup, setIsDateGroupAtEnd, setIsDateGroupAtStart);
      dateGroup.addEventListener('scroll', () => 
        handleScroll(dateGroup, setIsDateGroupAtEnd, setIsDateGroupAtStart)
      );
    }

    if (timeGroup) {
      handleScroll(timeGroup, setIsTimeGroupAtEnd, setIsTimeGroupAtStart);
      timeGroup.addEventListener('scroll', () => 
        handleScroll(timeGroup, setIsTimeGroupAtEnd, setIsTimeGroupAtStart)
      );
    }

    return () => {
      if (dateGroup) {
        dateGroup.removeEventListener('scroll', () => 
          handleScroll(dateGroup, setIsDateGroupAtEnd, setIsDateGroupAtStart)
        );
      }
      if (timeGroup) {
        timeGroup.removeEventListener('scroll', () => 
          handleScroll(timeGroup, setIsTimeGroupAtEnd, setIsTimeGroupAtStart)
        );
      }
    };
  }, []);

  const handleDateChange = (value: string) => {
    console.log('🗓️ handleDateChange - value:', value);
    console.log('🗓️ Current selectedDates:', selectedDates);

    if (value === 'custom') {
      setIsDatePickerOpen(true);
      return;
    }

    let newValues: string[];
    if (selectedDates.includes(value)) {
      // Si déjà sélectionné, on le retire
      newValues = selectedDates.filter(date => date !== value);
    } else {
      // Sinon on l'ajoute
      newValues = [...selectedDates, value];
    }
    
    console.log('🗓️ New selectedDates:', newValues);
    onDateFilterChange(newValues);
    if (newValues.length === 0) {
      setCustomDate(null);
    }
  };

  const handleTimeChange = (value: string) => {
    let newValues: string[];
    
    if (selectedTimes.includes(value)) {
      // Si le filtre est déjà sélectionné, on le retire
      newValues = selectedTimes.filter(time => time !== value);
    } else {
      // Si on essaie d'ajouter le dernier filtre non sélectionné
      if (selectedTimes.length === TIME_FILTERS.length - 1) {
        // On désélectionne tout
        newValues = [];
      } else {
        // Sinon on l'ajoute normalement
        newValues = [...selectedTimes, value];
      }
    }
    
    onTimeFilterChange(newValues);
  };

  const handleCustomDateChange = (date: Date | null) => {
    console.log('📅 handleCustomDateChange - date:', date);
    if (date) {
      const customValue = `custom_${formatDate(date)}`;
      console.log('📅 Formatted custom date:', customValue);
      
      // Si la date est déjà sélectionnée, on la retire
      if (selectedDates.includes(customValue)) {
        console.log('📅 Date already selected, removing it');
        setCustomDate(null);
        const newDates = selectedDates.filter(d => d !== customValue);
        onDateFilterChange(newDates);
      } else {
        // Sinon on l'ajoute
        setCustomDate(date);
        const newDates = [...selectedDates.filter(d => !d.startsWith('custom_')), customValue];
        console.log('📅 Final dates array:', newDates);
        onDateFilterChange(newDates);
      }
      setIsDatePickerOpen(false);
    }
  };

  const handleCustomDateClick = () => {
    // Si une date personnalisée est déjà sélectionnée, on la retire
    if (selectedDates.some(d => d.startsWith('custom_'))) {
      setCustomDate(null);
      const newDates = selectedDates.filter(d => !d.startsWith('custom_'));
      onDateFilterChange(newDates);
      return;
    }

    if ('ontouchstart' in window) {
      const input = document.createElement('input');
      input.type = 'date';
      input.min = new Date().toISOString().split('T')[0];
      input.value = customDate ? formatDate(customDate) : '';
      
      input.style.position = 'absolute';
      input.style.top = '0';
      input.style.left = '0';
      input.style.opacity = '0.01';
      input.style.pointerEvents = 'none';
      
      const handleChange = (e: Event) => {
        const selectedDate = (e.target as HTMLInputElement).value;
        if (selectedDate) {
          handleCustomDateChange(new Date(selectedDate));
        }
        cleanup();
      };
  
      const handleCancel = () => {
        cleanup();
      };
  
      const cleanup = () => {
        input.removeEventListener('change', handleChange);
        input.removeEventListener('blur', handleCancel);
        document.body.removeChild(input);
      };
  
      input.addEventListener('change', handleChange);
      input.addEventListener('blur', handleCancel);
      
      document.body.appendChild(input);
      input.focus();
      input.click();
    } else {
      setIsDatePickerOpen(true);
    }
  };

  return (
    <div className="datetime-filter">
      {/* Section Date */}
      <div className="datetime-filter__section">
        <div className="datetime-filter__group-wrapper">
          <div 
            ref={dateGroupRef}
            className={`datetime-filter__group ${isDateGroupAtEnd ? 'at-end' : ''} ${!isDateGroupAtStart ? 'scrolled' : ''}`}
          >
            {DATE_FILTERS.map((filter) => (
              filter.value === 'custom' ? (
                <button 
                  key={filter.id}
                  className={`datetime-filter__button ${selectedDates.some(d => d.startsWith('custom_')) ? 'selected' : ''}`}
                  onClick={handleCustomDateClick}
                  data-custom="true"
                >
                  <Tag 
                    size="lg"
                    label={
                      <>
                        <Icon name="Calendar" size={16} className="datetime-filter__time-icon" />
                        {selectedDates.some(d => d.startsWith('custom_')) 
                          ? selectedDates.find(d => d.startsWith('custom_'))?.split('_')[1] 
                          : 'Select a date'}
                      </>
                    }
                  />
                </button>
              ) : (
                <button 
                  key={filter.id}
                  className={`datetime-filter__button ${selectedDates.includes(filter.value) ? 'selected' : ''}`}
                  onClick={() => handleDateChange(filter.value)}
                >
                  <Tag 
                    label={filter.label}
                    size="lg"
                  />
                </button>
              )
            ))}
          </div>
        </div>
      </div>

      {/* Section Heure */}
      <div className="datetime-filter__section">
        <div className="datetime-filter__group-wrapper">
          <div 
            ref={timeGroupRef}
            className={`datetime-filter__group ${isTimeGroupAtEnd ? 'at-end' : ''} ${!isTimeGroupAtStart ? 'scrolled' : ''}`}
          >
            {TIME_FILTERS.map((filter) => (
              <button 
                key={filter.id}
                className={`datetime-filter__button ${selectedTimes.includes(filter.value) ? 'selected' : ''}`}
                onClick={() => handleTimeChange(filter.value)}
              >
                <Tag 
                  size="lg"
                  label={
                    <>
                      <Icon 
                        name={filter.value === 'morning' ? 'Sunrise' : 
                              filter.value === 'afternoon' ? 'Sun' : 
                              'Sunset'} 
                        size={16}
                        className="datetime-filter__time-icon"
                      />
                      {filter.label}
                    </>
                  }
                />
              </button>
            ))}
          </div>
        </div>
      </div>

      {/* DatePicker Modal */}
      {isDatePickerOpen && window.innerWidth > 768 && (
        <>
          <div 
            className="datetime-filter__picker-overlay"
            onClick={() => setIsDatePickerOpen(false)}
          />
          <div className="datetime-filter__picker-container">
            <button 
              className="datetime-filter__picker-close"
              onClick={() => setIsDatePickerOpen(false)}
            >
              ×
            </button>
            <DatePicker
              selected={customDate}
              onChange={handleCustomDateChange}
              inline
              minDate={new Date()}
              dateFormat="yyyy-MM-dd"
              calendarClassName="custom-calendar"
              monthsShown={1}
              showPopperArrow={false}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default DateTimeFilters;