import React from 'react';
import '../../../styles/components/datetime-input.css';

type InputType = 'date' | 'time';

export interface DateTimeInputProps {
  // Props essentielles
  id: string;
  name: string;
  value: string;
  onChange: (value: string) => void;
  type: InputType;
  
  // Props optionnelles
  label?: string;
  helper?: string;
  error?: string;
  min?: string;
  max?: string;
  required?: boolean;
  disabled?: boolean;
  className?: string;
}

export const DateTimeInput: React.FC<DateTimeInputProps> = ({
  id,
  name,
  value,
  onChange,
  type,
  label,
  helper,
  error,
  min,
  max,
  required = false,
  disabled = false,
  className = '',
}) => {
  const containerClass = type === 'date' ? 'date-input-container' : 'time-input-container';
  const fieldClass = type === 'date' ? 'date-input-field' : 'time-input-field';
  const wrapperClass = type === 'date' ? 'date-input-wrapper' : 'time-input-wrapper';
  const labelClass = type === 'date' ? 'date-input-label' : 'time-input-label';
  const helperClass = type === 'date' ? 'date-input-helper' : 'time-input-helper';
  const errorClass = type === 'date' ? 'date-input-error' : 'time-input-error';

  const inputClasses = [
    fieldClass,
    error ? 'has-error' : '',
    disabled ? 'is-disabled' : '',
    className
  ].filter(Boolean).join(' ');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <div className={containerClass}>
      {label && (
        <label htmlFor={id} className={labelClass}>
          {label}
          {required && <span className="required-mark">*</span>}
        </label>
      )}
      
      <div className={wrapperClass}>
        <input
          id={id}
          name={name}
          type={type}
          value={value}
          onChange={handleChange}
          min={min}
          max={max}
          disabled={disabled}
          required={required}
          className={inputClasses}
          aria-invalid={!!error}
          aria-describedby={helper ? `${id}-helper` : undefined}
        />
      </div>

      {helper && !error && (
        <p id={`${id}-helper`} className={helperClass}>
          {helper}
        </p>
      )}
      
      {error && (
        <p id={`${id}-error`} className={errorClass} role="alert">
          {error}
        </p>
      )}
    </div>
  );
};

// Composants pratiques pour l'utilisation
export const DateInput: React.FC<Omit<DateTimeInputProps, 'type'>> = (props) => (
  <DateTimeInput {...props} type="date" />
);

export const TimeInput: React.FC<Omit<DateTimeInputProps, 'type'>> = (props) => (
  <DateTimeInput {...props} type="time" />
);

export default DateTimeInput; 