import React, { useState } from 'react';
import { Icon, IconName } from './Icon';
import '../../../styles/components/input.css';
import { MonkeyCursor } from './MonkeyCursor';
import { MonkeyProps } from '../types/monkey.types';

export interface InputProps extends MonkeyProps {
  // Props essentielles
  id: string;
  name: string;
  value: string;
  onChange: (value: string) => void;
  
  // Props optionnelles
  label?: string;
  placeholder?: string;
  helper?: string;
  error?: string;
  icon?: IconName;
  type?: 'text' | 'email' | 'password' | 'search' | 'tel' | 'url';
  required?: boolean;
  disabled?: boolean;
  className?: string;
  maxLength?: number;
  size?: 'sm' | 'md' | 'lg';
}

export const Input: React.FC<InputProps> = ({
  // Props essentielles
  id,
  name,
  value,
  onChange,
  // Props optionnelles
  label,
  placeholder,
  helper,
  error,
  icon,
  type = 'text',
  required = false,
  disabled = false,
  className = '',
  maxLength,
  size = 'md',
  // Props Monkey avec valeurs par défaut
  showMonkey = false,
  monkeyVariant = 'singeOrange',
  monkeyType = 'walking',
  monkeyLookAt = 'right'
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [hasValue, setHasValue] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
    setHasValue(e.target.value.length > 0);
  };

  const handleReset = () => {
    onChange('');
    setHasValue(false);
  };

  const inputClasses = [
    'input-field',
    error ? 'has-error' : '',
    disabled ? 'is-disabled' : '',
    icon ? 'has-icon' : '',
    className
  ].filter(Boolean).join(' ');

  return (
    <div className="input-container">
      {label && (
        <label htmlFor={id} className="input-label">
          {label}
          {required && <span className="required-mark">*</span>}
        </label>
      )}

      <div className="input-wrapper">
        <MonkeyCursor
          showMonkey={showMonkey}
          monkeyVariant={monkeyVariant}
          monkeyType={monkeyType}
          monkeyLookAt={monkeyLookAt}
          offset={{ x: 190, y: monkeyType === 'hanging' ? -76 : -2 }}
          size={size}
        />
        
        {icon && (
          <Icon 
            name={icon} 
            size={16} 
            className="input-icon"
            aria-hidden="true"
          />
        )}
       
        <input
          id={id}
          name={name}
          type={type}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          disabled={disabled}
          required={required}
          maxLength={maxLength}
          className={inputClasses}
          aria-invalid={!!error}
          aria-describedby={helper ? `${id}-helper` : undefined}
        />
        
        {hasValue && (
          <button
            type="button"
            className="input-reset-button"
            onClick={handleReset}
            aria-label="Clear text"
          >
            <Icon name="X" size={16} className="input-reset-icon" />
          </button>
        )}
      </div>

      {helper && !error && (
        <p id={`${id}-helper`} className="input-helper">
          {helper}
        </p>
      )}
      
      {error && (
        <p id={`${id}-error`} className="input-error" role="alert">
          {error}
        </p>
      )}
    </div>
  );
};

export default Input; 