import React from 'react';
import { Icon } from './Icon';
import '../../../styles/components/select.css';

export interface SelectOption {
  value: string;
  label: string;
  disabled?: boolean;
  icon?: string;
}

export interface SelectProps {
  // Props essentielles
  id: string;
  name: string;
  value: string;
  options: SelectOption[];
  onChange: (value: string) => void;
  
  // Props optionnelles
  label?: string;
  placeholder?: string;
  helper?: string;
  error?: string;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  size?: 'sm' | 'md' | 'lg';
}

export const Select: React.FC<SelectProps> = ({
  id,
  name,
  value,
  options,
  onChange,
  label,
  placeholder,
  helper,
  error,
  required = false,
  disabled = false,
  className = '',
  size = 'md',
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const selectRef = React.useRef<HTMLDivElement>(null);

  const selectClasses = [
    'select-field',
    `select-${size}`,
    error ? 'has-error' : '',
    disabled ? 'is-disabled' : '',
    className
  ].filter(Boolean).join(' ');

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(e.target.value);
  };

  return (
    <div className="select-container">
      {label && (
        <label htmlFor={id} className="select-label">
          {label}
          {required && <span className="required-mark">*</span>}
        </label>
      )}
      
      <div className="select-wrapper">
        <select
          id={id}
          name={name}
          value={value}
          onChange={handleChange}
          disabled={disabled}
          required={required}
          className={selectClasses}
          aria-invalid={!!error}
          aria-describedby={helper ? `${id}-helper` : undefined}
        >
          {placeholder && (
            <option value="" disabled>
              {placeholder}
            </option>
          )}
          
          {options.map((option) => (
            <option 
              key={option.value} 
              value={option.value}
              disabled={option.disabled}
            >
              {option.label}
            </option>
          ))}
        </select>
        
        <Icon 
          name="ChevronDown" 
          size={16} 
          className="select-icon"
          aria-hidden="true"
        />
      </div>

      {helper && !error && (
        <p id={`${id}-helper`} className="select-helper">
          {helper}
        </p>
      )}
      
      {error && (
        <p id={`${id}-error`} className="select-error" role="alert">
          {error}
        </p>
      )}
    </div>
  );
};

export default Select; 