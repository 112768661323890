import React, { useState } from 'react';
import { Event } from '../../../types/event.types';
import { useEventSeries } from '../../../hooks/useEventSeries';
import { formatDateTime } from '../../../utils/dateFormatter';
import { Icon, Button } from '../../../pages/DesignSystem';
import './EventList.css';
import { LogoSVG } from '../../../pages/DesignSystem';

interface RecurringEventDetailsProps {
  mainEvent: Event;
  seriesEvents: Event[];
  onEditSeries: (event: Event) => void;
  onEditOccurrence: (event: Event) => void;
  onStatusChange?: (event: Event, newStatus: 'draft' | 'active') => void;
  isExpanded: boolean;
  onToggleExpand: (expanded: boolean) => void;
}

const RecurringEventDetails: React.FC<RecurringEventDetailsProps> = ({
  mainEvent,
  seriesEvents,
  onEditSeries,
  onEditOccurrence,
  onStatusChange,
  isExpanded,
  onToggleExpand
}) => {
  const { formatRecurrencePattern } = useEventSeries(mainEvent.seriesId);

  const sortedEvents = [...seriesEvents].sort((a, b) => {
    const dateA = a.datetime ? a.datetime.getTime() : 0;
    const dateB = b.datetime ? b.datetime.getTime() : 0;
    return dateA - dateB;
  });

  return (
    <>
      {/* Header Sticky */}
      <div className="organizer-card__header organizer-card__header--sticky">
        {mainEvent.image && (
          <div className="organizer-card__image">
            <img 
              src={mainEvent.image} 
              alt={mainEvent.title} 
              className="organizer-card__image-content"
            />
          </div>
        )}

        <div className="organizer-card__content">
          <h3 className="organizer-card__title">
            {mainEvent.title}
          </h3>
          <p className="organizer-card__recurrence-pattern">{formatRecurrencePattern()}</p>
        </div>

        <div className="organizer-card__actions" onClick={(e) => e.stopPropagation()}>
          <Button 
            variant="primary"
            size="sm"
            onClick={(e) => {
              e.stopPropagation();
              onEditSeries(mainEvent);
            }}
          >
            <Icon name="Edit" size={16} />
            Modify series
          </Button>
          <Button
            variant="ghost"
            size="sm"
            onClick={(e) => {
              e.stopPropagation();
              onToggleExpand(!isExpanded);
            }}
            className="organizer-card__expand-button"
          >
            <Icon name={isExpanded ? "ChevronUp" : "ChevronDown"} size={20} />
          </Button>
        </div>
      </div>

      {/* Contenu défilable */}
      <div className={`organizer-occurrences__content ${isExpanded ? 'organizer-occurrences__content--expanded' : ''}`}>
        {isExpanded && (
          <div className="organizer-occurrences">
            <h4 className="organizer-occurrences__title">All dates</h4>
            {sortedEvents.map(event => (
              <div 
                key={event.id} 
                className="organizer-occurrence"
              >
                <div className="organizer-occurrence__info">
                  <div className="organizer-occurrence__date">
                    <span className="organizer-occurrence__date-value">
                      {formatDateTime(event.datetime).date}
                    </span>
                    <span className="organizer-occurrence__time">
                      {formatDateTime(event.datetime).time}
                    </span>
                  </div>
                  {event.isException && (
                    <span className="organizer-occurrence__modified">
                      <Icon name="AlertCircle" size={14} />
                      Modified
                    </span>
                  )}
                </div>
                <div className="organizer-occurrence__actions">
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={(e) => {
                      e.stopPropagation();
                      onEditOccurrence(event);
                    }}
                  >
                    <Icon name="Edit" size={14} />
                    Modify
                  </Button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default RecurringEventDetails;
