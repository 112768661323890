import React, { useEffect, useState } from 'react';
import '../../../styles/components/StatusMessage.css';

interface Message {
  id: number;
  text: string;
  type: 'success' | 'error' | 'info';
  removing?: boolean;
}

interface StatusMessageProps {
  messages: Message[];
  onDismiss: (id: number) => void;
}

const StatusMessage: React.FC<StatusMessageProps> = ({ messages: incomingMessages, onDismiss }) => {
  const [messages, setMessages] = useState<Message[]>([]);

  useEffect(() => {
    setMessages(incomingMessages);
  }, [incomingMessages]);

  useEffect(() => {
    messages.forEach(msg => {
      if (!msg.removing) {
        const timer = setTimeout(() => {
          // Marquer le message pour l'animation de sortie
          setMessages(prev => 
            prev.map(m => 
              m.id === msg.id ? { ...m, removing: true } : m
            )
          );
          
          // Supprimer le message après l'animation
          setTimeout(() => {
            onDismiss(msg.id);
          }, 300); // Durée de l'animation de sortie
        }, 3000);

        return () => clearTimeout(timer);
      }
    });
  }, [messages, onDismiss]);

  if (messages.length === 0) return null;

  return (
    <div className="status-messages-container">
      {messages.map(msg => (
        <div 
          key={msg.id} 
          className={`status-message ${msg.type} ${msg.removing ? 'removing' : ''}`}
        >
          {msg.text}
        </div>
      ))}
    </div>
  );
};

export default StatusMessage; 