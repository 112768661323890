// Messages pour les événements sauvegardés en brouillon
export const draftSuccessMessages = [
  {
    title: "Draft saved! 📝",
    message: "Your event is safely stored - come back anytime to finish and publish!"
  },
  {
    title: "Saved for later! ⏱️",
    message: "Your draft is waiting patiently for when inspiration strikes again!"
  },
  {
    title: "Good start! 🌱",
    message: "Your event draft is saved. Take your time to make it perfect!"
  },
  {
    title: "Progress preserved! 💾",
    message: "Your draft is safely tucked away until you're ready to continue."
  },
  {
    title: "Draft stashed away! 📋",
    message: "We'll keep this safe until you're ready to share it with the world!"
  },
  {
    title: "Ideas captured! 💡",
    message: "Your event draft is saved - refine it whenever you're ready!"
  }
];

// Messages pour les événements publiés
export const publishSuccessMessages = [
  {
    title: "Woohoo! Your event is live! 🎉",
    message: "People can now discover and get excited about your event!"
  },
  {
    title: "Success! Event launched! 🚀",
    message: "Your event is now out there for the world to see and enjoy!"
  },
  {
    title: "It's happening! 🌟",
    message: "Your event is published and ready to attract an amazing crowd!"
  },
  {
    title: "Event published! 🎭",
    message: "Time to spread the word and get people excited about your event!"
  },
  {
    title: "You're on the map! 🗺️",
    message: "Your event is now live for everyone to discover and attend!"
  },
  {
    title: "Champagne moment! 🥂",
    message: "Your event is published! Now watch as people start to discover it!"
  }
];

// Fonction pour obtenir un message aléatoire
export const getRandomToastMessage = (messages: Array<{title: string, message: string}>) => {
  const randomIndex = Math.floor(Math.random() * messages.length);
  return messages[randomIndex];
}; 