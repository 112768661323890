import React, { useEffect, useState } from 'react';
import '../../../styles/components/notification-bubble.css';

interface NotificationBubbleProps {
  message: string;
  show: boolean;
  type?: 'success' | 'info' | 'error';
  duration?: number;
  onHide?: () => void;
}

const NotificationBubble: React.FC<NotificationBubbleProps> = ({
  message,
  show,
  type = 'success',
  duration = 3000,
  onHide
}) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (show) {
      setIsVisible(true);
      // Annoncer pour l'accessibilité
      const announcement = document.createElement('div');
      announcement.setAttribute('role', 'status');
      announcement.setAttribute('aria-live', 'polite');
      announcement.className = 'sr-only';
      announcement.textContent = message;
      document.body.appendChild(announcement);

      const timer = setTimeout(() => {
        setIsVisible(false);
        onHide?.();
        document.body.removeChild(announcement);
      }, duration);

      return () => {
        clearTimeout(timer);
        if (announcement.parentNode) {
          document.body.removeChild(announcement);
        }
      };
    }
  }, [show, message, duration, onHide]);

  if (!show && !isVisible) return null;

  return (
    <div className={`notification-bubble ${type} ${isVisible ? 'show' : ''}`}>
      {message}
    </div>
  );
};

export default NotificationBubble; 