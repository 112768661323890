// src/pages/DesignSystem/sections/FilterDrawerSection.tsx
import React, { useState } from 'react';
import { FilterDrawer, FilterChip, Filters } from '../patterns/FilterDrawer';
import { Icon, IconName } from '../primitives/Icon';
import { BottomNav } from '../patterns/BottomNav';
import MenuDrawer from '../patterns/MenuDrawer/MenuDrawer';
import './FilterDrawerSection.css';

interface CodeSection {
  id: string;
  isCollapsed: boolean;
}

const FilterDrawerSection: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeFilters, setActiveFilters] = useState<Filters>({
    where: {
      city: "",
      location: undefined,
      distance: 5,
    },
    when: {
      dates: [],
      times: [],
    },
    what: {
      query: "",
      tags: [],
    },
  });
  const [viewMode, setViewMode] = useState<'list' | 'map'>('list');
  const [favoriteCount] = useState(5); // Pour la démo
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [collapsedSections, setCollapsedSections] = React.useState<CodeSection[]>([
    { id: 'filterDrawer', isCollapsed: true },
  ]);

  const toggleSection = (sectionId: string) => {
    setCollapsedSections(prev => prev.map(section => 
      section.id === sectionId 
        ? { ...section, isCollapsed: !section.isCollapsed }
        : section
    ));
  };

  const isSectionCollapsed = (sectionId: string) => {
    return collapsedSections.find(section => section.id === sectionId)?.isCollapsed ?? true;
  };

  // Données de démonstration
  const demoFilters = {
    where: ['Paris', 'Lyon', 'Marseille'],
    when: ['Today', 'Tomorrow', 'This Week'],
    what: ['Concert', 'Sport', 'Art', 'Food']
  };

  // Configuration des items de la navigation
  const navItems = [
    {
      icon: viewMode === 'list' ? 'Map' : 'List' as IconName,
      label: viewMode === 'list' ? 'Map view' : 'List view',
      onClick: () => setViewMode(viewMode === 'list' ? 'map' : 'list'),
      viewMode: viewMode
    },
    {
      icon: 'Filter' as IconName,
      label: 'Filters',
      onClick: () => setIsOpen(!isOpen)
    },
    {
      icon: 'Heart' as IconName,
      label: 'Favorites',
      onClick: () => console.log('Favorites clicked'),
      badge: favoriteCount
    },
    {
      icon: 'Menu' as IconName,
      label: 'Menu',
      onClick: () => setIsMenuOpen(true)
    }
  ];

  // Prépare la liste des filtres actifs pour l'affichage
  const getActiveFilters = () => {
    const filters: string[] = [];

    // Where filters
    if (activeFilters.where.city) filters.push(activeFilters.where.city);
    if (activeFilters.where.location) filters.push('Ma position');
    
    // When filters
    if (activeFilters.when.dates.length > 0) {
      filters.push(...activeFilters.when.dates);
    }
    if (activeFilters.when.times.length > 0) {
      filters.push(...activeFilters.when.times);
    }
    
    // What filters
    if (activeFilters.what.query) filters.push(activeFilters.what.query);
    if (activeFilters.what.tags.length > 0) {
      filters.push(...activeFilters.what.tags);
    }

    return filters;
  };

  // Supprime un filtre
  const handleRemoveFilter = (filterToRemove: string) => {
    setActiveFilters(prev => ({
      where: {
        ...prev.where,
        city: prev.where.city === filterToRemove ? "" : prev.where.city,
        location: prev.where.location && filterToRemove === 'Ma position' ? undefined : prev.where.location,
      },
      when: {
        ...prev.when,
        dates: prev.when.dates.filter(date => date !== filterToRemove),
        times: prev.when.times.filter(time => time !== filterToRemove)
      },
      what: {
        ...prev.what,
        query: prev.what.query === filterToRemove ? "" : prev.what.query,
        tags: prev.what.tags.filter(tag => tag !== filterToRemove),
      },
    }));
  };

  return (
    <div className="section-content">
      <p className="section-description">
        Le Bottom Nav est un pattern de navigation principal qui combine plusieurs éléments interactifs :
        - Une barre de navigation fixe en bas de l'écran avec des boutons d'action
        - Un FilterDrawer pour filtrer le contenu de manière intuitive
        - Un MenuDrawer pour accéder aux fonctionnalités secondaires
        L'ensemble offre une expérience de navigation fluide et cohérente sur mobile.
      </p>

      <section className="subsection">
        <h4>Démo interactive</h4>
        <p>Utilisez les boutons de la barre de navigation pour interagir avec les différents drawers.</p>
        
        <div className="showcase-interactive">
          {/* Zone de démonstration */}
          <div className="demo-container">
            {/* Contenu principal (simulé) */}
            <div className="demo-content">
              <div className="demo-placeholder">
                <Icon name="Layout" size={32} />
                <p>Zone de contenu</p>
              </div>
            </div>

            {/* Chips des filtres actifs */}
            <div className="active-filters">
              {getActiveFilters().map(filter => (
                <FilterChip 
                  key={filter}
                  label={filter}
                  onRemove={() => handleRemoveFilter(filter)}
                />
              ))}
            </div>

            {/* BottomNav */}
            <BottomNav items={navItems} />

            {/* Le FilterDrawer */}
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <FilterDrawer 
              isOpen={isOpen}
              onClose={() => setIsOpen(false)}
              onReset={() => {
                // Le FilterDrawer gère maintenant la réinitialisation
                console.log('Filters reset in demo');
              }}
            />

            <MenuDrawer
              isOpen={isMenuOpen}
              onClose={() => setIsMenuOpen(false)}
              onOrganizerClick={() => console.log('Organizer clicked')}
              onContactClick={() => console.log('Contact clicked')}
              onIdeaClick={() => console.log('Idea clicked')}
              onAmbassadorClick={() => console.log('Ambassador clicked')}
            />
          </div>
        </div>
      </section>

      <section className="subsection">
        <h4>Anatomie du pattern</h4>
        <div className="anatomy-grid">
          <div className="anatomy-item">
            <h5>1. Drawer</h5>
            <p>Monte du bas de l'écran avec une animation fluide et un effet de glassmorphism</p>
            <code>transition: transform 0.3s var(--transition-timing-smooth)</code>
          </div>
          <div className="anatomy-item">
            <h5>2. Chips</h5>
            <p>Affichent les filtres actifs avec une animation d'entrée/sortie</p>
            <code>animation: slideIn 0.2s var(--transition-timing-bounce)</code>
          </div>
          <div className="anatomy-item">
            <h5>3. Sections</h5>
            <p>Organisation claire avec des icônes et des transitions fluides</p>
            <code>gap: var(--spacing-lg)</code>
          </div>
        </div>
      </section>

      <section className="subsection">
        <h4>Utilisation</h4>
        <div className="component-code">
          <div className={`code-example ${isSectionCollapsed('filterDrawer') ? 'collapsed' : ''}`}>
            <h3 onClick={() => toggleSection('filterDrawer')}>Code</h3>
            <pre>
              <code>{`
import { FilterDrawer, FilterChip } from '../patterns/FilterDrawer';

// État des filtres
const [isOpen, setIsOpen] = useState(false);
const [activeFilters, setActiveFilters] = useState<Filters>({
  where: {
    city: "",
    location: undefined,
    distance: 5,
  },
  when: {
    dates: [],
    times: [],
  },
  what: {
    query: "",
    tags: [],
  },
});

// Utilisation
<FilterDrawer 
  isOpen={isOpen}
  onClose={() => setIsOpen(false)}
  onReset={() => {
    // Réinitialiser tous les filtres sauf la localisation
    setActiveFilters(prev => ({
      where: { 
        city: "", 
        location: prev.where.location, // Préserver la localisation
        distance: 5 
      },
      when: { dates: [], times: [] },
      what: { query: "", tags: [] }
    }));
  }}
/>

// Affichage des chips
{getActiveFilters().map(filter => (
  <FilterChip 
    key={filter}
    label={filter}
    onRemove={() => handleRemoveFilter(filter)}
  />
))}
              `}</code>
            </pre>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FilterDrawerSection;