import { db } from './config';
import { collection, addDoc, Timestamp, updateDoc, doc, getDoc, DocumentData, query, where, getDocs, orderBy, limit } from 'firebase/firestore';
import { Event } from '../types/event.types';
import { geoEventQueries } from './geoEventQueries';

const COLLECTION_NAME = 'events';
const ORGANIZERS_COLLECTION = 'organizers';

// Interface minimale pour les données organisateur nécessaires à l'événement
interface OrganizerData {
  organizer: string;
  organizerImage: string;
  subtitle?: string;
}

/**
 * Convertit une date et une heure de fin en string ISO
 * Format: "YYYY-MM-DDTHH:mm"
 */
export const calculateEndTimeString = (startDate: Date, endTimeStr: string): string => {
  const [hours, minutes] = endTimeStr.split(':').map(Number);
  const endDate = new Date(startDate);
  endDate.setHours(hours, minutes);

  // Si l'heure de fin est avant l'heure de début, ajouter 1 jour
  if (endDate < startDate) {
    endDate.setDate(endDate.getDate() + 1);
  }

  // Formatage manuel pour éviter la conversion UTC
  const year = endDate.getFullYear();
  const month = String(endDate.getMonth() + 1).padStart(2, '0');
  const day = String(endDate.getDate()).padStart(2, '0');
  const hour = String(endDate.getHours()).padStart(2, '0');
  const minute = String(endDate.getMinutes()).padStart(2, '0');

  // Retourne "YYYY-MM-DDTHH:mm" en heure locale
  return `${year}-${month}-${day}T${hour}:${minute}`;
};

export const eventService = {
  /**
   * Crée un nouvel événement
   */
  async createEvent(eventData: any): Promise<string> {
    try {
      console.log("Création d'événement avec les données:", eventData);
      
      // Valider uniquement si l'événement n'est PAS un brouillon
      if (eventData.status !== 'draft') {
        if (!eventData.datetime || !eventData.endTime) {
          throw new Error('Start date/time and end time are required');
        }
      } else {
        console.log("Mode brouillon: ignorer les validations de date/heure");
      }
      
      // Si c'est un brouillon et qu'il n'y a pas de datetime, créer une date future par défaut
      if (eventData.status === 'draft' && !eventData.datetime) {
        // Date par défaut: 1 mois dans le futur
        const defaultDate = new Date();
        defaultDate.setMonth(defaultDate.getMonth() + 1);
        eventData.datetime = defaultDate;
        
        // Si pas d'heure de fin, mettre une heure par défaut aussi
        if (!eventData.endTime) {
          eventData.endTime = '00:00';
        }
        
        console.log("Valeurs par défaut ajoutées pour un brouillon:", 
                   { datetime: eventData.datetime, endTime: eventData.endTime });
      }
      
      const eventRef = await addDoc(collection(db, COLLECTION_NAME), eventData);
      console.log("Événement créé avec ID:", eventRef.id);
      return eventRef.id;
    } catch (error) {
      console.error('Erreur lors de la création de l\'événement:', error);
      throw error;
    }
  },

  /**
   * Met à jour un événement existant
   */
  async updateEvent(eventId: string, eventData: Partial<Omit<Event, 'id'>>): Promise<void> {
    try {
      const updateData: any = {
        ...eventData,
        updatedAt: Timestamp.now()
      };

      if (eventData.datetime && eventData.endTime) {
        updateData.datetime = Timestamp.fromDate(eventData.datetime);
        updateData.endTime = calculateEndTimeString(eventData.datetime, eventData.endTime);
      } else if (eventData.datetime) {
        updateData.datetime = Timestamp.fromDate(eventData.datetime);
      }

      const eventRef = doc(db, COLLECTION_NAME, eventId);
      await updateDoc(eventRef, updateData);
    } catch (error) {
      console.error('Erreur lors de la mise à jour de l\'événement:', error);
      throw error;
    }
  },

  /**
   * Récupère les données complètes d'un événement spécifique
   */
  async getEventById(eventId: string): Promise<Event | null> {
    try {
      const eventRef = doc(db, COLLECTION_NAME, eventId);
      const eventDoc = await getDoc(eventRef);
      
      if (!eventDoc.exists()) {
        return null;
      }

      const data = eventDoc.data();
      
      // Récupération des données minimales de l'organisateur si disponible
      let organizerData: OrganizerData = {
        organizer: '',
        organizerImage: '',
        subtitle: ''
      };
      
      if (data.organizerId) {
        const organizerRef = doc(db, ORGANIZERS_COLLECTION, data.organizerId);
        const organizerDoc = await getDoc(organizerRef);
        if (organizerDoc.exists()) {
          const orgData = organizerDoc.data();
          organizerData = {
            organizer: orgData.organizer || '',
            organizerImage: orgData.organizerImage || '',
            subtitle: orgData.subtitle || ''
          };
        }
      }

      // Retourne l'événement complet avec les données minimales de l'organisateur
      return {
        id: eventDoc.id,
        title: data.title || '',
        description: data.description || '',
        datetime: data.datetime instanceof Timestamp ? data.datetime.toDate() : undefined,
        endTime: data.endTime instanceof Timestamp 
          ? data.endTime.toDate().toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit', hour12: false })
          : data.endTime,
        location: data.location || '',
        locationName: data.locationName || '',
        latitude: data.latitude,
        longitude: data.longitude,
        image: data.image || '',
        tags: data.tags || [],
        status: data.status || 'active',
        prices: data.prices || [],
        ticketingUrl: data.ticketingUrl || '',
        views: data.views || 0,
        organizerId: data.organizerId || '',
        organizerContact: data.organizerContact || '',
        organizer: organizerData.organizer,
        organizerImage: organizerData.organizerImage,
        organizerSubtitle: organizerData.subtitle,
        createdAt: data.createdAt instanceof Timestamp ? data.createdAt.toDate() : undefined,
        updatedAt: data.updatedAt instanceof Timestamp ? data.updatedAt.toDate() : undefined,
      } as Event;
    } catch (error) {
      console.error('Erreur lors de la récupération de l\'événement:', error);
      throw error;
    }
  },

  /**
   * Délégation des requêtes géolocalisées à geoEventQueries
   */
  getNearbyEvents: geoEventQueries.getEventsInZone,
  getEventsInArea: geoEventQueries.getEventsInZone,
  getEventsInAdjacentZones: geoEventQueries.getEventsInAdjacentZones,

  /**
   * Récupère tous les événements d'un organisateur spécifique
   */
  async getEventsByOrganizerId(organizerId: string, activeOnly: boolean = true): Promise<Event[]> {
    try {
      const eventsRef = collection(db, COLLECTION_NAME);
      
      // Définir les filtres pour la requête
      let constraints = [
        where('organizerId', '==', organizerId)
      ];
      
      // Si on ne veut que les événements actifs/à venir
      if (activeOnly) {
        // Ajouter des filtres pour les événements dans le futur ou en cours
        constraints.push(where('status', '==', 'active'));
        constraints.push(where('datetime', '>=', Timestamp.fromDate(new Date())));
      }
      
      // Créer la requête avec tous les filtres
      const eventsQuery = query(
        eventsRef,
        ...constraints,
        orderBy('datetime', 'asc')  // Trier par date croissante
      );
      
      const querySnapshot = await getDocs(eventsQuery);
      
      const events: Event[] = [];
      querySnapshot.forEach(doc => {
        const data = doc.data();
        
        // Convertir les données Firestore en objet Event
        events.push({
          id: doc.id,
          title: data.title || '',
          description: data.description || '',
          datetime: data.datetime instanceof Timestamp ? data.datetime.toDate() : new Date(),
          endTime: data.endTime || '',
          location: data.location || '',
          locationName: data.locationName || '',
          image: data.image || '',
          tags: data.tags || [],
          prices: data.prices || [],
          status: data.status || 'active',
          organizerId: data.organizerId,
          seriesId: data.seriesId || null,
          // Ajouter d'autres champs si nécessaire
        } as Event);
      });
      
      return events;
    } catch (error) {
      console.error('Erreur lors de la récupération des événements de l\'organisateur:', error);
      throw error;
    }
  },
};