import { PriceItem } from '../../../types/event.types';

export interface FormData {
    id?: string;
    organizerId: string;
    organizerContact?: string;
    title: string;
    description?: string;
    date?: string;
    time?: string;
    endTime?: string;
    location?: string;
    locationName?: string;
    latitude?: number;
    longitude?: number;
    isRecurring: boolean;
    startDate?: string;
    startTime?: string;
    endDate?: string;
    endType: 'date' | 'occurrences';
    occurrences?: number;
    frequency: 'daily' | 'weekly';
    weekDays: string[];
    tags: string[];
    prices: PriceItem[];
    ticketingUrl?: string;
    isLocationNameManuallySet?: boolean;
    image: string;
    status: 'draft' | 'active';
    
}


export const initialFormData: FormData = {
    organizerId: '',
    organizerContact: '',
    title: '',
    description: '',
    date: '',
    time: '',
    endTime: '',
    location: '',
    locationName: '',
    isLocationNameManuallySet: false,
    latitude: 0,
    longitude: 0,
    tags: [],
    prices: [],
    ticketingUrl: '',
    status: 'draft',
    image: '',
    
    // Default values for recurring events
    isRecurring: false,
    frequency: 'weekly',
    startDate: '',
    startTime: '',
    endDate: '',
    endType: 'date',
    occurrences: 1,
    weekDays: [],
   
};

export const REQUIRED_FOR_DRAFT = ['title', 'organizerId'];

export const REQUIRED_FOR_PUBLISH = [
  ...REQUIRED_FOR_DRAFT,
  'description',
  'location',
  'locationName',
  // Pour les événements ponctuels
  'date', 
  'time',
  'endTime',
  // Pour les événements récurrents
  'startDate',
  'startTime',
  'endTime',
  'frequency',
  'weekDays' // Si frequency === 'weekly'
];
