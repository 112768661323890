import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Event } from '../../types/event.types';
import './card.css';
import { useEventSeries } from '../../hooks/useEventSeries';
import { openInMaps, sortEvents } from '../../utils/eventSorting';
import { 
  Tag, 
  Icon, 
  Button, 
  FavoriteButton,
  ShareButton,
  NotificationBubble,
  LogoSVG,
  DateIcon,
  ContentBlock
} from '../../pages/DesignSystem';

import { useFavoriteState } from '../../hooks/useFavoriteState';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEventContext } from '../../contexts/EventContext';
import { AnimatedList } from '../../pages/DesignSystem';
import { SwipeableCard } from '../../pages/DesignSystem/patterns/Modal/BaseModal';
import { formatDateTime, formatTimeString, formatDateForIcon } from '../../utils/dateFormatter';
import { isEventPast } from '../../utils/eventUtils';
import { useDistance } from '../../hooks/useDistance';

// Hook personnalisé pour détecter le mobile
const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(() => window.innerWidth <= 768);

  useEffect(() => {
    // Utilisation de requestAnimationFrame pour optimiser les performances
    let frameId: number;
    const handleResize = () => {
      cancelAnimationFrame(frameId);
      frameId = requestAnimationFrame(() => {
        setIsMobile(window.innerWidth <= 768);
      });
    };

    // Utilisation de matchMedia pour une meilleure performance
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    const handleChange = (e: MediaQueryListEvent) => setIsMobile(e.matches);
    
    mediaQuery.addEventListener('change', handleChange);
    window.addEventListener('resize', handleResize);
    
    return () => {
      mediaQuery.removeEventListener('change', handleChange);
      window.removeEventListener('resize', handleResize);
      cancelAnimationFrame(frameId);
    };
  }, []);

  return isMobile;
};

interface EventCardProps {
  event: Event;
  userLocation?: {
    latitude: number;
    longitude: number;
  };
  onDismiss?: (eventId: string) => void;
  onFavorite?: (eventId: string) => void;
  seriesEvents?: Event[];
  onClick?: (event: Event, seriesEvents?: Event[]) => void;
  disableSwipe?: boolean;
}

interface EventListProps {
  events: Event[];
}

const EventList = ({ events }: EventListProps) => {
  return (
    <AnimatedList>
      {events.map((event: Event) => (
        <EventCard key={event.id} event={event} />
      ))}
    </AnimatedList>
  );
};


const EventCard = React.memo(({ 
  event, 
  userLocation,
  onDismiss,
  onFavorite,
  seriesEvents,
  onClick,
  disableSwipe = false
}: EventCardProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state as { isMapView?: boolean; userLocation?: { latitude: number; longitude: number } };
  const { hiddenEvents } = useEventContext();

  const sortedSeriesEvents = useMemo(() => {
    if (!seriesEvents) return undefined;
    // Filtrer d'abord les événements passés
    const upcomingEvents = seriesEvents.filter(event => event.datetime && !isEventPast(event.datetime));
    // Puis utiliser la fonction de tri existante
    return sortEvents(upcomingEvents, 'list');
  }, [seriesEvents]);

  const isMobile = useIsMobile();
  const [imageLoaded, setImageLoaded] = useState(false);
  const [showNotif, setShowNotif] = useState(false);
  const { series } = useEventSeries(event.seriesId);
  const { toggleFavorite } = useFavoriteState(
    event.seriesId || event.id,
    event.datetime
  );

  const imageRef = useRef<HTMLImageElement>(null);

  // Vérifier si l'événement est caché
  const isHidden = useMemo(() => {
    return hiddenEvents.some(hiddenEvent => 
      event.seriesId 
        ? hiddenEvent.eventId === event.seriesId  // Pour les événements récurrents
        : hiddenEvent.eventId === event.id        // Pour les événements simples
    );
  }, [hiddenEvents, event.id, event.seriesId]);

  useEffect(() => {
    // Intersection Observer pour le lazy loading
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting && imageRef.current) {
            imageRef.current.src = event.image || '';
            observer.unobserve(entry.target);
          }
        });
      },
      { rootMargin: '50px' }
    );

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => observer.disconnect();
  }, [event.image]);

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(event, sortedSeriesEvents);
    } else {
      // Utiliser le pathname actuel pour déterminer le contexte
      const currentPath = location.pathname;
      const basePath = currentPath.startsWith('/favorites') ? '/favorites' : '';
      
      if (event.seriesId) {
        navigate(`${basePath}/series/${event.seriesId}`, {
          state: {
            isMapView: false,
            userLocation,
            seriesEvents: sortedSeriesEvents
          }
        });
      } else {
        navigate(`${basePath}/event/${event.id}`, {
          state: {
            isMapView: false,
            userLocation
          }
        });
      }
    }
  }, [onClick, sortedSeriesEvents, event, navigate, userLocation, location.pathname]);

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      handleClick();
    }
  };

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const handleOpenMaps = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (event.latitude && event.longitude) {
      openInMaps(event.latitude, event.longitude, event.location || 'Event');
    }
  };

  const distance = useDistance(userLocation, {
    latitude: event.latitude,
    longitude: event.longitude
  });

  const renderTags = () => (
    <div className="event-tags">
      {event.tags?.map(tag => (
        <Tag 
          key={tag} 
          label={tag} 
          type="activity"
          size= "md"
        />
      ))}
    </div>
  );

  const renderDirectionsButton = () => (
    <Button
      variant="outline"
      size="sm"
      onClick={handleOpenMaps}
    >
      Get directions
    </Button>
  );

  const handleTouchStart = useCallback(() => {
    // Préchargement des données de la modale
    if (event.seriesId) {
      // Précharger les données nécessaires
    }
  }, [event.seriesId]);



 

  return (
    <SwipeableCard
      onLike={() => {
        toggleFavorite(true);
        if (onFavorite) {
          onFavorite(event.id);
        }
      }}
      onDismiss={() => {
        if (onDismiss) {
          onDismiss(event.id);
        }
      }}
      eventId={event.id}
      seriesId={event.seriesId}
      disableSwipe={disableSwipe}
    >
      <div 
        className="event-card"
        onClick={handleClick}
        onKeyPress={handleKeyPress}
        tabIndex={0}
        role="button"
        aria-label={`View details for ${event.title}`}
      >
        {/* Header */}
        <div className="event-card__header">
          <div className="event-card__tags">
           
            {renderTags()}
          </div>
          <div className="event-card__actions" onClick={e => e.stopPropagation()}>
            <ShareButton
              shareData={{
                title: event.title,
                text: `what2do.today\nCome discover ${event.title},\non ${formatDateTime(event.datetime).date} at ${formatDateTime(event.datetime).time}\n`,
                url: window.location.href
              }}
              variant="icon"
              size="lg"
              shouldTriggerSuccess={false}
              onShareSuccess={() => setShowNotif(true)}
            />
            <FavoriteButton
              id={event.id}
              seriesId={event.seriesId}
              expiresAt={event.datetime}
              size="lg"
            />
          </div>
        </div>

        {/* Main Content */}
        <div className="event-card__main">
          <div className="event-card__image">
            {event.image ? (
              <img 
                ref={imageRef}
                alt={event.title}
                className={`event-image ${imageLoaded ? 'loaded' : ''}`}
                onLoad={handleImageLoad}
                loading="lazy"
              />
            ) : (
              <LogoSVG 
                variant="eventConnect"
                size="auto"
                padding={16}
                color="var(--primary-dark)"
                className="event-fallback-logo"
              />
            )}
          </div>

          <div className="event-card__info">
            <h3 className="event-card__title">{event.title}</h3>
            <ContentBlock>
              <div className="event-card__datetime">
                {event.datetime && (
                  <>
                    <DateIcon 
                      {...formatDateForIcon(event.datetime)}
                      variant="light"
                      size="sm"
                    />
                    <div className="event-card__time-info">
                      <span className="day">
                        {formatDateTime(event.datetime).weekday}
                      </span>
                      <span className="hours">
                        {formatDateTime(event.datetime).time}
                        {event.endTime && ` - ${formatTimeString(event.endTime)}`}
                      </span>
                    </div>
                  </>
                )}
              </div>
            </ContentBlock>
          </div>
        </div>

        {/* Footer */}
        <div className="event-card__footer">
           {/* Prix multiples */}
           {event.prices && event.prices.length > 0 && event.prices.some(price => price.amount > 0) && (
            <ContentBlock>

                  <span className="modal-item-content">
                  <span className="modal-item-icon">
                   <Icon name="Ticket" size={32} />
                  </span>
                    <span className="price-list">
                      {event.prices.map((price, index) => (
                        <span key={index} className="price-item">
                          {price.amount === 0 ? (
                            <span className="price-free">Free</span>
                          ) : (
                            <span className="price-amount">
                              {price.amount} {price.currency === 'EUR' ? '€' : price.currency}
                            </span>
                          )}
                          {price.description && (
                            <span className="price-description">
                              {' - '}{price.description}
                            </span>
                          )}
                        </span>
                      ))}
                    </span>
                  </span>
               
            </ContentBlock>
          )}
          <ContentBlock>
            <div className="event-card__location">
              <div className="modal-item-icon">
                <Icon name="MapPinned" size={32} />
              </div>
              <div className="event-card__location-content">
                <div className="event-card__location-details">
                <div className="event-card__location-top-line">
                  <span className="event-card__location-name">{event.locationName}</span>
                  {distance && (
                    <span className="event-card__location-distance">{distance}</span>
                  )}
                </div>
                {event.latitude && event.longitude && renderDirectionsButton()}
              </div>
              </div>
            </div>
          </ContentBlock>
        </div>
      </div>

      <NotificationBubble
        show={showNotif}
        message="Link copied! ✨"
        type="success"
        duration={3000}
        onHide={() => setShowNotif(false)}
      />
    </SwipeableCard>
  );
});

export default EventCard;
export { EventList };