import React from 'react';
import './styles/foundation-styles.css';
import './styles/foundation-radius-showcase.css';

const RadiusSection = () => {
  const radiusTokens = [
    { name: '--radius-sm', value: '0.25rem', px: '4px', usage: 'Petits éléments comme les badges' },
    { name: '--radius-md', value: '0.5rem', px: '8px', usage: 'Boutons et champs de formulaire' },
    { name: '--radius-lg', value: '0.75rem', px: '12px', usage: 'Cartes et panneaux' },
    { name: '--radius-xl', value: '1rem', px: '16px', usage: 'Grands conteneurs' },
    { name: '--radius-full', value: '9999px', px: '∞', usage: 'Éléments circulaires' },
  ];

  const examples = [
    { size: 'sm', content: 'Badge', description: 'Badge avec petit rayon' },
    { size: 'full', content: 'Tag', type: 'tag', description: 'Tag avec rayon complet pour un style pilule' },
    { size: 'md', content: 'Bouton', description: 'Bouton avec rayon moyen' },
    { size: 'lg', content: 'Carte', description: 'Carte avec grand rayon' },
    { size: 'xl', content: 'Modal', description: 'Modal avec très grand rayon' },
    { size: 'full', content: 'A', type: 'avatar', description: 'Avatar avec rayon complet' },
  ];

  return (
    <div className="foundation-section">
      <h2>Border Radius</h2>
      
      <section>
        <h3>Tokens de rayon</h3>
        <p>Notre système utilise des rayons de bordure cohérents pour créer une interface harmonieuse.</p>
        
        <div className="radius-grid">
          {radiusTokens.map(radius => (
            <div key={radius.name} className="radius-item" data-radius={radius.name.split('--radius-')[1]}>
              <div className="radius-preview" />
              <div className="radius-info">
                <code className="radius-name">{radius.name}</code>
                <span className="radius-value">{radius.value} ({radius.px})</span>
                <p className="radius-usage">{radius.usage}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section>
        <h3>Exemples d'utilisation</h3>
        <div className="radius-examples">
          {examples.map(example => (
            <div key={`${example.size}-${example.type || example.content}`} className="radius-example" data-example={example.size} data-type={example.type}>
              <div className="radius-example-header">
                <h4>{example.content}</h4>
                <code>--radius-{example.size}</code>
              </div>
              <div className="radius-example-preview">
                <div className="radius-example-shape">
                  {example.content}
                </div>
              </div>
              <p className="radius-example-description">{example.description}</p>
            </div>
          ))}
        </div>
      </section>

      <section>
        <h3>Usage Guidelines</h3>
        <ul className="guidelines-list">
          <li>Utilisez des rayons cohérents pour des éléments similaires</li>
          <li>Les plus petits rayons pour les petits éléments, les plus grands pour les grands éléments</li>
          <li>Le rayon complet (--radius-full) est parfait pour les avatars et badges circulaires</li>
          <li>Évitez de mélanger trop de rayons différents dans une même interface</li>
          <li>Gardez à l'esprit que les rayons plus grands créent une ambiance plus décontractée</li>
        </ul>
      </section>
    </div>
  );
};

export default RadiusSection; 