import React, { useEffect, useCallback, useState, useRef } from 'react';
import './MapDrawer.css';

interface MapDrawerProps {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  className?: string;
  hasOverlay?: boolean;
  title: string;
  subtitle?: string;
}

interface DrawerState {
  isDragging: boolean;
  startY: number;
  currentY: number;
  velocity: number;
  lastTime: number;
}

const MapDrawer: React.FC<MapDrawerProps> = ({
  children,
  isOpen,
  onClose,
  className = '',
  hasOverlay = true,
  title,
  subtitle
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [isFullyOpen, setIsFullyOpen] = useState(false);
  const drawerRef = useRef<HTMLDivElement>(null);
  
  const [drawerState, setDrawerState] = useState<DrawerState>({
    isDragging: false,
    startY: 0,
    currentY: 0,
    velocity: 0,
    lastTime: 0
  });

  // Handle opening/closing
  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
      setIsClosing(false);
      setIsReady(false);
      setIsFullyOpen(false);
      
      const mountingDelay = setTimeout(() => {
        requestAnimationFrame(() => {
          setIsReady(true);
          requestAnimationFrame(() => {
            setIsFullyOpen(true);
          });
        });
      }, 50);

      return () => clearTimeout(mountingDelay);
    } else {
      setIsClosing(true);
      setIsFullyOpen(false);
      const timer = setTimeout(() => {
        setIsVisible(false);
        setIsReady(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  // Gestionnaire de touchstart
  const handleTouchStart = useCallback((e: React.TouchEvent<HTMLElement>) => {
    const touch = e.touches[0];
    const target = e.target as HTMLElement;
    const listElement = target.closest('.preview-card-map__list');
    
    // Si on est dans la liste, vérifier si on est au top
    if (listElement) {
      // Si on n'est pas au top (avec une marge de 5px), ne pas démarrer le drag
      if (listElement.scrollTop > 5) return;
    }
    
    setDrawerState(prev => ({
      ...prev,
      isDragging: true,
      startY: touch.clientY,
      currentY: touch.clientY,
      lastTime: Date.now()
    }));

    if (drawerRef.current) {
      drawerRef.current.classList.add('map-drawer--dragging');
    }
  }, []);

  // Gestionnaire de touchmove
  const handleTouchMove = useCallback((e: React.TouchEvent<HTMLElement>) => {
    if (!drawerState.isDragging || !drawerRef.current) return;
    
    const touch = e.touches[0];
    const target = e.target as HTMLElement;
    const listElement = target.closest('.preview-card-map__list');
    
    // Si on est dans la liste et pas au top, arrêter le drag
    if (listElement && listElement.scrollTop > 5) {
      drawerRef.current.style.removeProperty('--drag-offset');
      drawerRef.current.classList.remove('map-drawer--dragging');
      setDrawerState(prev => ({
        ...prev,
        isDragging: false,
        velocity: 0
      }));
      return;
    }
    
    const currentTime = Date.now();
    const deltaTime = currentTime - drawerState.lastTime;
    const deltaY = touch.clientY - drawerState.currentY;
    
    const velocity = deltaY / deltaTime;
    const totalDeltaY = touch.clientY - drawerState.startY;
    
    drawerRef.current.style.setProperty('--drag-offset', `${Math.max(0, totalDeltaY)}px`);
    
    setDrawerState(prev => ({
      ...prev,
      currentY: touch.clientY,
      velocity,
      lastTime: currentTime
    }));
  }, [drawerState]);

  // Gestionnaire de touchend
  const handleTouchEnd = useCallback(() => {
    if (!drawerState.isDragging || !drawerRef.current) return;

    const totalDeltaY = drawerState.currentY - drawerState.startY;
    const shouldClose = totalDeltaY > 100 || drawerState.velocity > 0.5;

    drawerRef.current.style.removeProperty('--drag-offset');
    drawerRef.current.classList.remove('map-drawer--dragging');

    setDrawerState(prev => ({
      ...prev,
      isDragging: false,
      velocity: 0
    }));

    if (shouldClose) {
      onClose();
    }
  }, [drawerState, onClose]);

  // Gestion de l'échap
  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isOpen) {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEscapeKey);
    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [isOpen, onClose]);

  // Empêcher le scroll du body quand le drawer est ouvert
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen]);

  if (!isVisible) return null;

  return (
    <>
      {hasOverlay && (
        <div 
          className={`map-drawer-overlay ${isOpen ? 'map-drawer-overlay--visible' : ''}`}
          onClick={onClose}
          aria-hidden="true"
        />
      )}
      <div className={`map-drawer-container ${className}`}>
        <div 
          ref={drawerRef}
          className={`map-drawer ${isReady ? 'map-drawer--ready' : ''} ${isFullyOpen ? 'map-drawer--open' : ''} ${isClosing ? 'map-drawer--closing' : ''}`}
          role="dialog"
          aria-modal="true"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <header className="map-drawer__header">
            <h2>{title}</h2>
            {subtitle && <p>{subtitle}</p>}
          </header>
          {children}
        </div>
      </div>
    </>
  );
};

export default MapDrawer; 