// Formatage ISO pour les dates
export const formatDate = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

// Création de timestamps
export const createTimestamp = (): Date => {
  return new Date();
};

// Vérifications temporelles
export const isToday = (date: Date): boolean => {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

// Vérifie si une date est demain
export const isTomorrow = (date: Date): boolean => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  return (
    date.getDate() === tomorrow.getDate() &&
    date.getMonth() === tomorrow.getMonth() &&
    date.getFullYear() === tomorrow.getFullYear()
  );
};

// Vérifie si une date est ce weekend
export const isWeekend = (date: Date): boolean => {
  const today = new Date();

  // Trouve le prochain vendredi
  const friday = new Date(today);
  while (friday.getDay() !== 5) {
    friday.setDate(friday.getDate() + 1);
  }
  friday.setHours(0, 0, 0, 0);

  // Trouve le prochain dimanche
  const sunday = new Date(friday);
  sunday.setDate(friday.getDate() + 2);
  sunday.setHours(23, 59, 59, 999);

  // Compare les dates
  return date.getTime() >= friday.getTime() && date.getTime() <= sunday.getTime();
};

// Modifié pour accepter un nombre (heure) au lieu d'une chaîne
export const getTimeOfDay = (hour: number): string => {
  if (hour >= 5 && hour < 12) return 'morning';
  if (hour >= 12 && hour < 18) return 'afternoon';
  return 'evening';
};

// Comparaison de dates
export const compareDates = (date1: Date, date2: Date): number => {
  return date1.getTime() - date2.getTime();
};

// Vérifier si une date est dans le futur
export const isFutureDate = (date: Date): boolean => {
  return date.getTime() > Date.now();
};

// Vérifier si une date est dans le passé
export const isPastDate = (date: Date): boolean => {
  return date.getTime() < Date.now();
};

// Obtenir le début de la journée
export const getStartOfDay = (date: Date): Date => {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
};

// Obtenir la fin de la journée
export const getEndOfDay = (date: Date): Date => {
  const newDate = new Date(date);
  newDate.setHours(23, 59, 59, 999);
  return newDate;
};

export const formatTimeString = (timeString: any) => {
  if (!timeString) return '';
  
  // Si c'est un Timestamp Firestore
  if (timeString && typeof timeString === 'object' && 'toDate' in timeString) {
    const date = timeString.toDate();
    return date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    });
  }
  
  // Si c'est un format ISO (YYYY-MM-DDTHH:mm)
  if (typeof timeString === 'string' && timeString.includes('T')) {
    const [_, time] = timeString.split('T');
    return time.slice(0, 5); // Prend HH:mm
  }
  
  // Format legacy (HH:mm)
  if (typeof timeString === 'string') {
    const [hours, minutes] = timeString.split(':');
    return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
  }
  
  return '';
};