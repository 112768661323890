import React from 'react';
import { Icon, IconName } from '../primitives/Icon';
import './IconSection.css';

interface IconDisplayProps {
  name: IconName;
  description: string;
  usage: string;
}

const IconDisplay: React.FC<IconDisplayProps> = ({ name, description, usage }) => (
  <div className="icon-type-container">
    <div className="icon-group">
      <h4>{name}</h4>
      <p className="icon-description">{description}</p>
      <div className="icon-preview">
        <div className="icon-sizes">
          <div className="icon-size">
            <Icon name={name} size={16} />
            <span className="size-label">16px</span>
          </div>
          <div className="icon-size">
            <Icon name={name} size={24} />
            <span className="size-label">24px</span>
          </div>
          <div className="icon-size">
            <Icon name={name} size={32} />
            <span className="size-label">32px</span>
          </div>
        </div>
        <div className="icon-colors">
          <div className="icon-color">
            <Icon name={name} size={24} color="var(--text-primary)" />
            <span className="color-label">Default</span>
          </div>
          <div className="icon-color">
            <Icon name={name} size={24} color="var(--primary-color)" />
            <span className="color-label">Primary</span>
          </div>
          <div className="icon-color">
            <Icon name={name} size={24} color="var(--secondary-color)" />
            <span className="color-label">Secondary</span>
          </div>
        </div>
      </div>
      <div className="showcase-info">
        <p className="showcase-usage">Utilisation : {usage}</p>
        <pre className="showcase-code">
          <code>{`<Icon name="${name}" size={24} />`}</code>
        </pre>
      </div>
    </div>
  </div>
);

interface IconListItem {
  name: IconName;
  description: string;
  usage: string;
}

const IconSection: React.FC = () => {
  const iconsList: IconListItem[] = [
    // Navigation et Actions
    {
      name: 'ArrowLeft',
      description: 'Go back',
      usage: 'Navigation, back button'
    },
    {
      name: 'ArrowRight',
      description: 'Next',
      usage: 'Navigation, next button'
    },
    {
      name: 'X',
      description: 'Close',
      usage: 'Modal closing, cancellation'
    },
    {
      name: 'Pencil',
      description: 'Edit',
      usage: 'Content editing'
    },
    {
      name: 'Trash2',
      description: 'Delete',
      usage: 'Content deletion'
    },
    {
      name: 'Share2',
      description: 'Share',
      usage: 'Content sharing'
    },

    // Interface Navigation
    {
      name: 'Search',
      description: 'Search',
      usage: 'Search bar'
    },
    {
      name: 'Navigation',
      description: 'Navigation',
      usage: 'Direction, orientation'
    },
    {
      name: 'Map',
      description: 'Map',
      usage: 'Map display'
    },
    {
      name: 'MapPin',
      description: 'Marker',
      usage: 'Location on map'
    },

    // Event Management
    {
      name: 'Calendar',
      description: 'Event',
      usage: 'Event management'
    },
    {
      name: 'Clock',
      description: 'Time',
      usage: 'Schedule, duration'
    },
    {
      name: 'CalendarDays',
      description: 'Calendar',
      usage: 'Dates, planning'
    },
    {
      name: 'Ticket',
      description: 'Ticket',
      usage: 'Ticketing'
    },

    // Interactions and Statistics
    {
      name: 'Eye',
      description: 'Views',
      usage: 'Visibility statistics'
    },
    {
      name: 'ThumbsUp',
      description: 'Like',
      usage: 'Positive interactions'
    },
    {
      name: 'Heart',
      description: 'Favorite',
      usage: 'Mark as favorite'
    },
    {
      name: 'Star',
      description: 'Star',
      usage: 'Rating, favorites'
    },

    // Social
    {
      name: 'Users',
      description: 'Users',
      usage: 'Groups, participants'
    },
    {
      name: 'MessageCircle',
      description: 'Message',
      usage: 'Communication'
    },
    {
      name: 'Bell',
      description: 'Notification',
      usage: 'Alerts, updates'
    },

    // States and Status
    {
      name: 'CheckCircle',
      description: 'Success',
      usage: 'Confirmation, successful action'
    },
    {
      name: 'XCircle',
      description: 'Error',
      usage: 'Error, failed action'
    },
    {
      name: 'AlertTriangle',
      description: 'Warning',
      usage: 'Attention, caution'
    },
    {
      name: 'Info',
      description: 'Information',
      usage: 'Informative message'
    },
    {
      name: 'Settings',
      description: 'Settings',
      usage: 'Configuration, options'
    },

    // Media
    {
      name: 'Image',
      description: 'Image',
      usage: 'Photos, illustrations'
    },
    {
      name: 'Video',
      description: 'Video',
      usage: 'Video content'
    },
    {
      name: 'Music',
      description: 'Music',
      usage: 'Audio content'
    }
  ];

  const categories = [
    { title: 'Navigation and Actions', start: 0, end: 6 },
    { title: 'Navigation Interface', start: 6, end: 10 },
    { title: 'Event Management', start: 10, end: 14 },
    { title: 'Interactions and Statistics', start: 14, end: 18 },
    { title: 'Social', start: 18, end: 21 },
    { title: 'States and Status', start: 21, end: 26 },
    { title: 'Media', start: 26, end: 29 }
  ];

  return (
    <div className="icons-showcase">
      <section className="icon-type-container">
        <div className="icon-group">
          <h4>Propriétés disponibles</h4>
          <p className="icon-description">
            Les icônes sont utilisées pour améliorer la compréhension et l'expérience utilisateur dans l'application.
            Elles doivent être utilisées de manière cohérente pour maintenir une identité visuelle uniforme.
          </p>
          <div className="properties-table">
            <table>
              <thead>
                <tr>
                  <th>Propriété</th>
                  <th>Type</th>
                  <th>Défaut</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>name</td>
                  <td>string</td>
                  <td>required</td>
                  <td>Nom de l'icône à afficher</td>
                </tr>
                <tr>
                  <td>size</td>
                  <td>number</td>
                  <td>24</td>
                  <td>Taille de l'icône en pixels</td>
                </tr>
                <tr>
                  <td>color</td>
                  <td>string</td>
                  <td>currentColor</td>
                  <td>Couleur de l'icône (CSS)</td>
                </tr>
                <tr>
                  <td>className</td>
                  <td>string</td>
                  <td>""</td>
                  <td>Classes CSS additionnelles</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>

      {categories.map(({ title, start, end }, index) => (
        <section key={index} className="icon-type-container">
          <div className="icon-group">
            <h4>{title}</h4>
            <div className="component-grid">
              {iconsList.slice(start, end).map((icon) => (
                <IconDisplay key={icon.name} {...icon} />
              ))}
            </div>
          </div>
        </section>
      ))}
    </div>
  );
};

export default IconSection; 