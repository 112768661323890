import React from 'react';
import LogoSVG from '../primitives/LogoSVG';
import '../../../styles/components/monkey-cursor.css';
import { MonkeyProps } from '../types/monkey.types';

// Extension des MonkeyProps de base avec les props spécifiques au MonkeyCursor
interface MonkeyCursorProps extends MonkeyProps {
  children?: React.ReactNode;
  position?: 'left' | 'right';
  offset?: {
    x?: number;
    y?: number;
  };
  size?: 'sm' | 'md' | 'lg' | 'auto';
}

export const MonkeyCursor: React.FC<MonkeyCursorProps> = ({
  children,
  showMonkey = true,
  position = 'left',
  monkeyVariant = 'singeOrange',
  monkeyType = 'walking',
  offset = { x: 8, y: 0 },
  monkeyLookAt,
  size = 'md'
}) => {
  if (!showMonkey) return <>{children}</>;

  const finalLookAt = monkeyLookAt || (position === 'left' ? 'right' : 'left');

  return (
    <div className="monkey-cursor-wrapper">
      {children}
      <div 
        className={`
          monkey-cursor 
          monkey-cursor--${position} 
          monkey-cursor--look-${finalLookAt}
          monkey-cursor--size-${size}
          monkey-cursor--type-${monkeyType}
        `}
        style={{
          '--offset-x': `${offset.x}px`,
          '--offset-y': `${offset.y}px`
        } as React.CSSProperties}
      >
        <LogoSVG variant={monkeyVariant} size="auto" />
      </div>
    </div>
  );
};