export interface FormattedDateTime {
  date: string;
  time: string;
  weekday: string;
}

export const formatDateTime = (datetime: Date | undefined, timezone?: string): FormattedDateTime => {
  if (!datetime) return { date: 'Date not set', time: 'Time not set', weekday: '' };
  
  // Séparons les formateurs
  const dateFormatter = new Intl.DateTimeFormat('en-US', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    weekday: 'long',
  });

  const timeFormatter = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  });

  const dateParts = dateFormatter.formatToParts(datetime);
  const timeParts = timeFormatter.formatToParts(datetime);
  
  return {
    date: dateParts
      .filter(part => ['day', 'month', 'year'].includes(part.type))
      .map(part => part.value)
      .join(' '),
    time: timeParts
      .filter(part => ['hour', 'minute'].includes(part.type))
      .map(part => part.value)
      .join(':'),
    weekday: dateParts
      .find(part => part.type === 'weekday')?.value || ''
  };
};

// Nouvelle fonction pour formater une chaîne d'heure
export const formatTimeString = (timeString: string | undefined): string => {
  if (!timeString) return '';
  
  try {
    // Cas spécial: format ISO complet (YYYY-MM-DDThh:mm:ss)
    if (typeof timeString === 'string' && timeString.includes('T')) {
      const parts = timeString.split('T');
      if (parts.length === 2) {
        // Extraire juste la partie heure
        const timePart = parts[1].substring(0, 5); // Prendre les 5 premiers caractères (HH:MM)
        return timePart;
      }
    }
    
    // Si c'est un format non-standard, essayez d'utiliser l'autre formatTimeString
    if (!timeString.includes(':') || timeString.split(':').length !== 2) {
      console.warn('Format d\'heure non standard détecté:', timeString);
      // Solution de secours
      return timeString;
    }
    
    // Créer une date aujourd'hui avec l'heure spécifiée
    const [hours, minutes] = timeString.split(':').map(Number);
    
    // Vérifier que les valeurs sont valides
    if (isNaN(hours) || isNaN(minutes)) {
      console.warn('Valeurs d\'heure invalides détectées:', timeString, hours, minutes);
      return timeString; // Retourner tel quel plutôt que de planter
    }
    
    const date = new Date();
    date.setHours(hours, minutes);

    // Utiliser le même formateur que pour les autres heures
    const timeFormatter = new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    });

    return timeFormatter.format(date);
  } catch (error) {
    console.error('Erreur lors du formatage de l\'heure:', error, timeString);
    return timeString || ''; // Retourner la chaîne originale ou une chaîne vide
  }
};


// Formatte la date pour l'affichage dans l'icône
export const formatDateForIcon = (datetime: Date | undefined) => {
  if (!datetime) return { month: '', day: '' };
  
  const formatter = new Intl.DateTimeFormat(navigator.language || 'en-US', {
    month: 'short',  // 'JAN'
    day: 'numeric'   // '15'
  });
  
  const parts = formatter.formatToParts(datetime);
  return {
    month: parts.find(p => p.type === 'month')?.value.toUpperCase() || '',
    day: parts.find(p => p.type === 'day')?.value || ''
  };
}; 