import React from 'react';
import { Icon } from '../../primitives/Icon';
import { FilterChipProps } from './index';
import './FilterDrawer.css';

const FilterChip: React.FC<FilterChipProps> = ({ label, onRemove }) => {
  return (
    <div className="filter-chip">
      <span className="filter-chip-label">{label}</span>
      <button 
        className="filter-chip-remove"
        onClick={onRemove}
        aria-label={`Supprimer le filtre ${label}`}
      >
        <Icon name="X" size={14} />
      </button>
    </div>
  );
};

export default FilterChip;
