import React, { useState, useEffect } from 'react';
import '../../../styles/components/tags.css';

interface TagProps {
  label: React.ReactNode;
  type?: 'activity' | 'price' | 'status';
  color?: string;
  size?: 'sm' | 'md' | 'lg';
  isFree?: boolean;
  status?: 'published' | 'modified' | 'draft';
  icon?: string;
}

export const Tag: React.FC<TagProps> = ({ 
  label, 
  type = 'activity',
  color = 'blue',
  size,
  isFree = false,
  status,
  icon
}) => {
  const baseClasses = ['tag'];
  
  if (size) baseClasses.push(`tag-${size}`);
  
  if (type === 'price') {
    baseClasses.push('tag-price');
    if (isFree) {
      baseClasses.push('tag-free');
    }
  } else if (type === 'status') {
    baseClasses.push('tag-status');
    if (status) baseClasses.push(`tag-${status}`);
  } else {
    baseClasses.push('tag-activity');
  }

  const finalClassName = baseClasses.join(' ');

  return (
    <span 
      className={finalClassName} 
      data-type={type} 
      data-free={isFree}
      style={{ border: isFree ? '1px solid red' : undefined }}
    >
      {icon && <span className="tag-icon">{icon}</span>}
      {label}
    </span>
  );
};

interface TagGroupProps {
  children: React.ReactNode;
  className?: string;
}

export const TagGroup: React.FC<TagGroupProps> = ({ children, className }) => {
  return (
    <div className={`tag-group ${className || ''}`}>
      {children}
    </div>
  );
};

interface ActivityTagsProps {
  tags: Array<{ label: string; color?: string; icon?: string; }>;
}

export const ActivityTags: React.FC<ActivityTagsProps> = ({ tags }) => {
  return (
    <div className="activity-tags">
      {tags.map(tag => (
        <Tag 
          key={tag.label} 
          label={tag.label}
          icon={tag.icon}
          type="activity"
        />
      ))}
    </div>
  );
};

interface NoImageTagsProps {
  price?: { label: string; isFree: boolean; };
  activityTags: Array<{ label: string; color?: string; icon?: string; }>;
  size?: 'sm' | 'md' | 'lg';
}

export const NoImageTags: React.FC<NoImageTagsProps> = ({ price, activityTags, size = 'md' }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Créer un tableau avec tous les tags
  const allTags = [
    ...(price ? [{
      label: price.label,
      type: 'price' as const,
      isFree: price.isFree,
      icon: undefined
    }] : []),
    ...activityTags.map(tag => ({
      label: tag.label,
      type: 'activity' as const,
      icon: tag.icon,
      isFree: undefined
    }))
  ];

  // Calculer le nombre de tags par ligne
  const tagsPerRow = Math.ceil(allTags.length / 2);

  return (
    <div className="no-image-tag-container" data-tags-count={allTags.length}>
      <div className="tags-grid">
        {allTags.map((tag, index) => (
          <Tag
            key={`${tag.type}-${tag.label}-${index}`}
            label={tag.label}
            type={tag.type}
            icon={tag.icon}
            isFree={tag.isFree}
            size={size}
          />
        ))}
      </div>
    </div>
  );
}; 