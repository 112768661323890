import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { useAuth } from '../contexts/AuthContext';
import { useOrganizer } from '../contexts/OrganizerContext';
import './styles/organizer-layout.css';

const OrganizerLayout: React.FC = () => {
  const { user } = useAuth();
  const { organizerProfile } = useOrganizer();

  // Rediriger vers login si non authentifié
  if (!user) {
    return <Navigate to="/login" replace />;
  }

  return (
    <div className="organizer-layout">
      <nav className="organizer-sidebar">
        {/* Menu de navigation organisateur */}
      </nav>
      
      <main className="organizer-content">
        <Outlet />
      </main>

      <Toaster 
        position="top-right"
        toastOptions={{
          className: 'organizer-toast',
          duration: 3000
        }}
      />
    </div>
  );
};

export default OrganizerLayout; 