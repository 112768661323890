import { Event } from '../../../../types/event.types';

const baseEventProps = {
  organizerId: 'mock-organizer',
  status: 'active' as const,
  views: 0,
  createdAt: new Date(),
  updatedAt: new Date()
};

export const singleEvent: Event = {
  ...baseEventProps,
  id: 'event1',
  title: 'Jazz Night at Blue Note',
  datetime: new Date('2024-03-15T20:00:00'),
  locationName: 'Blue Note Jazz Club',
  location: '131 W 3rd St, New York, NY 10012',
  image: 'https://images.unsplash.com/photo-1533174072545-7a4b6ad7a6c3',
  tags: ['Music', 'Jazz', 'Live'],
  prices: [
    {
      amount: 25,
      currency: 'EUR',
      description: 'Standard ticket'
    }
  ]
};

export const multipleEvents: Event[] = [
  {
    ...baseEventProps,
    id: 'event2',
    title: 'Weekly Art Workshop',
    datetime: new Date('2024-03-16T14:00:00'),
    locationName: 'Creative Space Gallery',
    location: '123 Art Street, Paris',
    image: 'https://images.unsplash.com/photo-1460661419201-fd4cecdf8a8b',
    tags: ['Art', 'Workshop', 'Creative'],
    prices: [
      {
        amount: 0,
        currency: 'EUR',
        description: 'Free entry'
      }
    ],
    seriesId: 'art-workshop-series'
  },
  {
    ...baseEventProps,
    id: 'event3',
    title: 'Wine Tasting Evening',
    datetime: new Date('2024-03-16T19:00:00'),
    locationName: 'Creative Space Gallery',
    location: '123 Art Street, Paris',
    image: 'https://images.unsplash.com/photo-1510812431401-41d2bd2722f3',
    tags: ['Wine', 'Tasting', 'Social'],
    prices: [
      {
        amount: 35,
        currency: 'EUR',
        description: 'Includes wine tasting'
      }
    ]
  },
  {
    ...baseEventProps,
    id: 'event4',
    title: 'Photography Exhibition',
    datetime: new Date('2024-03-17T11:00:00'),
    locationName: 'Creative Space Gallery',
    location: '123 Art Street, Paris',
    image: 'https://images.unsplash.com/photo-1552334405-4929565998d5',
    tags: ['Photography', 'Art', 'Exhibition'],
    prices: [
      {
        amount: 15,
        currency: 'EUR',
        description: 'Exhibition entry'
      }
    ]
  },
  {
    ...baseEventProps,
    id: 'event5',
    title: 'Modern Dance Performance',
    datetime: new Date('2024-03-17T20:00:00'),
    locationName: 'Creative Space Gallery',
    location: '123 Art Street, Paris',
    image: 'https://images.unsplash.com/photo-1508700115892-45ecd05ae2ad',
    tags: ['Dance', 'Performance', 'Modern'],
    prices: [
      {
        amount: 45,
        currency: 'EUR',
        description: 'Performance ticket'
      }
    ]
  }
]; 