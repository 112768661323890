import React, { useEffect, useRef, useState } from 'react';
import { MapMarker } from './MapMarker';
import { MonkeyCursor } from './MonkeyCursor';
import { MonkeyProps } from '../types/monkey.types';
import '../../../styles/components/distance-slider.css';

interface DistanceSliderProps extends MonkeyProps {
  value: number;
  onChange: (value: number) => void;
  min?: number;
  max?: number;
  step?: number;
  unit?: string;
  disabled?: boolean;
  className?: string;
}

const MIN_DISTANCE = 1; // 500m
const DEAD_ZONE_PERCENT = 18; // Augmentation de la zone morte à 20%

// Définir les offsets pour chaque type de singe et position
const MONKEY_OFFSETS = {
  walking: {
    position1: { x: 30, y: -23 },     // Marche à gauche du slider
    position2: { x: 220, y: -23 }     // Marche à droite du slider
  },
  hanging: {
    position1: { x: 30, y: -58 },   // Suspendu à gauche du slider
    position2: { x: 220, y: -58 }   // Suspendu à droite du slider
  }
};

const DistanceSlider: React.FC<DistanceSliderProps> = ({
  value,
  onChange,
  min = MIN_DISTANCE,
  max = 50,
  step = 1,
  unit = 'km',
  disabled = false,
  className = '',
  // Props Monkey avec valeurs par défaut
  showMonkey = false,
  monkeyVariant = 'singeOrange',
  monkeyType = 'walking',
  monkeyLookAt = 'right',
  monkeyPosition = 'position1'
}) => {
  const trackRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);

  // Convertit une valeur en pourcentage de position
  const valueToPercent = (val: number): number => {
    const adjustedPercent = ((val - min) / (max - min)) * (100 - DEAD_ZONE_PERCENT);
    return DEAD_ZONE_PERCENT + adjustedPercent;
  };

  // Convertit un pourcentage en valeur avec snap
  const percentToValue = (percent: number): number => {
    const adjustedPercent = Math.max(0, percent - DEAD_ZONE_PERCENT);
    const rawValue = (adjustedPercent * (max - min)) / (100 - DEAD_ZONE_PERCENT) + min;
    const stepsCount = Math.round(rawValue / step);
    return Math.max(min, Number((stepsCount * step).toFixed(1)));
  };

  // Formate la distance pour l'affichage
  const formatDistance = (distance: number): string => {
    if (distance < 1) {
      return `${(distance * 1000).toFixed(0)}m`;
    }
    return `${distance.toString().replace('.', ',')} ${unit}`;
  };

  // Gère le déplacement du curseur
  const handleMove = (clientX: number) => {
    if (!trackRef.current) return;

    const rect = trackRef.current.getBoundingClientRect();
    const percent = Math.min(Math.max(((clientX - rect.left) / rect.width) * 100, DEAD_ZONE_PERCENT), 100);
    const newValue = percentToValue(percent);
    
    if (newValue !== value) {
      onChange(newValue);
    }
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    if (disabled) return;
    setIsDragging(true);
    handleMove(e.clientX);
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    if (disabled) return;
    setIsDragging(true);
    handleMove(e.touches[0].clientX);
  };

  useEffect(() => {
    if (!isDragging) return;

    const handleMouseMove = (e: MouseEvent) => {
      handleMove(e.clientX);
    };

    const handleTouchMove = (e: TouchEvent) => {
      handleMove(e.touches[0].clientX);
    };

    const handleEnd = () => {
      setIsDragging(false);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleEnd);
    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchend', handleEnd);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleEnd);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleEnd);
    };
  }, [isDragging]);

  // Force la valeur minimale
  useEffect(() => {
    if (value < MIN_DISTANCE) {
      onChange(MIN_DISTANCE);
    }
  }, [value]);

  const percent = valueToPercent(Math.max(min, value));

  // Récupérer l'offset correspondant au type et à la position du singe
  const monkeyOffset = MONKEY_OFFSETS[monkeyType][monkeyPosition];

  return (
    <div 
      className={`distance-slider ${disabled ? 'disabled' : ''} ${className}`}
      style={{ '--slider-percent': `${percent}%` } as React.CSSProperties}
    >
      <div className="fixed-elements">
        <div className="distance-display">{formatDistance(value)}</div>
        <div className="user-circle" />
        <div className="slider-line" />
        <div className="slider-line-active" />
      </div>

      <div 
        className="interactive-elements"
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchStart}
      >
        <div 
          ref={trackRef}
          className="slider-track" 
        />
        <MonkeyCursor
          showMonkey={showMonkey}
          monkeyVariant={monkeyVariant}
          monkeyType={monkeyType}
          monkeyLookAt={monkeyLookAt}
          offset={monkeyOffset}
        />
        <div 
          className="slider-handle"
          style={{ left: `${percent}%` }}
          role="slider"
          aria-valuemin={min}
          aria-valuemax={max}
          aria-valuenow={value}
          aria-disabled={disabled}
          tabIndex={disabled ? -1 : 0}
        >
          <MapMarker size="sm" />
        </div>
      </div>
    </div>
  );
};

export default DistanceSlider;

