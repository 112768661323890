import React from 'react';
import '../../../styles/components/DateIcon.css';

interface DateIconProps {
  month: string;
  day: string | number;
  variant?: 'light' | 'dark';
  size?: 'sm' | 'md' | 'lg';
  className?: string;
}

export const DateIcon: React.FC<DateIconProps> = ({
  month,
  day,
  variant = 'light',
  size = 'md',
  className = ''
}) => {
  return (
    <div className={`date-icon date-icon--${variant} date-icon--${size} ${className}`}>
      <div className="date-icon__month">{month}</div>
      <div className="date-icon__day">{day}</div>
    </div>
  );
}; 