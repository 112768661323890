import React, { useState } from 'react';
import Input from '../primitives/Input';
import Textarea from '../primitives/Textarea';
import Select from '../primitives/Select';
import NumberInput from '../primitives/NumberInput';
import { DateInput, TimeInput } from '../primitives/DateTimeInput';
import FileInput from '../primitives/FileInput';
import Checkbox from '../primitives/Checkbox';
import { Icon } from '../primitives/Icon';
import './FormFieldsSection.css';
import { CurrencyInput } from '../primitives/CurrencyInput';
import Button from '../primitives/Button';
import { useGeolocation } from '../../../utils/hooks/useGeolocation';
import { MultiPriceInput } from '../primitives/MultiPriceInput';
import { useRandomMonkey } from '../../../hooks/useRandomMonkey';

interface CodeSection {
  id: string;
  isCollapsed: boolean;
}

interface SimulatedLocation {
  city: string;
  country: string;
  locale: string;
  currency: string;
}

// Liste des localisations de test avec leurs devises associées
const TEST_LOCATIONS = [
  { city: 'Colombo', country: 'Sri Lanka', locale: 'si-LK', currency: 'LKR' },
  { city: 'Paris', country: 'France', locale: 'fr-FR', currency: 'EUR' },
  { city: 'New York', country: 'États-Unis', locale: 'en-US', currency: 'USD' },
  { city: 'Londres', country: 'Royaume-Uni', locale: 'en-GB', currency: 'GBP' },
  { city: 'Tokyo', country: 'Japon', locale: 'ja-JP', currency: 'JPY' },
  { city: 'Mumbai', country: 'Inde', locale: 'hi-IN', currency: 'INR' }
];

const FormControlsSection: React.FC = () => {
  // États pour les démos
  const [textValue, setTextValue] = useState('');
  const [textareaValue, setTextareaValue] = useState('');
  const [selectValue, setSelectValue] = useState('');
  const [numberValue, setNumberValue] = useState<number>(0);
  const [dateValue, setDateValue] = useState<string>('');
  const [fileValue, setFileValue] = useState<File[]>([]);
  const [checkboxValue, setCheckboxValue] = useState(false);
  const [timeValue, setTimeValue] = useState<string>('');
  const [currencyValue, setCurrencyValue] = useState({ amount: 0, currency: '' });

  // État pour la localisation simulée
  const [simulatedLocation, setSimulatedLocation] = useState<SimulatedLocation | null>(null);
  const { location: realLocation, loading } = useGeolocation();

  // État pour le collapse des sections de code
  const [collapsedSections, setCollapsedSections] = React.useState<CodeSection[]>([
    { id: 'input', isCollapsed: true },
    { id: 'textarea', isCollapsed: true },
    { id: 'select', isCollapsed: true },
    { id: 'number', isCollapsed: true },
    { id: 'date', isCollapsed: true },
    { id: 'file', isCollapsed: true },
    { id: 'checkbox', isCollapsed: true },
    { id: 'time', isCollapsed: true },
    { id: 'currency', isCollapsed: true }
  ]);

  // Hook pour les singes aléatoires
  const { getMonkeyProps } = useRandomMonkey({
    mode: 'atLeastOne',
    availablePositions: [0, 1, 2]  // Un index pour chaque input
  });

  const toggleSection = (sectionId: string) => {
    setCollapsedSections(prev => prev.map(section => 
      section.id === sectionId 
        ? { ...section, isCollapsed: !section.isCollapsed }
        : section
    ));
  };

  const isSectionCollapsed = (sectionId: string) => {
    return collapsedSections.find(section => section.id === sectionId)?.isCollapsed ?? true;
  };

  // Gestionnaires d'événements typés
  const handleInputChange = (value: string) => {
    setTextValue(value);
  };

  const handleTextareaChange = (value: string) => {
    setTextareaValue(value);
  };

  const handleSelectChange = (value: string) => {
    setSelectValue(value);
  };

  const handleNumberChange = (value: number) => {
    setNumberValue(value);
  };

  const handleDateChange = (value: string) => {
    setDateValue(value);
  };

  const handleFileChange = (files: FileList | null) => {
    setFileValue(files ? Array.from(files) : []);
  };

  const handleCheckboxChange = (checked: boolean) => {
    setCheckboxValue(checked);
  };

  const handleTimeChange = (value: string) => {
    setTimeValue(value);
  };

  // Fonction pour simuler un changement de localisation
  const simulateLocation = (testLocation: typeof TEST_LOCATIONS[0]) => {
    setSimulatedLocation(testLocation);
    setCurrencyValue(prev => ({ ...prev, currency: testLocation.currency }));
    setPrices(prev => prev.map(price => ({ ...price, currency: testLocation.currency })));
  };

  // Fonction pour réinitialiser la simulation
  const resetSimulation = () => {
    setSimulatedLocation(null);
    setCurrencyValue(prev => ({ ...prev, currency: '' }));
    setPrices(prev => prev.map(price => ({ ...price, currency: '' })));
  };

  // Afficher la localisation actuelle (réelle ou simulée)
  const currentLocation = simulatedLocation || {
    city: 'Paris',
    country: 'France',
    locale: navigator.language,
    currency: 'EUR'
  };

  // Options pour le Select
  const selectOptions = [
    { label: 'Option 1', value: 'option1' },
    { label: 'Option 2', value: 'option2' },
    { label: 'Option 3', value: 'option3' }
  ];

  // Currency multiple input
  const [prices, setPrices] = useState<Array<{ amount: number; currency: string; description?: string }>>([
    { amount: 0, currency: '' }
  ]);

  return (
    <section className="component-section">
      <div className="controls-showcase">
        {/* Text Input */}
        <div className="control-type-container">
          <div className="control-group">
            <h4>Text Input</h4>
            <p className="control-description">
              Standard text field with support for disabled states, error, helper text, and interactive monkey.
            </p>
            <div className="control-preview">
              <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', maxWidth: '300px' }}>
                <Input
                  id="demo-input"
                  name="demo-input"
                  label="Text field"
                  value={textValue}
                  onChange={handleInputChange}
                  placeholder="Enter text"
                  helper="Optional helper text"
                  {...getMonkeyProps(0, true)}
                />
                <Input
                  id="demo-input-monkey-left"
                  name="demo-input-monkey-left"
                  label="With monkey on the left"
                  value={textValue}
                  onChange={handleInputChange}
                  placeholder="Monkey looks right"
                  {...getMonkeyProps(1, true)}
                />
                <Input
                  id="demo-input-monkey-right"
                  name="demo-input-monkey-right"
                  label="With monkey on the right"
                  value={textValue}
                  onChange={handleInputChange}
                  placeholder="Monkey looks left"
                  {...getMonkeyProps(2, true)}
                />
                <Input
                  id="demo-input-error"
                  name="demo-input-error"
                  label="With error"
                  value=""
                  onChange={() => {}}
                  error="Error message"
                />
                <Input
                  id="demo-input-disabled"
                  name="demo-input-disabled"
                  label="Disabled"
                  value="Disabled value"
                  onChange={() => {}}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="component-code">
            <div className={`code-example ${isSectionCollapsed('input') ? 'collapsed' : ''}`}>
              <h3 onClick={() => toggleSection('input')}>Code</h3>
              <pre>
{`<Input
  id="demo-input"
  name="demo-input"
  label="Text field"
  value={value}
  onChange={handleInputChange}
  placeholder="Enter text"
  helper="Optional helper text"
  {...getMonkeyProps(0, true)}
/>`}
              </pre>
            </div>
          </div>
        </div>

        {/* Textarea */}
        <div className="control-type-container">
          <div className="control-group">
            <h4>Textarea</h4>
            <p className="control-description">
              Multi-line text field for longer content.
            </p>
            <div className="control-preview">
              <div style={{ maxWidth: '300px' }}>
                <Textarea
                  id="demo-textarea"
                  name="demo-textarea"
                  label="Description"
                  value={textareaValue}
                  onChange={handleTextareaChange}
                  placeholder="Enter a description"
                  rows={4}
                />
              </div>
            </div>
          </div>
          <div className="component-code">
            <div className={`code-example ${isSectionCollapsed('textarea') ? 'collapsed' : ''}`}>
              <h3 onClick={() => toggleSection('textarea')}>Code</h3>
              <pre>
{`<Textarea
  id="demo-textarea"
  name="demo-textarea"
  label="Description"
  value={value}
  onChange={handleTextareaChange}
  placeholder="Enter a description"
  rows={4}
/>`}
              </pre>
            </div>
          </div>
        </div>

        {/* Select */}
        <div className="control-type-container">
          <div className="control-group">
            <h4>Select</h4>
            <p className="control-description">
              Dropdown menu to select an option from a list.
            </p>
            <div className="control-preview">
              <div style={{ maxWidth: '300px' }}>
                <Select
                  id="demo-select"
                  name="demo-select"
                  label="Select an option"
                  value={selectValue}
                  onChange={handleSelectChange}
                  options={selectOptions}
                />
              </div>
            </div>
          </div>
          <div className="component-code">
            <div className={`code-example ${isSectionCollapsed('select') ? 'collapsed' : ''}`}>
              <h3 onClick={() => toggleSection('select')}>Code</h3>
              <pre>
{`const options = [
  { label: 'Option 1', value: 'option1' },
  { label: 'Option 2', value: 'option2' },
  { label: 'Option 3', value: 'option3' }
];

<Select
  id="demo-select"
  name="demo-select"
  label="Select an option"
  value={value}
  onChange={handleSelectChange}
  options={options}
/>`}
              </pre>
            </div>
          </div>
        </div>

        {/* Number Input */}
        <div className="control-type-container">
          <div className="control-group">
            <h4>Number Input</h4>
            <p className="control-description">
              Numeric field with +/- controls and validation.
            </p>
            <div className="control-preview">
              <div style={{ maxWidth: '300px' }}>
                <NumberInput
                  id="demo-number"
                  name="demo-number"
                  label="Quantity"
                  value={numberValue}
                  onChange={handleNumberChange}
                  min={0}
                  max={100}
                />
              </div>
            </div>
          </div>
          <div className="component-code">
            <div className={`code-example ${isSectionCollapsed('number') ? 'collapsed' : ''}`}>
              <h3 onClick={() => toggleSection('number')}>Code</h3>
              <pre>
{`<NumberInput
  id="demo-number"
  name="demo-number"
  label="Quantity"
  value={value}
  onChange={handleNumberChange}
  min={0}
  max={100}
/>`}
              </pre>
            </div>
          </div>
        </div>

        {/* Date Input */}
        <div className="control-type-container">
          <div className="control-group">
            <h4>Date Input</h4>
            <p className="control-description">
              Date selector with integrated calendar.
            </p>
            <div className="control-preview">
              <div style={{ maxWidth: '300px' }}>
                <DateInput
                  id="demo-date"
                  name="demo-date"
                  label="Date"
                  value={dateValue}
                  onChange={handleDateChange}
                />
              </div>
            </div>
          </div>
          <div className="component-code">
            <div className={`code-example ${isSectionCollapsed('date') ? 'collapsed' : ''}`}>
              <h3 onClick={() => toggleSection('date')}>Code</h3>
              <pre>
{`<DateInput
  id="demo-date"
  name="demo-date"
  label="Date"
  value={value}
  onChange={handleDateChange}
/>`}
              </pre>
            </div>
          </div>
        </div>

        {/* File Input */}
        <div className="control-type-container">
          <div className="control-group">
            <h4>File Input</h4>
            <p className="control-description">
              File drop zone with drag & drop support.
            </p>
            <div className="control-preview">
              <div style={{ maxWidth: '400px' }}>
                <FileInput
                  id="demo-file"
                  name="demo-file"
                  label="Files"
                  onChange={handleFileChange}
                  accept="image/*"
                  multiple
                />
              </div>
            </div>
          </div>
          <div className="component-code">
            <div className={`code-example ${isSectionCollapsed('file') ? 'collapsed' : ''}`}>
              <h3 onClick={() => toggleSection('file')}>Code</h3>
              <pre>
{`<FileInput
  id="demo-file"
  name="demo-file"
  label="Files"
  onChange={handleFileChange}
  accept="image/*"
  multiple
/>`}
              </pre>
            </div>
          </div>
        </div>

        {/* Checkbox */}
        <div className="control-type-container">
          <div className="control-group">
            <h4>Checkbox</h4>
            <p className="control-description">
              Checkbox with support for indeterminate state.
            </p>
            <div className="control-preview">
              <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                <Checkbox
                  id="demo-checkbox"
                  name="demo-checkbox"
                  checked={checkboxValue}
                  onChange={handleCheckboxChange}
                  label="Option to check"
                />
                <Checkbox
                  id="demo-checkbox-indeterminate"
                  name="demo-checkbox-indeterminate"
                  checked={false}
                  onChange={() => {}}
                  indeterminate
                  label="Indeterminate state"
                />
                <Checkbox
                  id="demo-checkbox-disabled"
                  name="demo-checkbox-disabled"
                  checked={true}
                  onChange={() => {}}
                  disabled
                  label="Disabled option"
                />
              </div>
            </div>
          </div>
          <div className="component-code">
            <div className={`code-example ${isSectionCollapsed('checkbox') ? 'collapsed' : ''}`}>
              <h3 onClick={() => toggleSection('checkbox')}>Code</h3>
              <pre>
{`<Checkbox
  id="demo-checkbox"
  name="demo-checkbox"
  checked={checked}
  onChange={handleCheckboxChange}
  label="Option to check"
/>`}
              </pre>
            </div>
          </div>
        </div>

        {/* Time Input */}
        <div className="control-type-container">
          <div className="control-group">
            <h4>Time Input</h4>
            <p className="control-description">
              Time selection field with HH:MM format.
            </p>
            <div className="control-preview">
              <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                <TimeInput
                  id="demo-time"
                  name="demo-time"
                  label="Time"
                  value={timeValue}
                  onChange={handleTimeChange}
                  helper="24h format"
                />
                <TimeInput
                  id="demo-time-error"
                  name="demo-time-error"
                  label="With error"
                  value=""
                  onChange={() => {}}
                  error="Invalid time"
                />
                <TimeInput
                  id="demo-time-disabled"
                  name="demo-time-disabled"
                  label="Disabled"
                  value="14:30"
                  onChange={() => {}}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="component-code">
            <div className={`code-example ${isSectionCollapsed('time') ? 'collapsed' : ''}`}>
              <h3 onClick={() => toggleSection('time')}>Code</h3>
              <pre>
{`<TimeInput
  id="demo-time"
  name="demo-time"
  label="Time"
  value={value}
  onChange={handleTimeChange}
  helper="24h format"
/>`}
              </pre>
            </div>
          </div>
        </div>

        {/* Multi Price Input */}
        <div className="control-type-container">
          <div className="control-group">
            <h4>Multi Price Input</h4>
            <p className="control-description">
              Input field for multiple prices with support for up to 3 different prices.
              {loading && !simulatedLocation ? (
                <em> (Detecting location...)</em>
              ) : currentLocation ? (
                <em> (Current location: {currentLocation.city}, {currentLocation.country})</em>
              ) : null}
            </p>
            
            {/* Location test section */}
            <div className="locale-test-section">
              <p>Simulate a location:</p>
              <div className="locale-buttons">
                {TEST_LOCATIONS.map((loc) => (
                  <Button
                    key={loc.locale}
                    onClick={() => simulateLocation(loc)}
                    variant={currentLocation?.city === loc.city ? 'primary' : 'secondary'}
                  >
                    {loc.city}
                  </Button>
                ))}
                {simulatedLocation && (
                  <Button
                    onClick={resetSimulation}
                    variant="secondary"
                  >
                    Back to real location
                  </Button>
                )}
              </div>
            </div>

            <div className="control-preview">
              <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', maxWidth: '400px' }}>
                <MultiPriceInput
                  id="price-demo"
                  prices={prices}
                  onChange={setPrices}
                  label="Event price"
                  helper="Add up to 3 different prices"
                  userLocation={simulatedLocation || realLocation ? {
                    latitude: realLocation?.latitude || 0,
                    longitude: realLocation?.longitude || 0,
                    city: currentLocation?.city
                  } : undefined}
                />
                <MultiPriceInput
                  id="price-demo-error"
                  prices={[{ amount: 0, currency: currentLocation?.currency || 'LKR' }]}
                  onChange={() => {}}
                  label="With error"
                  error="Invalid price"
                  userLocation={simulatedLocation || realLocation ? {
                    latitude: realLocation?.latitude || 0,
                    longitude: realLocation?.longitude || 0,
                    city: currentLocation?.city
                  } : undefined}
                />
                <MultiPriceInput
                  id="price-demo-disabled"
                  prices={[{ amount: 42.50, currency: currentLocation?.currency || 'LKR' }]}
                  onChange={() => {}}
                  label="Disabled"
                  disabled
                  userLocation={simulatedLocation || realLocation ? {
                    latitude: realLocation?.latitude || 0,
                    longitude: realLocation?.longitude || 0,
                    city: currentLocation?.city
                  } : undefined}
                />
              </div>
            </div>
          </div>
          <div className="component-code">
            <div className={`code-example ${isSectionCollapsed('currency') ? 'collapsed' : ''}`}>
              <h3 onClick={() => toggleSection('currency')}>Code</h3>
              <pre>
{`<MultiPriceInput
  id="price-demo"
  prices={prices}
  onChange={setPrices}
  label="Event price"
  helper="Add up to 3 different prices"
  userLocation={userLocation}
/>`}
              </pre>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FormControlsSection;