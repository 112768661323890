import React from 'react';
import '../../../styles/components/textarea.css';

export interface TextareaProps {
  // Props essentielles
  id: string;
  name: string;
  value: string;
  onChange: (value: string) => void;
  
  // Props optionnelles
  label?: string;
  placeholder?: string;
  helper?: string;
  error?: string;
  required?: boolean;
  disabled?: boolean;
  rows?: number;
  maxLength?: number;
  className?: string;
  autoResize?: boolean;
}

export const Textarea: React.FC<TextareaProps> = ({
  id,
  name,
  value,
  onChange,
  label,
  placeholder,
  helper,
  error,
  required = false,
  disabled = false,
  rows = 3,
  maxLength,
  className = '',
  autoResize = false,
}) => {
  const textareaClasses = [
    'textarea-field',
    error ? 'has-error' : '',
    disabled ? 'is-disabled' : '',
    autoResize ? 'auto-resize' : '',
    className
  ].filter(Boolean).join(' ');

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.target.value);
    
    if (autoResize) {
      e.target.style.height = 'auto';
      e.target.style.height = `${e.target.scrollHeight}px`;
    }
  };

  return (
    <div className="textarea-container">
      {label && (
        <label htmlFor={id} className="textarea-label">
          {label}
          {required && <span className="required-mark">*</span>}
        </label>
      )}
      
      <textarea
        id={id}
        name={name}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        disabled={disabled}
        required={required}
        rows={rows}
        maxLength={maxLength}
        className={textareaClasses}
        aria-invalid={!!error}
        aria-describedby={helper ? `${id}-helper` : undefined}
      />

      {helper && !error && (
        <p id={`${id}-helper`} className="textarea-helper">
          {helper}
        </p>
      )}
      
      {error && (
        <p id={`${id}-error`} className="textarea-error" role="alert">
          {error}
        </p>
      )}
      
      {maxLength && (
        <div className="textarea-counter">
          {value.length} / {maxLength}
        </div>
      )}
    </div>
  );
};

export default Textarea; 