import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './BottomNav.css';
import { Icon, IconName } from '../../primitives/Icon';
import MenuDrawer from '../MenuDrawer/MenuDrawer';
import FilterDrawer from '../FilterDrawer/FilterDrawer';
import { useBackButton } from '../../../../navigation/hooks/useBackButton';
import OrganizerMenuDrawer from '../MenuDrawer/OrganizerMenuDrawer';

interface NavItem {
  icon: IconName;
  label: string;
  onClick?: () => void;
  badge?: number;
  viewMode?: 'list' | 'map';
  type?: 'filter' | 'menu';
}

interface MenuItem {
  label: string;
  icon: IconName;
  onClick?: () => void;
  subItems?: MenuItem[];
}

interface BottomNavProps {
  items: NavItem[];
  className?: string;
  onFilterApply?: (filters: any) => void;
  filterProps?: any;
  onBeforeDrawerOpen?: () => void;
  menuItems?: MenuItem[];
  organizerMode?: boolean;
  onProfileClick?: () => void;
}

export const BottomNav: React.FC<BottomNavProps> = ({ 
  items, 
  className = '', 
  onFilterApply,
  filterProps,
  onBeforeDrawerOpen,
  organizerMode,
  onProfileClick
}) => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [previousIcon, setPreviousIcon] = useState<IconName | null>(null);
  const [isAnimating, setIsAnimating] = useState(false);

  // Utiliser notre hook pour le Menu drawer
  useBackButton(isMenuOpen, () => setIsMenuOpen(false));
  
  // Utiliser notre hook pour le Filter drawer
  useBackButton(isFilterOpen, () => setIsFilterOpen(false));

  const handleClick = (item: NavItem) => {
    // Si un drawer est ouvert, on le ferme d'abord
    if (isMenuOpen || isFilterOpen) {
      // On ferme les drawers
      setIsMenuOpen(false);
      setIsFilterOpen(false);
      
      // Si on clique sur le même bouton que le drawer ouvert, on s'arrête là
      if ((item.type === 'menu' && isMenuOpen) || (item.type === 'filter' && isFilterOpen)) {
        return;
      }
      
      // Si on clique sur un bouton de navigation pendant qu'un drawer est ouvert
      if (item.viewMode || !item.type) {
        // Si c'est un changement de vue et qu'on est sur la page d'accueil,
        // on ne fait rien d'autre que fermer le drawer
        if (item.viewMode && window.location.pathname === '/') {
          return;
        }
        // On attend un peu que le drawer soit fermé avant de naviguer
        setTimeout(() => {
          item.onClick?.();
        }, 50);
        return;
      }
    }

    // Gestion des drawers
    if (item.type === 'menu' || item.type === 'filter') {
      // On attend que onBeforeDrawerOpen soit terminé avant d'ouvrir le drawer
      Promise.resolve(onBeforeDrawerOpen?.()).then(() => {
        if (item.type === 'menu') {
          setIsMenuOpen(!isMenuOpen);
          setIsFilterOpen(false);
        } else {
          setIsFilterOpen(!isFilterOpen);
          setIsMenuOpen(false);
        }
      });
      return;
    }

    // Gestion du changement de vue
    if (item.viewMode) {
      // Si PreviewCard est ouverte
      if (window.location.pathname.includes('/events/')) {
        // 1. On démarre l'animation de l'icône
        setPreviousIcon(item.icon);
        setIsAnimating(true);
        
        // 2. On attend un peu que l'animation commence
        setTimeout(() => {
          navigate('/', { replace: true });
          
          // 3. On attend la fin de l'animation de fermeture
          setTimeout(() => {
            setIsAnimating(false);
            if (item.onClick) {
              // On force le changement de vue
              onBeforeDrawerOpen?.();
              item.onClick();
            }
          }, 300);
        }, 50);
      } else {
        // Cas normal sans PreviewCard
        setPreviousIcon(item.icon);
        setIsAnimating(true);
        setTimeout(() => {
          setIsAnimating(false);
          item.onClick?.();
        }, 300);
      }
      return;
    }

    // Pour les autres boutons
    item.onClick?.();
  };

  return (
    <>
      <nav className={`bottom-nav ${className}`}>
        <div className="bottom-nav-content">
          {items.map((item, index) => (
            <button
              key={index}
              className="nav-item"
              onClick={() => handleClick(item)}
              data-view-mode={item.viewMode}
              data-animating={isAnimating}
              aria-pressed={
                (item.type === 'menu' && isMenuOpen) ||
                (item.type === 'filter' && isFilterOpen)
              }
            >
              <div className="nav-item-icon">
                {item.viewMode && isAnimating && previousIcon && (
                  <div className="icon-leaving">
                    <Icon name={previousIcon} size={24} />
                  </div>
                )}
                <div className="icon-entering">
                  <Icon name={item.icon} size={24} />
                </div>
                {item.badge !== undefined && (
                  <span className="nav-item-badge">{item.badge}</span>
                )}
              </div>
              <span className="nav-item-label">{item.label}</span>
            </button>
          ))}
        </div>
      </nav>

      {organizerMode ? (
        <OrganizerMenuDrawer
          isOpen={isMenuOpen}
          onClose={() => setIsMenuOpen(false)}
        />
      ) : (
        <MenuDrawer
          isOpen={isMenuOpen}
          onClose={() => setIsMenuOpen(false)}
        />
      )}

      {filterProps && (
        <FilterDrawer
          {...filterProps}
          isOpen={isFilterOpen}
          onClose={() => setIsFilterOpen(false)}
        />
      )}
    </>
  );
};

export default BottomNav;