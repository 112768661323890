import React, { useState } from 'react';
import DateTimeFilters from '../patterns/DateTimeFilters';

const DateTimeFiltersSection: React.FC = () => {
  const [selectedDates, setSelectedDates] = useState<string[]>([]);
  const [selectedTimes, setSelectedTimes] = useState<string[]>([]);

  const handleDateFilterChange = (dates: string[]) => {
    setSelectedDates(dates);
  };

  const handleTimeFilterChange = (times: string[]) => {
    setSelectedTimes(times);
  };

  return (
    <div className="section">
      <h3 className="section-title">Date & Time Filters</h3>
      <p className="section-description">
        Permet aux utilisateurs de filtrer les événements par date et heure.
      </p>

      <div className="showcase-grid">
        {/* Version mobile */}
        <div className="showcase-item">
          <div className="showcase-preview" style={{ maxWidth: '375px' }}>
            <DateTimeFilters
              onDateFilterChange={handleDateFilterChange}
              onTimeFilterChange={handleTimeFilterChange}
              selectedDates={selectedDates}
              selectedTimes={selectedTimes}
            />
          </div>
          <div className="showcase-info">
            <code className="showcase-name">Mobile view</code>
            <p className="showcase-usage">Version mobile avec scroll horizontal</p>
          </div>
        </div>

        {/* Version desktop */}
        <div className="showcase-item">
          <div className="showcase-preview">
            <DateTimeFilters
              onDateFilterChange={handleDateFilterChange}
              onTimeFilterChange={handleTimeFilterChange}
              selectedDates={selectedDates}
              selectedTimes={selectedTimes}
            />
          </div>
          <div className="showcase-info">
            <code className="showcase-name">Desktop view</code>
            <p className="showcase-usage">Version desktop avec wrap des filtres</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateTimeFiltersSection; 