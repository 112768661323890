import React, { useState } from 'react';
import '../../../styles/components/radio.css';

interface RadioOption {
    label: string;
    value: string;
    disabled?: boolean;
    content?: React.ReactNode;
}

interface RadioButtonGroupProps {
    options: RadioOption[];
    name: string;
    onChange: (value: string) => void;
    defaultValue?: string;
}

const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({ 
    options, 
    name, 
    onChange, 
    defaultValue = options[0]?.value 
}) => {
    const [selected, setSelected] = useState(defaultValue);

    const handleChange = (value: string) => {
        setSelected(value);
        onChange(value);
    };

    return (
        <div className="custom-radio-group">
            {options.map((option) => (
                <div key={option.value} className="custom-radio-option">
                    <label className="custom-radio-container">
                        <input
                            type="radio"
                            name={name}
                            value={option.value}
                            checked={selected === option.value}
                            onChange={() => handleChange(option.value)}
                            disabled={option.disabled}
                        />
                        <span className="custom-radio-mark"></span>
                        <span className="custom-radio-label">{option.label}</span>
                    </label>
                    {selected === option.value && option.content && (
                        <div className="custom-radio-content">
                            {option.content}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default RadioButtonGroup;