import React from 'react';
import { BottomNav } from './BottomNav';
import { IconName } from '../../primitives/Icon';
import { useNavigate } from 'react-router-dom';


interface OrganizerBottomNavProps {
  activeTab: 'active' | 'draft' | 'past';
  onTabChange: (tab: 'active' | 'draft' | 'past') => void;
  className?: string;
  onProfileClick: () => void;
}

export const OrganizerBottomNav: React.FC<OrganizerBottomNavProps> = ({
  activeTab,
  onTabChange,
  className,
  onProfileClick
}) => {
  const navigate = useNavigate();

  const items = [
    {
      icon: 'Calendar' as IconName,
      label: 'Upcoming',
      onClick: () => onTabChange('active')
    },
    {
      icon: 'FileText' as IconName,
      label: 'Drafts',
      onClick: () => onTabChange('draft')
    },
    {
      icon: 'History' as IconName,
      label: 'Past',
      onClick: () => onTabChange('past')
    },
    {
      icon: 'Menu' as IconName,
      label: 'Menu',
      type: 'menu' as const
    }
  ];

  return (
    <BottomNav 
      items={items}
      className={className}
      organizerMode={true}
      onBeforeDrawerOpen={() => {/* Si besoin */}}
      onProfileClick={onProfileClick}
    />
  );
}; 