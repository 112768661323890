import React, { useState } from 'react';
import './DesignSystem.css';
import '../../styles/form-components.css';
import '../../styles/components/step-indicator.css';
import ButtonSection from './sections/ButtonSection';
import FormControlsSection from './sections/FormControlsSection';
import FormFieldsSection from './sections/FormFieldsSection';
import StatusSection from './sections/StatusSection';
import NavigationSection from './sections/NavigationSection';
import TagsSection from './sections/TagsSection';
import MapMarkerSection from './sections/MapMarkerSection';
import IconSection from './sections/IconSection';
import ColorsSection from './sections/Foundations/Colors';
import TypographySection from './sections/Foundations/Typography';
import SpacingSection from './sections/Foundations/Spacing';
import AnimationsSection from './sections/Foundations/Animations';
import RadiusSection from './sections/Foundations/Radius';
import FilterDrawerSection from './sections/FilterDrawerSection';
import DistanceSliderSection from './sections/DistanceSliderSection';
import SearchBarSection from './sections/SearchBarSection';
import LocationFilterSection from './sections/LocationFilterSection';
import DateTimeFiltersSection from './sections/DateTimeFiltersSection';
import MapDrawerSection from './sections/MapDrawerSection';

interface Section {
  id: string;
  title: string;
  component: React.ReactNode;
  isCollapsed: boolean;
  category?: string;
}

const DesignSystem: React.FC = () => {
  const [sections, setSections] = useState<Section[]>([
    // Foundations
    {
      id: 'colors', 
      title: 'Colors', 
      component: <ColorsSection />, 
      isCollapsed: true,
      category: 'Foundations'
    },
    { 
      id: 'typography', 
      title: 'Typography', 
      component: <TypographySection />, 
      isCollapsed: true,
      category: 'Foundations'
    },
    { 
      id: 'spacing', 
      title: 'Spacing', 
      component: <SpacingSection />, 
      isCollapsed: true,
      category: 'Foundations'
    },
    { 
      id: 'animations', 
      title: 'Animations', 
      component: <AnimationsSection />, 
      isCollapsed: true,
      category: 'Foundations'
    },
    { 
      id: 'radius', 
      title: 'Border Radius', 
      component: <RadiusSection />, 
      isCollapsed: true,
      category: 'Foundations'
    },
    // Components
    { 
      id: 'buttons', 
      title: 'Buttons', 
      component: <ButtonSection />, 
      isCollapsed: true,
      category: 'Components'
    },
    { 
      id: 'form-controls', 
      title: 'Form Controls', 
      component: <FormControlsSection />, 
      isCollapsed: true,
      category: 'Components'
    },
    { 
      id: 'form-fields', 
      title: 'Form Fields', 
      component: <FormFieldsSection />, 
      isCollapsed: true,
      category: 'Components'
    },
    { 
      id: 'status', 
      title: 'Status Messages', 
      component: <StatusSection />, 
      isCollapsed: true,
      category: 'Components'
    },
    { 
      id: 'navigation', 
      title: 'Navigation', 
      component: <NavigationSection />, 
      isCollapsed: true,
      category: 'Components'
    },
    // Patterns
    {
      id: 'search-bar',
      title: 'Search Bar',
      component: <SearchBarSection />,
      isCollapsed: true,
      category: 'Patterns'
    },
    {
      id: 'location-filter',
      title: 'Location Filter',
      component: <LocationFilterSection />,
      isCollapsed: true,
      category: 'Patterns'
    },
    {
      id: 'datetime-filters',
      title: 'Date & Time Filters',
      component: <DateTimeFiltersSection />,
      isCollapsed: true,
      category: 'Patterns'
    },
    {
      id: 'filter-drawer',
      title: 'Bottom Nav (Filter & Menu)',
      component: <FilterDrawerSection />,
      isCollapsed: true,
      category: 'Patterns' 
    },
    {
      id: 'distance-slider',
      title: 'Distance Slider',
      component: <DistanceSliderSection />,
      isCollapsed: true,
      category: 'Components'
    },
    // Indicators
    { 
      id: 'tags', 
      title: 'Tags', 
      component: <TagsSection />, 
      isCollapsed: true,
      category: 'Indicators'
    },
    { 
      id: 'map-markers', 
      title: 'Map Markers', 
      component: <MapMarkerSection />, 
      isCollapsed: true,
      category: 'Indicators'
    },
    {
      id: 'icons',
      title: 'Icons', 
      component: <IconSection />,
      isCollapsed: true,
      category: 'Indicators'
    },
    {
      id: 'map-drawer',
      title: 'Map Drawer',
      component: <MapDrawerSection />,
      isCollapsed: true,
      category: 'Patterns'
    }
  ]);

  const toggleSection = (sectionId: string) => {
    setSections(prev => prev.map(section => 
      section.id === sectionId 
        ? { ...section, isCollapsed: !section.isCollapsed }
        : section
    ));
  };

  // Grouper les sections par catégorie
  const groupedSections = sections.reduce((acc, section) => {
    const category = section.category || 'Other';
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(section);
    return acc;
  }, {} as Record<string, Section[]>);

  return (
    <div className="design-system-container">
      <h1>Design System</h1>
      
      {Object.entries(groupedSections).map(([category, categorySections]) => (
        <div key={category} className="design-system-category">
          <h2 className="category-title">{category}</h2>
          {categorySections.map(section => (
            <div key={section.id} className="design-system-section">
              <button 
                className="section-header" 
                onClick={() => toggleSection(section.id)}
              >
                <span className="section-title">{section.title}</span>
                <span className={`collapse-icon ${section.isCollapsed ? 'collapsed' : ''}`}>
                  ▼
                </span>
              </button>
              <div className={`section-content ${section.isCollapsed ? 'collapsed' : ''}`}>
                {section.component}
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default DesignSystem;