import React, { useState, useRef, useEffect } from 'react';
import { OrganizerProfile } from '../../../types/organizer.types';
import { 
  Icon, 
  ContentBlock,
  Button,
  Tag
} from '../../../pages/DesignSystem';
import './ProfileHeader.css';

interface ProfileHeaderProps {
  profile: OrganizerProfile | null;
  isOwner: boolean;
  onEdit?: () => void;
}

const ProfileHeader: React.FC<ProfileHeaderProps> = ({ 
  profile, 
  isOwner,
  onEdit 
}) => {
  // Utiliser les images du profil ou un tableau vide si aucune n'est disponible
  const galleryImages = profile?.galleryImages || [];
  
  // État pour le carrousel
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const galleryRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Détecter le mobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Gestion du défilement des images
  const handleMouseDown = (e: React.MouseEvent) => {
    if (!galleryRef.current || galleryImages.length <= 1) return;
    setIsDragging(true);
    setStartX(e.pageX - galleryRef.current.offsetLeft);
    setScrollLeft(galleryRef.current.scrollLeft);
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    if (!galleryRef.current || galleryImages.length <= 1) return;
    setIsDragging(true);
    setStartX(e.touches[0].pageX - galleryRef.current.offsetLeft);
    setScrollLeft(galleryRef.current.scrollLeft);
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isDragging || !galleryRef.current) return;
    e.preventDefault();
    const x = e.pageX - galleryRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Vitesse de défilement
    galleryRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (!isDragging || !galleryRef.current) return;
    const x = e.touches[0].pageX - galleryRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    galleryRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleDragEnd = () => {
    setIsDragging(false);
    if (!galleryRef.current) return;
    
    // Déterminer l'index de l'image visible
    const imageWidth = galleryRef.current.offsetWidth;
    const index = Math.round(galleryRef.current.scrollLeft / imageWidth);
    setCurrentImageIndex(index);
  };

  const goToImage = (index: number) => {
    if (!galleryRef.current || galleryImages.length <= 1) return;
    
    const newIndex = Math.max(0, Math.min(index, galleryImages.length - 1));
    setCurrentImageIndex(newIndex);
    
    // Défilement doux vers l'image
    galleryRef.current.scrollTo({
      left: newIndex * galleryRef.current.offsetWidth,
      behavior: 'smooth'
    });
  };

  // Ouvrir l'image en plein écran
  const openImageInNewTab = (imageUrl: string) => {
    window.open(imageUrl, '_blank');
  };

  // Surveiller les changements d'index pour mettre à jour le défilement
  useEffect(() => {
    if (!galleryRef.current || galleryImages.length <= 1) return;
    
    const handleScroll = () => {
      if (isDragging || !galleryRef.current) return;
      
      const imageWidth = galleryRef.current.offsetWidth;
      const index = Math.round(galleryRef.current.scrollLeft / imageWidth);
      
      if (index !== currentImageIndex) {
        setCurrentImageIndex(index);
      }
    };
    
    galleryRef.current.addEventListener('scroll', handleScroll);
    return () => {
      galleryRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, [currentImageIndex, isDragging, galleryImages.length]);

  // Image par défaut si aucune n'est fournie
  const defaultImage = "https://via.placeholder.com/800x400?text=No+Image+Available";

  // Mock contacts pour la démonstration
  const mockContacts = [
    { type: 'instagram', value: '@eventconnect' },
    { type: 'email', value: 'contact@eventconnect.app' },
    { type: 'phone', value: '+94 76 123 4567' },
    { type: 'whatsapp', value: '+94 76 123 4567' },
    { type: 'website', value: 'www.eventconnect.app' }
  ];
  
  // Helper pour générer les liens de contact
  const getContactLink = (type: string, value: string) => {
    switch(type) {
      case 'instagram': return `https://instagram.com/${value.replace('@', '')}`;
      case 'email': return `mailto:${value}`;
      case 'phone': return `tel:${value}`;
      case 'whatsapp': return `https://wa.me/${value.replace(/[^0-9]/g, '')}`;
      case 'website': return value.startsWith('http') ? value : `https://${value}`;
      default: return '#';
    }
  };
  
  // Vérification initiale si le profil existe
  if (!profile) {
    return (
      <ContentBlock className="profile-header profile-header--not-found">
        <h1>Oops! Organizer not found</h1>
        <p>This organizer profile is no longer available. They might have moved to a new adventure! 🌟</p>
        <Button
          variant="secondary"
          size="sm"
          onClick={() => window.history.back()}
        >
          Go back
        </Button>
      </ContentBlock>
    );
  }

  // Vérification si organizer est valide
  if (!profile.organizer) {
    return (
      <ContentBlock className="profile-header profile-header--error">
       <h1>Oh snap! Where did they go? 🕵️</h1>
       <p>Looks like this organizer profile is playing hide and seek with us! ✨</p>
        <Button
          variant="secondary"
          size="sm"
          onClick={() => window.history.back()}
        >
          Go back
        </Button>
      </ContentBlock>
    );
  }

  return (
    <div className="profile-header">
      {/* Galerie d'images avec carrousel - affichée seulement s'il y a des images */}
      {galleryImages.length > 0 && (
        <div className="profile-header__gallery">
          <div 
            ref={galleryRef}
            className="profile-header__carousel"
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleDragEnd}
            onMouseLeave={handleDragEnd}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleDragEnd}
          >
            {galleryImages.map((image, index) => (
              <div 
                key={index} 
                className="profile-header__carousel-item"
              >
                <img 
                  src={image} 
                  alt={`${profile?.organizer || "Organizer"} gallery image ${index + 1}`} 
                  className="profile-header__image"
                />
                
                {/* Bouton pour ouvrir l'image en plein écran */}
                <div 
                  className="profile-header__image-open-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    openImageInNewTab(image);
                  }}
                  role="button"
                  aria-label="Open image in new tab"
                >
                  <Icon name="Maximize2" size={24} />
                </div>
              </div>
            ))}
          </div>
          
          {/* Flèches de navigation (desktop uniquement et seulement s'il y a plus d'une image) */}
          {!isMobile && galleryImages.length > 1 && (
            <>
              <button 
                className="profile-header__nav-arrow profile-header__nav-arrow--prev"
                onClick={() => goToImage(currentImageIndex - 1)}
                disabled={currentImageIndex === 0}
                aria-label="Previous image"
              >
                <Icon name="ChevronLeft" size={24} />
              </button>
              <button 
                className="profile-header__nav-arrow profile-header__nav-arrow--next"
                onClick={() => goToImage(currentImageIndex + 1)}
                disabled={currentImageIndex === galleryImages.length - 1}
                aria-label="Next image"
              >
                <Icon name="ChevronRight" size={24} />
              </button>
            </>
          )}
          
          {/* Indicateurs de position (dots) - seulement s'il y a plus d'une image */}
          {galleryImages.length > 1 && (
            <div className="profile-header__indicators">
              {galleryImages.map((_, index) => (
                <button
                  key={index}
                  className={`profile-header__indicator ${
                    index === currentImageIndex ? 'profile-header__indicator--active' : ''
                  }`}
                  onClick={() => goToImage(index)}
                  aria-label={`Go to image ${index + 1}`}
                />
              ))}
            </div>
          )}
        </div>
      )}

      {/* Informations du profil */}
      <ContentBlock className="profile-header__info">
        {/* Titre et avatar, réorganisés pour placer le nom à droite de l'avatar */}
        <div className="profile-header__title-container">
          {/* Avatar */}
          <div className="profile-header__avatar">
            {profile?.organizerImage ? (
              <img 
                src={profile.organizerImage} 
                alt={profile.organizer} 
                className="profile-header__avatar-image"
              />
            ) : (
              <div className="profile-header__avatar-placeholder">
                <Icon name="User" size={48} />
              </div>
            )}
          </div>
          
          {/* Informations de titre à droite de l'avatar */}
          <div className="profile-header__title-info">
            <h1 className="profile-header__name">{profile?.organizer}</h1>
            {profile?.subtitle && (
              <p className="profile-header__subtitle">{profile.subtitle}</p>
            )}
            {profile?.location && (
              <div className="profile-header__location">
                <Icon name="MapPin" size={16} />
                <span>{profile.locationName || profile.location}</span>
              </div>
            )}
          </div>
        </div>
        
        {/* Description */}
        {profile?.description && (
          <div className="profile-header__description">
            <p>{profile.description}</p>
          </div>
        )}
        
        {/* Contact */}
        <div className="profile-header__contact-section">
          <h3 className="profile-header__section-title">Contact</h3>
          <div className="profile-header__contact-grid">
            {/* Téléphone - afficher seulement si disponible */}
            {profile?.phone && (
              <div className="profile-header__contact-item">
                <Icon name="Phone" size={16} className="profile-header__contact-icon" />
                <span className="profile-header__contact-value">{profile.phone}</span>
              </div>
            )}
            
            {/* Email - afficher seulement si disponible */}
            {profile?.email && (
              <div className="profile-header__contact-item">
                <Icon name="Mail" size={16} className="profile-header__contact-icon" />
                <span className="profile-header__contact-value">{profile.email}</span>
              </div>
            )}
            
            {/* Site web - afficher seulement si disponible */}
            {profile?.website && (
              <div className="profile-header__contact-item">
                <Icon name="Globe" size={16} className="profile-header__contact-icon" />
                <a 
                  href={profile.website} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="profile-header__contact-link"
                >
                  {profile.website.replace(/^https?:\/\//, '')}
                </a>
              </div>
            )}
            
            {/* Instagram - afficher seulement si disponible */}
            {profile?.instagram && (
              <div className="profile-header__contact-item">
                <Icon name="Instagram" size={16} className="profile-header__contact-icon" />
                <a 
                  href={`https://instagram.com/${profile.instagram}`} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="profile-header__contact-link"
                >
                  @{profile.instagram}
                </a>
              </div>
            )}
          </div>
        </div>
      </ContentBlock>
      
      {/* Bouton d'édition */}
      {isOwner && onEdit && (
        <Button
          variant="secondary"
          size="sm"
          onClick={onEdit}
          className="profile-header__edit-button"
        >
          <Icon name="Edit" size={16} />
          Edit Profile
        </Button>
      )}
    </div>
  );
};

export default ProfileHeader; 