// Imports
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Event } from '../../types/event.types';
import { useNavigate, useLocation } from 'react-router-dom';
import { formatDateTime, formatDateForIcon, formatTimeString } from '../../utils/dateFormatter';
import { 
  NoImageTags,
  FavoriteButton,
  ShareButton,
  NotificationBubble,
  LogoSVG,
  DateIcon,
  ContentBlock,
  Button
} from '../../pages/DesignSystem';
import MapDrawer from '../../pages/DesignSystem/patterns/MapDrawer';
import { useEventSeries } from '../../hooks/useEventSeries';
import { eventService } from '../../firebase/event.service';
import './PreviewCardMap.css';
import { geoEventQueries } from '../../firebase/geoEventQueries';

// Types & Interfaces
interface PreviewCardMapProps {
  events: Event[];
  location: string;
  isOpen: boolean;
  onClose: () => void;
  onEventClick: (event: Event) => void;
  isSingleEvent?: boolean;
  currentEvent?: Event;
  seriesEvents?: Event[];
}

interface EventCardProps {
  event: Event;
  onSelect: (event: Event) => void;
  onShareSuccess: () => void;
  sortedSeriesEvents?: Event[];
}

// Helper Functions
const formatLocation = (fullLocation: string): string => {
  const parts = fullLocation.split(',').map(part => part.trim());
  
  // Si nous avons plusieurs parties, prendre la ville/quartier
  if (parts.length > 2) {
    // Prendre la ville (généralement l'avant-dernière partie)
    return parts[parts.length - 3] || 'this area';
  } else if (parts.length > 1) {
    // Si nous avons au moins 2 parties, prendre la première
    return parts[0] || 'this area';
  }
  
  // Fallback
  return 'this area';
};

const FALLBACK_IMAGE = '/images/logoverttexte.png';

const EventDetails = React.memo(({ 
  event, 
  onEndDateChange 
}: { 
  event: Event; 
  onEndDateChange?: (date?: Date) => void 
}) => {
  const { formatRecurrencePattern, endDate } = useEventSeries(event.seriesId);

  useEffect(() => {
    if (onEndDateChange) {
      onEndDateChange(endDate);
    }
  }, [endDate, onEndDateChange]);

  return (
    <div className="preview-card-map__details">
      {event.seriesId ? (
        <span>{formatRecurrencePattern()}</span>
      ) : (
       
          <>
            <DateIcon 
              {...formatDateForIcon(event.datetime)}
              variant="light"
              size="sm"
            />
            <div className="event-card__time-info">
              <span className="day">
                {formatDateTime(event.datetime).weekday}
              </span>
              <span className="hours">
                {formatDateTime(event.datetime).time}
                {event.endTime && ` - ${formatTimeString(event.endTime)}`}
              </span>
            </div>
          </>
        )}
     
    </div>
  );
});

// Main Components
const EventCard = React.memo(({ event, onSelect, onShareSuccess, sortedSeriesEvents }: EventCardProps) => {
  const [fullEventData, setFullEventData] = useState<Event | null>(null);
  const [seriesEndDate, setSeriesEndDate] = useState<Date | undefined>();
  const { date, time } = formatDateTime(event.datetime);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const loadFullEvent = async () => {
      try {
        const fullEvent = await eventService.getEventById(event.id);
        if (fullEvent) {
          setFullEventData(fullEvent);
        }
      } catch (error) {
        console.error('Error loading full event:', error);
      }
    };
    
    loadFullEvent();
  }, [event.id]);

  const handleSelect = (event: Event) => {
    onSelect(fullEventData || event);
  };

  return (
    <ContentBlock
      variant="light"
      padding="sm"
      className="preview-card-map__card"
      onClick={() => handleSelect(event)}
      
    >
      <div className="preview-card-map__tags">
        <NoImageTags
          price={!event.prices || event.prices.length === 0 ? {
            label: 'Free',
            isFree: true
          } : {
            label: event.prices.length > 1 ? 'Check details' :
                   event.prices[0].amount === 0 ? 'Free' :
                   `${event.prices[0].amount} ${event.prices[0].currency}`,
            isFree: event.prices.length === 1 && event.prices[0].amount === 0
          }}
          activityTags={event.tags?.slice(0, 3).map(tag => ({ label: tag })) || []}
          size="md"
        />
      </div>

      <div className="preview-card-map__main-content">
        <div className="preview-card-map__image">
          {event.image ? (
            <img 
              src={event.image}
              alt={event.title}
              className={`event-image ${imageLoaded ? 'loaded' : ''}`}
              onLoad={() => setImageLoaded(true)}
              loading="lazy"
            />
          ) : (
            <img 
              src={FALLBACK_IMAGE}
              alt=""
              className="event-fallback-image"
              loading="lazy"
            />
          )}
        </div>

        <div className="preview-card-map__content">
          <div className="preview-card-map__header">
            <h3 className="preview-card-map__title">{event.title}</h3>
            <EventDetails 
              event={event} 
              onEndDateChange={setSeriesEndDate}
            />
          </div>

          <div className="preview-card-map__footer">
          <Button variant="outline" size="sm" className="preview-card-map__check-details">
              Check details
            </Button>
            <div className="preview-card-map__actions" onClick={(e) => e.stopPropagation()}>
              <ShareButton
                shareData={{
                  title: event.title,
                  text: `what2do.today\nCome discover ${event.title},\non ${date} at ${time}\n`,
                  url: window.location.href
                }}
                variant="icon"
                size="lg"
                className="share-button"
                shouldTriggerSuccess={false}
                onShareSuccess={onShareSuccess}
              />
              <FavoriteButton
                id={event.id}
                seriesId={event.seriesId}
                expiresAt={seriesEndDate || event.datetime}
                seriesEvents={sortedSeriesEvents}
                size="lg"
                className="favorite-button"
              />
            </div>
           
          </div>
        </div>
      </div>
    </ContentBlock>
  );
});

export default React.memo(function PreviewCardMap({
  events,
  location,
  isOpen,
  onClose,
  onEventClick,
  isSingleEvent,
  currentEvent,
  seriesEvents
}: PreviewCardMapProps) {
  const navigate = useNavigate();
  const routeLocation = useLocation();
  const locationState = routeLocation.state as { 
    isMapView?: boolean; 
    userLocation?: { 
      latitude: number; 
      longitude: number 
    } 
  };
  const [showNotif, setShowNotif] = useState(false);

  const sortedSeriesEvents = useMemo(() => {
    if (!seriesEvents) return undefined;
    
    const now = new Date();
    const filtered = seriesEvents.filter(event => {
      if (!event.datetime) return false;
      const eventDate = new Date(event.datetime);
      return eventDate > now;
    });

    const sorted = filtered.sort((a, b) => {
      if (!a.datetime || !b.datetime) return 0;
      const dateA = new Date(a.datetime);
      const dateB = new Date(b.datetime);
      return dateA.getTime() - dateB.getTime();
    });

    return sorted;
  }, [seriesEvents]);

  // Nouvelle fonction pour vérifier si tous les événements sont au même endroit
  const checkSameLocation = useMemo(() => {
    if (!events.length) return false;
    
    const firstLocation = events[0].locationName;
    return events.every(event => event.locationName === firstLocation) && firstLocation;
  }, [events]);

  const handleClose = useCallback(() => {
    navigate(-1);
    onClose();
  }, [navigate, onClose]);

  const handleEventSelect = useCallback(async (event: Event) => {
    try {
      const fullEvent = await geoEventQueries.getEventDetails(event.id);
      
      if (!fullEvent) {
        console.error('❌ Could not load full event details');
        return;
      }

      // Si c'est un événement de série
      if (fullEvent.seriesId) {
        const relevantSeriesEvents = events.filter(e => e.seriesId === fullEvent.seriesId);
        
        navigate(`/series/${fullEvent.seriesId}`, {
          state: {
            isMapView: true,
            userLocation: locationState?.userLocation,
            event: fullEvent,
            seriesEvents: relevantSeriesEvents,
            returnToMulti: true,
            multiEvents: events,
            location: location
          }
        });
        return;
      }
      
      // Navigation normale pour un événement simple
      navigate(`/event/${event.id}`, {
        state: {
          isMapView: true,
          userLocation: locationState?.userLocation,
          event: fullEvent,
          returnToMulti: true,
          multiEvents: events,
          location: location
        }
      });
    } catch (error) {
      console.error('❌ Error in handleEventSelect:', error);
    }
  }, [navigate, locationState?.userLocation, events, location]);

  if (!isOpen) return null;

  const title = isSingleEvent 
    ? events[0].locationName || formatLocation(location)
    : checkSameLocation 
      ? checkSameLocation // Si tous les événements sont au même endroit, utiliser ce nom
      : `Events in ${formatLocation(location)}`;
    
  const subtitle = !isSingleEvent && events.length > 1 
    ? `${events.length} events${checkSameLocation ? ' at this location' : ' in this area'}`
    : undefined;

  return (
    <MapDrawer
      isOpen={isOpen}
      onClose={handleClose}
      className={isSingleEvent ? 'map-drawer--single' : ''}
      title={title}
      subtitle={subtitle}
      hasOverlay={false}
    >
      <div className="preview-card-map__list" role="list">
        {events.map(event => (
          <EventCard
            key={event.id}
            event={event}
            onSelect={handleEventSelect}
            onShareSuccess={() => setShowNotif(true)}
            sortedSeriesEvents={sortedSeriesEvents}
          />
        ))}
      </div>
      <NotificationBubble
        show={showNotif}
        message="Link copied! ✨"
        type="success"
        duration={3000}
        onHide={() => setShowNotif(false)}
      />
    </MapDrawer>
  );
});