export const ORGANIZER_HELP_MESSAGES = {
    // Messages fixes qui seront toujours affichés
    fixed: [
      "Click the \"Create Event\" button to add a new event",
      "Use tabs to switch between active, draft, and past events",
      "Click on any event to edit its details",
      "Statistics are incoming !"
    ],
    // Tips qui seront affichés aléatoirement (un seul à la fois)
    tips: [
      
    ]
  };
  
  // Fonction utilitaire pour obtenir un tip aléatoire
  export const getRandomTip = () => {
    const tips = ORGANIZER_HELP_MESSAGES.tips;
    return tips[Math.floor(Math.random() * tips.length)];
  };