import React, { useEffect } from 'react';
import { FormData } from '../types';
import { PREDEFINED_TAGS } from '../../../../pages/DesignSystem/types/tags.types';
import { cloudinaryService } from '../../../../services/cloudinary.service';
import { 
  Textarea, 
  MultiPriceInput, 
  FileInput,
  TagList,
  ContentBlock,
  Input
} from '../../../../pages/DesignSystem';
import '../../../../styles/form-components.css';

interface DetailsStepProps {
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
  errors: Partial<Record<keyof FormData, string>>;
  handleTagToggle: (tagId: string) => void;
}

const DetailsStep: React.FC<DetailsStepProps> = ({
  formData,
  setFormData,
  errors,
  handleTagToggle
}) => {
  // Make sure there's always at least one price initialized
  useEffect(() => {
    if (!formData.prices || formData.prices.length === 0) {
      setFormData(prev => ({
        ...prev,
        prices: [{ amount: 0, currency: 'EUR', description: 'Standard price' }]
      }));
    }
  }, []); // Only runs when component mounts

  const handleImageUpload = (files: FileList | null) => {
    const file = files?.[0];
    if (file) {
      // Create a temporary URL for preview
      const tempUrl = URL.createObjectURL(file);
      setFormData((prev: FormData) => ({ ...prev, image: tempUrl }));

      // Upload to Cloudinary
      cloudinaryService.uploadImage(file)
        .then(cloudinaryUrl => {
          if (cloudinaryUrl) {
            setFormData(prev => ({ ...prev, image: cloudinaryUrl }));
            console.log('FormData updated with Cloudinary URL');
          } else {
            console.error('No URL received from Cloudinary');
          }
          // Clean up temporary URL
          URL.revokeObjectURL(tempUrl);
        })
        .catch(error => {
          console.error('Error uploading image:', error);
          // Reset image on error
          setFormData(prev => ({ ...prev, image: '' }));
          URL.revokeObjectURL(tempUrl);
        });
    }
  };

  return (
    <div className="event-form__step-content">
      {/* Description Section */}
      <ContentBlock 
        title="Event Description"
        spacing="md"
      >
        <div className="event-form__group">
          <label className="event-form__label event-form__label--required">
            <span className="event-form__label-hint">
              (Tell people what makes your event special!)
            </span>
          </label>
          <Textarea
            id="description"
            name="description"
            value={formData.description || ''}
            onChange={(value) => setFormData((prev: FormData) => ({ ...prev, description: value }))}
            placeholder="Example: Join us for an amazing evening of... We'll have... Don't miss out on..."
            error={errors.description}
            rows={4}
            required
            helper="💡 Tip: Include what people can expect, what to bring, and why they should come. The more details, the better!"
          />
        </div>
      </ContentBlock>

      {/* Price Section */}
      <ContentBlock 
        title="Event Price"
        
      >
        <div className="event-form__group">
          <label className="event-form__label">
            <span className="event-form__label-hint">
              (How much will it cost to attend?)
            </span>
          </label>
          <MultiPriceInput
            id="event-prices"
            prices={formData.prices || [{ amount: 0, currency: 'EUR', description: 'Standard price' }]}
            onChange={(newPrices) => {
              // Accepter tous les prix pendant l'édition, sans déduplication
              setFormData(prev => ({
                ...prev,
                prices: newPrices.map(price => ({
                  amount: typeof price.amount === 'string' ? parseFloat(price.amount) : price.amount,
                  currency: price.currency,
                  description: price.description || 'Standard price'
                }))
              }));
            }}
            error={errors.prices}
            helper="💡 Tip: Leave it at 0€ for a free event, or add up to 3 different prices (e.g., Early Bird, Standard, VIP)"
          />
        </div>

        {/* Section Réservation */}
        <div className="event-form__group">
          <label className="event-form__label">
            <span className="event-form__label-hint">
              (How can people book or reserve their spot?)
            </span>
          </label>
          <Input
            id="booking-info"
            name="ticketingUrl"
            type="text"
            value={formData.ticketingUrl || ''}
            onChange={(value) => setFormData(prev => ({
              ...prev,
              ticketingUrl: value
            }))}
            placeholder="Website, WhatsApp number, or booking instructions"
            helper="💡 Tip: Add a link, phone number or instructions for reservations"
            icon="Ticket"
          />
        </div>
      </ContentBlock>

      {/* Image Section */}
      <ContentBlock 
        title="Event Image"
        spacing="md"
      >
        <div className="event-form__group">
          <label className="event-form__label">
            <span className="event-form__label-hint">
              (A picture is worth a thousand words!)
            </span>
          </label>
          <FileInput
            id="event-image"
            name="image"
            accept="image/*"
            onChange={handleImageUpload}
            helper="💡 Tip: Use a clear, attractive image that represents your event. Best size is 4:5 (portrait, like an Instagram post). Maximum 5MB."
            dragAndDrop
          />
          {formData.image && (
            <div className="event-form__image-preview">
              <img src={formData.image} alt="Preview" />
            </div>
          )}
        </div>
      </ContentBlock>

      {/* Categories Section */}
      <ContentBlock 
        title="Event Categories"
        
      >
        <div className="event-form__group">
          <label className="event-form__label">
            <span className="event-form__label-hint">
              (Help people find your event!)
            </span>
          </label>
          <TagList
            tags={PREDEFINED_TAGS.filter(tag => tag.id !== 'favorites')}
            selectedTags={formData.tags || []}
            onTagSelect={handleTagToggle}
            maxTags={3}
            onMaxTagsReached={() => {
              console.warn('Maximum 3 tags allowed');
            }}
          />
          <p className="input-helper">
            💡 Tip: Choose up to 3 categories that best describe your event. This helps people discover it when searching!
          </p>
        </div>
      </ContentBlock>
    </div>
  );
};

export default DetailsStep;
