import React, { useEffect, useRef } from 'react';
import { Autocomplete, StandaloneSearchBox } from '@react-google-maps/api';
import { FormData } from '../types';
import { 
  Input, 
  Icon, 
  ToggleSwitch,
  BinaryToggle,
  DateInput,
  TimeInput,
  ContentBlock,
  MultiSelectToggle,
  RadioButtonGroup,
  NumberInput
} from '../../../../pages/DesignSystem';
import '../../../../styles/form-components.css';
import { isGoogleMapsLoaded } from '../../../../utils/googleMapsUtils';

interface BasicInfoStepProps {
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
  errors: Partial<Record<keyof FormData, string>>;
  autocomplete: google.maps.places.Autocomplete | null;
  setAutocomplete: (autocomplete: google.maps.places.Autocomplete | null) => void;
  onPlaceSelected: () => void;
  isLoaded: boolean;
  showLocationNameInput: boolean;
  isDraft: boolean;
  setShowLocationNameInput: React.Dispatch<React.SetStateAction<boolean>>;
}

const WEEK_DAYS = [
  { id: 'monday', label: 'Mon' },
  { id: 'tuesday', label: 'Tue' },
  { id: 'wednesday', label: 'Wed' },
  { id: 'thursday', label: 'Thu' },
  { id: 'friday', label: 'Fri' },
  { id: 'saturday', label: 'Sat' },
  { id: 'sunday', label: 'Sun' }
];

const END_TYPE_OPTIONS = [
  { value: 'date', label: 'Until a specific date' },
  { value: 'occurrences', label: 'After a number of weeks' }
];

const BasicInfoStep: React.FC<BasicInfoStepProps> = ({
  formData,
  setFormData,
  errors,
  autocomplete,
  setAutocomplete,
  onPlaceSelected,
  isLoaded,
  showLocationNameInput, 
  isDraft,
  setShowLocationNameInput
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const lastValueRef = useRef<string>('');
  const searchBoxRef = useRef<google.maps.places.SearchBox | null>(null);
  
  // Capture l'élément input après le rendu
  useEffect(() => {
    if (!isLoaded) return;
    
    // Trouver l'élément d'entrée
    const inputElement = document.getElementById('location') as HTMLInputElement;
    if (!inputElement) {
      console.error("Élément input non trouvé");
      return;
    }
    
    inputRef.current = inputElement;
    console.log("Référence à l'élément input capturée", inputElement);
    
    // Fonction de détection de changement par Google Places
    const checkForPlaceSelection = () => {
      if (!inputElement || !autocomplete) return;
      
      // Si l'entrée correspond à une sélection de lieu
      const currentValue = inputElement.value;
      
      // Détecter un changement qui pourrait être une sélection
      if (currentValue !== lastValueRef.current && currentValue.length > lastValueRef.current.length) {
        console.log("Changement détecté:", currentValue);
        
        // Attendre un court instant pour laisser Google finir
        setTimeout(() => {
          try {
            const place = autocomplete.getPlace();
            if (place && place.geometry) {
              console.log("Place détectée:", place);
              onPlaceSelected();
            }
          } catch (e) {
            console.log("Pas de place sélectionnée après changement");
          }
        }, 300);
      }
      
      lastValueRef.current = currentValue;
    };
    
    // Écouter divers événements qui pourraient indiquer une sélection
    inputElement.addEventListener('change', checkForPlaceSelection);
    inputElement.addEventListener('blur', checkForPlaceSelection);
    inputElement.addEventListener('keydown', (e) => {
      if (e.key === 'Enter' || e.key === 'Tab') {
        checkForPlaceSelection();
      }
    });
    
    // Observer les clics dans le document qui pourraient être sur une suggestion
    document.addEventListener('click', (e) => {
      // Vérifier si le clic pourrait être sur une suggestion de Google
      const target = e.target as HTMLElement;
      if (target.closest('.pac-container') || target.closest('.pac-item')) {
        console.log("Clic sur suggestion détecté");
        setTimeout(checkForPlaceSelection, 300);
      }
    });
    
    return () => {
      inputElement.removeEventListener('change', checkForPlaceSelection);
      inputElement.removeEventListener('blur', checkForPlaceSelection);
      document.removeEventListener('click', checkForPlaceSelection);
    };
  }, [isLoaded, autocomplete, onPlaceSelected]);

  // Ajoutez ce code de débogage juste après la déclaration des useRef
  useEffect(() => {
    console.log("🔍 BasicInfoStep - Statut de chargement Google Maps:", isLoaded);
    console.log("📋 Vérification alternative:", isGoogleMapsLoaded());
    
    if (isLoaded) {
      console.log("✅ BasicInfoStep - API Google Maps chargée");
      console.log("🌐 window.google existe:", !!window.google);
      console.log("🗺️ google.maps existe:", !!(window.google && window.google.maps));
      console.log("📍 places API existe:", !!(window.google && window.google.maps && window.google.maps.places));
      console.log("🔑 Autocomplete existe:", !!(window.google && window.google.maps && window.google.maps.places && window.google.maps.places.Autocomplete));
    } else {
      console.log("❌ BasicInfoStep - API Google Maps non chargée");
    }
  }, [isLoaded]);

  const handleRecurrenceChange = (checked: boolean) => {
    setFormData(prev => ({ 
      ...prev, 
      isRecurring: checked,
      // Réinitialiser les valeurs si on passe en mode "one shot"
      ...(checked === false && {
        startDate: '',
        endDate: '',
        endType: 'date',
        frequency: 'daily',
        weekDays: []
      })
    }));
  };

  const handleFrequencyChange = (value: boolean) => {
    setFormData(prev => ({ 
      ...prev, 
      frequency: value ? 'weekly' : 'daily',
      ...(value === false && {
        weekDays: []
      })
    }));
  };

  const handleWeekDayToggle = (dayId: string) => {
    setFormData((prev: FormData) => ({
      ...prev,
      weekDays: prev.weekDays?.includes(dayId)
        ? prev.weekDays.filter((id: string) => id !== dayId)
        : [...(prev.weekDays || []), dayId]
    }));
  };

  const handleInputChange = (name: string) => (value: string | React.ChangeEvent<HTMLInputElement>) => {
    const newValue = typeof value === 'string' ? value : value.target.value;
    setFormData(prev => ({ ...prev, [name]: newValue }));
  };

  const handleLocationNameChange = (value: string | React.ChangeEvent<HTMLInputElement>) => {
    const newValue = typeof value === 'string' ? value : value.target.value;
    setFormData(prev => ({ 
      ...prev, 
      locationName: newValue,
      isLocationNameManuallySet: true
    }));
  };

  const handleEndTypeChange = (value: string) => {
    if (value !== 'date' && value !== 'occurrences') return;
    
    setFormData(prev => ({
      ...prev,
      endType: value,
      // Réinitialiser les valeurs non pertinentes
      ...(value === 'date' ? { occurrences: undefined } : { endDate: '' })
    }));
  };

  // Calculer la date maximale (1 mois après la date de début)
  const getMaxEndDate = () => {
    if (!formData.startDate) return '';
    const startDate = new Date(formData.startDate);
    const maxDate = new Date(startDate);
    maxDate.setMonth(maxDate.getMonth() + 1);
    return maxDate.toISOString().split('T')[0];
  };

  const handleOccurrencesChange = (newValue: number) => {
    // Limiter à 3 semaines maximum
    const limitedValue = Math.min(newValue, 3);
    setFormData(prev => ({
      ...prev,
      occurrences: limitedValue
    }));
  };

  // Modifiez cette partie pour mieux comprendre l'initialisation de l'Autocomplete
  const onLoad = (autocompleteInstance: google.maps.places.Autocomplete) => {
    console.log("🎯 Autocomplete chargé:", autocompleteInstance);
    setAutocomplete(autocompleteInstance);
    
    // Essayer d'attacher manuellement les listeners
    if (autocompleteInstance) {
      try {
        autocompleteInstance.addListener('place_changed', () => {
          console.log("🏠 Place changée via listener Google");
          onPlaceSelected();
        });
        console.log("✅ Listener 'place_changed' attaché avec succès");
      } catch (error) {
        console.error("❌ Erreur lors de l'attachement du listener:", error);
      }
    }
  };

  return (
    <div className="event-form__step-content">
      {/* Title Section */}
      <ContentBlock 
        title="Event Title"
        
      >
        <div className="event-form__group">
          <label className="event-form__label event-form__label--required">
            Title
            <span className="event-form__label-hint">
              (Give your event a clear name that people will understand)
            </span>
          </label>
          <Input
            id="title"
            name="title"
            type="text"
            value={formData.title || ''}
            onChange={handleInputChange('title')}
            error={errors.title}
            placeholder="Example: Weekly Yoga Class, Monthly Book Club..."
          />
        </div>
      </ContentBlock>

      {/* Location Section */}
      <ContentBlock 
        title="Location Details"
        spacing="md"
      >
        <div className="event-form__group">
          <label className="event-form__label">
            Adress
            <span className="event-form__label-hint">
              (Where will your event take place?)
            </span>
          </label>
          {isLoaded ? (
            <div className="event-form__location">
              <div style={{ position: 'relative', zIndex: 1000 }}>
                <Autocomplete
                  onLoad={(autocompleteInstance) => {
                    console.log("🎯 Autocomplete chargé:", autocompleteInstance);
                    setAutocomplete(autocompleteInstance);
                  }}
                  onPlaceChanged={() => {
                    console.log("🏠 Place changée via onPlaceChanged");
                    if (autocomplete) {
                      const place = autocomplete.getPlace();
                      console.log("Place sélectionnée:", place);
                      if (place && place.geometry) {
                        onPlaceSelected();
                      }
                    }
                  }}
                >
                  <Input
                    id="location"
                    name="location"
                    placeholder="Search for a place or enter an address..."
                    value={formData.location || ''}
                    onChange={handleInputChange('location')}
                    error={errors.location}
                  />
                </Autocomplete>
              </div>

              <div className="event-form__group">
                <label className="event-form__label">
                  Place Name
                  <span className="event-form__label-hint">
                    (Name of the place people will be looking for)
                  </span>
                  <span className="event-form__label-hint">
                    {formData.locationName && !formData.isLocationNameManuallySet && (
                      " (auto-filled)"
                    )}
                  </span>
                </label>
                <Input
                  id="locationName"
                  name="locationName"
                  type="text"
                  value={formData.locationName || ''}
                  onChange={handleLocationNameChange}
                  placeholder="Enter a name people will recognize..."
                  error={errors.locationName}
                  icon={formData.locationName && !formData.isLocationNameManuallySet ? "Building2" : undefined}
                />
                {formData.locationName && !formData.isLocationNameManuallySet && (
                  <span className="event-form__help-text">
                    You can change this name if needed
                  </span>
                )}
              </div>
            </div>
          ) : (
            <Input
              id="location-loading"
              name="location-loading"
              type="text"
              value=""
              onChange={() => {}}
              disabled
              placeholder="Loading..."
              icon="Loader"
            />
          )}
        </div>
      </ContentBlock>

      {/* Date and Time Section */}
      <ContentBlock 
        title="Event Schedule"
        
      >
        <div className="event-form__group">
          <div className="event-form__recurrence-question">
            <span className="event-form__label">Will this event happen multiple times?</span>
          </div>
          <BinaryToggle
            leftLabel="No"
            rightLabel="Yes"
            value={formData.isRecurring}
            onChange={handleRecurrenceChange}
          />
        </div>

        {formData.isRecurring ? (
          <div className="event-form__recurrence">
            <div className="event-form__group event-form__group--row">
              <div className="event-form__date">
                <label className="event-form__label">Start Date</label>
                <DateInput
                  id="start-date"
                  name="start-date"
                  value={formData.startDate || ''}
                  onChange={handleInputChange('startDate')}
                  error={errors.startDate}
                />
              </div>
              <div className="event-form__time-range">
                <label className="event-form__label">Event Time</label>
                <div className="event-form__time-inputs">
                  <div className="event-form__time-from">
                    <span className="event-form__time-label">From</span>
                    <TimeInput
                      id="start-time"
                      name="start-time"
                      value={formData.startTime || ''}
                      onChange={handleInputChange('startTime')}
                      error={errors.startTime}
                    />
                  </div>
                  <div className="event-form__time-to">
                    <span className="event-form__time-label">to</span>
                    <TimeInput
                      id="end-time"
                      name="end-time"
                      value={formData.endTime || ''}
                      onChange={handleInputChange('endTime')}
                      error={errors.endTime}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="event-form__group">
              <div className="event-form__frequency-question">
                <span className="event-form__label">How often does this event repeat?</span>
              </div>
              <BinaryToggle
                leftLabel="Some days"
                rightLabel="Every day"
                value={formData.frequency !== 'weekly'}
                onChange={(value) => handleFrequencyChange(!value)}
                className="frequency-toggle"
              />
            </div>

            {formData.frequency === 'weekly' && (
              <div className="event-form__group">
                <label className="event-form__label">
                  Which days of the week?
                  <span className="event-form__label-hint">
                    (Select all days when the event will happen)
                  </span>
                </label>
                <MultiSelectToggle
                  options={WEEK_DAYS}
                  selectedValues={formData.weekDays || []}
                  onChange={(selectedDays) => setFormData(prev => ({ ...prev, weekDays: selectedDays }))}
                />
                {errors.weekDays && (
                  <span className="event-form__error">{errors.weekDays}</span>
                )}
              </div>
            )}

            <div className="event-form__group">
              <label className="event-form__label">When does it end?</label>
              <RadioButtonGroup
                name="end-type"
                options={[
                  {
                    value: 'date',
                    label: 'Until a specific date',
                    content: formData.endType === 'date' && (
                      <DateInput
                        id="end-date"
                        name="end-date"
                        value={formData.endDate || ''}
                        onChange={handleInputChange('endDate')}
                        error={errors.endDate}
                        min={formData.startDate || ''}
                        max={getMaxEndDate()}
                        helper="💡 Tip: You can select a date up to 1 month after the start date"
                      />
                    )
                  },
                  {
                    value: 'occurrences',
                    label: 'After a number of weeks',
                    content: formData.endType === 'occurrences' && (
                      <NumberInput
                        id="occurrences"
                        name="occurrences"
                        value={formData.occurrences || 1}
                        onChange={handleOccurrencesChange}
                        error={errors.occurrences}
                        min={1}
                        max={3}
                        helper="💡 Tip: You can choose between 1 and 3 weeks"
                      />
                    )
                  }
                ]}
                onChange={handleEndTypeChange}
                defaultValue={formData.endType}
              />
            </div>
          </div>
        ) : (
          <div className="event-form__group event-form__group--row">
            <div className="event-form__date">
              <label className="event-form__label">Event Date</label>
              <DateInput
                id="event-date"
                name="event-date"
                value={formData.date || ''}
                onChange={handleInputChange('date')}
                error={errors.date}
              />
            </div>
            <div className="event-form__time-range">
              <label className="event-form__label">Event Time</label>
              <div className="event-form__time-inputs">
                <div className="event-form__time-from">
                  <span className="event-form__time-label">From</span>
                  <TimeInput
                    id="event-time"
                    name="event-time"
                    value={formData.time || ''}
                    onChange={handleInputChange('time')}
                    error={errors.time}
                  />
                </div>
                <div className="event-form__time-to">
                  <span className="event-form__time-label">to</span>
                  <TimeInput
                    id="event-end-time"
                    name="event-end-time"
                    value={formData.endTime || ''}
                    onChange={handleInputChange('endTime')}
                    error={errors.endTime}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </ContentBlock>
    </div>
  );
};

export default BasicInfoStep;
