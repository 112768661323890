import { useState } from 'react';

interface HiddenEvent {
  id: string;
  seriesId?: string;
  }

export const useHiddenEvents = () => {
  const [hiddenEvents, setHiddenEvents] = useState<HiddenEvent[]>([]);
  const [lastRemovedEvent, setLastRemovedEvent] = useState<HiddenEvent | null>(null);

  const addToHiddenEvents = (eventId: string, seriesId?: string) => {
   

    const newHiddenEvent = { id: eventId, seriesId};

    setHiddenEvents(prev => [...prev, newHiddenEvent]);
    setLastRemovedEvent(newHiddenEvent);
  };

  const undoLastRemoval = () => {
    if (lastRemovedEvent) {
      setHiddenEvents(prev => 
        prev.filter(event => 
          lastRemovedEvent.seriesId
            ? event.seriesId !== lastRemovedEvent.seriesId
            : event.id !== lastRemovedEvent.id
        )
      );
      setLastRemovedEvent(null);
    }
  };

  const isHidden = (eventId: string, seriesId?: string) => {
    return hiddenEvents.some(event => 
      seriesId 
        ? event.seriesId === seriesId 
        : event.id === eventId
    );
  };

  return {
    hiddenEvents: hiddenEvents.map(event => event.id),
    addToHiddenEvents,
    undoLastRemoval,
    isHidden
  };
}; 