import { useState, useEffect, useCallback } from 'react';

interface Location {
  latitude: number;
  longitude: number;
  cityName?: string;
  error?: string;
  shouldCenter?: boolean;
  source?: 'gps' | 'manual' | 'map';
}

interface CachedLocation {
  latitude: number;
  longitude: number;
  timestamp: number;
  source: 'gps' | 'manual' | 'map';
  accuracy?: number;
  cityName?: string;
}

export const useGeolocation = () => {
  const [location, setLocation] = useState<Location | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | undefined>();

  const getCityName = async (lat: number, lon: number) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}`
      );
      const data = await response.json();
      return data.address.city || data.address.town || data.address.village;
    } catch (error) {
      console.error('Erreur lors de la récupération de la ville:', error);
      return undefined;
    }
  };

  const updateFromMap = useCallback(async (lat: number, lon: number) => {
    console.log("🗺️ [Firefox Debug] updateFromMap called with:", { lat, lon });
    try {
      const cityName = await getCityName(lat, lon);
      const newLocation = {
        latitude: lat,
        longitude: lon,
        timestamp: Date.now(),
        source: 'map' as const,
        cityName
      };
      
      // Ne pas déclencher de recentrage pour les mises à jour manuelles
      setLocation({
        latitude: lat,
        longitude: lon,
        cityName,
        shouldCenter: false, // Toujours false pour les updates manuels
        source: 'map'
      });

      // Stocker sans déclencher d'événement storage
      const currentTab = Date.now().toString();
      sessionStorage.setItem('currentTab', currentTab);
      localStorage.setItem('userLocation_currentTab', currentTab);
      localStorage.setItem('userLocation', JSON.stringify(newLocation));
    } catch (error) {
      console.error('Error updating location from map:', error);
      setError('Could not update location from map');
    }
  }, []);

  useEffect(() => {
    // 1. Vérifier d'abord les données préchargées
    const preloadedData = window.__PRELOADED_DATA__;
    if (preloadedData?.location) {
      setLocation({
        ...preloadedData.location,
        source: 'gps',
        shouldCenter: true
      });
      setLoading(false);
      return;
    }

    // 2. Sinon, vérifier le cache
    const cachedLocation = localStorage.getItem('userLocation');
    if (cachedLocation) {
      try {
        const parsed: CachedLocation = JSON.parse(cachedLocation);
        const now = Date.now();
        
        if (now - parsed.timestamp < 24 * 60 * 60 * 1000) {
          setLocation({
            latitude: parsed.latitude,
            longitude: parsed.longitude,
            cityName: parsed.cityName,
            source: parsed.source,
            shouldCenter: true
          });
          setLoading(false);
          return;
        }
      } catch (e) {
        console.warn('Error parsing cached location:', e);
      }
    }

    // 3. Si pas de données préchargées ni de cache valide
    const timeoutId = setTimeout(() => {
      setError('Location request timed out');
      setLoading(false);
    }, 5000);

    navigator.geolocation.getCurrentPosition(
      (position) => {
        clearTimeout(timeoutId);
        const newLocation = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          source: 'gps' as const,
          shouldCenter: true
        };
        
        const cacheData: CachedLocation = {
          ...newLocation,
          timestamp: Date.now(),
          accuracy: position.coords.accuracy
        };
        localStorage.setItem('userLocation', JSON.stringify(cacheData));
        
        setLocation(newLocation);
        setLoading(false);
      },
      (error) => {
        clearTimeout(timeoutId);
        setError(error.message);
        setLoading(false);
      }
    );
  }, []);

  // Modifier l'effet de storage
  useEffect(() => {
    const currentTab = Date.now().toString();
    sessionStorage.setItem('currentTab', currentTab);

    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === 'userLocation' && event.newValue) {
        const sourceTab = localStorage.getItem('userLocation_currentTab');
        const thisTab = sessionStorage.getItem('currentTab');
        
        // Ignorer les événements du même onglet
        if (sourceTab === thisTab) {
          return;
        }
        
        const parsed: CachedLocation = JSON.parse(event.newValue);
        
        // Ne recentrer que pour les mises à jour GPS ou manuelles
        setLocation({
          latitude: parsed.latitude,
          longitude: parsed.longitude,
          cityName: parsed.cityName,
          shouldCenter: parsed.source !== 'map',
          source: parsed.source
        });
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  return { location, loading, error, updateFromMap };
};