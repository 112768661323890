import React, { useState } from 'react';
import { BottomNav } from '../patterns/BottomNav/BottomNav';
import { IconName } from '../primitives/Icon';
import StepIndicator from '../primitives/StepIndicator';
import Button from '../primitives/Button';

interface CodeSection {
  id: string;
  isCollapsed: boolean;
}

const NavigationSection: React.FC = () => {
  // États pour BottomNav
  const [viewMode, setViewMode] = useState<'list' | 'map'>('list');
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [favoriteCount, setFavoriteCount] = useState(3);

  // États pour StepIndicator
  const [currentStep, setCurrentStep] = useState(0);
  const steps = [
    { title: 'Information', description: 'Basic details' },
    { title: 'Location', description: 'Place & access' },
    { title: 'Confirmation', description: 'Final check' }
  ];

  const [collapsedSections, setCollapsedSections] = React.useState<CodeSection[]>([
    { id: 'bottomnav', isCollapsed: true },
    { id: 'stepIndicator', isCollapsed: true }
  ]);

  const toggleSection = (sectionId: string) => {
    setCollapsedSections(prev => prev.map(section => 
      section.id === sectionId 
        ? { ...section, isCollapsed: !section.isCollapsed }
        : section
    ));
  };

  const isSectionCollapsed = (sectionId: string) => {
    return collapsedSections.find(section => section.id === sectionId)?.isCollapsed ?? true;
  };

  const handleNextStep = () => {
    setCurrentStep(prev => Math.min(prev + 1, steps.length - 1));
  };

  const handlePrevStep = () => {
    setCurrentStep(prev => Math.max(prev - 1, 0));
  };

  const handleIncrementFavorites = () => {
    setFavoriteCount(prev => prev + 1);
  };

  const handleDecrementFavorites = () => {
    setFavoriteCount(prev => Math.max(0, prev - 1));
  };

  const navigationItems = [
    {
      icon: (viewMode === 'list' ? 'Map' : 'List') as IconName,
      label: viewMode === 'list' ? 'Map view' : 'List view',
      onClick: () => setViewMode(viewMode === 'list' ? 'map' : 'list'),
      viewMode: viewMode
    },
    {
      icon: 'Filter' as IconName,
      label: 'Filters',
      onClick: () => setIsFilterOpen(!isFilterOpen)
    },
    {
      icon: 'Heart' as IconName,
      label: 'Favorites',
      onClick: () => console.log('Favorites clicked'),
      badge: favoriteCount
    },
    {
      icon: 'Menu' as IconName,
      label: 'Menu',
      onClick: () => setIsMenuOpen(!isMenuOpen)
    }
  ];

  return (
    <section className="component-section">
      {/* Bottom Navigation */}
      <div className="navigation-showcase">
        <div className="navigation-type-container">
          <div className="navigation-group">
            <h4>Bottom Navigation</h4>
            <p className="navigation-description">
              Floating navigation bar for the main app navigation.
              Mobile-friendly with safe areas support.
            </p>
            <div className="navigation-preview" style={{ 
              position: 'relative',
              height: '400px',
              border: '1px solid var(--border-color)',
              borderRadius: 'var(--radius-lg)',
              overflow: 'hidden',
              background: 'var(--background)',
              isolation: 'isolate',
              '--bottom-nav-position': 'absolute'
            } as React.CSSProperties}>
              <div style={{
                position: 'absolute',
                top: '20px',
                left: '50%',
                transform: 'translateX(-50%)',
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
                padding: '8px 16px',
                background: 'var(--background-light)',
                borderRadius: 'var(--radius-md)',
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                zIndex: 10
              }}>
                <span style={{ fontSize: 'var(--font-size-sm)', color: 'var(--text-secondary)' }}>
                  Test Favorites Badge:
                </span>
                <Button 
                  variant="outline" 
                  onClick={handleDecrementFavorites}
                  style={{ padding: '4px 8px', minWidth: '32px', cursor: 'pointer' }}
                >
                  -
                </Button>
                <span style={{ 
                  minWidth: '24px', 
                  textAlign: 'center',
                  color: 'var(--text-primary)'
                }}>
                  {favoriteCount}
                </span>
                <Button 
                  variant="outline" 
                  onClick={handleIncrementFavorites}
                  style={{ padding: '4px 8px', minWidth: '32px', cursor: 'pointer' }}
                >
                  +
                </Button>
              </div>
              
              <div style={{ 
                position: 'relative',
                height: '100%',
                background: 'transparent',
                borderRadius: 'var(--radius-md)',
                overflow: 'hidden'
              }}>
                <div style={{ 
                  position: 'absolute',
                  bottom: '20px',
                  left: '0',
                  right: '0',
                  padding: '0 16px'
                }}>
                  <BottomNav items={navigationItems} />
                </div>
              </div>
            </div>
          </div>
          <div className="component-code">
            <div className={`code-example ${isSectionCollapsed('bottomnav') ? 'collapsed' : ''}`}>
              <h3 onClick={() => toggleSection('bottomnav')}>Code</h3>
              <pre>
{`const navigationItems = [
  {
    icon: viewMode === 'list' ? 'Map' : 'List',
    label: viewMode === 'list' ? 'Map view' : 'List view',
    onClick: () => setViewMode(viewMode === 'list' ? 'map' : 'list'),
    viewMode: viewMode
  },
  {
    icon: 'Filter',
    label: 'Filters',
    onClick: () => setIsFilterOpen(!isFilterOpen)
  },
  {
    icon: 'Heart',
    label: 'Favorites',
    onClick: () => console.log('Favorites clicked'),
    badge: favoriteCount
  },
  {
    icon: 'Menu',
    label: 'Menu',
    onClick: () => setIsMenuOpen(!isMenuOpen)
  }
];`}
              </pre>
            </div>
          </div>
        </div>
      </div>

      {/* Step Indicator */}
      <div className="control-type-container">
        <div className="control-group">
          <h4>Step Indicator</h4>
          <p className="control-description">
            Interactive progress indicator to guide users through a multi-step process.
          </p>
          <div className="control-preview">
            <StepIndicator steps={steps} currentStep={currentStep} />
            <div style={{ marginTop: '2rem', display: 'flex', gap: '1rem', justifyContent: 'center' }}>
              <Button variant="outline" onClick={handlePrevStep} disabled={currentStep === 0}>Previous</Button>
              <Button onClick={handleNextStep} disabled={currentStep === steps.length - 1}>Next</Button>
            </div>
          </div>
        </div>
        <div className="component-code">
          <div className={`code-example ${isSectionCollapsed('stepIndicator') ? 'collapsed' : ''}`}>
            <h3 onClick={() => toggleSection('stepIndicator')}>Code</h3>
            <pre>
{`const steps = [
  { title: 'Information', description: 'Basic details' },
  { title: 'Location', description: 'Place & access' },
  { title: 'Confirmation', description: 'Final check' }
];

<StepIndicator 
  steps={steps}
  currentStep={currentStep}
/>`}
            </pre>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NavigationSection; 