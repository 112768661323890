import React from 'react';
import { FilterSectionProps } from './index';
import './FilterDrawer.css';

const FilterSection: React.FC<FilterSectionProps> = ({ title, icon, children }) => {
  return (
    <div className="filter-section">
      <div className="filter-section-header">
        <h3 className="filter-section-title">
          {icon}
          <span>{title}</span>
        </h3>
      </div>
      <div className="filter-section-content">
        {children}
      </div>
    </div>
  );
};

export default FilterSection;
