import React from 'react';
import { testIndexes } from '../../firebase/testIndexes';

interface IndexTesterProps {
  isVisible?: boolean;
}

const IndexTester: React.FC<IndexTesterProps> = ({ isVisible = false }) => {
  const handleTest = async () => {
    try {
      await testIndexes();
    } catch (error) {
      console.error('Erreur lors du test des index:', error);
    }
  };

  if (!isVisible) return null;

  return (
    <div style={{ padding: '20px' }}>
      <h2>Test des Index Firestore</h2>
      <button 
        onClick={handleTest}
        style={{
          padding: '10px 20px',
          backgroundColor: '#4CAF50',
          color: 'white',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer'
        }}
      >
        Lancer les tests d'index
      </button>
      <p>
        👉 Regardez la console Firebase pour voir les index manquants à créer
      </p>
    </div>
  );
}

export default IndexTester; 