import React, { useState, useEffect } from 'react';
import { 
  ContentBlock, 
  Icon,
  Button
} from '../../../pages/DesignSystem';
import { Event } from '../../../types/event.types';
import EventCard from '../../../components/event/card';
import RecurringEventCard from '../../../components/event/RecurringEventCard';
import { isEventPast } from '../../../utils/eventUtils';
import { eventService } from '../../../firebase/event.service';
import './ProfileEvents.css';

interface ProfileEventsProps {
  organizerId?: string;
}

const ProfileEvents: React.FC<ProfileEventsProps> = ({ organizerId }) => {
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState<Event[]>([]);
  const [seriesEvents, setSeriesEvents] = useState<Record<string, Event[]>>({});
  
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        setLoading(true);
        
        if (!organizerId) {
          setLoading(false);
          return;
        }
        
        // Utiliser la nouvelle méthode du service pour récupérer les événements
        const organizerEvents = await eventService.getEventsByOrganizerId(organizerId, true);
        
        // Organiser les événements en série
        const seriesMap: Record<string, Event[]> = {};
        organizerEvents.forEach(event => {
          if (event.seriesId) {
            if (!seriesMap[event.seriesId]) {
              seriesMap[event.seriesId] = [];
            }
            seriesMap[event.seriesId].push(event);
          }
        });
        
        setEvents(organizerEvents);
        setSeriesEvents(seriesMap);
        setLoading(false);
      } catch (error) {
        console.error('Error loading events:', error);
        setLoading(false);
      }
    };
    
    if (organizerId) {
      fetchEvents();
    } else {
      setLoading(false);
    }
  }, [organizerId]);
  
  // On n'a plus besoin de filtrer les événements passés car notre requête Firestore
  // ne retourne que les événements à venir, mais on garde l'organisation en séries
  
  // Filtrer les événements uniques (sans série)
  const uniqueEvents = events.filter(event => !event.seriesId);
  
  // Obtenir les IDs de série
  const seriesIds = Object.keys(seriesEvents);
  const hasUpcomingEvents = uniqueEvents.length > 0 || seriesIds.length > 0;

  return (
    <div className="profile-events">
      <ContentBlock>
        <div className="profile-events__header">
          <h2 className="profile-events__title">Upcoming Events</h2>
        </div>
        
        {/* Event list */}
        <div className="profile-events__content-area">
          {loading ? (
            <div className="profile-events__loading">
              <Icon name="Loader" size={24} />
              <span>Loading events...</span>
            </div>
          ) : hasUpcomingEvents ? (
            <div className="profile-events__grid">
              {/* Événements en série */}
              {seriesIds.map(seriesId => {
                const seriesEventsList = seriesEvents[seriesId] || [];
                if (seriesEventsList.length === 0) return null;
                
                // Utiliser le premier événement comme référence
                const mainEvent = seriesEventsList[0];
                
                return (
                  <RecurringEventCard 
                    key={seriesId}
                    mainEvent={mainEvent}
                    seriesEvents={seriesEventsList}
                    disableSwipe={true}
                  />
                );
              })}
              
              {/* Événements uniques */}
              {uniqueEvents.map(event => (
                <EventCard 
                  key={event.id} 
                  event={event}
                  disableSwipe={true}
                />
              ))}
            </div>
          ) : (
            <div className="profile-events__empty">
              <Icon name="Calendar" size={32} />
              <p>No upcoming events</p>
              <p className="profile-events__empty-subtitle">
                This organizer doesn't have any upcoming events at the moment.
              </p>
              <Button 
                variant="outline" 
                size="sm"
                onClick={() => window.location.href = '/'}
              >
                Explore other events
              </Button>
            </div>
          )}
        </div>
      </ContentBlock>
    </div>
  );
};

export default ProfileEvents; 