import React, { useState } from 'react';
import { Button, FavoriteButton, ShareButton } from '../index';
import { useRandomMonkey } from '../../../hooks/useRandomMonkey';

interface CodeSection {
  title: string;
  isCollapsed: boolean;
}

const ButtonSection: React.FC = () => {
  const [collapsedSections, setCollapsedSections] = useState<CodeSection[]>([
    { title: 'primary', isCollapsed: true },
    { title: 'secondary', isCollapsed: true },
    { title: 'outline', isCollapsed: true },
    { title: 'ghost', isCollapsed: true },
    { title: 'danger', isCollapsed: true },
    { title: 'favorite', isCollapsed: true },
    { title: 'share', isCollapsed: true }
  ]);

  const toggleSection = (sectionId: string) => {
    setCollapsedSections(prev => prev.map(section => 
      section.title === sectionId 
        ? { ...section, isCollapsed: !section.isCollapsed }
        : section
    ));
  };

  const isSectionCollapsed = (sectionId: string) => {
    return collapsedSections.find(section => section.title === sectionId)?.isCollapsed ?? true;
  };

  const { getMonkeyProps } = useRandomMonkey({
    mode: 'atLeastOne',
   
    availablePositions: [0, 1, 2, 3, 4, 5]  // Un index pour chaque groupe de boutons
  });

  return (
    <section className="component-section">
      <p className="section-description">
        Les boutons permettent aux utilisateurs d'effectuer des actions et de faire des choix en un seul clic.
      </p>

      <div className="buttons-showcase">
        {/* Primary Buttons */}
        <div className="button-type-container">
          <div className="button-group">
            <h4>Primary Button</h4>
            <p className="button-description">
              Le bouton principal, utilisé pour les actions principales et les plus importantes.
            </p>
            <div className="button-row">
              <div className="button-sizes">
                <Button size="sm" {...getMonkeyProps(3, false)}>Small</Button>
                <Button>Medium</Button>
                <Button size="lg" {...getMonkeyProps(0, false)}>Large</Button>
              </div>
              <div className="button-disabled">
                <Button disabled>Disabled</Button>
              </div>
            </div>
          </div>
          <div className="component-code">
            <div className={`code-example ${isSectionCollapsed('primary') ? 'collapsed' : ''}`}>
              <h3 onClick={() => toggleSection('primary')}>Code</h3>
              <pre>
{`// Primary Button
<Button size="sm">Small</Button>
<Button>Medium</Button>
<Button size="lg">Large</Button>
<Button disabled>Disabled</Button>`}
              </pre>
            </div>
          </div>
        </div>

        {/* Secondary Buttons */}
        <div className="button-type-container">
          <div className="button-group">
            <h4>Secondary Button</h4>
            <p className="button-description">
              Le bouton secondaire, pour les actions importantes mais moins prioritaires.
            </p>
            <div className="button-row">
              <div className="button-sizes">
                <Button variant="secondary" size="sm">Small</Button>
                <Button variant="secondary" size="md" {...getMonkeyProps(2, false)}>Medium</Button>
                <Button variant="secondary" size="lg" {...getMonkeyProps(1, false)}>Large</Button>
              </div>
              <div className="button-disabled">
                <Button variant="secondary" disabled>Disabled</Button>
              </div>
            </div>
          </div>
          <div className="component-code">
            <div className={`code-example ${isSectionCollapsed('secondary') ? 'collapsed' : ''}`}>
              <h3 onClick={() => toggleSection('secondary')}>Code</h3>
              <pre>
{`// Secondary Button
<Button variant="secondary" size="sm">Small</Button>
<Button variant="secondary">Medium</Button>
<Button variant="secondary" size="lg">Large</Button>
<Button variant="secondary" disabled>Disabled</Button>`}
              </pre>
            </div>
          </div>
        </div>

        {/* Outline Buttons */}
        <div className="button-type-container">
          <div className="button-group">
            <h4>Outline Button</h4>
            <p className="button-description">
              Bouton avec contour, idéal pour les actions secondaires ou dans un contexte où la subtilité est importante.
            </p>
            <div className="button-row">
              <div className="button-sizes">
                <Button variant="outline" size="sm" {...getMonkeyProps(5, false)}>Small</Button>
                <Button variant="outline">Medium</Button>
                <Button variant="outline" size="lg" {...getMonkeyProps(4, false)}>Large</Button>
              </div>
              <div className="button-disabled">
                <Button variant="outline" disabled>Disabled</Button>
              </div>
            </div>
          </div>
          <div className="component-code">
            <div className={`code-example ${isSectionCollapsed('outline') ? 'collapsed' : ''}`}>
              <h3 onClick={() => toggleSection('outline')}>Code</h3>
              <pre>
{`// Outline Button
<Button variant="outline" size="sm">Small</Button>
<Button variant="outline">Medium</Button>
<Button variant="outline" size="lg">Large</Button>
<Button variant="outline" disabled>Disabled</Button>`}
              </pre>
            </div>
          </div>
        </div>

        {/* Ghost Buttons */}
        <div className="button-type-container">
          <div className="button-group">
            <h4>Ghost Button</h4>
            <p className="button-description">
              Bouton discret sans fond, parfait pour les actions tertiaires ou les interfaces épurées.
            </p>
            <div className="button-row">
              <div className="button-sizes">
                <Button variant="ghost" size="sm">Small</Button>
                <Button variant="ghost">Medium</Button>
                <Button variant="ghost" size="lg">Large</Button>
              </div>
              <div className="button-disabled">
                <Button variant="ghost" disabled>Disabled</Button>
              </div>
            </div>
          </div>
          <div className="component-code">
            <div className={`code-example ${isSectionCollapsed('ghost') ? 'collapsed' : ''}`}>
              <h3 onClick={() => toggleSection('ghost')}>Code</h3>
              <pre>
{`// Ghost Button
<Button variant="ghost" size="sm">Small</Button>
<Button variant="ghost">Medium</Button>
<Button variant="ghost" size="lg">Large</Button>
<Button variant="ghost" disabled>Disabled</Button>`}
              </pre>
            </div>
          </div>
        </div>

        {/* Danger Buttons */}
        <div className="button-type-container">
          <div className="button-group">
            <h4>Danger Button</h4>
            <p className="button-description">
              Bouton pour les actions destructives ou dangereuses qui nécessitent une attention particulière.
            </p>
            <div className="button-row">
              <div className="button-sizes">
                <Button variant="danger" size="sm">Small</Button>
                <Button variant="danger">Medium</Button>
                <Button variant="danger" size="lg">Large</Button>
              </div>
              <div className="button-disabled">
                <Button variant="danger" disabled>Disabled</Button>
              </div>
            </div>
          </div>
          <div className="component-code">
            <div className={`code-example ${isSectionCollapsed('danger') ? 'collapsed' : ''}`}>
              <h3 onClick={() => toggleSection('danger')}>Code</h3>
              <pre>
{`// Danger Button
<Button variant="danger" size="sm">Small</Button>
<Button variant="danger">Medium</Button>
<Button variant="danger" size="lg">Large</Button>
<Button variant="danger" disabled>Disabled</Button>`}
              </pre>
            </div>
          </div>
        </div>

        {/* Favorite Button */}
        <div className="button-type-container">
          <div className="button-group">
            <h4>Favorite Button</h4>
            <p className="button-description">
              Bouton en forme de cœur pour marquer les éléments comme favoris. Supporte la persistance dans le localStorage.
            </p>
            <div className="button-row">
              <div className="button-sizes">
                <FavoriteButton id="demo-small" size="sm" />
                <FavoriteButton id="demo-medium" />
                <FavoriteButton id="demo-large" size="lg" />
              </div>
              <div className="button-states">
                <FavoriteButton id="demo-active" expiresAt={new Date(Date.now() + 86400000)} />
                <FavoriteButton id="demo-interactive" />
              </div>
              <div className="button-variants">
                <FavoriteButton 
                  id="demo-button-variant" 
                  variant="button" 
                  size="lg"
                />
              </div>
            </div>
          </div>
          <div className="component-code">
            <div className={`code-example ${isSectionCollapsed('favorite') ? 'collapsed' : ''}`}>
              <h3 onClick={() => toggleSection('favorite')}>Code</h3>
              <pre>
{`// Favorite Button - Tailles (Icon variant)
<FavoriteButton id="event-123" size="sm" />
<FavoriteButton id="event-456" />  {/* size="md" par défaut */}
<FavoriteButton id="event-789" size="lg" />

// État actif (expire dans 24h)
<FavoriteButton id="event-active" expiresAt={new Date(Date.now() + 86400000)} />

// Variante bouton
<FavoriteButton 
  id="event-123"
  variant="button"
  size="lg"
  onFavoriteChange={(isFavorite) => console.log('Favori:', isFavorite)}
/>

// Avec persistance localStorage (automatique)
<FavoriteButton 
  id="event-123"
  onFavoriteChange={(isFavorite) => console.log('Favori:', isFavorite)}
/>`}
              </pre>
            </div>
          </div>
        </div>

        {/* Share Button */}
        <div className="button-type-container">
          <div className="button-group">
            <h4>Share Button</h4>
            <p className="button-description">
              Bouton de partage qui s'adapte au contexte (mobile/desktop) et utilise les API natives quand disponibles.
            </p>
            <div className="button-row">
              <div className="button-sizes">
                <ShareButton
                  size="sm"
                  shareData={{
                    title: "Event Connect",
                    text: "Découvrez cet événement incroyable !",
                    url: window.location.href
                  }}
                  onShareSuccess={() => console.log('Partagé avec succès')}
                />
                <ShareButton
                  shareData={{
                    title: "Event Connect",
                    text: "Découvrez cet événement incroyable !",
                    url: window.location.href
                  }}
                />
                <ShareButton
                  size="lg"
                  shareData={{
                    title: "Event Connect",
                    text: "Découvrez cet événement incroyable !",
                    url: window.location.href
                  }}
                />
              </div>
              <div className="button-variants">
                <ShareButton
                  variant="button"
                  shareData={{
                    title: "Event Connect",
                    text: "Découvrez cet événement incroyable !",
                    url: window.location.href
                  }}
                />
                <ShareButton
                  variant="icon"
                  shareData={{
                    title: "Event Connect",
                    text: "Découvrez cet événement incroyable !",
                    url: window.location.href
                  }}
                />
              </div>
            </div>
          </div>
          <div className="component-code">
            <div className={`code-example ${isSectionCollapsed('share') ? 'collapsed' : ''}`}>
              <h3 onClick={() => toggleSection('share')}>Code</h3>
              <pre>
{`// Share Button - Tailles (Icon variant par défaut)
<ShareButton
  size="sm"
  shareData={{
    title: "Event Connect",
    text: "Découvrez cet événement incroyable !",
    url: window.location.href
  }}
  onShareSuccess={() => console.log('Partagé avec succès')}
/>

// Variantes
<ShareButton
  variant="button"
  shareData={{
    title: "Event Connect",
    text: "Découvrez cet événement incroyable !",
    url: window.location.href
  }}
/>

<ShareButton
  variant="icon"
  shareData={{
    title: "Event Connect",
    text: "Découvrez cet événement incroyable !",
    url: window.location.href
  }}
/>`}
              </pre>
            </div>
          </div>
        </div>

      </div>
    </section>
  );
};

export default ButtonSection;