import React, { useState } from 'react';
import '../../../styles/components/multi-select-toggle.css';

interface Option {
    id: string;
    label: string;
    disabled?: boolean;
}

interface MultiSelectToggleProps {
    options: Option[];
    selectedValues: string[];
    onChange: (selectedIds: string[]) => void;
    disabled?: boolean;
}

const MultiSelectToggle: React.FC<MultiSelectToggleProps> = ({
    options,
    selectedValues,
    onChange,
    disabled = false
}) => {
    const handleToggle = (id: string) => {
        const newSelection = selectedValues.includes(id)
            ? selectedValues.filter(value => value !== id)
            : [...selectedValues, id];
        onChange(newSelection);
    };

    return (
        <div className="multi-select-group">
            {options.map(option => (
                <button
                    key={option.id}
                    className={`multi-select-button ${selectedValues.includes(option.id) ? 'active' : ''}`}
                    onClick={() => handleToggle(option.id)}
                    disabled={disabled || option.disabled}
                >
                    {option.label}
                </button>
            ))}
        </div>
    );
};

export default MultiSelectToggle;