/// <reference types="@types/google.maps" />

import React, { useState, useEffect } from 'react';
import { Event } from '../../../types/event.types';
import { Autocomplete } from '@react-google-maps/api';
import { StepIndicator, Icon, Button, ContentBlock } from '../../../pages/DesignSystem';
import BasicInfoStep from './steps/BasicInfoStep';
import DetailsStep from './steps/DetailsStep';
import { useOrganizer } from '../../../contexts/OrganizerContext';
import PreviewStep from './steps/PreviewStep';
import { cloudinaryService } from '../../../services/cloudinary.service';
import { FormData, initialFormData } from './types';
import { handleRecurringEvent, updateRecurringEvent } from './handleRecurringEvent';
import { cleanEventData } from '../../../utils/eventUtils';
import './EventForm.css';
import { toast } from 'react-hot-toast';
import { eventSeriesService } from '../../../firebase/eventSeries.service';
import { draftSuccessMessages, publishSuccessMessages, getRandomToastMessage } from '../../../components/messages/toastMessages';
import { GOOGLE_MAPS_LIBRARIES } from '../../../utils/googleMapsUtils';
import { useGoogleMaps } from '../../../contexts/GoogleMapsContext';

interface EventFormProps {
  event?: Event & { seriesId?: string };  // Ajout de seriesId pour les événements récurrents
  organizerId: string; 
  onSubmit: (eventData: Partial<Omit<Event, 'id'>> & { id?: string }) => void; 
  onClose: () => void;
}

const FORM_STEPS = [
  {
    title: "Basic Info",
    description: "Title, date and location"
  },
  {
    title: "Details",
    description: "Description, price and tags"
  },
  {
    title: "Review",
    description: "Preview and publish"
  }
];

const EventForm: React.FC<EventFormProps> = ({ event, organizerId, onSubmit, onClose }) => {
  const { organizerProfile } = useOrganizer();
  const [currentStep, setCurrentStep] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState<Partial<Record<keyof FormData, string>>>({});
  const [showLocationNameInput, setShowLocationNameInput] = useState(false);
  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);

  const formatDateForInput = (datetime: Date | undefined | null): { date: string, time: string } => {
    if (!datetime) return { date: '', time: '' };
    try {
      const d = new Date(datetime);
      if (isNaN(d.getTime())) return { date: '', time: '' };
      
      // Format YYYY-MM-DD pour input date
      const date = d.toISOString().split('T')[0];
      
      // Format HH:MM pour input time
      const hours = String(d.getHours()).padStart(2, '0');
      const minutes = String(d.getMinutes()).padStart(2, '0');
      const time = `${hours}:${minutes}`;
      
      return { date, time };
    } catch {
      return { date: '', time: '' };
    }
  };

  const [formData, setFormData] = useState<FormData>({
    ...initialFormData,
    organizerId: organizerId,
    organizerContact: event?.organizerContact || organizerProfile?.organizerContact || '',
    location: event?.location || organizerProfile?.location || '',
    locationName: event?.locationName || organizerProfile?.locationName || '', 
    latitude: event?.latitude || organizerProfile?.latitude || 0,
    longitude: event?.longitude || organizerProfile?.longitude || 0,
    ...(event && {
      ...event,
      ...(event.datetime && {
        ...formatDateForInput(event.datetime)
      })
    })
  });

  useEffect(() => {
    // Charger correctement les données d'un événement existant
    if (event) {
      console.log('Chargement d\'un événement existant:', event);
      
      let initialData: FormData = {
        ...initialFormData,
        organizerId: organizerId,
        organizerContact: event.organizerContact || organizerProfile?.organizerContact || '',
        location: event.location || '',
        locationName: event.locationName || '',
        latitude: event.latitude || 0,
        longitude: event.longitude || 0,
        title: event.title || '',
        description: event.description || '',
        tags: event.tags || [],
        prices: event.prices || [],
        ticketingUrl: event.ticketingUrl || '',
        image: event.image || '',
        status: event.status || 'draft',
        id: event.id
      };
      
      // Traitement spécifique selon le type d'événement (récurrent ou non)
      if (event.seriesId) {
        // Événement récurrent
        console.log('Événement récurrent détecté avec seriesId:', event.seriesId);
        
        // Charger les données de la série complète
        const loadSeriesData = async () => {
          try {
            const seriesData = await eventSeriesService.getEventSeriesById(event.seriesId as string);
            console.log('Données de série chargées:', seriesData);
            
            if (seriesData) {
              initialData = {
                ...initialData,
                isRecurring: true,
                startDate: seriesData.startDate || '',
                startTime: seriesData.time || '',
                endTime: seriesData.endTime || '',
                frequency: seriesData.frequency || 'weekly',
                weekDays: seriesData.weekDays || [],
                endType: seriesData.endType || 'date',
                endDate: seriesData.endDate || '',
                occurrences: seriesData.occurrences
              };
              
              setFormData(initialData);
            }
          } catch (error) {
            console.error('Erreur lors du chargement des données de série:', error);
            toast.error('Impossible de charger toutes les informations de l\'événement récurrent');
          }
        };
        
        loadSeriesData();
      } else if (event.datetime) {
        // Événement ponctuel
        const { date, time } = formatDateForInput(event.datetime);
        initialData = {
          ...initialData,
          isRecurring: false,
          date,
          time,
          endTime: event.endTime || ''
        };
        
        setFormData(initialData);
      }
    }
  }, [event, organizerId, organizerProfile]);

  const validateForPublication = (): { valid: boolean, errors: Record<string, string> } => {
    const newErrors: Record<string, string> = {};
    
    // Validation pour tous les événements avec des messages amicaux
    if (!formData.title) newErrors.title = 'Your event needs a catchy title! ✍️';
    if (!formData.description) newErrors.description = 'Add a description to get people excited! 📝';
    if (!formData.location) newErrors.location = 'Where is your event happening? 📍';
    if (!formData.locationName) newErrors.locationName = 'Give your venue a name people will remember! 🏢';
    
    // Validation spécifique au type d'événement
    if (formData.isRecurring) {
      if (!formData.startDate) newErrors.startDate = 'When does your event series begin? 📅';
      if (!formData.startTime) newErrors.startTime = 'What time do your events start? ⏰';
      if (!formData.endTime) newErrors.endTime = 'When do your events end? This is required! ⌛';
      if (formData.frequency === 'weekly' && (!formData.weekDays || formData.weekDays.length === 0)) {
        newErrors.weekDays = 'Which days of the week will your event happen? 🗓️';
      }
      if (formData.endType === 'date' && !formData.endDate) {
        newErrors.endDate = 'When does your event series end? 📅';
      }
      if (formData.endType === 'occurrences' && (!formData.occurrences || formData.occurrences < 1)) {
        newErrors.occurrences = 'How many times will your event repeat? 🔄';
      }
    } else {
      if (!formData.date) newErrors.date = 'Pick a date for your event! 📅';
      if (!formData.time) newErrors.time = 'What time does your event start? ⏰';
      if (!formData.endTime) newErrors.endTime = 'When does your event end? ⌛';
    }
    
    return { 
      valid: Object.keys(newErrors).length === 0,
      errors: newErrors
    };
  };

  const handleSubmit = async (e: React.MouseEvent, isDraft: boolean) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    try {
      // Validation selon le mode (brouillon ou publication)
      if (isDraft) {
        // Validation minimale pour brouillon
        if (!formData.title) {
          setErrors({ title: 'Title is required even for a draft' });
          setIsSubmitting(false);
          return;
        }
      } else {
        // Validation complète pour publication
        const validation = validateForPublication();
        if (!validation.valid) {
          setErrors(validation.errors);
          // Montrer un message d'erreur explicatif
          toast.error('Some information is missing to publish the event');
          // Faire défiler jusqu'à la première erreur
          const firstErrorField = document.querySelector('[aria-invalid="true"]');
          if (firstErrorField) {
            firstErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
          setIsSubmitting(false);
          return;
        }
      }
      
      let finalImageUrl = formData.image || '';
      if (finalImageUrl && (finalImageUrl.startsWith('blob:') || finalImageUrl.startsWith('data:'))) {
        try {
          const response = await fetch(finalImageUrl);
          const blob = await response.blob();
          const file = new File([blob], 'image.jpg', { type: blob.type });
          finalImageUrl = await cloudinaryService.uploadImage(file);
        } catch (error) {
          console.error('Erreur lors de l\'upload de l\'image:', error);
          finalImageUrl = '';
        }
      }

      const updatedFormData = {
        ...formData,
        image: finalImageUrl,
        status: isDraft ? 'draft' as const : 'active' as const
      };

      console.log('FormData mis à jour:', updatedFormData);

      if (formData.isRecurring) {
        // Gestion des événements récurrents
        if (event?.seriesId) {
          // Modification d'un événement récurrent existant
          const updateAll = window.confirm(
            'Do you want to apply these changes to all events in the series?'
          );
          await updateRecurringEvent(event.seriesId, updatedFormData, updateAll);
        } else {
          // Création d'une nouvelle série d'événements
          await handleRecurringEvent(updatedFormData);
        }
      } else {
        // Événement unique
        let eventDateTime = undefined;
        if (formData.date) {
          const [year, month, day] = formData.date.split('-').map(Number);
          const [hours, minutes] = formData.time ? formData.time.split(':').map(Number) : [0, 0];
          eventDateTime = new Date(year, month - 1, day, hours, minutes);
        }

        try {
          // Nettoyer les données avant la soumission
          const cleanedData = cleanEventData(updatedFormData);
          console.log('Données nettoyées:', cleanedData);

          const submissionData = {
            ...cleanedData,
            datetime: eventDateTime,
            endTime: formData.endTime, 
            createdAt: new Date(),
            updatedAt: new Date(),
            ...(event?.id && { id: event.id })
          };

          console.log('Données finales à soumettre:', submissionData);
          await onSubmit(submissionData);

          showSuccessToast(isDraft);
        } catch (error) {
          console.error('Erreur lors du nettoyage ou de la soumission:', error);
          throw error;
        }
      }

      onClose();
    } catch (error) {
      console.error('Erreur lors de la soumission:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleNext = () => {
    console.log("Bouton Next cliqué");
    
    // Validation simple : vérifier uniquement le titre
    if (!formData.title || formData.title.trim() === '') {
      console.log("Validation échouée : titre manquant");
      setErrors({ title: 'Your event needs a catchy title before we continue! ✍️' });
      
      // Faire défiler jusqu'au champ titre
      const titleField = document.querySelector('[name="title"]');
      if (titleField) {
        titleField.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      
      toast.error('Please add a title to continue', {
        icon: '✏️',
        style: {
          borderRadius: '10px',
          background: '#FFF8E1',
          color: '#333',
        },
      });
      return;
    }
    
    // Si la validation passe, passer à l'étape suivante
    console.log(`Passage de l'étape ${currentStep} à l'étape ${currentStep + 1}`);
    setCurrentStep(prevStep => prevStep + 1);
  };

  const handlePrevious = () => {
    setCurrentStep(prev => Math.max(prev - 1, 0));
  };

  const validateContact = (contact: string): boolean => {
    if (!contact) return true;
  
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\+?[\d\s-]{10,}$/;
    const instaRegex = /^@[a-zA-Z0-9._]{1,30}$/;
    const whatsappRegex = /^(wa\.me\/|whatsapp:\/\/)?(\+[\d\s-]{8,})$/;
    
    return emailRegex.test(contact) || 
           phoneRegex.test(contact) || 
           instaRegex.test(contact) || 
           whatsappRegex.test(contact);
  };

  const handleSaveAsDraft = (e: React.MouseEvent) => handleSubmit(e, true);
  const handlePublish = async () => {
    console.log("🔍 handlePublish commencé, validation...");
    setIsSubmitting(true);
    
    // Vérifiez si tous les champs requis sont remplis
    const validation = validateForPublication();
    
    console.log("🔍 Erreurs de validation:", validation.errors);
    
    if (!validation.valid) {
      console.error("⚠️ Validation échouée:", validation.errors);
      setErrors(validation.errors);
      
      // Faire défiler jusqu'au premier champ en erreur
      const firstErrorField = document.querySelector('[aria-invalid="true"]');
      if (firstErrorField) {
        firstErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      
      // Créer un message détaillé avec la liste des informations manquantes
      const missingFields = Object.entries(validation.errors)
        .map(([field, message]) => {
          // Convertir le nom du champ en texte plus lisible
          const fieldNameMap: Record<string, string> = {
            title: "Title",
            description: "Description",
            location: "Location",
            locationName: "Venue name",
            date: "Date",
            time: "Start time",
            endTime: "End time",
            startDate: "Series start date",
            startTime: "Series start time",
            weekDays: "Days of the week",
            image: "Event image"
          };
          
          return `${fieldNameMap[field] || field}`;
        });
      
      // Afficher le toast avec la liste des informations manquantes
      toast.error(
        <div style={{ padding: '4px 0' }}>
          <div style={{ 
            marginBottom: '10px',
            display: 'flex',
            alignItems: 'center',
            gap: '8px'
          }}>
            <span style={{ fontSize: '18px' }}>✨</span>
            <h4 style={{ 
              margin: 0, 
              fontWeight: '600',
              fontSize: '15px'
            }}>
              Almost there! Your event needs a few more details:
            </h4>
          </div>
          <ul style={{ 
            margin: '0 0 12px 0',
            paddingLeft: '24px',
            listStyleType: 'circle'
          }}>
            {missingFields.map((field, index) => (
              <li key={index} style={{ 
                padding: '3px 0',
                fontSize: '14px'
              }}>
                {field}
              </li>
            ))}
          </ul>
          <div style={{
            fontSize: '13px',
            fontStyle: 'italic',
            background: 'rgba(255,255,255,0.5)',
            padding: '8px 10px',
            borderRadius: '8px',
            marginTop: '4px',
            display: 'flex',
            alignItems: 'center',
            gap: '6px'
          }}>
            <span style={{ fontSize: '14px' }}>💡</span>
            <span>You can also save as draft and complete it later!</span>
          </div>
        </div>,
        {
          duration: 6000,
          style: {
            borderRadius: '12px',
            background: 'linear-gradient(to bottom, #FFF8E1, #FFFCF2)',
            color: '#664500',
            boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
            padding: '12px 16px',
            maxWidth: '350px'
          },
          icon: '📝',
        }
      );
      
      setIsSubmitting(false);
      return;
    }
    
    try {
      console.log("🔍 Préparation des données pour soumission:", {
        isRecurring: formData.isRecurring,
        hasSeriesId: !!event?.seriesId
      });
      
      // Dédupliquer les prix avant soumission
      const uniquePrices: {amount: number; currency: string; description: string}[] = [];
      const seen = new Set();
      
      for (const price of formData.prices || []) {
        const priceKey = `${price.amount}-${price.currency}`;
        
        if (!seen.has(priceKey)) {
          seen.add(priceKey);
          uniquePrices.push({
            amount: typeof price.amount === 'string' ? parseFloat(price.amount) : price.amount,
            currency: price.currency,
            description: price.description || 'Standard price'
          });
        }
      }
      
      // Modifier directement formData
      formData.prices = uniquePrices;
      
      // Forcer le statut à 'active' pour la publication
      const publishData = {
        ...formData,
        status: 'active' as 'active'
      };
      
      // Appel de la fonction de soumission
      if (formData.isRecurring) {
        console.log("🔍 Traitement d'un événement récurrent");
        // Traiter comme événement récurrent
        if (event?.seriesId) {
          console.log("🔍 Mise à jour de série existante:", event.seriesId);
          await updateRecurringEvent(event.seriesId, publishData);
        } else {
          console.log("🔍 Création d'une nouvelle série");
          console.log("🔍 Données envoyées à handleRecurringEvent:", publishData);
          const seriesId = await handleRecurringEvent(publishData);
          console.log("🔍 handleRecurringEvent terminé, seriesId:", seriesId);
        }
      } else {
        // Événement unique
        let eventDateTime = undefined;
        if (formData.date) {
          const [year, month, day] = formData.date.split('-').map(Number);
          const [hours, minutes] = formData.time ? formData.time.split(':').map(Number) : [0, 0];
          eventDateTime = new Date(year, month - 1, day, hours, minutes);
        }

        try {
          // Nettoyer les données avant la soumission
          const cleanedData = cleanEventData(formData);
          console.log('Données nettoyées:', cleanedData);

          const submissionData = {
            ...cleanedData,
            datetime: eventDateTime,
            endTime: formData.endTime, 
            createdAt: new Date(),
            updatedAt: new Date(),
            status: 'active' as 'active',  // Forcer le statut à 'active' ici aussi
            ...(event?.id && { id: event.id })
          };

          console.log('Données finales à soumettre:', submissionData);
          await onSubmit(submissionData);

          showSuccessToast(false);
        } catch (error) {
          console.error('Erreur lors du nettoyage ou de la soumission:', error);
          throw error;
        }
      }

      onClose();
    } catch (error) {
      console.error("❌ Erreur critique lors de la publication:", error);
      console.error("Détails de l'erreur:", error instanceof Error ? error.message : String(error));
      toast.error('An error occurred during publication. Please try again.');
      setIsSubmitting(false);
    }
  };

  const { isLoaded } = useGoogleMaps();

  const handleTagToggle = (tagId: string) => {
    setFormData(prev => ({
      ...prev,
      tags: prev.tags.includes(tagId)
        ? prev.tags.filter(id => id !== tagId)
        : [...prev.tags, tagId]
    }));
  };

  // Fonction modifiée pour afficher un message aléatoire
  const showSuccessToast = (isDraft: boolean) => {
    const messageData = isDraft 
      ? getRandomToastMessage(draftSuccessMessages)
      : getRandomToastMessage(publishSuccessMessages);
      
    toast.success(
      <div>
        <strong>{messageData.title}</strong>
        <p>{messageData.message}</p>
      </div>,
      { duration: 5000 }
    );
  };

  // Modification de validateCurrentStep pour permettre la navigation avec juste un titre
  const validateCurrentStep = (): boolean => {
    // Vider les erreurs existantes
    setErrors({});
    
    let newErrors: Partial<Record<keyof FormData, string>> = {};
    let isValid = true;
    
    if (currentStep === 0) {
      // Pour l'étape 1, seul le titre est obligatoire pour la navigation
      if (!formData.title) {
        newErrors.title = 'Your event needs a name to continue! ✏️';
        isValid = false;
      }
    }
    
    // Mettre à jour les erreurs
    setErrors(newErrors);
    
    // Retourner si l'étape est valide pour la navigation
    return isValid;
  };

  const onPlaceSelected = () => {
    if (!autocomplete) return;
    
    const place = autocomplete.getPlace();
    if (!place || !place.geometry) return;
    
    const location = place.formatted_address || '';
    const locationName = place.name || location.split(',')[0] || '';
    const lat = place.geometry.location?.lat();
    const lng = place.geometry.location?.lng();
    
    // Mise à jour des données du formulaire
    setFormData(prev => ({
      ...prev,
      location,
      locationName,
      latitude: lat,
      longitude: lng
    }));
    
    // Afficher le champ pour le nom du lieu
    setShowLocationNameInput(true);
  };

  return (
    <div className="event-form">
      <div className="event-form__header">
        <h2 className="event-form__title">
          {event ? 'Edit Event' : 'Create Event'}
          {event && (
            <span className={`event-status event-status--${event.status}`}>
              {event.status === 'draft' ? '· Draft' : '· Published'}
            </span>
          )}
        </h2>
        <button 
          className="event-form__close"
          onClick={onClose}
          aria-label="Fermer le formulaire"
        >
          <Icon name="X" size={24} />
        </button>
      </div>

      <StepIndicator 
        steps={FORM_STEPS} 
        currentStep={currentStep} 
      />

      <div className="event-form__step">
      
          {currentStep === 0 && (
            <BasicInfoStep
              formData={formData}
              setFormData={setFormData}
              errors={errors}
              isLoaded={isLoaded}
              showLocationNameInput={showLocationNameInput}
              setShowLocationNameInput={setShowLocationNameInput}
              isDraft={formData.status === 'draft'}
              autocomplete={autocomplete}
              setAutocomplete={setAutocomplete}
              onPlaceSelected={onPlaceSelected}
            />
          )}

          {currentStep === 1 && (
            <DetailsStep
              formData={formData}
              setFormData={setFormData}
              errors={errors}
              handleTagToggle={handleTagToggle}
            />
          )}

          {currentStep === 2 && (
            <PreviewStep formData={formData} />
          )}
        
      </div>

      <div className="event-form__buttons-container">
        {currentStep > 0 && (
          <Button 
            variant="outline"
            size="md"
            onClick={handlePrevious}
            className="event-form__button event-form__button--back"
          >
            <Icon name="ArrowLeft" size={16} />
            Back
          </Button>
        )}
        
        <div className="event-form__spacer"></div>
        
        <div className="event-form__right-buttons">
          <Button 
            variant="outline"
            size="md"
            onClick={handleSaveAsDraft}
            disabled={isSubmitting || !formData.title}
            className="event-form__button event-form__button--draft"
          >
            {isSubmitting ? (
              <>
                <span className="loading-spinner"></span>
                Saving...
              </>
            ) : (
              <>
                <Icon name="Save" size={16} />
                Save as Draft
              </>
            )}
          </Button>
          
          {currentStep < FORM_STEPS.length - 1 ? (
            <Button 
              variant="primary"
              size="md"
              onClick={handleNext}
              className="event-form__button event-form__button--next"
            >
              Next
              <Icon name="ArrowRight" size={16} />
            </Button>
          ) : (
            <Button 
              variant="primary"
              size="md"
              onClick={handlePublish}
              disabled={isSubmitting}
              className="event-form__button event-form__button--publish"
            >
              {isSubmitting ? (
                <>
                  <span className="loading-spinner"></span>
                  Publishing...
                </>
              ) : (
                <>
                  <Icon name="Send" size={16} />
                  Publish
                </>
              )}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default EventForm;
