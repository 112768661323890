import React from 'react';
import FilterSection from './FilterSection';
import { Icon } from '../../primitives/Icon';
import Button from '../../primitives/Button';
import SearchBar from '../../primitives/SearchBar';
import TagList from '../../primitives/TagList';
import LocationFilter from '../LocationFilter';
import DateTimeFilters from '../DateTimeFilters';
import DistanceSlider from '../../primitives/DistanceSlider';
import { PREDEFINED_TAGS } from '../../types/tags.types';
import { Filters } from './index';
import BaseDrawer from '../Drawer/BaseDrawer';
import './FilterDrawer.css';
import { useRandomMonkey } from '../../../../hooks/useRandomMonkey';

interface FilterDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  viewMode?: 'list' | 'map';
  // Where
  searchCity?: string;
  setSearchCity?: (city: string) => void;
  currentLocation?: { latitude: number; longitude: number };
  isLoadingLocation?: boolean;
  onLocationChange?: (location: { latitude: number; longitude: number }) => void;
  distance?: number;
  onDistanceChange?: (distance: number) => void;
  // When
  selectedDates?: string[];
  onDateChange?: (dates: string[]) => void;
  selectedTimes?: string[];
  onTimeChange?: (times: string[]) => void;
  // What
  searchText?: string;
  onSearchTextChange?: (text: string) => void;
  selectedTags?: string[];
  onTagSelect?: (tagId: string) => void;
  // Reset function
  onReset: () => void;
}

const FilterDrawer: React.FC<FilterDrawerProps> = ({
  isOpen,
  onClose,
  viewMode = 'list',
  // Where
  searchCity = "",
  setSearchCity = () => {},
  currentLocation = undefined,
  isLoadingLocation = false,
  onLocationChange = () => {},
  distance = 5,
  onDistanceChange = () => {},
  // When
  selectedDates = [],
  onDateChange = () => {},
  selectedTimes = [],
  onTimeChange = () => {},
  // What
  searchText = "",
  onSearchTextChange = () => {},
  selectedTags = [],
  onTagSelect = () => {},
  // Reset function
  onReset = () => {},
}) => {
  // Fonction de réinitialisation interne
  const handleReset = () => {
    // Réinitialiser tous les filtres sauf la localisation et la distance en vue liste
    setSearchCity("");
    onSearchTextChange("");
    // On réinitialise la distance uniquement en vue carte
    if (viewMode !== 'list') {
      onDistanceChange(5);
    }
    onDateChange([]);
    onTimeChange([]);
    // Réinitialiser les tags en les désélectionnant un par un
    selectedTags.forEach(tag => onTagSelect(tag));
    // On ne réinitialise pas onLocationChange pour préserver la localisation
  };

  // Prépare les filtres à appliquer
  const prepareFilters = (): Filters => ({
    where: {
      city: searchCity,
      location: currentLocation || undefined,
      distance: viewMode === 'list' ? distance : undefined,
    },
    when: {
      dates: selectedDates,
      times: selectedTimes,
    },
    what: {
      query: searchText,
      tags: selectedTags,
    },
  });

  const { getMonkeyProps } = useRandomMonkey({
    mode: 'atLeastOne',
    probability: 1,
    triggerChange: isOpen,
    availablePositions: [0, 1, 2, 3]
  });

  return (
    <BaseDrawer
      isOpen={isOpen}
      onClose={onClose}
      className="filter-drawer"
    >
      <div className="filter-drawer-content">
        {/* WHERE Section */}
        <FilterSection 
          title="Where to do?" 
          icon={<Icon name="MapPin" size={24} />}
        >
          <div className="location-filters">
            <LocationFilter
              {...getMonkeyProps(0, false)}
              onLocationChange={onLocationChange}
              isLoading={isLoadingLocation}
              currentLocation={currentLocation}
              
            />
            {viewMode === 'list' && (
              <DistanceSlider
                {...getMonkeyProps(1, true)}
                value={distance}
                onChange={onDistanceChange}
                
              />
            )}
          </div>
        </FilterSection>

        {/* WHEN Section */}
        <FilterSection 
          title="When to do?" 
          icon={<Icon name="Clock" size={24} />}
        >
          <DateTimeFilters
            onDateFilterChange={onDateChange}
            onTimeFilterChange={onTimeChange}
            selectedDates={selectedDates}
            selectedTimes={selectedTimes}
          />
        </FilterSection>

        {/* WHAT Section */}
        <FilterSection 
          title="What to do?" 
          icon={<Icon name="LocateFixed" size={24} />}
        >
          <div className="what-filters">
            <SearchBar
              {...getMonkeyProps(2, false)}
              query={searchText}
              onQueryChange={onSearchTextChange}
              placeholder="Search for an activity..."
              
            />
            <TagList 
              tags={PREDEFINED_TAGS}
              selectedTags={selectedTags}
              onTagSelect={onTagSelect}
            />
          </div>
        </FilterSection>

        <div className="drawer-footer">
          <div className="action-buttons">
            <Button 
              variant="secondary"
              onClick={() => {
                handleReset();
                onReset(); // On garde onReset pour la compatibilité
              }}
            >
              Reset
            </Button>
            <Button 
              {...getMonkeyProps(3, false)}
              variant="primary"
              onClick={onClose}
              >
              Apply
            </Button>
          </div>
        </div>
      </div>
    </BaseDrawer>
  );
};

export default FilterDrawer;
