import { useEffect } from 'react';

interface NavigateEvent extends Event {
  preventDefault: () => void;
}

/**
 * Hook personnalisé pour gérer le bouton retour avec les drawers
 * @param isDrawerOpen - État d'ouverture du drawer
 * @param onBack - Fonction à appeler quand le bouton retour est pressé
 */
export const useBackButton = (isDrawerOpen: boolean, onBack: () => void) => {
  useEffect(() => {
    const handleBackButton = (e: NavigateEvent) => {
      if (isDrawerOpen) {
        // Empêcher la navigation arrière
        e.preventDefault();
        // Fermer le drawer
        onBack();
      }
    };

    // Utiliser l'API Navigation si disponible
    if ('navigation' in window) {
      // @ts-ignore (Navigation API est encore récente)
      window.navigation.addEventListener('navigate', handleBackButton);
      
      return () => {
        // @ts-ignore
        window.navigation.removeEventListener('navigate', handleBackButton);
      };
    }
    
    // Fallback pour les navigateurs qui ne supportent pas l'API Navigation
    const handlePopState = () => {
      if (isDrawerOpen) {
        // Ajouter une nouvelle entrée dans l'historique
        window.history.pushState(null, '', window.location.pathname);
        // Fermer le drawer
        onBack();
      }
    };

    window.addEventListener('popstate', handlePopState);
    
    // Si le drawer est ouvert, ajouter une entrée dans l'historique
    if (isDrawerOpen) {
      window.history.pushState(null, '', window.location.pathname);
    }

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [isDrawerOpen, onBack]);
}; 