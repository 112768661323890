import React from 'react';
import './styles/foundation-styles.css';
import './styles/foundation-typography-showcase.css';

const TypographySection = () => {
  const fontSizes = [
    { name: '--font-size-xs', value: '0.75rem', px: '12px', usage: 'Texte très petit, mentions légales', example: 'Mentions légales et petits textes' },
    { name: '--font-size-sm', value: '0.875rem', px: '14px', usage: 'Texte secondaire, légendes', example: 'Description secondaire et légendes' },
    { name: '--font-size-md', value: '1rem', px: '16px', usage: 'Texte principal, paragraphes', example: 'Texte principal de votre contenu' },
    { name: '--font-size-lg', value: '1.125rem', px: '18px', usage: 'Sous-titres, texte mis en avant', example: 'Sous-titre important' },
    { name: '--font-size-xl', value: '1.25rem', px: '20px', usage: 'Petits titres', example: 'Titre de section' },
    { name: '--font-size-2xl', value: '1.5rem', px: '24px', usage: 'Titres de section', example: 'Grand titre' },
    { name: '--font-size-3xl', value: '1.875rem', px: '30px', usage: 'Grands titres', example: 'Titre principal' },
  ];

  const fontWeights = [
    { name: '--font-weight-regular', value: '400', usage: 'Texte courant', example: 'Texte normal pour la lecture' },
    { name: '--font-weight-medium', value: '500', usage: 'Emphase légère', example: 'Texte légèrement mis en avant' },
    { name: '--font-weight-semibold', value: '600', usage: 'Sous-titres et emphase moyenne', example: 'Texte important à souligner' },
    { name: '--font-weight-bold', value: '700', usage: 'Titres et forte emphase', example: 'Titre en gras pour l\'impact' },
  ];

  const lineHeights = [
    { name: '--line-height-tight', value: '1.2', usage: 'Titres et textes courts', 
      example: 'Titre sur\nplusieurs lignes avec\nun espacement serré' },
    { name: '--line-height-normal', value: '1.5', usage: 'Paragraphes et texte courant', 
      example: 'Texte normal sur plusieurs lignes\navec un espacement confortable\npour une lecture agréable' },
    { name: '--line-height-relaxed', value: '1.75', usage: 'Textes longs et contenu dense', 
      example: 'Texte avec beaucoup d\'espace\nentre les lignes pour une\nlecture très confortable' },
  ];

  const letterSpacings = [
    { name: '--letter-spacing-tight', value: '-0.025em', usage: 'Grands titres et texte compact',
      example: 'ESPACEMENT SERRÉ' },
    { name: '--letter-spacing-normal', value: '0', usage: 'Texte courant',
      example: 'ESPACEMENT NORMAL' },
    { name: '--letter-spacing-wide', value: '0.025em', usage: 'Petits textes et emphase',
      example: 'ESPACEMENT LARGE' },
  ];

  return (
    <div className="foundation-section">
      <h2>Typography</h2>
      
      <section>
        <h3>Font Families</h3>
        <p>Notre système utilise deux familles de polices principales pour une lisibilité optimale.</p>
        
        <div className="typography-families">
          <div className="typography-family">
            <h4>Sans Serif</h4>
            <div className="font-preview" style={{ fontFamily: 'var(--font-family-sans)' }}>
              The quick brown fox jumps over the lazy dog
              <br />
              ABCDEFGHIJKLMNOPQRSTUVWXYZ
              <br />
              0123456789
            </div>
            <code className="font-name">--font-family-sans</code>
            <p className="font-usage">Police principale pour le contenu et l'interface</p>
          </div>

          <div className="typography-family">
            <h4>Monospace</h4>
            <div className="font-preview" style={{ fontFamily: 'var(--font-family-mono)' }}>
              The quick brown fox jumps over the lazy dog
              <br />
              ABCDEFGHIJKLMNOPQRSTUVWXYZ
              <br />
              0123456789
            </div>
            <code className="font-name">--font-family-mono</code>
            <p className="font-usage">Police pour le code et les valeurs techniques</p>
          </div>
        </div>
      </section>

      <section>
        <h3>Font Sizes</h3>
        <p>Notre système typographique utilise une échelle harmonieuse pour créer une hiérarchie visuelle claire.</p>
        
        <div className="typography-grid">
          {fontSizes.map(size => (
            <div key={size.name} className="typography-item" data-font-size={size.name.replace('--font-size-', '')}>
              <div className="typography-preview">
                {size.example}
              </div>
              <div className="typography-info">
                <code className="typography-name">{size.name}</code>
                <span className="typography-value">{size.value} ({size.px})</span>
                <p className="typography-usage">{size.usage}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section>
        <h3>Font Weights</h3>
        <p>Les différents poids de police permettent de créer une hiérarchie et d'accentuer le contenu important.</p>

        <div className="typography-weights">
          {fontWeights.map(weight => (
            <div key={weight.name} className="weight-item" data-font-weight={weight.name.replace('--font-weight-', '')}>
              <div className="weight-preview">
                {weight.example}
              </div>
              <div className="weight-info">
                <code className="weight-name">{weight.name}</code>
                <span className="weight-value">{weight.value}</span>
                <p className="weight-usage">{weight.usage}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section>
        <h3>Line Heights</h3>
        <p>Les hauteurs de ligne sont optimisées pour la lisibilité selon le contexte d'utilisation.</p>

        <div className="line-height-examples">
          {lineHeights.map(height => (
            <div key={height.name} className="line-height-item" data-line-height={height.name.replace('--line-height-', '')}>
              <div className="line-height-preview">
                {height.example}
              </div>
              <div className="line-height-info">
                <code className="line-height-name">{height.name}</code>
                <span className="line-height-value">{height.value}</span>
                <p className="line-height-usage">{height.usage}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section>
        <h3>Letter Spacing</h3>
        <p>L'espacement des lettres est ajusté pour optimiser la lisibilité selon la taille et l'usage.</p>

        <div className="letter-spacing-examples">
          {letterSpacings.map(spacing => (
            <div key={spacing.name} className="letter-spacing-item" data-letter-spacing={spacing.name.replace('--letter-spacing-', '')}>
              <div className="letter-spacing-preview">
                {spacing.example}
              </div>
              <div className="letter-spacing-info">
                <code className="letter-spacing-name">{spacing.name}</code>
                <span className="letter-spacing-value">{spacing.value}</span>
                <p className="letter-spacing-usage">{spacing.usage}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section>
        <h3>Exemples de combinaisons</h3>
        <div className="typography-examples">
          <div className="typography-example">
            <h1 style={{ 
              fontSize: 'var(--font-size-3xl)',
              fontWeight: 'var(--font-weight-bold)',
              letterSpacing: 'var(--letter-spacing-tight)',
              lineHeight: 'var(--line-height-tight)'
            }}>
              Titre principal de la page
            </h1>
            <p className="typography-label">Combinaison pour les grands titres</p>
          </div>
          
          <div className="typography-example">
            <h2 style={{ 
              fontSize: 'var(--font-size-2xl)',
              fontWeight: 'var(--font-weight-semibold)',
              letterSpacing: 'var(--letter-spacing-normal)',
              lineHeight: 'var(--line-height-tight)'
            }}>
              Titre de section important
            </h2>
            <p className="typography-label">Combinaison pour les titres de section</p>
          </div>
          
          <div className="typography-example">
            <p style={{ 
              fontSize: 'var(--font-size-md)',
              fontWeight: 'var(--font-weight-regular)',
              letterSpacing: 'var(--letter-spacing-normal)',
              lineHeight: 'var(--line-height-relaxed)'
            }}>
              Voici un exemple de paragraphe avec une hauteur de ligne relaxée pour une meilleure lisibilité sur les longs contenus. 
              La combinaison de propriétés typographiques crée une expérience de lecture agréable.
            </p>
            <p className="typography-label">Combinaison pour le texte courant</p>
          </div>
          
          <div className="typography-example">
            <p style={{ 
              fontSize: 'var(--font-size-sm)',
              fontWeight: 'var(--font-weight-medium)',
              letterSpacing: 'var(--letter-spacing-wide)',
              lineHeight: 'var(--line-height-normal)'
            }}>
              Texte secondaire avec un espacement plus large pour une meilleure lisibilité à petite taille.
            </p>
            <p className="typography-label">Combinaison pour le texte secondaire</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TypographySection; 