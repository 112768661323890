import { Event } from '../types/event.types';
import L from 'leaflet';
import { calculateDistance } from './eventSorting';

interface Cluster {
  center: {
    lat: number;
    lng: number;
  };
  events: Event[];
}

export const CLUSTER_RADIUS = 0.25; // Réduit de 0.3 à 0.25 km

export function clusterEvents(events: Event[], zoomLevel: number): Cluster[] {
  // Changer le niveau de zoom de référence de 16 à 15 pour une séparation plus précoce
  const baseRadius = CLUSTER_RADIUS;
  const zoomFactor = Math.pow(1.4, 15 - zoomLevel); // Changé de 16 à 15
  const radius = baseRadius * Math.max(zoomFactor, 0.12);
  
  const clusters: Cluster[] = [];

  // Filtrer les événements sans coordonnées
  const validEvents = events.filter(
    event => event.latitude !== undefined && event.longitude !== undefined
  );

  validEvents.forEach(event => {
    const eventLat = event.latitude!;
    const eventLng = event.longitude!;

    // Chercher un cluster existant proche
    const existingCluster = clusters.find(cluster => {
      const distance = calculateDistance(
        cluster.center.lat,
        cluster.center.lng,
        eventLat,
        eventLng
      );
      return distance <= radius;
    });

    if (existingCluster) {
      // Ajouter l'événement au cluster existant
      existingCluster.events.push(event);
      // Recalculer le centre du cluster
      existingCluster.center = calculateClusterCenter(existingCluster.events);
    } else {
      // Créer un nouveau cluster
      clusters.push({
        center: { lat: eventLat, lng: eventLng },
        events: [event]
      });
    }
  });

  return clusters;
}

function calculateClusterCenter(events: Event[]): { lat: number; lng: number } {
  const validEvents = events.filter(
    event => event.latitude !== undefined && event.longitude !== undefined
  );

  const sum = validEvents.reduce(
    (acc, event) => ({
      lat: acc.lat + event.latitude!,
      lng: acc.lng + event.longitude!
    }),
    { lat: 0, lng: 0 }
  );

  return {
    lat: sum.lat / validEvents.length,
    lng: sum.lng / validEvents.length
  };
}

export function calculateMapBounds(
  events: Event[],
  userLocation?: { latitude: number; longitude: number }
): { bounds: L.LatLngBounds | undefined; zoom: number } {
  if (userLocation) {
    const bounds = L.latLngBounds([]);
    bounds.extend([userLocation.latitude, userLocation.longitude]);
    
    // Réduit à 10km
    const nearbyEvents = events.filter(event => {
      if (!event.latitude || !event.longitude) return false;
      const distance = calculateDistance(
        userLocation.latitude,
        userLocation.longitude,
        event.latitude,
        event.longitude
      );
      return distance <= 10; // 10km
    });

    if (nearbyEvents.length > 0) {
      nearbyEvents.forEach(event => {
        bounds.extend([event.latitude!, event.longitude!]);
      });
      return { bounds, zoom: nearbyEvents.length === 1 ? 15 : 13 };
    }

    // Vue centrée sur l'utilisateur
    return { 
      bounds: L.latLngBounds([
        [userLocation.latitude - 0.05, userLocation.longitude - 0.05], // Réduit aussi la zone visible par défaut
        [userLocation.latitude + 0.05, userLocation.longitude + 0.05]
      ]), 
      zoom: 13 
    };
  }

  return { bounds: undefined, zoom: 13 };
}

export function calculateOptimalZoom(
  eventLocation: { latitude: number; longitude: number },
  userLocation?: { latitude: number; longitude: number }
): number {
  // Si pas de localisation utilisateur, zoom par défaut
  if (!userLocation) return 15;

  // Calculer la distance entre l'événement et l'utilisateur
  const distance = calculateDistance(
    eventLocation.latitude,
    eventLocation.longitude,
    userLocation.latitude,
    userLocation.longitude
  );

  // Définir le zoom en fonction de la distance
  if (distance < 0.5) return 16;      // Très proche (<500m)
  if (distance < 2) return 14;        // Distance de marche
  if (distance < 5) return 13;        // Distance vélo
  if (distance < 10) return 12;       // Courte distance en voiture
  if (distance < 20) return 10;       // Distance moyenne
  return 12;                          // Grande distance
}

export function calculateOptimalCenter(
  eventLocation: { latitude: number; longitude: number },
  userLocation?: { latitude: number; longitude: number }
): [number, number] {
  // Si pas de localisation utilisateur, centrer sur l'événement
  if (!userLocation) {
    return [eventLocation.latitude, eventLocation.longitude];
  }

  // Calculer le point médian
  return [
    (eventLocation.latitude + userLocation.latitude) / 2,
    (eventLocation.longitude + userLocation.longitude) / 2
  ];
}

export function createMarkerIcon(count?: number, isActive: boolean = false, isFavorite: boolean = false): L.DivIcon {
  if (count && count > 1) {
    // Style pour les clusters
    return L.divIcon({
      className: 'custom-marker',
      html: `
        <div class="marker-pin">
          <span class="marker-count">${count}</span>
        </div>
      `,
      iconSize: [30, 30],
      iconAnchor: [15, 30],
      popupAnchor: [0, -30]
    });
  } else {
    // Style pour les marqueurs simples
    const className = `marker-pin${isFavorite ? ' highlight' : ''}${isActive ? ' active' : ''}`;
    return L.divIcon({
      className: 'custom-marker',
      html: `<div class="${className}"></div>`,
      iconSize: [30, 30],
      iconAnchor: [15, 30],
      popupAnchor: [0, -30]
    });
  }
}
