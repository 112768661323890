import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useOrganizer } from '../../contexts/OrganizerContext';
import { useAuth } from '../../contexts/AuthContext';
import { OrganizerProfile } from '../../types/organizer.types';
import { ProfileHeader, ProfileEvents } from './components';
import ProfileEditForm from './components/ProfileEditForm';
import './OrganizerProfile.css';

const OrganizerProfilePage: React.FC = () => {
  const { organizerId } = useParams();
  const { user } = useAuth();
  const { organizerProfile, getPublicProfile } = useOrganizer();
  const [profile, setProfile] = useState<OrganizerProfile | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  // Déterminer si on est sur la route /organizer/profile (espace organisateur)
  const isOrganizerSpace = location.pathname.startsWith('/organizer/profile');
  
  // Si nous sommes dans l'espace organisateur, c'est le profil de l'utilisateur connecté
  const effectiveOrganizerId = isOrganizerSpace ? user?.uid : organizerId;
  
  // Déterminer si l'utilisateur est le propriétaire du profil
  const isOwner = user?.uid === effectiveOrganizerId;

  useEffect(() => {
    const loadProfile = async () => {
      try {
        setLoading(true);
        
        if (!effectiveOrganizerId) {
          console.error('No organizer ID available');
          setLoading(false);
          return;
        }
        
        if (isOwner && organizerProfile) {
          // Utiliser le profil complet depuis le contexte si disponible et si c'est le propriétaire
          setProfile(organizerProfile);
        } else {
          // Charger le profil public pour tout le monde (y compris les non connectés)
          console.log('Loading public profile for ID:', effectiveOrganizerId);
          const publicProfile = await getPublicProfile(effectiveOrganizerId);
          console.log('Public profile loaded:', publicProfile);
          setProfile(publicProfile);
        }
      } catch (error) {
        console.error('Error loading profile:', error);
      } finally {
        setLoading(false);
      }
    };

    loadProfile();
  }, [effectiveOrganizerId, isOwner, organizerProfile, getPublicProfile]);

  if (loading) {
    return <div>Loading...</div>; // À remplacer par un composant de chargement du Design System
  }

  return (
    <div className="organizer-profile">
      {!isEditing ? (
        <>
          <ProfileHeader 
            profile={profile}
            isOwner={isOwner}
            onEdit={() => setIsEditing(true)}
          />
          <ProfileEvents organizerId={effectiveOrganizerId} />
        </>
      ) : (
        <ProfileEditForm
          profile={profile}
          onClose={() => setIsEditing(false)}
          onSave={(updatedProfile) => {
            setProfile(updatedProfile);
            setIsEditing(false);
          }}
        />
      )}
    </div>
  );
};

export default OrganizerProfilePage;
