export interface Tag {
  id: string;
  label: string;
  icon: string;
}

export interface FilterTag extends Tag {
  type?: 'filter';
}

export interface CategoryTag extends Tag {
  type?: 'category';
}

// Types spécifiques pour les tags d'événements
export interface EventTag extends Tag {
  type?: 'event';
}

// Collection de tags prédéfinis
export const PREDEFINED_TAGS: Tag[] = [
  { id: 'free', label: 'Free', icon: '' },
  { id: 'music', label: 'Music', icon: '' },
  { id: 'sports', label: 'Sports', icon: '' },
  { id: 'art', label: 'Art', icon: '' },
  { id: 'food', label: 'Food', icon: '' },
  { id: 'culture', label: 'Culture', icon: '' },
  { id: 'yoga', label: 'Yoga', icon: '' },
  { id: 'spirituality', label: 'Spirituality', icon: '' },
  { id: 'children', label: 'Children', icon: '' },
  { id: 'party', label: 'Party', icon: '' },
];