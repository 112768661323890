import { Tag, PREDEFINED_TAGS } from '../pages/DesignSystem/types/tags.types';

export type EventTag = Tag;

export interface DistanceFilter {
  value: number;
  maxDistance: number;
}

export interface PriceItem {
  amount: number;
  description: string;
  currency: string;  // Pour spécifier la devise (EUR, LKR, etc.)
}

export interface Event {
  id: string;
  organizerId: string;
  organizerContact?: string;
  organizer?: string;
  organizerImage?: string;
  organizerSubtitle?: string;
  title: string;
  description?: string;
  datetime?: Date;
  endTime?: string;
  endDateTime?: string;  // Ajout du nouveau champ
  timezone?: string; // Fuseau horaire de l'événement
  location?: string;
  locationName?: string;
  latitude?: number;
  longitude?: number;
  tags: string[];
  image?: string;
  prices?: PriceItem[];
  ticketingUrl?: string; 
  views?: number;
  status: 'active' | 'draft';
  attendees?: number;
  createdAt?: Date;
  updatedAt?: Date;
  seriesId?: string;
  isException?: boolean;
   // Optional ticketing URL
}

export type ActiveView = 'list' | 'create' | 'stats';
export type ActiveTab = 'active' | 'draft';  // Mis à jour pour refléter les statuts possibles

export type DateFilterValue = 'today' | 'tomorrow' | 'weekend' | 'custom' | '';

export interface DateFilter {
  id: string;
  label: string;
  value: DateFilterValue;
}

export interface TimeFilter {
  id: string;
  label: string;
  icon: string;
  value: 'morning' | 'afternoon' | 'evening' | 'all';
}

export interface FilterOptions {
  searchText: string;
  searchCity: string;
  selectedTags: string[];
  dateFilter: string[];
  timeFilter: string[];
  customDate: string | null;
}

export const DATE_FILTERS: DateFilter[] = [
  { id: 'today', label: 'Today', value: 'today' },
  { id: 'tomorrow', label: 'Tomorrow', value: 'tomorrow' },
  { id: 'weekend', label: 'This Weekend', value: 'weekend' },
  { id: 'custom', label: 'Choose Date', value: 'custom' }
];

export const TIME_FILTERS: TimeFilter[] = [
  { id: 'morning', label: 'Morning', icon: '🌅', value: 'morning' },
  { id: 'afternoon', label: 'Afternoon', icon: '☀️', value: 'afternoon' },
  { id: 'evening', label: 'Evening', icon: '🌙', value: 'evening' }
];

export { PREDEFINED_TAGS as EVENT_TAGS };
