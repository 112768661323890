import React from 'react';
import { Outlet } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import './styles/mobile-layout.css';

const MobileLayout: React.FC = () => {
  // Empêcher le zoom sur mobile
  React.useEffect(() => {
    const viewport = document.querySelector('meta[name=viewport]');
    if (viewport) {
      viewport.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0');
    }
    return () => {
      if (viewport) {
        viewport.setAttribute('content', 'width=device-width, initial-scale=1.0');
      }
    };
  }, []);

  return (
    <div className="mobile-layout">
      <main className="mobile-content">
        <Outlet />
      </main>
      <Toaster 
        position="bottom-center"
        toastOptions={{
          className: 'mobile-toast',
          duration: 2000
        }}
      />
    </div>
  );
};

export default MobileLayout; 