import React, { useRef, useState } from 'react';
import { Icon } from './Icon';
import '../../../styles/components/file-input.css';

export interface FileInputProps {
  // Props essentielles
  id: string;
  name: string;
  onChange: (files: FileList | null) => void;
  
  // Props optionnelles
  label?: string;
  helper?: string;
  error?: string;
  accept?: string;
  multiple?: boolean;
  maxSize?: number; // en bytes
  required?: boolean;
  disabled?: boolean;
  className?: string;
  dragAndDrop?: boolean;
  // Nouvelles props pour la galerie
  previewFiles?: string[];
  onFileRemove?: (index: number) => void;
  previewType?: 'names' | 'thumbnails';
}

export const FileInput: React.FC<FileInputProps> = ({
  id,
  name,
  onChange,
  label,
  helper,
  error,
  accept,
  multiple = false,
  maxSize,
  required = false,
  disabled = false,
  className = '',
  dragAndDrop = true,
  // Nouvelles props avec valeurs par défaut
  previewFiles = [],
  onFileRemove,
  previewType = 'thumbnails',
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const containerClasses = [
    'file-input-container',
    dragAndDrop ? 'drag-drop-enabled' : '',
    isDragging ? 'is-dragging' : '',
    error ? 'has-error' : '',
    disabled ? 'is-disabled' : '',
    className
  ].filter(Boolean).join(' ');

  const handleFileChange = (files: FileList | null) => {
    if (!files || disabled) return;

    // Vérification de la taille des fichiers
    if (maxSize) {
      const isValidSize = Array.from(files).every(file => file.size <= maxSize);
      if (!isValidSize) {
        setSelectedFiles(null);
        onChange(null);
        return;
      }
    }

    setSelectedFiles(files);
    onChange(files);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleFileChange(e.target.files);
  };

  const handleDragEnter = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (!disabled && dragAndDrop) setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    if (!disabled && dragAndDrop) {
      handleFileChange(e.dataTransfer.files);
    }
  };

  const getFileNames = () => {
    if (!selectedFiles) return '';
    return Array.from(selectedFiles)
      .map(file => file.name)
      .join(', ');
  };

  return (
    <div className="file-input-wrapper">
      {label && (
        <label htmlFor={id} className="file-input-label">
          {label}
          {required && <span className="required-mark">*</span>}
        </label>
      )}
      
      {/* Prévisualisation des fichiers existants */}
      {previewFiles.length > 0 && (
        <div className="file-input-previews">
          {previewFiles.map((file, index) => (
            <div key={index} className="file-input-preview-item">
              {previewType === 'thumbnails' ? (
                <img 
                  src={file} 
                  alt={`Preview ${index + 1}`}
                  className="file-input-preview-image"
                />
              ) : (
                <span className="file-input-preview-name">
                  {file.split('/').pop()?.split('?')[0] || `File ${index + 1}`}
                </span>
              )}
              
              {onFileRemove && (
                <button 
                  type="button"
                  className="file-input-preview-remove"
                  onClick={() => onFileRemove(index)}
                  aria-label={`Remove file ${index + 1}`}
                >
                  <Icon name="X" size={16} />
                </button>
              )}
            </div>
          ))}
        </div>
      )}
      
      <div
        className={containerClasses}
        onDragEnter={handleDragEnter}
        onDragOver={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        role="button"
        tabIndex={disabled ? -1 : 0}
      >
        <input
          ref={fileInputRef}
          id={id}
          name={name}
          type="file"
          accept={accept}
          multiple={multiple}
          required={required}
          disabled={disabled}
          className="file-input-field"
          onChange={handleInputChange}
          aria-invalid={!!error}
          aria-describedby={helper ? `${id}-helper` : undefined}
          style={{ 
            position: 'absolute',
            opacity: 0,
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            cursor: 'pointer',
          }}
        />

        <div className="file-input-content">
          <Icon 
            name="Upload" 
            size={24} 
            className="file-input-icon"
            aria-hidden="true"
          />
          
          <div className="file-input-text">
            {selectedFiles ? (
              <span className="selected-files">{getFileNames()}</span>
            ) : (
              <>
                <span className="primary-text">
                  {dragAndDrop ? 'Drag and drop your files here or' : 'Choose files'}
                </span>
                <span className="secondary-text">Browse</span>
              </>
            )}
          </div>
        </div>
      </div>

      {helper && !error && (
        <p id={`${id}-helper`} className="file-input-helper">
          {helper}
        </p>
      )}
      
      {error && (
        <p id={`${id}-error`} className="file-input-error" role="alert">
          {error}
        </p>
      )}
    </div>
  );
};

export default FileInput; 