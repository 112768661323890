import React from 'react';
import { Icon, IconName } from '../../primitives/Icon';
import BaseDrawer from '../Drawer/BaseDrawer';
import './MenuDrawer.css';
import { useNavigate } from 'react-router-dom';
import { useOrganizer } from '../../../../contexts/OrganizerContext';

interface OrganizerMenuDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

const OrganizerMenuDrawer: React.FC<OrganizerMenuDrawerProps> = ({
  isOpen,
  onClose
}) => {
  const navigate = useNavigate();
  const { organizerProfile } = useOrganizer();

  const menuItems = [
    {
      icon: 'Home' as IconName,
      label: 'Public Space',
      onClick: () => {
        onClose();
        setTimeout(() => {
          navigate('/', { replace: true });
        }, 100);
      }
    },
    {
      icon: 'User' as IconName,
      label: 'Profile Settings',
      onClick: () => {
        onClose();
        setTimeout(() => {
          navigate('/organizer/profile');
        }, 100);
      },
      badge: !organizerProfile ? '!' : undefined
    },
    {
      icon: 'Mail' as IconName,
      label: 'Email',
      onClick: () => {
        onClose();
        window.location.href = "mailto:what2dotodaypro@gmail.com";
      }
    },
    {
      icon: 'MessageCircle' as IconName,
      label: 'WhatsApp',
      onClick: () => {
        onClose();
        window.open("https://wa.me/33771006946", "_blank");
      }
    },
    {
      icon: 'MessageSquare' as IconName,
      label: 'Feedback',
      onClick: () => {
        onClose();
        window.open("https://forms.gle/WsLPhinC1fS5eYJcA", "_blank");
      }
    }
  ];

  return (
    <BaseDrawer
      isOpen={isOpen}
      onClose={onClose}
      className="menu-drawer"
    >
      <div className="menu-drawer-header">
        <h2>Menu</h2>
      </div>
      <div className="menu-drawer-content">
        {menuItems.map((item, index) => (
          <button
            key={index}
            className="menu-item"
            onClick={item.onClick}
          >
            <Icon name={item.icon} size={24} />
            <span>{item.label}</span>
            {item.badge && <span className="setup-badge">{item.badge}</span>}
          </button>
        ))}
      </div>
    </BaseDrawer>
  );
};

export default OrganizerMenuDrawer; 