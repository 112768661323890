import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { OrganizerProvider } from './contexts/OrganizerContext';
import { Toaster } from 'react-hot-toast';
import { useOfflineStatus } from './hooks/useOfflineStatus';
import OfflineMessage from './components/offline/OfflineMessage';
import { useNavigate } from 'react-router-dom';
import { GoogleMapsProvider } from './contexts/GoogleMapsContext';

// Layouts
import MobileLayout from './layouts/MobileLayout';
import OrganizerLayout from './layouts/OrganizerLayout';

// Pages existantes
import HomePage from './pages/HomePage/HomePage';
import LoginPage from './pages/LoginPage/LoginPage';
import RegisterPage from './pages/RegisterPage/RegisterPage';
import OrganizerPage from './pages/OrganizerPage/OrganizerPage';
import DesignSystem from './pages/DesignSystem/DesignSystem';
import FavoritesPage from './pages/FavoritesPage/FavoritesPage';
import { OrganizerProfilePage } from './pages/OrganizerProfile';

// Components
import EventModalRoute from './components/event/EventModalRoute';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';

// Ajouter l'interface pour les données préchargées
interface PreloadedData {
  location?: {
    latitude: number;
    longitude: number;
    cityName?: string;
  };
  events?: Array<any>; // ou le type exact de vos événements
  needLocation?: boolean;
}

// Étendre l'interface Window pour TypeScript
declare global {
  interface Window {
    __PRELOADED_DATA__?: PreloadedData;
  }
}

const App: React.FC = () => {
  const { isOffline, hasCache } = useOfflineStatus();
  const navigate = useNavigate();

  useEffect(() => {
    const preloadedData = window.__PRELOADED_DATA__;
    
    if (preloadedData?.needLocation) {
      navigate('/', { state: { showLocationFilter: true } });
    }
  }, [navigate]);

  return (
    <>
      {isOffline && <OfflineMessage hasCache={hasCache} />}
      <AuthProvider>
        <OrganizerProvider>
          <GoogleMapsProvider>
            <Routes>
              {/* Mobile Routes (Public) */}
              <Route element={<MobileLayout />}>
                <Route path="/" element={<HomePage />}>
                  <Route path="event/:id" element={<EventModalRoute />} />
                  <Route path="series/:seriesId" element={<EventModalRoute />} />
                  <Route path="events/:location" element={<EventModalRoute />} />
                </Route>
                <Route path="/favorites" element={<FavoritesPage />} />
                <Route path="/organizer-profile/:organizerId" element={<OrganizerProfilePage />} />
              </Route>

              {/* Organizer Routes (Protected) */}
              <Route
                path="/organizer/*"
                element={
                  <PrivateRoute>
                    <OrganizerLayout />
                  </PrivateRoute>
                }
              >
                <Route index element={<OrganizerPage />} />
                <Route path="profile" element={<OrganizerProfilePage />} />
                <Route path="events" element={<OrganizerPage />} />
                <Route path="stats" element={<OrganizerPage />} />
              </Route>

              {/* Auth Routes */}
              <Route path="/login" element={<LoginPage />} />
              <Route path="/register" element={<RegisterPage />} />

              {/* Design System */}
              <Route path="/design-system" element={<DesignSystem />} />

              {/* Fallback */}
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
            <Toaster position="top-right" />
          </GoogleMapsProvider>
        </OrganizerProvider>
      </AuthProvider>
    </>
  );
};

export default App;
