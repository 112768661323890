import React from 'react';
import { MapMarker, MarkerGroup } from '../primitives/MapMarker';

const MapMarkerSection = () => {
  const markerSizes = [
    { size: 'sm', label: 'Petit', description: 'Pour les zones denses en marqueurs' },
    { size: undefined, label: 'Normal', description: 'Taille standard' },
    { size: 'lg', label: 'Grand', description: 'Pour mettre en évidence des événements importants' },
  ] as const;

  const markerStates = [
    { 
      label: 'Standard', 
      description: 'Marqueur par défaut (couleur primaire)' 
    },
    { 
      isSelected: true, 
      label: 'Sélectionné', 
      description: 'Marqueur sélectionné avec animation pulse en vert' 
    },
    { 
      isFavorite: true, 
      label: 'Favori', 
      description: 'Événement marqué comme favori (couleur secondaire)' 
    },
    { 
      isCluster: true, 
      count: 5, 
      label: 'Petit groupe', 
      description: 'Groupe de 5 événements' 
    },
    { 
      isCluster: true, 
      count: 12, 
      label: 'Grand groupe', 
      description: 'Groupe de plus de 10 événements' 
    }
  ];

  return (
    <section className="design-section">
      <h3>Marqueurs de carte</h3>
      <p>Les marqueurs de carte permettent de localiser et d'identifier les événements sur une carte interactive.</p>

      <div className="section-content">
        <section className="subsection">
          <h4>Tailles de marqueurs</h4>
          <p>Différentes tailles sont disponibles pour s'adapter aux besoins d'affichage.</p>
          
          <div className="showcase-grid">
            {markerSizes.map(marker => (
              <div key={marker.label} className="showcase-item">
                <div className="marker-preview">
                  <MarkerGroup>
                    <MapMarker size={marker.size} />
                  </MarkerGroup>
                </div>
                <div className="showcase-info">
                  <code className="showcase-name">
                    {marker.size ? `size="${marker.size}"` : 'size="md" (défaut)'}
                  </code>
                  <p className="showcase-usage">{marker.description}</p>
                </div>
              </div>
            ))}
          </div>
        </section>

        <section className="subsection">
          <h4>États des marqueurs</h4>
          <p>Les marqueurs peuvent avoir différents états pour indiquer leur sélection, favori ou regroupement.</p>
          
          <div className="showcase-grid">
            {markerStates.map(marker => (
              <div key={marker.label} className="showcase-item">
                <div className="marker-preview">
                  <MarkerGroup>
                    <MapMarker 
                      isSelected={marker.isSelected}
                      isFavorite={marker.isFavorite}
                      isCluster={marker.isCluster}
                      count={marker.count}
                    />
                  </MarkerGroup>
                </div>
                <div className="showcase-info">
                  <code className="showcase-name">
                    {!marker.isSelected && !marker.isFavorite && !marker.isCluster && 'MapMarker />'}
                    {marker.isSelected && 'isSelected={true}'}
                    {marker.isFavorite && 'isFavorite={true}'}
                    {marker.isCluster && `isCluster={true} count={${marker.count}}`}
                  </code>
                  <p className="showcase-usage">{marker.description}</p>
                </div>
              </div>
            ))}
          </div>
        </section>

        <section className="subsection">
          <h4>Contenu personnalisé</h4>
          <p>Les marqueurs peuvent afficher un logo ou un nombre.</p>
          
          <div className="showcase-grid">
            <div className="showcase-item">
              <div className="marker-preview">
                <MarkerGroup>
                  <MapMarker logo="path/to/logo.png" />
                </MarkerGroup>
              </div>
              <div className="showcase-info">
                <code className="showcase-name">logo="path/to/logo.png"</code>
                <p className="showcase-usage">Marqueur avec logo personnalisé</p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  );
};

export default MapMarkerSection; 