import React, { createContext, useContext, useState, useEffect } from 'react';
import { organizerService } from '../firebase/organizer.service';
import { OrganizerProfile } from '../types/organizer.types';
import { useAuth } from './AuthContext';
import { useGoogleMaps } from './GoogleMapsContext';

interface OrganizerContextType {
  organizerProfile: OrganizerProfile | null;
  publicProfiles: Record<string, any>;
  loading: boolean;
  updateProfile: (data: Partial<OrganizerProfile>) => Promise<void>;
  getPublicProfile: (organizerId: string) => Promise<any>;
  isGoogleMapsLoaded: boolean;
}

const OrganizerContext = createContext<OrganizerContextType | undefined>(undefined);

export const OrganizerProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const auth = useAuth();
  const { isLoaded: isGoogleMapsLoaded } = useGoogleMaps();
  const [organizerProfile, setOrganizerProfile] = useState<OrganizerProfile | null>(null);
  const [publicProfiles, setPublicProfiles] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log("🌍 OrganizerContext - Google Maps chargé:", isGoogleMapsLoaded);
  }, [isGoogleMapsLoaded]);

  useEffect(() => {
    if (auth.loading) return;
    
    if (!auth.user) {
      console.log("Pas d'utilisateur connecté");
      return;
    }

    setLoading(true);
    
    const unsubscribe = organizerService.subscribeToProfile(
      auth.user.uid,
      (profile: OrganizerProfile) => {
        setOrganizerProfile(profile);
        setLoading(false);
      },
      (error: any) => {
        console.error('Erreur:', error);
        setLoading(false);
      }
    );
  
    return () => unsubscribe();
  }, [auth.user, auth.loading]);

  const getPublicProfile = async (organizerId: string) => {
    if (publicProfiles[organizerId]) {
      return publicProfiles[organizerId];
    }

    try {
      const profile = await organizerService.getPublicProfile(organizerId);
      setPublicProfiles(prev => ({
        ...prev,
        [organizerId]: profile
      }));
      return profile;
    } catch (error) {
      console.error('Erreur lors du chargement du profil public:', error);
      return null;
    }
  };

  const updateProfile = async (data: Partial<OrganizerProfile>) => {
    if (!auth.user) return;
    
    try {
      await organizerService.saveProfile(auth.user.uid, {
        ...organizerProfile,
        ...data
      } as OrganizerProfile);
      setOrganizerProfile(prev => prev ? { ...prev, ...data } : null);
    } catch (error) {
      console.error('Erreur lors de la mise à jour du profil:', error);
      throw error;
    }
  };

  return (
    <OrganizerContext.Provider value={{
      organizerProfile,
      publicProfiles,
      loading,
      updateProfile,
      getPublicProfile,
      isGoogleMapsLoaded
    }}>
      {children}
    </OrganizerContext.Provider>
  );
};

export const useOrganizer = () => {
  const context = useContext(OrganizerContext);
  if (context === undefined) {
    throw new Error('useOrganizer must be used within an OrganizerProvider');
  }
  return context;
};