import { FormData } from './types';
import { eventSeriesService } from '../../../firebase/eventSeries.service';
import { EventSeries, SeriesEvent } from '../../../types/eventSeries.types';
import { createTimestamp } from '../../../utils/dateUtils';

export async function handleRecurringEvent(formData: FormData): Promise<string> {
  // Convertir les données du formulaire en format EventSeries
  const seriesData: Omit<EventSeries, 'id'> = {
    organizerId: formData.organizerId,
    organizerContact: formData.organizerContact,
    title: formData.title,
    description: formData.description,
    location: formData.location,
    locationName: formData.locationName,
    latitude: formData.latitude,
    longitude: formData.longitude,
    tags: formData.tags,
    image: formData.image,
    prices: formData.prices,
    ticketingUrl: formData.ticketingUrl || '',
    status: formData.status,
    
    // Données de récurrence
    frequency: formData.frequency,
    startDate: formData.startDate,
    time: formData.startTime,
    endTime: formData.endTime, 
    endType: formData.endType,
    endDate: formData.endDate,
    occurrences: formData.occurrences,
    weekDays: formData.weekDays,
    
    // Métadonnées
    createdAt: createTimestamp(),
    updatedAt: createTimestamp(),
    
  };

  // Créer la série d'événements
  try {
    const seriesId = await eventSeriesService.createEventSeries(seriesData);
    console.log("📅 Série créée avec succès, ID:", seriesId);
    
    // Appelez manuellement la génération d'événements ici
    console.log("📅 Tentative directe de génération d'événements");
    await eventSeriesService._generateSeriesEvents(seriesId);
    console.log("📅 Génération directe d'événements réussie");
    
    return seriesId;
  } catch (error) {
    console.error('Erreur lors de la création de la série:', error);
    throw error;
  }
}

// Fonction utilitaire pour vérifier si un événement est passé
export function isEventPast(datetime: Date): boolean {
  const now = createTimestamp();
  return datetime < now;
}

interface EventUpdateData extends Partial<SeriesEvent> {
  updatedAt: Date;
}

// Fonction pour mettre à jour un événement récurrent
export async function updateRecurringEvent(
  seriesId: string, 
  formData: FormData, 
  updateAll: boolean = true
): Promise<void> {
  try {
    const baseUpdateData: EventUpdateData = {
      title: formData.title,
      description: formData.description,
      location: formData.location,
      locationName: formData.locationName,
      latitude: formData.latitude,
      longitude: formData.longitude,
      tags: formData.tags,
      image: formData.image,
      prices: formData.prices,
      ticketingUrl: formData.ticketingUrl || '',
      status: formData.status,
      
      updatedAt: createTimestamp()
    };

    if (updateAll) {
      // Récupérer la série actuelle pour comparer les changements
      const currentSeries = await eventSeriesService.getEventSeriesById(seriesId);
      
      // Mettre à jour toute la série
      const seriesUpdateData: Partial<EventSeries> = {
        ...baseUpdateData,
        frequency: formData.frequency,
        startDate: formData.startDate,
        time: formData.startTime,
        endTime: formData.endTime,
        endType: formData.endType,
        endDate: formData.endDate,
        occurrences: formData.occurrences,
        weekDays: formData.weekDays
      };
      
      // Log pour débogage
      console.log('Mise à jour de la série avec les données:', seriesUpdateData);
      
      await eventSeriesService.updateEventSeries(seriesId, seriesUpdateData);
      
      // Log après mise à jour
      console.log('Série mise à jour avec succès');
    } else if (formData.id) {
      // Mettre à jour uniquement l'événement spécifique
      const singleEventUpdateData: EventUpdateData = {
        ...baseUpdateData,
        isException: true // Marquer comme exception car modifié individuellement
      };

      // Si la date ou l'heure est modifiée, mettre à jour datetime
      if (formData.date && formData.time) {
        const [year, month, day] = formData.date.split('-').map(Number);
        const [hours, minutes] = formData.time.split(':').map(Number);
        singleEventUpdateData.datetime = new Date(year, month - 1, day, hours, minutes);
      }

      await eventSeriesService.updateSingleEvent(formData.id, singleEventUpdateData);
    }
  } catch (error) {
    console.error('Erreur lors de la mise à jour de la série:', error);
    throw error;
  }
}

// Fonction pour changer le statut d'un événement ou d'une série
export async function updateEventStatus(
  eventId: string,
  seriesId: string | undefined,
  newStatus: 'active' | 'draft',
  updateAll: boolean = false
): Promise<void> {
  const updateData: EventUpdateData = {
    status: newStatus,
    updatedAt: createTimestamp()
  };

  if (seriesId && updateAll) {
    // Mettre à jour le statut de toute la série
    await eventSeriesService.updateEventSeries(seriesId, updateData);
  } else {
    // Mettre à jour le statut d'un seul événement
    if (seriesId) {
      updateData.isException = true; // Marquer comme exception si fait partie d'une série
    }
    await eventSeriesService.updateSingleEvent(eventId, updateData);
  }
}
