import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Event } from '../../types/event.types';
import { formatDateTime, formatTimeString, formatDateForIcon } from '../../utils/dateFormatter';
import { openInMaps } from '../../utils/eventSorting';
import { calculateOptimalZoom, calculateOptimalCenter } from '../../utils/mapUtils';
import { MapContainer, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { 
  BaseModal, 
  Button, 
  FavoriteButton,
  ShareButton,
  NotificationBubble,
  Icon,
  LeafletMarker,
  UserLocationMarker,
  Tag,
  DateIcon,
  ContentBlock,
  LogoSVG
} from '../../pages/DesignSystem';
import { useEventSeries } from '../../hooks/useEventSeries';
import { useFavoriteState } from '../../hooks/useFavoriteState';
import { useEventContext } from '../../contexts/EventContext';
import { SwipeableCard } from '../../pages/DesignSystem/patterns/Modal/BaseModal';
import './EventModal.css';
import './card.css';
import { useNavigate } from 'react-router-dom';
import { useDistance } from '../../hooks/useDistance';
import { useGeolocation } from '../../utils/hooks/useGeolocation';

interface EventModalProps {
  event: Event;
  isOpen: boolean;
  onClose: () => void;
  onFavorite?: (eventId: string) => void;
  onDismiss?: (eventId: string) => void;
  userLocation?: {
    latitude: number;
    longitude: number;
  };
  isMapView?: boolean;
  returnToMulti?: boolean;
  multiEvents?: Event[];
  location?: string;
  isRecurring?: boolean;
  seriesEvents?: Event[];
}

const FALLBACK_IMAGE = '/images/logoverttexte.png';

const getCachedLocation = () => {
  try {
    const cached = localStorage.getItem('userLocation');
    if (cached) {
      const parsed = JSON.parse(cached);
      const isValid = Date.now() - parsed.timestamp < 24 * 60 * 60 * 1000;
      
      if (isValid) {
        return {
          latitude: parsed.latitude,
          longitude: parsed.longitude
        };
      }
    }
    return undefined;
  } catch {
    return undefined;
  }
};

const EventModal: React.FC<EventModalProps> = ({
  event,
  isOpen,
  onClose,
  onFavorite,
  onDismiss,
  userLocation,
  isMapView = false,
  returnToMulti = false,
  multiEvents,
  location,
  isRecurring,
  seriesEvents
}) => {
  useEffect(() => {
    if (event) {
      console.log('Event complet dans la modale:', event);
      console.log('ticketingUrl:', event.ticketingUrl);
    }
  }, [event]);

  const [imageLoaded, setImageLoaded] = useState(false);
  const [showShareNotification, setShowShareNotification] = useState(false);
  const [showCopyNotification, setShowCopyNotification] = useState(false);
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
  const [showExpandButton, setShowExpandButton] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const descriptionRef = useRef<HTMLParagraphElement>(null);
  const { series, formatRecurrencePattern } = useEventSeries(event.seriesId);
  const navigate = useNavigate();
  const { location: geoLocation } = useGeolocation();

  const getExpirationDate = () => {
    if (seriesEvents && seriesEvents.length > 0) {
      const sortedEvents = [...seriesEvents].sort((a, b) => {
        if (!a.datetime || !b.datetime) return 0;
        return new Date(b.datetime).getTime() - new Date(a.datetime).getTime();
      });
      return sortedEvents[0].datetime ? new Date(sortedEvents[0].datetime) : new Date();
    }
    return event.datetime ? new Date(event.datetime) : new Date();
  };

  const { toggleFavorite } = useFavoriteState(
    event.seriesId || event.id,
    getExpirationDate(),
    (newValue) => {
      if (onFavorite && newValue) {
        onFavorite(event.seriesId || event.id);
      }
    }
  );

  const { hiddenEvents, setHiddenEvents } = useEventContext();

  const sortedEvents = useMemo(() => {
    const now = new Date();
    return seriesEvents
      ?.filter(event => {
        if (!event.datetime) return false;
        const eventDate = new Date(event.datetime);
        return eventDate > now;
      })
      .sort((a, b) => {
        if (!a.datetime || !b.datetime) return 0;
        const dateA = new Date(a.datetime);
        const dateB = new Date(b.datetime);
        return dateA.getTime() - dateB.getTime();
      }) || [];
  }, [seriesEvents]);

  useEffect(() => {
    if (descriptionRef.current) {
      const element = descriptionRef.current;
      setShowExpandButton(element.scrollHeight > element.clientHeight);
    }
  }, [event.description]);

  const handleOpenMaps = () => {
    if (event.latitude && event.longitude) {
      openInMaps(event.latitude, event.longitude, event.location || 'Event');
    }
  };

  const effectiveLocation = userLocation ?? getCachedLocation() ?? geoLocation;
  const distance = useDistance(effectiveLocation, {
    latitude: event.latitude,
    longitude: event.longitude
  });

  const handleDismiss = () => {
    const idToHide = event.seriesId || event.id;
    setHiddenEvents([...hiddenEvents, {
      eventId: idToHide,
      timestamp: Date.now(),
      reason: 'dismissed'
    }]);
    onClose();
  };

  

  return (
    <BaseModal isOpen={isOpen} onClose={onClose}>
      <SwipeableCard
        onLike={() => {
          toggleFavorite(true);
          if (onFavorite) {
            onFavorite(event.seriesId || event.id);
          }
          onClose();
        }}
        onDismiss={() => {
          if (onDismiss) {
            onDismiss(event.seriesId || event.id);
          }
          const idToHide = event.seriesId || event.id;
          setHiddenEvents([...hiddenEvents, {
            eventId: idToHide,
            timestamp: Date.now(),
            reason: 'dismissed'
          }]);
          onClose();
        }}
        eventId={event.id}
        seriesId={event.seriesId}
      >
        <div className="event-modal">
          {/* Header avec tags et boutons d'action */}
          <div className="event-card__header">
            <div className="event-card__tags">
            
              {event.tags?.map(tag => (
                <Tag 
                  key={tag} 
                  label={tag} 
                  type="activity"
                  size="md"
                />
              ))}
            </div>
            <div className="event-card__actions">
              <ShareButton
                shareData={{
                  title: event.title,
                  text: `what2do.today\nCome discover ${event.title}`,
                  url: window.location.href
                }}
                variant="icon"
                size="lg"
              />
              <FavoriteButton
                id={event.id}
                seriesId={event.seriesId}
                expiresAt={getExpirationDate()}
                onFavoriteChange={(newValue) => {
                  if (onFavorite && newValue) {
                    onFavorite(event.seriesId || event.id);
                  }
                }}
                size="lg"
              />
            </div>
          </div>

          {/* Main content */}
          <div className="event-card__main">
            <div className="event-card__image">
              {event.image ? (
                <>
                  <img 
                    src={event.image}
                    alt={event.title}
                    className={`event-image ${imageLoaded ? 'loaded' : ''}`}
                    onLoad={() => setImageLoaded(true)}
                  />
                  <div onClick={e => e.stopPropagation()}>
                    <div 
                      className="image-open-button"
                      onClick={() => window.open(event.image, '_blank')}
                      role="button"
                      aria-label="Open image in new tab"
                    >
                      <Icon name="Maximize2" size={24} />
                    </div>
                  </div>
                </>
              ) : (
                <img 
                  src={FALLBACK_IMAGE}
                  alt=""
                  className="event-fallback-image"
                  loading="lazy"
                />
              )}
            </div>

            <div className="event-card__info">
              <h3 className="event-card__title">{event.title}</h3>
              <ContentBlock>
                <div className="event-card__datetime">
                  {event.seriesId ? (
                    <div className="event-card__time-info">
                      <span className="recurrence-pattern">
                        {formatRecurrencePattern()}
                      </span>
                    </div>
                  ) : (
                    <>
                      {event.datetime ? (
                        <>
                          <DateIcon 
                            {...formatDateForIcon(event.datetime)}
                            variant="light"
                            size="sm"
                          />
                          <div className="event-card__time-info">
                            <span className="day">
                              {formatDateTime(event.datetime, event.timezone).weekday}
                            </span>
                            <span className="hours">
                              {formatDateTime(event.datetime, event.timezone).time}
                              {event.endTime && typeof event.endTime === 'string' && (
                                ` - ${event.endTime.includes('T') 
                                  ? event.endTime.split('T')[1] 
                                  : event.endTime}`
                              )}
                            </span>
                          </div>
                        </>
                      ) : (
                        <div className="event-card__time-info">
                          <span className="day">Date to be announced</span>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </ContentBlock>
            </div>
            </div>

            <div className="event-card__footer">

          {/* Dates récurrentes */}
          {event.seriesId && sortedEvents.length > 0 && (
            <ContentBlock padding="sm">
              <div 
                className="event-card__dates interactive"
                onClick={e => e.stopPropagation()}
                onTouchStart={e => e.stopPropagation()}
                onTouchMove={e => e.stopPropagation()}
              >
                <button 
                  className="event-card__dates-toggle interactive"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsExpanded(!isExpanded);
                  }}
                >
                  <Icon name={isExpanded ? "ChevronUp" : "ChevronDown"} size={16} />
                  {isExpanded ? "Hide dates" : "Show all dates"}
                </button>
                <div 
                  className={`event-card__dates-list interactive ${isExpanded ? 'event-card__dates-list--expanded' : ''}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsExpanded(false);
                  }}
                >
                  {sortedEvents.map((event) => (
                    <div 
                      key={event.id} 
                      className="event-card__date-item interactive"
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsExpanded(false);
                      }}
                    >
                      <div className="event-card__date-info">
                        <span className="event-card__date">
                          {formatDateTime(event.datetime, event.timezone).date}
                        </span>
                        <span className="event-card__time">
                          {formatDateTime(event.datetime, event.timezone).time}
                        </span>
                      </div>
                      <span className={`event-card__status event-card__status--${event.isException ? 'modified' : 'upcoming'}`}>
                        {event.isException ? 'Modified' : 'Upcoming'}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </ContentBlock>
          )}
          
          {/* Prix et réservation */}
          {event.prices && event.prices.length > 0 && event.prices.some(price => price.amount > 0) &&
            <ContentBlock>
              <span className="modal-item-content">
                <span className="modal-item-icon">
                  <Icon name="Ticket" size={32} />
                </span>
                <span className="price-booking-container">
                  {event.prices && event.prices.length > 0 && (
                    <span className="price-list">
                      {event.prices.map((price, index) => (
                        <span key={index} className="price-item">
                          {price.amount === 0 ? (
                            <span className="price-free">Free</span>
                          ) : (
                            <span className="price-amount">
                              {price.amount} {price.currency === 'EUR' ? '€' : price.currency}
                            </span>
                          )}
                          {price.description && (
                            <span className="price-description">
                              {' - '}{price.description}
                            </span>
                          )}
                        </span>
                      ))}
                      {event.ticketingUrl && (
                        <span className="price-item">
                          {(event.ticketingUrl.startsWith('http') || event.ticketingUrl.startsWith('www.')) ? (
                            <a 
                              href={event.ticketingUrl.startsWith('http') 
                                ? event.ticketingUrl 
                                : `https://${event.ticketingUrl}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="booking-link"
                            >
                              {event.ticketingUrl}
                            </a>
                          ) : (
                            <span className="booking-text">{event.ticketingUrl}</span>
                          )}
                        </span>
                      )}
                    </span>
                  )}
                </span>
              </span>
            </ContentBlock>
          }

          
          {/* Footer avec localisation */}
          
            <ContentBlock>
              <div className="event-card__location">
                {/* Carte à gauche */}
                {!isMapView && event.latitude && event.longitude && (
                  <div className="event-modal-map">
                    <MapContainer 
                      center={[event.latitude, event.longitude]}
                      zoom={15}
                      scrollWheelZoom={false}
                      className="event-modal-map-container"
                      dragging={false}
                      zoomControl={false}
                    >
                      <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />
                      <LeafletMarker 
                        position={[event.latitude, event.longitude]}
                      />
                    </MapContainer>
                  </div>
                )}
                {/* Informations à droite */}
                
                  <div className="event-card__location-details">
                  <div className="event-card__location-top-line">
                    <span className="event-card__location-name">{event.locationName}</span>
                    {distance && (
                      <span className="event-card__location-distance">{distance}</span>
                    )}
                 </div>
                  {event.latitude && event.longitude && (
                    <Button 
                    className='directions-button'
                      variant="outline"
                      size="sm"
                      position="bottom"
                      onClick={handleOpenMaps}
                    >
                      Get directions
                    </Button>
                  )}
                </div>
              </div>
            </ContentBlock>

            {/* N'afficher le bloc que si nous avons un nom d'organisateur */}
            {event.organizer && (
              <ContentBlock 
                className="event-modal-organizer-block"
                onClick={() => event.organizerId && navigate(`/organizer-profile/${event.organizerId}`)}
                padding="sm"
              >
                <div className="modal-item-content">
                  <div className="modal-item-icon">
                    {event.organizerImage ? (
                      <img 
                        src={event.organizerImage} 
                        alt={event.organizer} 
                        className="event-modal-organizer-avatar"
                      />
                    ) : (
                      <div className="event-modal-organizer-avatar-fallback">
                        {event.organizer.charAt(0).toUpperCase()}
                      </div>
                    )}
                  </div>
                  <div className="event-modal-organizer-info">
                    <span className="event-modal-organizer-name">
                      {event.organizer}
                    </span>
                    {event.organizerSubtitle && (
                      <span className="event-modal-organizer-subtitle">
                        {event.organizerSubtitle}
                      </span>
                    )}
                  </div>
                </div>
              </ContentBlock>
            )}

            {/* Description */}
            {event.description && (
              <ContentBlock>
                <div className="modal-description-container">
                  <p className="modal-description">
                    {event.description}
                  </p>
                </div>
              </ContentBlock>
            )}
          </div>
        </div>
      </SwipeableCard>

      <NotificationBubble
        show={showShareNotification}
        message="Link copied! ✨"
        type="success"
        duration={1500}
        onHide={() => setShowShareNotification(false)}
      />

      <NotificationBubble
        show={showCopyNotification}
        message="Contact copied! ✨"
        type="success"
        duration={1500}
        onHide={() => setShowCopyNotification(false)}
      />
    </BaseModal>
  );
};

export default EventModal;
