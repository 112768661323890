import { useState, useEffect, useRef } from 'react';
import { AnimatePresenceProps } from 'framer-motion';

interface AnimatedItemData {
  content: React.ReactNode;
  [key: string]: any; // Pour les autres propriétés possibles
}

interface AnimatedItem {
  id: string;
  data: AnimatedItemData;
}

interface AnimationConfig {
  exitDuration?: number;
}

export const useAnimatedList = (
  rawItems: { id: string; content: React.ReactNode }[],
  config: AnimationConfig = { exitDuration: 300 }
) => {
  // Transformer les items au format attendu
  const items = rawItems.map(item => ({
    id: item.id,
    data: {
      content: item.content
    }
  }));

  const [visibleItems, setVisibleItems] = useState<AnimatedItem[]>([]);
  const isFirstRender = useRef(true);
  
  useEffect(() => {
    // Premier rendu : on initialise simplement
    if (isFirstRender.current) {
      isFirstRender.current = false;
      if (items.length > 0) {
        setVisibleItems(items);
      }
      return;
    }

    // Rendus suivants : on ne met à jour que si nécessaire
    const currentIds = visibleItems.map(item => item.id).join(',');
    const newIds = items.map(item => item.id).join(',');
    
    if (currentIds !== newIds) {
      console.log('🔄 AnimatedList: Mise à jour des items', {
        current: visibleItems.length,
        new: items.length
      });
      setVisibleItems(items);
    }
  }, [items, visibleItems]);

  const getMotionProps = (itemId: string) => ({
    initial: { opacity: 0, y: 50 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -50 },
    transition: { duration: config.exitDuration || 300 }
  });

  return {
    animatedItems: visibleItems,
    getMotionProps
  };
}; 