import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { EventProvider } from './contexts/EventContext';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// Initialiser l'app et le SW
const initializeApp = async () => {
  if ('serviceWorker' in navigator) {
    try {
      const registration = await navigator.serviceWorker.register('/service-worker.js');
      console.log('✅ Service Worker enregistré:', registration);
    } catch (error) {
      console.error('❌ Erreur Service Worker:', error);
    }
  }

  // Rendre l'app avec le Router
  root.render(
    <BrowserRouter>
      <EventProvider>
        <App />
      </EventProvider>
    </BrowserRouter>
  );
};

// Démarrer l'initialisation
initializeApp();