import React, { useState, useRef, useEffect } from 'react';
import { Input } from '../../primitives/Input';
import { Icon } from '../../primitives/Icon';
import { Autocomplete } from '@react-google-maps/api';
import { MonkeyProps } from '../../types/monkey.types';
import { useGoogleMaps } from '../../../../contexts/GoogleMapsContext';
import './LocationFilter.css';

interface LocationFilterProps extends MonkeyProps {
  onLocationChange: (location: { 
    latitude: number; 
    longitude: number;
    shouldCenter?: boolean;
  }) => void;
  isLoading?: boolean;
  currentLocation?: { latitude: number; longitude: number } | null;
}

const LocationFilter: React.FC<LocationFilterProps> = ({
  onLocationChange,
  isLoading,
  currentLocation,
  showMonkey = false,
  monkeyVariant = 'singeOrange',
  monkeyType = 'walking',
  monkeyLookAt = 'right',
  monkeyPosition = 'position1'
}) => {
  const [searchInput, setSearchInput] = useState('');
  const [isGeolocating, setIsGeolocating] = useState(false);
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const selectedNameRef = useRef<string>('');
  
  // Utiliser le contexte Google Maps global au lieu de charger l'API individuellement
  const { isLoaded } = useGoogleMaps();

  // Gérer la position actuelle
  useEffect(() => {
    if (!window.google || selectedNameRef.current) return;
    
    if (currentLocation) {
      // Vérifier que l'API Google Maps est chargée
      if (window.google?.maps?.Geocoder) {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({
          location: { lat: currentLocation.latitude, lng: currentLocation.longitude }
        }, (results, status) => {
          if (status === 'OK' && results?.[0]) {
            const address = results[0].formatted_address;
            setSearchInput(address);
            selectedNameRef.current = address;
          } else {
            setSearchInput('');
            selectedNameRef.current = '';
          }
        });
      } else {
        // Si l'API n'est pas chargée, on affiche juste les coordonnées
        setSearchInput(`${currentLocation.latitude.toFixed(4)}, ${currentLocation.longitude.toFixed(4)}`);
      }
    } else {
      setSearchInput('');
      selectedNameRef.current = '';
    }
  }, [currentLocation]);
  
  const handlePlaceSelect = () => {
    const place = autocompleteRef.current?.getPlace();
    
    if (place && place.geometry?.location) {
      const newLocation = {
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        timestamp: Date.now(),
        source: 'manual' as const
      };
      
      // Sauvegarder dans le cache et déclencher l'événement storage
      localStorage.setItem('userLocation', JSON.stringify(newLocation));
      window.dispatchEvent(new StorageEvent('storage', {
        key: 'userLocation',
        newValue: JSON.stringify(newLocation)
      }));
      
      onLocationChange({ 
        latitude: newLocation.latitude, 
        longitude: newLocation.longitude,
        shouldCenter: true
      });

      // Utiliser directement le nom de l'établissement s'il existe
      const displayName = place.name && place.types?.includes('establishment')
        ? place.name
        : place.formatted_address || '';
        
      selectedNameRef.current = displayName;
      setSearchInput(displayName);
    }
  };

  // Vérifier périodiquement si la valeur a été écrasée
  useEffect(() => {
    if (!selectedNameRef.current) return;

    const checkInterval = setInterval(() => {
      const inputElement = document.getElementById('location-input') as HTMLInputElement;
      if (inputElement && inputElement.value !== selectedNameRef.current) {
        setSearchInput(selectedNameRef.current);
      }
    }, 100);

    const timeout = setTimeout(() => {
      clearInterval(checkInterval);
      selectedNameRef.current = '';
    }, 1000);

    return () => {
      clearInterval(checkInterval);
      clearTimeout(timeout);
    };
  }, [searchInput, currentLocation]);

  const handleInputChange = (value: string) => {
    setSearchInput(value);
  };

  const handleGeolocation = () => {
    setIsGeolocating(true);
    
    if (navigator.geolocation) {
      const timeoutId = setTimeout(() => {
        setIsGeolocating(false);
        console.error('Geolocation timeout');
      }, 5000); // 5s timeout

      navigator.geolocation.getCurrentPosition(
        async (position) => {
          clearTimeout(timeoutId);
          const newLocation = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            timestamp: Date.now(),
            source: 'manual' as const
          };
          
          try {
            // Vérifier que la position est valide
            if (isNaN(newLocation.latitude) || isNaN(newLocation.longitude)) {
              throw new Error('Invalid coordinates');
            }

            localStorage.setItem('userLocation', JSON.stringify(newLocation));
            window.dispatchEvent(new StorageEvent('storage', {
              key: 'userLocation',
              newValue: JSON.stringify(newLocation)
            }));
            
            onLocationChange({ 
              latitude: newLocation.latitude, 
              longitude: newLocation.longitude,
              shouldCenter: true
            });
            
            setSearchInput(`${position.coords.latitude.toFixed(4)}, ${position.coords.longitude.toFixed(4)}`);
          } catch (error) {
            console.error('Location processing error:', error);
          } finally {
            setIsGeolocating(false);
          }
        },
        (error) => {
          clearTimeout(timeoutId);
          console.error('Geolocation error:', error);
          setIsGeolocating(false);
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0
        }
      );
    }
  };

  const getCityName = async (lat: number, lon: number) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}`
      );
      const data = await response.json();
      return data.address.city || data.address.town || data.address.village;
    } catch (error) {
      console.error('Error retrieving city:', error);
      return undefined;
    }
  };

  return (
    <div className="location-filter">
      {isLoaded ? (
        <Autocomplete
          onLoad={(autocomplete) => {
            autocompleteRef.current = autocomplete;
            autocomplete.setFields(['geometry', 'formatted_address', 'name', 'types']);
          }}
          onPlaceChanged={handlePlaceSelect}
        >
          <div className="location-input-wrapper">
            <Input
              id="location-input"
              name="location"
              type="text"
              value={searchInput}
              onChange={handleInputChange}
              placeholder="Where?"
              icon="MapPin"
              className="location-input"
              disabled={isLoading}
              showMonkey={showMonkey}
              monkeyVariant={monkeyVariant}
              monkeyType={monkeyType}
              monkeyLookAt={monkeyLookAt}
              monkeyPosition={monkeyPosition}
            />
            <button 
              className={`location-geolocate-btn ${isGeolocating ? 'loading' : ''}`}
              onClick={handleGeolocation}
              disabled={isLoading || isGeolocating}
              aria-label="Use my location"
            >
              <Icon name={isGeolocating ? "Loader" : "Crosshair"} size={20} />
            </button>
          </div>
        </Autocomplete>
      ) : (
        <Input
          id="location-input"
          name="location"
          type="text"
          value={searchInput}
          placeholder="Loading..."
          icon="MapPin"
          className="location-input"
          disabled
          onChange={() => {}}
          showMonkey={showMonkey}
          monkeyVariant={monkeyVariant}
          monkeyType={monkeyType}
          monkeyLookAt={monkeyLookAt}
          monkeyPosition={monkeyPosition}
        />
      )}
    </div>
  );
};

export default LocationFilter;