import React from 'react';
import './styles/foundation-styles.css';

const ColorSection = () => {
  const primitiveColors = {
    'Green': [
      { name: '--color-green-100', value: '#54B7AB ' },
      { name: '--color-green-200', value: '#258E81' },
      { name: '--color-green-300', value: '#1D6E64' },
      { name: '--color-green-400', value: '#154F48' },
    ],
    'Red': [
      { name: '--color-red-200', value: '#FA8962' },
      { name: '--color-red-300', value: '#C16A4C' },
      { name: '--color-red-400', value: '#874A35' },
    ],
    'Blue': [
      { name: '--color-blue-100', value: '#E8F0F6' },
      { name: '--color-blue-200', value: '#A8C5D8' },
      { name: '--color-blue-300', value: '#6D99B3' },
      { name: '--color-blue-400', value: '#486A7D' },
    ],
    'Beige': [
      { name: '--color-beige-100', value: '#f2e9db' },
      { name: '--color-beige-200', value: '#e4d8c4' },
      { name: '--color-beige-300', value: '#d6c7ad' },
    ],
    'Gray': [
      { name: '--color-gray-100', value: '#f2f2f2' },
      { name: '--color-gray-200', value: '#e5e7e5' },
      { name: '--color-gray-300', value: '#717171' },
      { name: '--color-gray-400', value: '#4a4a4a' },
      { name: '--color-gray-500', value: '#2d2d2d' },
    ],
  };

  const semanticColors = {
    'Brand Colors': [
      { name: '--primary-color', value: 'var(--color-green-300)' },
      { name: '--primary-light', value: 'var(--color-green-200)' },
      { name: '--primary-dark', value: 'var(--color-green-400)' },
      { name: '--secondary-color', value: 'var(--color-red-300)' },
      { name: '--secondary-light', value: 'var(--color-red-200)' },
      { name: '--secondary-dark', value: 'var(--color-red-400)' },
    ],
    'Text Colors': [
      { name: '--text-primary', value: 'var(--color-gray-500)' },
      { name: '--text-secondary', value: 'var(--color-gray-400)' },
      { name: '--text-tertiary', value: 'var(--color-gray-300)' },
      { name: '--text-on-primary', value: 'var(--color-gray-100)' },
      { name: '--text-on-secondary', value: 'var(--color-gray-100)' },
    ],
    'Background Colors': [
      { name: '--color-background-primary', value: '#f9f4ee' },
      { name: '--color-background-secondary', value: '#f2e9db' },
      { name: '--color-background-tertiary', value: '#e4d8c4' },
    ],
    'Border Colors': [
      { name: '--color-border', value: '#d6c7ad' },
      { name: '--color-contrast', value: '#cbbda7' },
      { name: '--border-color-dark', value: 'color-mix(in srgb, var(--color-border) 85%, black)' },
      { name: '--border-color-light', value: 'color-mix(in srgb, var(--color-border) 85%, white)' },
    ],
  };

  return (
    <div className="foundation-section">
      <h2>Colors</h2>
      
      <section>
        <h3>Primitive Colors</h3>
        <p>These are our base colors that serve as the foundation for our semantic color system.</p>
        
        {Object.entries(primitiveColors).map(([category, colors]) => (
          <div key={category} className="color-category">
            <h4>{category}</h4>
            <div className="color-grid">
              {colors.map(color => (
                <div key={color.name} className="color-item">
                  <div 
                    className="color-preview" 
                    style={{ backgroundColor: color.value }}
                  />
                  <div className="color-info">
                    <code className="color-name">{color.name}</code>
                    <span className="color-value">{color.value}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </section>

      <section>
        <h3>Semantic Colors</h3>
        <p>These are our semantic colors that provide meaning and consistency across the application.</p>
        
        {Object.entries(semanticColors).map(([category, colors]) => (
          <div key={category} className="color-category">
            <h4>{category}</h4>
            <div className="color-grid">
              {colors.map(color => (
                <div key={color.name} className="color-item">
                  <div 
                    className="color-preview" 
                    style={{ 
                      backgroundColor: color.value.startsWith('#') 
                        ? color.value 
                        : color.value.startsWith('var') 
                          ? `var(${color.value.match(/--[a-z-]+[0-9]*/)?.at(0) || color.value})`
                          : color.value.startsWith('color-mix')
                            ? color.value
                            : color.value
                    }}
                  />
                  <div className="color-info">
                    <code className="color-name">{color.name}</code>
                    <span className="color-value">{color.value}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </section>

      <section>
        <h3>Usage Guidelines</h3>
        <ul className="guidelines-list">
          <li>Use semantic color variables instead of primitive colors directly</li>
          <li>Primary colors should be used for main actions and key UI elements</li>
          <li>Secondary colors are for complementary actions and highlights</li>
          <li>Ensure sufficient contrast for accessibility (WCAG 2.1 AA standards)</li>
          <li>Background colors should be used consistently across similar contexts</li>
        </ul>
      </section>
    </div>
  );
};

export default ColorSection; 