import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

import { Event } from '../../types/event.types';
import EventList from '../../components/organizer/EventList/EventList';
import EventFormContainer from '../../components/organizer/EventForm/EventFormContainer';
import OrganizerStat from '../../components/organizer/OrganizerStat/OrganizerStat';
import './OrganizerPage.css';
import { useOrganizer } from '../../contexts/OrganizerContext';
import { useOrganizerEvents } from '../../hooks/useEvents';
import { eventService } from '../../firebase/event.service';
import { 
  ContentBlock,
  Button,
  Icon,
  NotificationBubble
} from '../../pages/DesignSystem';

import { OrganizerBottomNav } from '../DesignSystem/patterns/BottomNav/OrganizerBottomNav';
import { ORGANIZER_HELP_MESSAGES, getRandomTip } from './OrganizerHelpMessages';

type ActiveView = 'list' | 'create' | 'stats';
type ActiveTab = 'active' | 'draft' | 'past';

interface StatsCardProps {
  title: string;
  value: number;
  icon: React.ReactNode;
  variant: 'green' | 'blue' | 'purple';
}

const StatsCard: React.FC<StatsCardProps> = ({ title, value, icon, variant }) => (
  <ContentBlock
    variant="light"
    size="md"
  >
    <div className="stat-content">
      <div className="stat-info">
        <p className="stat-value">{value}</p>
        <p className="stat-label">{title}</p>
      </div>
      <div className="stat-icon">
        {icon}
      </div>
    </div>
  </ContentBlock>
);

const OrganizerPage: React.FC = () => {
  const { user } = useAuth();
  const { organizerProfile, loading: profileLoading } = useOrganizer();
  const [activeView, setActiveView] = useState<ActiveView>('list');
  const [activeTab, setActiveTab] = useState<ActiveTab>('active');
  const [showHelp, setShowHelp] = useState(true);
  const [selectedEvent, setSelectedEvent] = useState<Event | undefined>();
  
  const hasShownNotifications = useRef(false);

  const { activeEvents, pastEvents, draftEvents, loading: eventsLoading, error: eventsError } 
  = useOrganizerEvents(user?.uid || '');
  const tabsRef = useRef<(HTMLButtonElement | null)[]>([]);
  const sliderRef = useRef<HTMLDivElement>(null);

  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationType, setNotificationType] = useState<'success' | 'error' | 'info'>('success');

  const [randomTip, setRandomTip] = useState(getRandomTip());

  const navigate = useNavigate();

  const openModal = () => {
    console.log('Opening modal, current selectedEvent:', selectedEvent);
    setActiveView('create'); 
    window.history.pushState({ modal: true }, '');
  };
  
  const closeModal = () => {
    console.log('Closing modal, clearing selectedEvent');
    setActiveView('list'); 
    setSelectedEvent(undefined);
  };

  const showToast = (message: string, type: 'success' | 'error' | 'info' = 'success') => {
    setNotificationMessage(message);
    setNotificationType(type);
    setShowNotification(true);
  };

  const handleEventStatusChange = async (eventId: string, newStatus: 'draft' | 'active') => {
    try {
      await eventService.updateEvent(eventId, { 
        status: newStatus,
        updatedAt: new Date()
      });
      
      showToast(
        newStatus === 'active'
          ? "Your event is now live on the public platform! ✨"
          : "Event has been removed from the public platform"
      );
    } catch (error) {
      console.error('Error changing status:', error);
      showToast("An error occurred while changing the status", 'error');
    }
  };

  const handleEventSubmit = async (eventData: Omit<Event, 'id'> & { id?: string }) => {
    try {
      if (eventData.id) {
        // Mise à jour d'un événement existant
        const { id, ...updateData } = eventData;
        await eventService.updateEvent(id, updateData);
        console.log('Événement mis à jour avec succès !');
      } else {
        // Création d'un nouvel événement
        const newEventData = {
          ...eventData,
          organizerId: user?.uid || '',
          views: 0
        };
        await eventService.createEvent(newEventData);
        console.log('Événement créé avec succès !');
      }
      
      closeModal();
    } catch (error) {
      console.error('Erreur lors de la sauvegarde:', error);
    }
  };

  const updateSliderPosition = () => {
    const activeTabElement = tabsRef.current[
      activeTab === 'active' ? 0 : activeTab === 'draft' ? 1 : 2
    ];
    const slider = sliderRef.current;

    if (activeTabElement && slider) {
      slider.style.width = `${activeTabElement.offsetWidth}px`;
      slider.style.transform = `translateX(${activeTabElement.offsetLeft}px)`;
    }
  };

  const getFilteredEvents = () => {
    switch (activeTab) {
      case 'active':
        return activeEvents;
      case 'draft':
        return draftEvents;
      case 'past':
        return pastEvents;
      default:
        return [];
    }
  };

  useEffect(() => {
    const handlePopState = (event: PopStateEvent) => {
      if (activeView === 'create') {
        closeModal();
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [activeView]);

  useEffect(() => {
    updateSliderPosition();
  }, [activeTab]);

  
  
  const stats = {
    activeEvents: activeEvents.length,
    totalViews: [...activeEvents, ...pastEvents, ...draftEvents]
      .reduce((sum, event) => sum + (event.views || 0), 0),
    totalAttendees: [...activeEvents, ...pastEvents, ...draftEvents].length * 10,
  };

  if (eventsLoading || profileLoading) {
    return (
      <div className="organizer-page">
        <main className="organizer-main">
          <ContentBlock variant="light" centered>
            <p>Loading...</p>
          </ContentBlock>
        </main>
      </div>
    );
  }

  if (eventsError) {
    return (
      <div className="organizer-page">
        <main className="organizer-main">
          <ContentBlock variant="light" centered>
            <p>Error: {eventsError}</p>
          </ContentBlock>
        </main>
      </div>
    );
  }

  return (
    <div className="organizer-page">
      <main className="organizer-content">
        {showHelp && (
          <ContentBlock
            variant="light"
            title="Welcome to your organizer space !"
            className="help-section"
          >
            <div className="help-content">
              <ul>
                {ORGANIZER_HELP_MESSAGES.fixed.map((message, index) => (
                  <li key={index}>{message}</li>
                ))}
                {ORGANIZER_HELP_MESSAGES.tips.length > 0 && (
                  <li className="tip">{randomTip}</li>
                )}
              </ul>
              <Button variant="outline" onClick={() => setShowHelp(false)}>
                Got it, thanks!
              </Button>
            </div>
          </ContentBlock>
        )}

        {/* Temporarily hiding stats section until proper implementation
        <div className="stats-grid">
          <StatsCard 
            title="Active Events" 
            value={stats.activeEvents} 
            icon={<Calendar className="icon" />} 
            variant="green"
          />
          <StatsCard 
            title="Total Views" 
            value={stats.totalViews} 
            icon={<TrendingUp className="icon" />} 
            variant="blue"
          />
          <StatsCard 
            title="Total Attendees" 
            value={stats.totalAttendees} 
            icon={<Users className="icon" />} 
            variant="purple"
          />
        </div>
        */}

        <EventList 
          events={getFilteredEvents()}
          onEventSelect={(event) => {
            setSelectedEvent(event);
            openModal();
          }}
          onEventStatusChange={handleEventStatusChange}
        />

        <div className="create-event-button-container">
          <Button 
            variant="primary"
            size="lg"
            className="create-event-button"
            onClick={() => {
              console.log('Create event button clicked');
              setSelectedEvent(undefined);
              openModal();
            }}
          >
            <Icon name="Plus" size={20} />
            <span>Create Event</span>
          </Button>
        </div>

        <OrganizerBottomNav
          activeTab={activeTab}
          onTabChange={setActiveTab}
          className="organizer-bottom-nav"
          onProfileClick={() => navigate('/organizer/profile')}
        />

        {activeView === 'stats' && (
          <OrganizerStat events={[...activeEvents, ...pastEvents, ...draftEvents]} />
        )}
      </main>

      {activeView === 'create' && (
        <EventFormContainer 
          event={selectedEvent}
          organizerId={user?.uid || ''}
          onSubmit={handleEventSubmit}
          onClose={closeModal}
        />
      )}


      <NotificationBubble
        show={showNotification}
        message={notificationMessage}
        type={notificationType}
        onHide={() => setShowNotification(false)}
      />
    </div>
  );
};

export default OrganizerPage;

