import React, { useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useSwipeCard } from '../../../../hooks/useSwipeCard';
import './BaseModal.css';
import { useAnimatedList } from '../../../../hooks/useAnimatedList';
import './SwipeableCard.css';
import { Icon } from '../../../DesignSystem';

interface BaseModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  className?: string;
}

export const SwipeableCard = ({ 
  children, 
  onLike, 
  onDismiss,
  eventId,
  seriesId,
  disableSwipe = false
}: {
  children: React.ReactNode;
  onLike: () => void;
  onDismiss: () => void;
  eventId: string;
  seriesId?: string;
  disableSwipe?: boolean;
}) => {
  const { dragProps, style } = useSwipeCard(
    eventId,
    onLike,
    onDismiss,
    seriesId
  );

  if (disableSwipe) {
    return <>{children}</>;
  }

  return (
    <motion.div
      {...dragProps}
      style={style}
    >
      {children}
    </motion.div>
  );
};

const BaseModal: React.FC<BaseModalProps> = ({
  isOpen,
  onClose,
  children,
  className = '',
}) => {
  // Gestion du scroll du body
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen]);

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div 
            className="base-modal__overlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            onClick={onClose}
          />
          <motion.div 
            className="swipe-hint"
            initial={{ opacity: 0 }}
            animate={{ 
              opacity: 1,
              transition: {
                duration: 0.3,
      
              }
            }}
          >
            <div className="swipe-hint__left">
              <Icon name="ArrowLeft" color="var(--text-on-primary)" size={24} />
              <h4>Swipe bye bye</h4>
            </div>
            <div className="swipe-hint__right">
              <h4>Swipe to like</h4>
              <Icon name="ArrowRight" color="var(--text-on-primary)" size={24} />
            </div>
          </motion.div>
          
          <motion.div
            className={`base-modal ${className}`}
            initial={{ opacity: 0, x: '-100%' }}
            animate={{ 
              opacity: 1, 
              x: 0,
              transition: {
                type: "spring",
                damping: 30,
                stiffness: 300,
              }
            }}
          >
            <div className="base-modal__container">
              {children}
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

export const AnimatedList = ({ children }: { children: React.ReactNode }) => {
  // Convertir les enfants React en tableau avec IDs
  const items = React.Children.toArray(children).map((child, index) => ({
    id: `item-${index}`,
    content: child
  }));

  const { animatedItems, getMotionProps } = useAnimatedList(items, {
    exitDuration: 300
  });

  return (
    <AnimatePresence>
      {animatedItems.map(item => (
        <motion.div
          key={item.id}
          {...getMotionProps(item.id)}
        >
          {item.data.content}
        </motion.div>
      ))}
    </AnimatePresence>
  );
};

export default BaseModal; 