import React from 'react';
import './styles/foundation-styles.css';

const AnimationsSection = () => {
  const transitions = [
    { 
      name: '--transition-fast',
      duration: '--duration-200',
      timing: '--transition-timing-smooth',
      description: 'Pour les interactions rapides comme les hovers'
    },
    { 
      name: '--transition-normal',
      duration: '--duration-300',
      timing: '--transition-timing-smooth',
      description: 'Pour la plupart des transitions standard'
    },
    { 
      name: '--transition-slow',
      duration: '--duration-500',
      timing: '--transition-timing-smooth',
      description: 'Pour les transitions plus élaborées'
    },
    { 
      name: '--transition-bounce',
      duration: '--duration-300',
      timing: '--transition-timing-bounce',
      description: 'Pour les animations avec effet de rebond'
    },
    { 
      name: '--transition-spring',
      duration: '--duration-300',
      timing: '--transition-timing-spring',
      description: 'Pour les animations avec effet de ressort'
    }
  ];

  const timingFunctions = [
    {
      name: '--transition-timing-smooth',
      value: 'cubic-bezier(0.4, 0, 0.2, 1)',
      description: 'Courbe douce et naturelle'
    },
    {
      name: '--transition-timing-bounce',
      value: 'cubic-bezier(0.17, 0.93, 0.38, 1)',
      description: 'Effet de rebond léger'
    },
    {
      name: '--transition-timing-spring',
      value: 'cubic-bezier(0.68, -0.55, 0.265, 1.55)',
      description: 'Effet de ressort dynamique'
    }
  ];

  return (
    <div className="foundation-section">
      <h2>Animations & Transitions</h2>
      
      <section>
        <h3>Transitions précomposées</h3>
        <p>Ces transitions combinent une durée et une courbe d'animation pour des cas d'usage spécifiques.</p>
        
        <div className="transitions-grid">
          {transitions.map(transition => (
            <div 
              key={transition.name} 
              className="transition-item"
              data-transition={transition.name.replace('--', '')}
            >
              <div className="transition-preview">
                <div className="animated-box" />
              </div>
              <div className="transition-info">
                <code className="transition-name">{transition.name}</code>
                <div className="transition-details">
                  <span>Durée: {transition.duration}</span>
                  <span>Timing: {transition.timing}</span>
                </div>
                <p className="transition-description">{transition.description}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section>
        <h3>Courbes d'animation</h3>
        <p>Ces courbes définissent la progression de l'animation dans le temps.</p>
        
        <div className="timing-functions-grid">
          {timingFunctions.map(timing => (
            <div 
              key={timing.name} 
              className="timing-function-item"
            >
              <div className="timing-preview">
                <div 
                  className="animated-dot"
                  style={{ 
                    transition: `transform 1.5s ${timing.value}`
                  }}
                />
              </div>
              <div className="timing-info">
                <code className="timing-name">{timing.name}</code>
                <span className="timing-value">{timing.value}</span>
                <p className="timing-description">{timing.description}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section>
        <h3>Usage Guidelines</h3>
        <ul className="guidelines-list">
          <li>Utilisez des transitions pour donner du feedback sur les interactions</li>
          <li>Gardez les animations courtes pour ne pas ralentir l'expérience utilisateur</li>
          <li>Choisissez la courbe d'animation en fonction de l'effet désiré</li>
          <li>Évitez les animations qui peuvent donner le mal des transports</li>
          <li>Pensez à désactiver les animations si l'utilisateur le préfère (prefers-reduced-motion)</li>
        </ul>
      </section>

      <section>
        <h3>Exemples d'utilisation</h3>
        <div className="animation-examples">
          <div className="animation-example">
            <h4>Hover d'un bouton</h4>
            <button className="demo-button">
              Passez la souris
            </button>
            <p className="animation-label">Utilise --transition-fast pour un feedback immédiat</p>
          </div>
          
          <div className="animation-example">
            <h4>Ouverture d'une carte</h4>
            <div className="demo-card">
              <div className="demo-card-content" />
            </div>
            <p className="animation-label">Utilise --transition-normal pour une expansion fluide</p>
          </div>
          
          <div className="animation-example">
            <h4>Animation avec rebond</h4>
            <div className="demo-bounce">
              <div className="demo-bounce-item" />
            </div>
            <p className="animation-label">Utilise --transition-bounce pour un effet ludique</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AnimationsSection; 