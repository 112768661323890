import React, { useState, useMemo } from 'react';
import { Event } from '../../../types/event.types';
import { useEventSeries } from '../../../hooks/useEventSeries';
import RecurringEventDetails from './RecurringEventDetails';
import { 
  StatusMessage, 
  ContentBlock,
  Icon,
  Button,
  LogoSVG
} from '../../../pages/DesignSystem';
import { formatDateTime } from '../../../utils/dateFormatter';
import './EventList.css';

interface Message {
  text: string;
  id: string;
  type: 'success' | 'error' | 'info' | 'warning';
}

interface EventListProps {
  events: Event[];
  onEventSelect: (event: Event, modifyType?: 'single' | 'series') => void;
  onEventStatusChange?: (eventId: string, newStatus: 'draft' | 'active') => void;
}

interface GroupedEvents {
  single: Event[];
  recurring: {
    [seriesId: string]: {
      mainEvent: Event;
      events: Event[];
    };
  };
}

const EventList: React.FC<EventListProps> = ({ events, onEventSelect, onEventStatusChange }) => {
  const [expandedSeries, setExpandedSeries] = useState<string[]>([]);
  const [showStatusMessage, setShowStatusMessage] = useState<string | null>(null);
  const [statusError, setStatusError] = useState<string | null>(null);

  const getEventDate = (event: Event): Date | null => {
    return event.datetime || null;
  };

  const validateEventForPublication = (event: Event): boolean => {
    if (!event.datetime) {
      setStatusError("Date and time are required to publish the event");
      return false;
    }
    if (!event.location) {
      setStatusError("Location is required to publish the event");
      return false;
    }
    return true;
  };

  const handleStatusChange = (event: Event, newStatus: 'draft' | 'active') => {
    if (!onEventStatusChange) return;

    setStatusError(null);

    if (newStatus === 'active' && !validateEventForPublication(event)) {
      return;
    }

    onEventStatusChange(event.id, newStatus);
    const message = newStatus === 'draft' 
      ? "Event has been removed from the public platform"
      : "Event is now visible on the public platform";

    setShowStatusMessage(message);
    setTimeout(() => setShowStatusMessage(null), 3000);
  };

  const groupedEvents = useMemo(() => {
    const grouped: GroupedEvents = {
      single: [],
      recurring: {}
    };

    events.forEach(event => {
      if (event.seriesId) {
        if (!grouped.recurring[event.seriesId]) {
          grouped.recurring[event.seriesId] = {
            mainEvent: event,
            events: []
          };
        }
        grouped.recurring[event.seriesId].events.push(event);
        
        const currentMainDate = getEventDate(grouped.recurring[event.seriesId].mainEvent);
        const newDate = getEventDate(event);
        if (currentMainDate && newDate && newDate < currentMainDate) {
          grouped.recurring[event.seriesId].mainEvent = event;
        }
      } else {
        grouped.single.push(event);
      }
    });

    return grouped;
  }, [events]);

  const handleEditSeries = (event: Event) => {
    const seriesEvents = event.seriesId ? groupedEvents.recurring[event.seriesId].events : [];
    const enrichedEvent = {
      ...event,
      isRecurring: true,
      seriesEvents
    };
    onEventSelect(enrichedEvent, 'series');
  };

  const handleEditOccurrence = (event: Event) => {
    const enrichedEvent = {
      ...event,
      isRecurring: false
    };
    onEventSelect(enrichedEvent, 'single');
  };

  return (
    <div className="organizer-list">
      <StatusMessage 
        messages={showStatusMessage ? [{
          id: Date.now(),
          text: showStatusMessage,
          type: 'success'
        }] : []}
        onDismiss={() => setShowStatusMessage(null)}
      />
      <StatusMessage 
        messages={statusError ? [{
          id: Date.now(),
          text: statusError,
          type: 'error'
        }] : []}
        onDismiss={() => setStatusError(null)}
      />
      <div className="organizer-list__container">
        {Object.entries(groupedEvents.recurring).map(([seriesId, group]) => (
          <div 
            key={seriesId} 
            className="organizer-card organizer-card--recurring"
            onClick={(event) => {
              const target = event?.target as HTMLElement;
              if (target.closest('.organizer-card__actions')) return;
              
              const currentExpanded = expandedSeries.includes(seriesId);
              setExpandedSeries(currentExpanded 
                ? expandedSeries.filter(id => id !== seriesId)
                : [...expandedSeries, seriesId]
              );
            }}
          >
            <RecurringEventDetails
              mainEvent={group.mainEvent}
              seriesEvents={group.events}
              onEditSeries={handleEditSeries}
              onEditOccurrence={handleEditOccurrence}
              onStatusChange={handleStatusChange}
              isExpanded={expandedSeries.includes(seriesId)}
              onToggleExpand={(expanded) => {
                setExpandedSeries(expanded 
                  ? [...expandedSeries, seriesId]
                  : expandedSeries.filter(id => id !== seriesId)
                );
              }}
            />
          </div>
        ))}
        {groupedEvents.single.map(event => (
          <div 
            key={event.id}
            className="organizer-card"
            onClick={() => onEventSelect(event)}
          >
            <div className="organizer-card__header">
              {event.image && (
                <div className="organizer-card__image">
                  <img 
                    src={event.image} 
                    alt={event.title || 'Untitled'} 
                    className="organizer-card__image-content"
                  />
                </div>
              )}
              <div className="organizer-card__content">
                <h3 className="organizer-card__title">
                  {event.title || 'Untitled'}
                </h3>
                <p className="organizer-card__date">
                  {event.status === 'draft' ? 'Draft' : formatDateTime(event.datetime).date}
                </p>
                <p className="organizer-card__location">
                  {event.location || 'Location not defined'}
                </p>
                <div className="organizer-card__stats">
                  {event.attendees && (
                    <span className="organizer-card__stat">
                      <Icon name="Users" size={16} /> {event.attendees} attendees
                    </span>
                  )}
                </div>
                {statusError && event.status === 'draft' && (
                  <div className="organizer-card__error">
                    <Icon name="AlertTriangle" size={16} />
                    {statusError}
                  </div>
                )}
              </div>
              <div className="organizer-card__actions" onClick={(e) => e.stopPropagation()}>
                <Button
                  variant={event.status === 'active' ? 'secondary' : 'primary'}
                  size="sm"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleStatusChange(event, event.status === 'active' ? 'draft' : 'active');
                  }}
                >
                  {event.status === 'active' ? 'Unpublish' : 'Publish'}
                </Button>
              </div>
            </div>
          </div>
        ))}
        {events.length === 0 && (
          <div className="organizer-card organizer-card--empty">
            <Icon name="Calendar" size={24} />
            <p>No events in this category</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default EventList;
