import React, { useState } from 'react';
import { Icon } from './Icon';
import Button from './Button';
import { useSpring, animated } from 'react-spring';
import '../../../styles/components/share-button.css';

interface ShareData {
  title: string;
  text: string;
  url: string;
}

interface ShareButtonProps {
  shareData: ShareData;
  onShareSuccess?: () => void;
  onShareError?: (error: Error) => void;
  className?: string;
  variant?: 'icon' | 'button';
  size?: 'sm' | 'md' | 'lg';
  shouldTriggerSuccess?: boolean; // Nouvelle prop
}

const ShareButton: React.FC<ShareButtonProps> = ({
  shareData,
  onShareSuccess,
  onShareError,
  className = '',
  variant = 'icon',
  size = 'md',
  shouldTriggerSuccess = true // Par défaut à true pour maintenir la compatibilité
}) => {
  const [isPressed, setIsPressed] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [touchStart, setTouchStart] = useState<{ x: number; y: number } | null>(null);
  
  const [{ scale }, api] = useSpring(() => ({
    scale: 1,
    config: {
      tension: 300,
      friction: 20
    }
  }));

  const handleShare = async (e?: React.MouseEvent) => {
    e?.stopPropagation();
    try {
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      
      if (isMobile) {
        if ('share' in navigator) {
          try {
            await navigator.share(shareData);
            shouldTriggerSuccess && onShareSuccess?.();
            return;
          } catch (shareError) {
            console.error('Native share failed:', shareError);
            if (shareError instanceof Error) {
              onShareError?.(shareError);
            }
          }
        }
        
        const shareText = encodeURIComponent(`${shareData.title}\n${shareData.text}\n${shareData.url}`);
        const shareUrl = `https://wa.me/?text=${shareText}`;
        window.open(shareUrl, '_blank');
        // On ne déclenche pas onShareSuccess pour WhatsApp
        return;
      }
      
      // Desktop: copier dans le presse-papier
      try {
        const shareText = `${shareData.title}\n${shareData.text}\n${shareData.url}`;
        await navigator.clipboard.writeText(shareText);
        shouldTriggerSuccess && onShareSuccess?.();
      } catch (error) {
        console.error('Copy failed:', error);
        if (error instanceof Error) {
          onShareError?.(error);
        }
      }
    } catch (error) {
      console.error('Share error:', error);
      if (error instanceof Error) {
        onShareError?.(error);
      }
    }
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    setIsPressed(true);
    setIsDragging(false);
    setTouchStart({
      x: e.touches[0].clientX,
      y: e.touches[0].clientY
    });
    api.start({ scale: 0.95 });
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (!touchStart) return;

    const deltaX = Math.abs(e.touches[0].clientX - touchStart.x);
    const deltaY = Math.abs(e.touches[0].clientY - touchStart.y);
    
    // Si le mouvement est plus grand que 5px dans n'importe quelle direction
    if (deltaX > 5 || deltaY > 5) {
      setIsDragging(true);
      setIsPressed(false);
      api.start({ scale: 1 });
    }
  };

  const handleTouchEnd = () => {
    setIsPressed(false);
    api.start({ scale: 1 });
    
    // Ne déclencher le partage que si ce n'était pas un drag
    if (!isDragging) {
      handleShare();
    }
    
    // Réinitialiser les états
    setTouchStart(null);
    setIsDragging(false);
  };

  const handleTouchCancel = () => {
    setIsPressed(false);
    setIsDragging(false);
    setTouchStart(null);
    api.start({ scale: 1 });
  };

  const handleMouseDown = () => {
    setIsPressed(true);
    api.start({ scale: 0.95 });
  };

  const handleMouseUp = () => {
    setIsPressed(false);
    api.start({ scale: 1 });
    handleShare();
  };

  if (variant === 'button') {
    return (
      <Button
        variant="outline"
        size={size}
        onClick={handleShare}
        className={className}
      >
        <Icon name="Share2" size={24} />
        Share
      </Button>
    );
  }

  const iconSize = size === 'sm' ? 16 : size === 'lg' ? 24 : 20;
  const classes = [
    'share-button',
    `share-button-${size}`,
    isPressed ? 'pressed' : '',
    className
  ].filter(Boolean).join(' ');

  return (
    <animated.button
      className={classes}
      style={{
        transform: scale.to(s => `scale(${s})`)
      }}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      onTouchCancel={handleTouchCancel}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={() => isPressed && handleMouseUp()}
      aria-label="Share"
      type="button"
    >
      <Icon name="Share2" size={iconSize} />
    </animated.button>
  );
};

export default ShareButton;