export const emptyStateMessages = [
  {
    title: "No events in sight... time to explore ! ",
    subtitle: "Try moving the map around, maybe there's a hidden gem nearby"
  },
  {
    title: "This area is a bit quiet... for now ! ",
    subtitle: "Try another spot or check back later for new adventures"
  },
  {
    title: "Empty dance floor here... ",
    subtitle: "Let's explore another area to find where the party's at !"
  },
  {
    title: "Nothing yet in this neck of the woods ",
    subtitle: "Time to be an explorer and discover new territories !"
  },
  {
    title: "This spot needs some action ! ",
    subtitle: "Maybe it's time to organize your own event here ?"
  }
];

export const getRandomEmptyStateMessage = () => {
  return emptyStateMessages[Math.floor(Math.random() * emptyStateMessages.length)];
}; 