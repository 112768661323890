import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import './HomePage.css';
import { sortEvents } from '../../utils/eventSorting';
import { useGeolocation } from '../../utils/hooks/useGeolocation';
import EventCard from '../../components/event/card';
import RecurringEventCard from '../../components/event/RecurringEventCard';
import PreviewCardMap from '../../components/event/PreviewCardMap';
import { usePublicEvents } from '../../hooks/usePublicEvents';
import { formatDate, isToday, isTomorrow, isWeekend, getTimeOfDay } from '../../utils/dateUtils';
import { Event, EVENT_TAGS } from '../../types/event.types';
import { useNavigate, Outlet } from 'react-router-dom';
import MapView from '../../components/map/MapView';
import { useNavMenu } from '../../hooks/useNavMenu';
import { useEventFilters } from '../../hooks/useEventFilters';
import { 
  BottomNav, 
  FilterDrawer, 
  type IconName, 
  type Filters,
  LocationFilter,
  AnimatedList
} from '../DesignSystem';
import { useNavigation } from '../../navigation/hooks/useNavigation';
import SwipeHeader from '../../components/SwipeHeader/SwipeHeader';
import { SwipeAction } from '../../hooks/useSwipeCard';
import LoadingScreen from '../../components/loading/LoadingScreen';
import LogoSVG from '../DesignSystem/primitives/LogoSVG';
import { eventService } from '../../firebase/event.service';
import IndexTester from '../../components/admin/IndexTester';
import { geoEventQueries } from '../../firebase/geoEventQueries';

type ViewMode = 'list' | 'map';

interface GroupedEvents {
  single: Event[];
  recurring: {
    [seriesId: string]: {
      mainEvent: Event;
      events: Event[];
    };
  };
}

const EmptyStateMessage: React.FC = () => (
  <div className="home__empty-state">
   
    <h3>No events here... yet!</h3>
    <p>
      Try another location, different tags,<br/>
      or maybe it's time for an ice cream and a massage? 🍦💆‍♂️
    </p>
    <div className="home__empty-state-icon">
      <LogoSVG 
        variant="eventConnect"
        size="auto"
      
        padding={8}
        color="var(--primary-dark)"
      />
    </div>
  </div>
);

const RELOAD_THRESHOLD = 10;

// Fonction utilitaire pour compter les favoris
const getFavoritesCount = () => {
  const now = new Date();
  return Object.keys(localStorage)
    .filter(key => {
      if (!key.startsWith('favorite-')) return false;
      try {
        const data = JSON.parse(localStorage.getItem(key) || '');
        return data.isFavorite && new Date(data.expiresAt) > now;
      } catch {
        return false;
      }
    })
    .length;
};

const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const routeLocation = useLocation();
  const { location, loading: geoLoading } = useGeolocation();
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [searchText, setSearchText] = useState('');
  const [searchCity, setSearchCity] = useState('');
  const [dateFilter, setDateFilter] = useState<string[]>([]);
  const [timeFilter, setTimeFilter] = useState<string[]>([]);
  const [distanceFilter, setDistanceFilter] = useState<number>(10);
  const [manualLocation, setManualLocation] = useState<{ 
    latitude: number; 
    longitude: number; 
  } | undefined>();
  const [viewMode, setViewMode] = useState<ViewMode>(() => {
    const savedViewMode = localStorage.getItem('lastViewMode');
    return (savedViewMode as ViewMode) || 'list';
  });
  const [lastViewMode, setLastViewMode] = useState<ViewMode>(viewMode);
  const { isOpen, toggleMenu } = useNavMenu();
  const [mapLocation, setMapLocation] = useState<{ latitude: number; longitude: number } | undefined>(undefined);
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);
  const [customDate, setCustomDate] = useState<string>('');
  const [currentLocation, setCurrentLocation] = useState<{ latitude: number; longitude: number } | undefined>(undefined);
  const [distance, setDistance] = useState(5);
  const [favoritesCount, setFavoritesCount] = useState(getFavoritesCount());
  const [hiddenEvents, setHiddenEvents] = useState<SwipeAction[]>(() => {
    try {
      return JSON.parse(localStorage.getItem('hiddenEvents') || '[]');
    } catch {
      return [];
    }
  });
  const [mapRadius, setMapRadius] = useState(5); // rayon en km pour la vue carte
  const [listRadius, setListRadius] = useState(5); // rayon en km pour la vue liste
  const [hiddenEventIds, setHiddenEventIds] = useState<string[]>([]);
  const [localEvents, setLocalEvents] = useState<Event[]>([]);
  const [isReloading, setIsReloading] = useState(false);
  const [isLoadingEvents, setIsLoadingEvents] = useState(false);

  const handleDrawerOpen = () => {
    if (routeLocation.pathname.includes('/events/') || routeLocation.pathname.includes('/event/')) {
      // On navigue vers l'accueil
      navigate('/', { replace: true });
      // On attend que la navigation soit terminée
      return new Promise<void>(resolve => {
        setTimeout(resolve, 50);
      });
    }
    return Promise.resolve();
  };

  const locationState = routeLocation.state as {
    showMultiEventModal?: boolean;
    multiEvents?: Event[];
    location?: string;
  };

  // Mettre à jour currentLocation quand location change
  useEffect(() => {
    if (location) {
      setCurrentLocation({
        latitude: location.latitude,
        longitude: location.longitude
      });
    }
  }, [location]);

  const handleViewModeChange = useCallback(() => {
    const newViewMode = viewMode === 'list' ? 'map' : 'list';
    setViewMode(newViewMode);
    setLastViewMode(newViewMode);
    // Sauvegarder dans le localStorage
    localStorage.setItem('lastViewMode', newViewMode);
  }, [viewMode, routeLocation.pathname]);

  // Surveiller les changements de route
  useEffect(() => {
    // Si on revient à l'accueil
    if (routeLocation.pathname === '/') {
      const savedViewMode = localStorage.getItem('lastViewMode') as ViewMode;
      if (savedViewMode) {
        setViewMode(savedViewMode);
        setLastViewMode(savedViewMode);
      }
    }
  }, [routeLocation.pathname]);

  const effectiveLocation = manualLocation || (location && !location.error ? {
    latitude: location.latitude,
    longitude: location.longitude
  } : undefined);

  const {
    events,
    loading: eventsLoading,
    error: eventsError,
    getDetailedEvent
  } = usePublicEvents({
    coords: effectiveLocation
  });

  // Mettre à jour manualLocation quand currentLocation change
  useEffect(() => {
    if (currentLocation) {
      setManualLocation(currentLocation);
    }
  }, [currentLocation]);

  const groupedEvents = useMemo(() => {
    const grouped: GroupedEvents = {
      single: [],
      recurring: {}
    };

    if (!events) return grouped;

    events.forEach(event => {
      if (event.seriesId) {
        if (!grouped.recurring[event.seriesId]) {
          grouped.recurring[event.seriesId] = {
            mainEvent: event,
            events: []
          };
        }
        grouped.recurring[event.seriesId].events.push(event);
      } else {
        grouped.single.push(event);
      }
    });

    return grouped;
  }, [events]);

  // Charger les événements pour la vue actuelle
  useEffect(() => {
    const loadEvents = async () => {
      if (!effectiveLocation) return;
      
      try {
        const radius = viewMode === 'map' ? 10 : 30;
        const loadedEvents = await geoEventQueries.getEventsInZone(effectiveLocation, radius);
        setLocalEvents(loadedEvents);
      } catch (error) {
        console.error('Erreur lors du chargement des événements:', error);
      }
    };

    loadEvents();
  }, [effectiveLocation, viewMode]);

  // Gestionnaire pour l'ouverture de la modale
  const handleEventClick = useCallback(async (event: Event, seriesEvents?: Event[]) => {
    try {
      if (event.seriesId) {
        navigate(`/series/${event.seriesId}`, {
          state: {
            isMapView: viewMode === 'map',
            userLocation: effectiveLocation,
            event: event,
            seriesEvents: seriesEvents,  // Passer les seriesEvents ici
            returnToMulti: true,
            multiEvents: events,
            location: searchCity || 'nearby'
          }
        });
        return;
      }

      navigate(`/event/${event.id}`, {
        state: {
          isMapView: viewMode === 'map',
          userLocation: effectiveLocation,
          returnToMulti: true,
          multiEvents: events,
          location: searchCity || 'nearby'
        }
      });
    } catch (error) {
      console.error('Error navigating to event:', error);
    }
  }, [navigate, viewMode, effectiveLocation, events, searchCity]);

  const handleMultiModalClose = () => {
    navigate('/', { replace: true });
  };

  const handleMultiEventClick = (event: Event) => {
    navigate(`/event/${event.id}`, {
      state: {
        isMapView: viewMode === 'map',
        userLocation: effectiveLocation,
        returnToMulti: true,
        multiEvents: locationState.multiEvents,
        location: locationState.location
      }
    });
  };

  const handleDateFilterChange = (dates: string[]) => {
    setDateFilter(dates);
  };
  
  const handleTimeFilterChange = (times: string[]) => {
    setTimeFilter(times);
  };

  const handleDistanceFilterChange = (distance: number) => {
    setDistanceFilter(distance);
  };

  const handleLocationChange = useCallback((newLocation: { latitude: number; longitude: number }) => {
    setManualLocation(newLocation);
  }, []);

  const getEventDate = (event: Event): Date | null => {
    if (!event.datetime) return null;
    const date = new Date(event.datetime);
    return isNaN(date.getTime()) ? null : date;
  };

  const handleTagSelect = (tagId: string) => {
    setSelectedTags(prev => 
      prev.includes(tagId) 
        ? prev.filter(id => id !== tagId)
        : [...prev, tagId]
    );
  };

  const filteredEvents = useEventFilters(events, {
    searchText,
    searchCity,
    selectedTags,
    dateFilter,
    timeFilter,
    customDate,
    distance,
    userLocation: effectiveLocation
  });

  // Fonction utilitaire pour regrouper les événements récurrents
  const groupRecurringEvents = useCallback((events: Event[]) => {
    const grouped: Event[] = [];
    const seenSeriesIds = new Set<string>();

    events.forEach(event => {
      if (event.seriesId) {
        if (!seenSeriesIds.has(event.seriesId)) {
          seenSeriesIds.add(event.seriesId);
          grouped.push(event);
        }
      } else {
        grouped.push(event);
      }
    });

    return grouped;
  }, []);

  // Les événements pour la carte (sans filtrage des événements masqués)
  const mapEvents = useMemo(() => {
    return groupRecurringEvents(filteredEvents);
  }, [filteredEvents, groupRecurringEvents]);

  // Les événements pour la liste (avec filtrage des événements masqués et favoris)
  const listEvents = useMemo(() => {
    // 1. Filtrer les événements masqués et favoris
    const visibleEvents = filteredEvents.filter(event => {
      const isDismissed = hiddenEvents.some(
        hidden => (hidden.eventId === event.id || hidden.eventId === event.seriesId) && 
        hidden.reason === 'dismissed'
      );

      const isFavorite = (() => {
        try {
          const favoriteKey = `favorite-${event.seriesId || event.id}`;
          const savedFavorite = localStorage.getItem(favoriteKey);
          if (!savedFavorite) return false;
          const favoriteData = JSON.parse(savedFavorite);
          return favoriteData.isFavorite && new Date(favoriteData.expiresAt) > new Date();
        } catch {
          return false;
        }
      })();

      return !isDismissed && !isFavorite;
    });

    // 2. Grouper les événements récurrents
    const groupedVisibleEvents = groupRecurringEvents(visibleEvents);

    // 3. Trier les événements
    const sortedEvents = sortEvents(
      groupedVisibleEvents,
      viewMode,
      effectiveLocation,
      distanceFilter
    );

    return sortedEvents.map(event => {
      if (event.seriesId && groupedEvents.recurring[event.seriesId]) {
        return {
          mainEvent: event,
          seriesEvents: groupedEvents.recurring[event.seriesId].events
        };
      }
      return { mainEvent: event, seriesEvents: [] };
    });
  }, [filteredEvents, hiddenEvents, groupedEvents, viewMode, effectiveLocation, distanceFilter, groupRecurringEvents]);

  // Gestionnaire pour le changement de centre de la carte
  const handleMapCenterChange = useCallback(async (newCenter: { latitude: number; longitude: number }) => {
    

    try {
      // Charger les événements
      const loadedEvents = await geoEventQueries.getEventsInZone(newCenter, mapRadius);
      
      // Attendre que l'animation soit terminée avant de mettre à jour
      setTimeout(() => {
        setLocalEvents(loadedEvents);
        
      }, 500);
    } catch (error) {
      console.error('Erreur lors du chargement des événements:', error);
      
    }
  }, [mapRadius]);

  // Gérer le bouton retour sur la page d'accueil
  useEffect(() => {
    // Ne s'applique que sur la route principale, pas sur les modales
    if (routeLocation.pathname === '/') {
      // Ajouter une entrée d'historique vide pour la gestion du retour
      window.history.pushState(null, '', window.location.pathname);

      const handlePopState = () => {
        // Si on est sur la page d'accueil sans modale, quitter l'application
        if (routeLocation.pathname === '/') {
          window.close();
          // Fallback pour les navigateurs qui bloquent window.close()
          window.history.go(-1);
        }
      };

      window.addEventListener('popstate', handlePopState);

      return () => {
        window.removeEventListener('popstate', handlePopState);
      };
    }
  }, [routeLocation.pathname]);

  // Gestion des filtres
  const handleFiltersApply = (filters: Filters) => {
    if (filters.where.city) {
      setSearchCity(filters.where.city);
    }
    if (filters.where.location) {
      setCurrentLocation(filters.where.location);
    }
    setDateFilter(filters.when.dates);
    setTimeFilter(filters.when.times);
    setSelectedTags(filters.what.tags);
    // Fermer le drawer
    setIsFilterPanelOpen(false);
  };

  // Comparaisons mises à jour pour les tableaux
  const hasDateFilter = dateFilter.length > 0;
  const hasTimeFilter = timeFilter.length > 0;

  const { getNavigationItems } = useNavigation('public');
  
  const navigationItems = getNavigationItems({
    viewMode,
    favoritesCount,
    onViewModeChange: handleViewModeChange
  });

  // Mettre à jour le compteur de favoris quand le localStorage change
  useEffect(() => {
    const handleStorageChange = () => {
      handleFavoritesUpdate();
    };

    const handleFavoritesUpdate = () => {
      // On ne garde que les mises à jour essentielles
      setHiddenEvents(prev => [...prev]);
      setFavoritesCount(getFavoritesCount());
      // On retire les mises à jour qui peuvent créer une boucle
      // setSearchText(prev => prev);
      // setViewMode(prev => prev);
    };

    // Ajouter les event listeners
    window.addEventListener('storage', handleStorageChange);
    window.addEventListener('favoritesUpdated', handleFavoritesUpdate);

    // Cleanup
    return () => {
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener('favoritesUpdated', handleFavoritesUpdate);
    };
  }, []); // Dépendances vides car on veut l'effet une seule fois

  const handleEventDismiss = useCallback((eventId: string) => {
    const newHiddenEvent: SwipeAction = {
      eventId,
      reason: 'dismissed',
      timestamp: Date.now()
    };
    setHiddenEvents(prev => [...prev, newHiddenEvent]);
    // Mise à jour du localStorage
    try {
      localStorage.setItem('hiddenEvents', JSON.stringify([...hiddenEvents, newHiddenEvent]));
    } catch (error) {
      console.error('Error updating localStorage:', error);
    }
  }, [hiddenEvents]);

  const handleEventFavorite = useCallback((eventId: string) => {
    const newHiddenEvent: SwipeAction = {
      eventId,
      reason: 'favorited',
      timestamp: Date.now()
    };
    setHiddenEvents(prev => [...prev, newHiddenEvent]);
    // Mise à jour du localStorage
    try {
      localStorage.setItem('hiddenEvents', JSON.stringify([...hiddenEvents, newHiddenEvent]));
    } catch (error) {
      console.error('Error updating localStorage:', error);
    }
  }, [hiddenEvents]);

  const handleUndo = useCallback(() => {
    const dismissedEvents = hiddenEvents.filter(
      event => event.reason === 'dismissed'
    );
    
    if (dismissedEvents.length > 0) {
      const lastDismissed = dismissedEvents[dismissedEvents.length - 1];
      setHiddenEvents(prev => 
        prev.filter(event => event.eventId !== lastDismissed.eventId)
      );
    
      // Mise à jour du localStorage
    try {
        localStorage.setItem('hiddenEvents', JSON.stringify(
          hiddenEvents.filter(event => event.eventId !== lastDismissed.eventId)
        ));
    } catch (error) {
        console.error('Error updating localStorage:', error);
    }
    }
  }, [hiddenEvents]);

  // Mise à jour du rendu des événements
  const renderEvents = () => (
    <AnimatedList>
      {listEvents.map(({ mainEvent, seriesEvents }) => {
        if (mainEvent.seriesId && groupedEvents.recurring[mainEvent.seriesId]) {
          return (
            <RecurringEventCard
              key={mainEvent.seriesId}
              mainEvent={mainEvent}
              seriesEvents={seriesEvents}
              userLocation={effectiveLocation}
              isMapView={viewMode === 'map'}
              onDismiss={handleEventDismiss}
              onFavorite={handleEventFavorite}
            />
          );
        } else {
          return (
            <EventCard 
              key={mainEvent.id} 
              event={mainEvent}
              seriesEvents={mainEvent.seriesId ? seriesEvents : undefined}
              userLocation={effectiveLocation}
              onDismiss={handleEventDismiss}
              onFavorite={handleEventFavorite}
              onClick={() => handleEventClick(mainEvent, seriesEvents)}
            />
          );
        }
      })}
    </AnimatedList>
  );

  const handleEventHide = async (eventId: string) => {
    try {
      // Sauvegarder l'événement caché
      const newHiddenEvent = {
        eventId,
        reason: 'hidden',
        timestamp: Date.now()
      };
      
      const stored = JSON.parse(localStorage.getItem('hiddenEvents') || '[]');
      localStorage.setItem('hiddenEvents', JSON.stringify([...stored, newHiddenEvent]));
      
      // Recharger les événements si nécessaire
      if (stored.length >= 10 && effectiveLocation) {
        const newEvents = await geoEventQueries.getEventsInZone(
          effectiveLocation,
          5,
          stored.map((e: any) => e.eventId)
        );
        if (newEvents.length > 0) {
          window.dispatchEvent(new CustomEvent('hiddenEventsUpdated'));
        }
      }
    } catch (error) {
      console.error('Error hiding event:', error);
    }
  };

  const handleLocationSelect = (location: { latitude: number; longitude: number }) => {
    setIsLoadingEvents(true);
    setManualLocation(location);
  };

  const handleEventsLoaded = () => {
    setIsLoadingEvents(false);
  };

  if (!effectiveLocation || isLoadingEvents) {
    return (
      <LoadingScreen 
        isGeolocating={geoLoading}
        isLoadingEvents={isLoadingEvents}
        onLocationSelect={handleLocationSelect}
        onEventsLoaded={handleEventsLoaded}
        geoError={!effectiveLocation ? "Please select your location to see events" : undefined}
        currentLocation={effectiveLocation}
      />
    );
  }

  return (
    <div className="home">
      {/* Testeur d'index maintenant visible */}
      <IndexTester isVisible={false} />
      
      <div className="home__content">
        <div className="home__views">
          <div className={`home__list-view ${viewMode === 'list' ? 'home__list-view--active' : ''}`}>
            <SwipeHeader onUndo={handleUndo} />
            {listEvents.length === 0 ? (
              <EmptyStateMessage />
            ) : (
              renderEvents()
            )}
          </div>

          <div className={`home__map-view ${viewMode === 'map' ? 'home__map-view--active' : ''}`}>
           
            
            <MapView 
              events={mapEvents}
              onEventClick={handleEventClick}
              onLocationChange={handleMapCenterChange}
              initialLocation={effectiveLocation || undefined}
              onMapClick={handleMultiModalClose}
            />
          </div>
        </div>

        <BottomNav 
          items={navigationItems} 
          onBeforeDrawerOpen={handleDrawerOpen}
          filterProps={{
            viewMode,
            searchText,
            onSearchTextChange: setSearchText,
            searchCity,
            setSearchCity,
            currentLocation,
            onLocationChange: setCurrentLocation,
            distance,
            onDistanceChange: setDistance,
            selectedDates: dateFilter,
            onDateChange: setDateFilter,
            selectedTimes: timeFilter,
            onTimeChange: setTimeFilter,
            selectedTags,
            onTagSelect: handleTagSelect,
            onReset: () => {
              // Rien à logger ici
            }
          }}
        />
      </div>

      <Outlet />
    </div>
  );
};

export default HomePage;
