import React from 'react';
import '../../../styles/components/step-indicator.css';

interface Step {
  title: string;
  description: string;
}

interface StepIndicatorProps {
  steps: Step[];
  currentStep: number;
}

const StepIndicator: React.FC<StepIndicatorProps> = ({ steps, currentStep }) => {
  const isStepActive = (index: number) => index === currentStep;
  const isStepCompleted = (index: number) => index < currentStep;

  return (
    <div className="step-indicator" data-step={currentStep}>
      {steps.map((step, index) => (
        <div
          key={index}
          className={`step-item ${isStepActive(index) ? 'active' : ''} ${
            isStepCompleted(index) ? 'completed' : ''
          }`}
          aria-current={isStepActive(index) ? 'step' : undefined}
          aria-label={`Step ${index + 1}: ${step.title}`}
        >
          <div className="step-circle">
            <span className="step-number" aria-hidden="true">{index + 1}</span>
          </div>
          <div className="step-content">
            <div className="step-label">{step.title}</div>
            {step.description && (
              <div className="step-description">{step.description}</div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default StepIndicator; 